import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { handoffsSplits } from "../constants";
import {
	getAllAccountData,
	getFetcher,
	getSellerantUsers,
	getTemplates,
	getUserInfo,
	requestPost,
} from "../requests";
import { getIconOptionsFromUsers, handleCaughtError } from "../utils";

const HandoffsContext = React.createContext();

const HandoffsContextWrapper = ({ children }) => {
	const [waiting, setWaiting] = useState(true);
	const [backPage, setBackPage] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [splitIndex, setSplitIndex] = useState(null);
	const [connected, setConnected] = useState([]);
	const [nylasData, setNylasData] = useState(null);
	const [splits, setSplits] = useState(handoffsSplits);
	const [userOptions, setUserOptions] = useState([]);
	let { page } = useParams();

	// Data Hooks

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	const {
		data: sellerantUsers,
		loading: usersLoading,
		error: usersError,
	} = getSellerantUsers();

	const { data: accountsData } = getAllAccountData();

	const {
		data: templatesData,
		isLoading: templatesIsLoading,
		isError: templatesError,
		mutate,
	} = getTemplates();

	// Effects

	useEffect(() => {
		setSplitIndex(
			handoffsSplits.findIndex((split) => split.id.toLowerCase() === page)
		);
	}, [page]);

	useEffect(() => {
		if (userData) {
			getNylasStatus();
			if (userData.admin) {
				setSplits(handoffsSplits);
			} else {
				setSplits(handoffsSplits);
			}
		}
	}, [userData]);

	useEffect(() => {
		if (userData) {
			getConnectedIntegrations();
		} else {
			setWaiting(true);
		}
	}, [userData]);

	useEffect(() => {
		if (!usersLoading && !usersError) {
			setUserOptions(getIconOptionsFromUsers(sellerantUsers.data));
		}
	}, [sellerantUsers]);
	// Mutations/Requests

	const getNylasStatus = async () => {
		try {
			const data = await getFetcher("/api/email/fetchAuthUrl");
			setNylasData(data);
		} catch (err) {
			handleCaughtError(err);
		}
	};

	const getConnectedIntegrations = async () => {
		try {
			let res = await requestPost("/api/generateParagonJWT", {
				userEmail: userData.email,
			});

			await paragon.authenticate(
				"8e2ac7c3-6825-4ab2-8c86-6e148dd00ecf",
				res
			);
			let user = await paragon.getUser();
			setConnected(user.integrations);
			setWaiting(false);
		} catch (err) {
			handleCaughtError(err);
		}
	};

	return (
		<HandoffsContext.Provider
			value={{
				waiting,
				setWaiting,
				backPage,
				setBackPage,
				menuOpen,
				setMenuOpen,
				splitIndex,
				setSplitIndex,
				connected,
				setConnected,
				nylasData,
				setNylasData,
				splits,
				setSplits,

				userData,
				userDataLoading,
				userDataError,
				userOptions,
				accountsData,
				templatesData,

				getNylasStatus,
				getConnectedIntegrations,
			}}
		>
			{children}
		</HandoffsContext.Provider>
	);
};

export { HandoffsContext, HandoffsContextWrapper };

import React from "react";

import { ReactComponent as High1 } from "../../assets/high1.svg";
import { ReactComponent as High2 } from "../../assets/high2.svg";
import { ReactComponent as High3 } from "../../assets/high3.svg";

const HighPriorityIcon = () => (
	<div className="flex items-end">
		<High1 />
		<High2 />
		<High3 />
	</div>
);

export default HighPriorityIcon;

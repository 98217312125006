import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useHotkeys } from "react-hotkeys-hook";
import { BsClock } from "react-icons/bs";

import Routes from "../../Routes";
import { ProgressBar } from "..";
import { Grade } from "..";
import { callWithoutModifiers } from "../../utils";
import { ResolveButton, SidebarInfoSection } from "../Sidebar";

const progressItems = [
	{ ApiName: "Initial Contact" },
	{ ApiName: "Meeting Scheduled" },
	{ ApiName: "Proposal Made" },
	{ ApiName: "Negotiation" },
	{ ApiName: "Closed" },
];

const CtaTab = ({ label, active = false, onClick }) => {
	return (
		<div
			onClick={onClick}
			className={`flex flex-row justify-center items-center h-8 min-w-20 px-2 rounded hover:bg-hovergray cursor-pointer truncate
				${active ? "body-small-semibold-black" : "body-small-semibold-gray"}`}
		>
			<p>{label}</p>
		</div>
	);
};

const CtaHeader = ({
	currentCtaData,
	tabs,
	grade,
	title,
	subtitle,
	logo,
	stage,
	activePage,
	skipLastTab = false,
	onClickSnooze,
	onClickCommandK,
	hideInformation,
	onClickResolve,
	variant = "cta",
	details,
	setDetails,
	mutateDetails,
	mutateSalesforceStage,
	resolved,
}) => {
	const [progressIndex, setProgressIndex] = useState(1);
	let history = useHistory();
	let location = useLocation();

	const [currentTabIndex, setCurrentTabIndex] = useState(
		tabs.findIndex((item) => item.page === activePage)
	);

	const cycleTabs = () => {
		let nextIndex = currentTabIndex + 1;
		let tabsLength = tabs.length;
		if (skipLastTab) tabsLength--;
		if (currentTabIndex === tabsLength - 1) {
			nextIndex = 0;
		}
		if (currentTabIndex === -1) {
			nextIndex = 1;
		}
		history.push(tabs[nextIndex].url);
		setCurrentTabIndex(nextIndex);
	};

	useEffect(() => {
		setCurrentTabIndex(tabs.findIndex((item) => item.page === activePage));
	}, [location]);

	useEffect(() => {
		if (currentCtaData?.type?.data?.StageName) {
			currentCtaData.type.stages.forEach((stage, index) => {
				if (stage.ApiName == currentCtaData?.type?.data?.StageName) {
					setProgressIndex(index);
				}
			});
		}
	}, [currentCtaData]);

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Tab":
					event.preventDefault();
					callWithoutModifiers(event, () => cycleTabs());
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[activePage, currentTabIndex, location]
	);

	return !hideInformation ? (
		<div className="flex flex-col flex-none w-full h-auto pt-6 pb-3 pr-6">
			{/* Top Row */}
			<div className="flex flex-row justify-between items-center">
				{/* Company Name + Resolve Button */}
				<div className="flex flex-row items-center mt-2">
					{logo ? (
						<img src={logo} className="rounded h-8 w-8" />
					) : (
						grade && <Grade grade={grade} size={32} text="" />
					)}

					<p className="mt-0.5 pl-3 h3 truncate">{title}</p>
					<div className="ml-3">
						<ResolveButton
							label={stage}
							variant="Adopting"
							style={{ height: "25px" }}
							onClick={() =>
								history.push(
									`${Routes.SEARCH_PAGE}/stage:${stage}`
								)
							}
						/>
					</div>
				</div>

				{/* Commands */}
				<div className="flex flex-row justify-between items-center pt-2 flex-none w-36">
					{!resolved && onClickSnooze && (
						<BsClock
							size={22}
							className="text-gray-400 cursor-pointer hover:text-gray-500"
							onClick={onClickSnooze}
						/>
					)}
					{onClickCommandK && (
						<ResolveButton
							label="⌘K"
							variant="⌘K"
							style={{ borderWidth: "1px" }}
							onClick={onClickCommandK}
						/>
					)}
					{onClickResolve && (
						<ResolveButton
							label={resolved ? "View Report" : "Resolve"}
							variant={resolved ? "View" : "Resolve"}
							onClick={onClickResolve}
						/>
					)}
				</div>
			</div>

			{/* Grade + Cta Name */}
			{variant == "cta" && (
				<div className="flex flex-row items-center my-4">
					{grade && (
						<div className="justify-self-center self-end">
							<Grade grade={grade} size={32} text="" />
						</div>
					)}
					<p className="h4 ml-3">{subtitle}</p>
				</div>
			)}

			{/* Progress Bar */}
			{currentCtaData?.type?.type == "expansion" && (
				<div className="flex justify-center items-center flex-none w-full h-24 bg-lightgray rounded ">
					<ProgressBar
						height={40}
						width={900}
						items={
							currentCtaData?.type?.stages
								? currentCtaData?.type?.stages
								: progressItems
						}
						progress={progressIndex}
						setProgress={(index) => {
							if (index === progressIndex) return;
							setProgressIndex(index);
							if (currentCtaData?.type?.stages) {
								mutateSalesforceStage(
									currentCtaData.type?.stages[index]
								);
							}
						}}
					/>
				</div>
			)}

			{/* Details */}
			{variant == "cta" && (
				<SidebarInfoSection
					title="Details"
					content={details}
					onBlur={mutateDetails}
					readOnly={
						variant === "actionItems" || variant === "accounts"
					}
					variant={"cta"}
				/>
			)}

			{/* Tabs */}
			<div className="flex flex-row justify-start items-center w-full mt-4 px-3 h-12 bg-lightgray rounded">
				{tabs.map((item, index) => (
					<div className="mr-1" key={index}>
						<CtaTab
							label={item.name}
							active={item.page === activePage}
							onClick={() => history.push(item.url)}
						/>
					</div>
				))}
			</div>
		</div>
	) : (
		<div className="w-full flex">
			<div className="flex flex-row mx-auto my-2 justify-start items-end w-11/12">
				{tabs.map((item, index) => (
					<div className="mr-1" key={index}>
						<CtaTab
							label={item.name}
							active={item.page === activePage}
							onClick={() => history.push(item.url)}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default CtaHeader;

import useSWR from "swr";

import getFetcher from "./getFetcher";

const getTags = () => {
	const { data, error, mutate } = useSWR("/api/getTags", getFetcher);

	return {
		data: data,
		loading: !error && !data,
		error: error,
		mutate,
	};
};

export default getTags;

import React from "react";
import { useHistory } from "react-router-dom";

const PageHeader = ({ splitIndex, splits }) => {
	let history = useHistory();

	return (
		<div className="flex flex-row h-10 items-center">
			{splits.map((item, index) => (
				<div
					key={index}
					className={`pr-6 text-lg font-sans font-semibold truncate cursor-pointer focus:outline-none select-none ${
						splitIndex === index ? "text-black" : "text-gray-400"
					}`}
					onClick={() => history.push(item.route)}
				>
					{item.title}
				</div>
			))}
		</div>
	);
};

export default PageHeader;

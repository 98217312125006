import axios from "axios";
import { handleCaughtError } from "../utils";

import bounceIfTokenError from "./bounceIfTokenError";

const settingsUrl = async () => {
	try {
		const domain = "foo-corp.com";
		const url = `/api/admin/portal?domain=${domain}`;
		let res = await axios.get(url, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		window.location = res.data.endpoint;
	} catch (err) {
		handleCaughtError(err);
		bounceIfTokenError(err);
	}
};

export default settingsUrl;

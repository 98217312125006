import React, { useRef, useState } from "react";

function PhaseTitle({ title, onBlur }) {
	const [input, setInput] = useState(title);
	const inputRef = useRef(null);

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			inputRef.current.blur();
		}
	};

	return (
		<div className="flex p-2">
			<p className="mr-2 select-none">Phase:</p>
			<input
				ref={inputRef}
				className="text-gray-700 font-normal bg-transparent border-b-2 border-indigo-400 focus:border-indigo-800 outline-none"
				value={input}
				onChange={(e) => setInput(e.target.value)}
				onBlur={onBlur ? () => onBlur(input) : null}
				onKeyDown={onKeyDown}
			/>
		</div>
	);
}

export default PhaseTitle;

import { Route } from "react-router-dom";

import { ErrorBoundary } from "../errors";
import PrivateRoute from "./PrivateRoute";

const CustomRoute = ({ privateRoute, children, ...rest }) => {
	if (privateRoute) {
		return <PrivateRoute {...rest}>{children}</PrivateRoute>;
	} else {
		return <Route {...rest}>{children}</Route>;
	}
};

const ErrorBoundaryRoute = ({
	children,
	path,
	fallbackRoute,
	location,
	exact = false,
	privateRoute = false,
}) => (
	<CustomRoute privateRoute={privateRoute} exact={exact} path={path}>
		<ErrorBoundary location={location} fallbackRoute={fallbackRoute}>
			{children}
		</ErrorBoundary>
	</CustomRoute>
);
export default ErrorBoundaryRoute;

import React, { useContext, useState } from "react";
import { Switch, useHistory } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ReactComponent as Test } from "../assets/test.svg";

const CustomerDataRoom = withRouter(({ match, history }) => {
	return (
		<div>
			<Test></Test>
		</div>
	);
});

export default CustomerDataRoom;

import useSWR from "swr";

import getFetcher from "./getFetcher";

const getUserInfo = () => {
	const url = `/api/user/getInfo?email=${localStorage.getItem("user_email")}`;
	const { data, error } = useSWR(url, getFetcher);

	if (data) {
		data.salesforce_id = "0053t000008yMESAA2";
		localStorage.setItem("user_id", data.user_id);
		const full_name = data.first_name + " " + data.last_name;
		localStorage.setItem("full_name", full_name);
	} else {
		localStorage.setItem("user_id", false);
		localStorage.setItem("full_name", false);
	}

	return {
		data: data,
		loading: !error && !data,
		error: error,
	};
};

export default getUserInfo;

import React, { useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IoClose } from "react-icons/io5";

import { ResponsiveTextArea } from "..";
import { effectOptions } from "../../constants";
import { callWithModifiers, callWithoutModifiers } from "../../utils";
import ConfirmModal from "./ConfirmModal";

const HealthEffectRadio = ({
	currentEffect,
	setCustomerEffect,
	customerEffect,
	readOnly = false,
}) => (
	<div className="flex flex-row items-center w-full my-1">
		<input
			type="radio"
			name="effect"
			onChange={
				readOnly ? () => {} : (e) => setCustomerEffect(e.target.value)
			}
			value={currentEffect.value}
			className="mr-2 cursor-pointer"
			checked={currentEffect.value === customerEffect}
		/>
		<label
			className="cursor-pointer"
			htmlFor={currentEffect.value}
			onClick={
				readOnly
					? () => {}
					: () => setCustomerEffect(currentEffect.value)
			}
		>
			<span>{currentEffect.title}</span>
		</label>
	</div>
);

const ResolveFormModal = ({
	title = "",
	setModalOpen,
	onClickSave,
	onClickResolve,
	resReport,
	resolved = false,
}) => {
	let modalRef = useRef(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [problem, setProblem] = useState(resReport.problem);
	const [stepsTaken, setStepsTaken] = useState(resReport.steps_taken);
	const [resolution, setResolution] = useState(resReport.resolution);
	const [notes, setNotes] = useState(resReport.notes);
	const [customerEffect, setCustomerEffect] = useState(
		resReport.health_effect
	);

	// Event Handlers

	const handleSave = () => {
		onClickSave(problem, stepsTaken, resolution, customerEffect, notes);
		setModalOpen(false);
	};

	const handleResolve = () => {
		onClickResolve(problem, stepsTaken, resolution, customerEffect, notes);
		setModalOpen(false);
	};

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(e, () => handleResolve(), ["cmd"]);
		}
	};

	// Hot Keys

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Enter":
					callWithModifiers(event, () => handleResolve(), ["cmd"]);
					break;
				case "Escape":
					callWithoutModifiers(event, () =>
						setConfirmModalOpen(true)
					);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	return (
		<div
			className="relative flex flex-col justify-start items-center shadow-xl rounded-lg"
			style={{ top: "10vh" }}
		>
			<div
				className="flex flex-col flex-grow justify-start items-center p-5 rounded-lg border border-gray-100 bg-white z-10"
				style={{ width: "500px" }}
				ref={modalRef}
			>
				{/* Close Button */}
				<div
					className="absolute top-0 right-0 p-4"
					onClick={
						resolved
							? () => setModalOpen(false)
							: () => setConfirmModalOpen(true)
					}
				>
					<IoClose
						className="text-gray-400 hover:text-gray-500 cursor-pointer"
						size={20}
					/>
				</div>

				{/* Modal Title */}
				<div className="flex justify-start items-center flex-none w-full">
					<p className="pr-2 pb-2 font-bold text-base text-gray-800 truncate">
						Resolution Report - {title}
					</p>
				</div>

				{/* Problem Section */}
				<div className="w-full my-3">
					<ResponsiveTextArea
						title="Problem"
						setText={setProblem}
						text={problem}
						onKeyDown={onKeyDown}
						readOnly={resolved}
					/>
				</div>

				{/* Steps Taken Section */}
				<div className="w-full my-3">
					<ResponsiveTextArea
						title="Steps Taken"
						setText={setStepsTaken}
						text={stepsTaken}
						onKeyDown={onKeyDown}
						readOnly={resolved}
					/>
				</div>

				{/* Resolution Section */}
				<div className="w-full my-3">
					<ResponsiveTextArea
						title="Resolution"
						setText={setResolution}
						text={resolution}
						onKeyDown={onKeyDown}
						readOnly={resolved}
					/>
				</div>

				{/* Effect on Customer Health Section */}
				<div className="w-full my-3">
					<div className="flex flex-row justify-start items-center w-full mb-2 text-sm">
						<p>Effect on Customer Health</p>
					</div>
					<div className="flex flex-col flex-grow w-full py-2 px-3 outline-none shadow rounded font-normal text-gray-900 text-xs">
						{effectOptions.map((effect) => (
							<HealthEffectRadio
								currentEffect={effect}
								customerEffect={customerEffect}
								setCustomerEffect={setCustomerEffect}
								readOnly={resolved}
							/>
						))}
					</div>
				</div>

				{/* Notes Section */}
				<div className="w-full my-3">
					<ResponsiveTextArea
						title="Additional Notes"
						setText={setNotes}
						text={notes}
						onKeyDown={onKeyDown}
						readOnly={resolved}
					/>
				</div>

				{/* Save/Resolve Buttons */}
				{!resolved && (
					<div className="flex flex-row justify-end items-center w-full">
						<button
							className="px-5 mt-2 rounded border whitespace-nowrap h-8 border-gray-400 text-center text-gray-500 text-sm mr-3"
							onClick={handleSave}
						>
							Save
						</button>
						<button
							className="h-8 px-5 mt-2 rounded border whitespace-nowrap bg-blue-400 text-center text-white text-sm"
							onClick={handleResolve}
						>
							Resolve
						</button>
					</div>
				)}
			</div>
			<div
				style={{
					position: "absolute",
					top: "30%",
				}}
			>
				{confirmModalOpen && (
					<ConfirmModal
						title="Are you sure you want to close the form without saving?"
						buttonLabel="Close without saving"
						onClick={() => setModalOpen(false)}
						setModalOpen={setConfirmModalOpen}
					/>
				)}
			</div>
		</div>
	);
};

export default ResolveFormModal;

import useSWR from "swr";

import getFetcher from "./getFetcher";

const getSalesforceFieldOptions = () => {
	const { data, error } = useSWR(
		"/api/handoffs/getSalesforceFieldOptions",
		getFetcher
	);

	return {
		data: data,
		loading: !error && !data,
		error: error,
	};
};

export default getSalesforceFieldOptions;

import * as Sentry from "@sentry/react";
import { useHistory } from "react-router";

import ErrorPage from "./ErrorPage";

const ErrorBoundary = ({ children, location, fallbackRoute }) => {
	let history = useHistory();

	return (
		<Sentry.ErrorBoundary
			beforeCapture={(scope) => scope.setTag("location", location)}
			fallback={({ resetError }) => (
				<ErrorPage
					onClickReturn={() => {
						resetError();
						history.push(fallbackRoute);
					}}
				/>
			)}
		>
			{children}
		</Sentry.ErrorBoundary>
	);
};

export default ErrorBoundary;

import { forwardRef, useState } from "react";
import { HiOutlineCalendar } from "react-icons/hi";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./datePickerStyle.css";

import TaskButton from "./TaskButton";

const formatDate = (date) => {
	if (isToday(date)) {
		return "Today";
	}
	return `${date.toLocaleString("default", {
		month: "short",
	})} ${date.getDate()}`;
};

const isToday = (someDate) => {
	const today = new Date();
	return (
		someDate.getDate() === today.getDate() &&
		someDate.getMonth() === today.getMonth() &&
		someDate.getFullYear() === today.getFullYear()
	);
};

const CustomDatePicker = ({ selectedDate, setSelectedDate, newTodo }) => {
	const [startDate, setStartDate] = useState(selectedDate ?? new Date());
	const [formattedDate, setFormattedDate] = useState(
		selectedDate ? formatDate(selectedDate) : "Date"
	);

	const DateButton = forwardRef(({ value, onClick }, ref) => (
		<span
			onClick={onClick}
			className={`text-xs flex flex-row pt-0.5 justify-center cursor-pointer hover:text-blue-400 ${
				formattedDate === "Today" ? "text-indigo-500" : "text-gray-400"
			}`}
		>
			<HiOutlineCalendar className="ml-2 mr-1" size={14} />
			<p className="hover:underline">{formattedDate}</p>
		</span>
	));

	const NewTodoDateButton = forwardRef(({ value, onClick }, ref) => (
		<TaskButton
			icon={<HiOutlineCalendar size={14} />}
			label={selectedDate ? formattedDate : "Due date"}
			onClick={onClick}
			colorClasses="bg-gray-100 hover:bg-gray-200"
		/>
	));

	return (
		<DatePicker
			renderCustomHeader={({
				monthDate,
				increaseMonth,
				decreaseMonth,
			}) => (
				<header className="flex flex-row m-2 justify-between">
					<span className="react-datepicker__current-month">
						{monthDate.toLocaleString("en-US", {
							month: "long",
							year: "numeric",
						})}
					</span>
					<span className={""}>
						<button
							aria-label="Previous Month"
							onClick={decreaseMonth}
							className="react-datepicker__navigation--previous"
						>
							<span>
								<IoIosArrowBack
									className="text-gray-400 ml-2 mr-1"
									size={14}
								/>
							</span>
						</button>
						<button
							aria-label="Next Month"
							onClick={increaseMonth}
							className="react-datepicker__navigation--next"
						>
							<span>
								<IoIosArrowForward
									className="text-gray-400 ml-2 mr-1"
									size={14}
								/>
							</span>
						</button>
					</span>
				</header>
			)}
			selected={startDate}
			onChange={(date) => {
				setStartDate(date);
				setFormattedDate(formatDate(date));
				setSelectedDate(date);
			}}
			customInput={!newTodo ? <DateButton /> : <NewTodoDateButton />}
			// shouldCloseOnSelect={false}
			// fixedHeight
		/>
	);
};

export default CustomDatePicker;

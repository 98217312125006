import React, { useState, useEffect } from "react";

import MainGrid from "../Grid";

import userInfo from "../../../requests/swr/getUserInfo";

import { useHistory } from "react-router-dom";

import ActiveTicketUpdate from "../../data/postZendeskInfo";
import fetchSupportData from "../../data/getSupportTicketData";
import fetchUsersInfo from "../../data/ZendeskFunctions/fetchUserDataZendesk";
const ViewTickets = ({ mutateTicketData, zendeskData }) => {
	const [authenticated, setAuthenticated] = useState(
		localStorage.getItem("access_token") ? true : false
	);
	const [userNameDatabase, setUserNameDatabase] = useState([]);

	const history = useHistory();

	//Fetch data from supabase

	//Trigger zendesk-paragon flow

	//fetch the original posters info. UserData mostly needed for the side bar
	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = userInfo();

	// ActiveTicketUpdate();

	useEffect(() => {
		// THIS USE EFFECT PRIMARILY IS NEEDED FOR THE SIDEBAR. MAY BE REDUNDANT.
		if (zendeskData !== null) {
			if (zendeskData.ticket_users?.data !== undefined) {
				setUserNameDatabase(zendeskData.ticket_users.data.output.users);
				console.log(userNameDatabase); //Passing the list of anybody associated with the ticket
			}
		}
	}, [zendeskData]);

	return (
		<div className="w-full mx-auto h-full overflow-y-hidden ">
			<MainGrid
				mutateTicketData={mutateTicketData}
				title={"Support Tickets"}
				data={zendeskData}
				userData={userData}
				userNameDatabase={userNameDatabase}
			></MainGrid>
		</div>
	);
};

export default ViewTickets;

import React, { useContext, useEffect, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { Switch } from "react-router-dom";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";

import CtaHeader from "../components/CtaComponents/Header";
import SubGrid from "../components/CtaComponents/SubGrid/SubGrid";
import {
	Sidebar,
	NavBar,
	PrivateRoute,
	FillableSidebar,
	CommandK,
	ResolveFormModal,
	ModalContainer,
	UserIcon,
	Todo,
	Chat,
	Notes,
	BlockPagePopup,
	DataRoom,
} from "../components";
import {
	callWithModifiers,
	callWithoutModifiers,
	generateCreatedAt,
	handleCaughtError,
} from "../utils";
import {
	getActionItemsCommands,
	getAssigneeCommands,
	getNavigationCommands,
	getPriorityCommands,
	getSnoozeCommands,
	getStatusCommands,
	getSalesforceCommands,
} from "../commands";
import { CtaContext, SearchContext, CtaSplitContext } from "../context";
import { getAllAccountData, requestUpdate } from "../requests";
import { priorities } from "../constants";

import Routes from "../Routes";

const Cta = withRouter(({ match, history }) => {
	const {
		currentCtaData,
		setCurrentCtaData,
		selectedIndex,
		setSelectedIndex,
		otherCtaData,
		details,
		setDetails,
		ctaName,
		setCtaName,
		assigned,
		setAssigned,
		followers,
		setFollowers,
		ctaUnread,
		setCtaUnread,
		userOptions,
		headerTabs,
		ctaLoading,
		ctaError,
		chatLoading,
		mutateCta,
		filteredData,
		allCtaData,
	} = useContext(CtaContext);

	const { backPage } = useContext(CtaSplitContext);

	const { goBackToSearch } = useContext(SearchContext);

	const [status, setStatus] = useState("");
	const [notes, setNotes] = useState("");
	const [priority, setPriority] = useState("");
	const [resolved, setResolved] = useState(false);

	const [snoozeQuery, setSnoozeQuery] = useState("");
	const [snoozeCommands, setSnoozeCommands] = useState([]);

	const [priorityDropdownOpen, setPriorityDropdownOpen] = useState(false);
	const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
	const [assignedDropdownOpen, setAssignedDropdownOpen] = useState(false);
	const [followerDropdownOpen, setFollowerDropdownOpen] = useState(false);

	const [actionCommandKOpen, setActionCommandKOpen] = useState(false);
	const [nameInputOpen, setNameInputOpen] = useState(false);
	const [statusCommandOpen, setStatusCommandOpen] = useState(false);
	const [priorityCommandOpen, setPriorityCommandOpen] = useState(false);
	const [assigneeCommandOpen, setAssigneeCommandOpen] = useState(false);
	const [snoozeCommandOpen, setSnoozeCommandOpen] = useState(false);
	const [resolveFormOpen, setResolveFormOpen] = useState(false);
	const [paletteOpen, setPaletteOpen] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [salesforceEnabled, setSalesforceEnabled] = useState(true);

	const myEmail = localStorage.getItem("user_email");

	// Data Hooks

	const {
		data: accountsData,
		isLoading: accountsLoading,
		isError: accountsError,
		mutate: mutateAccount,
	} = getAllAccountData();

	// Effects

	useEffect(() => {
		if (currentCtaData && filteredData) {
			mutateReadStatus(true);
			setResolved(currentCtaData.resolved);
		}
	}, [currentCtaData]);

	useEffect(() => {
		setSalesforceEnabled(
			paragon.getUser()?.integrations?.salesforce?.enabled
		);

		if (!salesforceEnabled && currentCtaData?.type?.type === "expansion") {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [currentCtaData, paragon]);

	useEffect(() => {
		setSnoozeCommands(
			getSnoozeCommands(
				currentCtaData?.cta_id,
				mutateCtaSnoozed,
				snoozeQuery,
				() => onClickDown()
			)
		);
	}, [snoozeQuery, currentCtaData]);

	useEffect(() => {
		if (!snoozeCommandOpen) {
			setSnoozeQuery("");
		}
	}, [snoozeCommandOpen]);

	useEffect(() => {
		if (
			actionCommandKOpen ||
			nameInputOpen ||
			statusCommandOpen ||
			priorityCommandOpen ||
			assigneeCommandOpen ||
			snoozeCommandOpen ||
			resolveFormOpen
		) {
			setPaletteOpen(true);
		} else {
			setPaletteOpen(false);
		}
	}, [
		actionCommandKOpen,
		nameInputOpen,
		statusCommandOpen,
		priorityCommandOpen,
		assigneeCommandOpen,
		snoozeCommandOpen,
		resolveFormOpen,
	]);

	// Mutations
	const getIndexOfPriority = (priorityString) => {
		for (let i = 0; i < priorities.length; i++) {
			if (
				priorities[i].value.toLowerCase() ===
				priorityString.toLowerCase()
			) {
				return i + 1;
			}
		}
	};

	const updateLocalCtas = (ctaId, attr, newValue) => {
		let allCtaDataCopy = cloneDeep(allCtaData.data);
		for (let i = 0; i < allCtaDataCopy.length; i++) {
			if (allCtaDataCopy[i].cta_id === ctaId) {
				allCtaDataCopy[i][attr] = newValue;
				return { data: allCtaDataCopy };
			}
		}
		return null;
	};

	const mutateCtaValue = async (
		currentValue,
		setValue,
		newValue,
		newNoteBody,
		ctaId,
		attr,
		endpoint,
		mutateWithNewValue = false
	) => {
		let previousValue = currentValue;
		if (previousValue === newValue) {
			return;
		}
		if (setValue) setValue(newValue);
		let newCtas = updateLocalCtas(
			ctaId,
			attr,
			mutateWithNewValue ? newValue : newNoteBody
		);
		if (newCtas === null) {
			return;
		}
		try {
			mutateCta(newCtas, false);
			await requestUpdate(endpoint, {
				cta_id: ctaId,
				note_body: newNoteBody,
			});
			mutateCta();
		} catch (err) {
			handleCaughtError(err);
			if (setValue) setValue(newValue);
		}
	};

	const mutateStatus = async (newStatus) => {
		await mutateCtaValue(
			status,
			setStatus,
			newStatus,
			newStatus,
			currentCtaData.cta_id,
			"status",
			"/api/updateCtaStatus"
		);
	};

	const mutateNote = async (newNote) => {
		await mutateCtaValue(
			notes,
			setNotes,
			newNote,
			newNote,
			currentCtaData.cta_id,
			"notes",
			"/api/updateCtaNote"
		);
	};

	const mutateDetails = async (newDetails) => {
		await mutateCtaValue(
			details,
			setDetails,
			newDetails,
			newDetails,
			currentCtaData.cta_id,
			"cta_summary",
			"/api/updateCtaSummary"
		);
	};

	const mutatePriority = async (newPriority) => {
		let newPriorityIndex = getIndexOfPriority(newPriority);
		await mutateCtaValue(
			priority,
			setPriority,
			newPriority,
			newPriorityIndex,
			currentCtaData.cta_id,
			"priority",
			"/api/updatePriority"
		);
	};

	const mutateAssigned = async (newAssignee) => {
		let newVisibilityData;
		if (newAssignee && newAssignee.email !== "unassigned") {
			newVisibilityData = {
				...currentCtaData.visiblity,
				assigned_to: [newAssignee.email],
			};
		} else {
			newVisibilityData = {
				...currentCtaData.visiblity,
				assigned_to: [],
			};
		}
		await mutateCtaValue(
			assigned,
			setAssigned,
			newAssignee,
			newVisibilityData,
			currentCtaData.cta_id,
			"visiblity",
			"/api/updateCtaVisiblity"
		);
	};

	const mutateFollowers = async (newFollower) => {
		let arr = cloneDeep(currentCtaData.visiblity.followed_by);

		if (arr.includes(newFollower.email)) {
			arr.splice(arr.indexOf(newFollower.email), 1);
		} else {
			arr.push(newFollower.email);
		}
		let newVisibilityData = {
			...currentCtaData.visiblity,
			followed_by: arr,
		};
		await mutateCtaValue(
			currentCtaData.visiblity.followed_by,
			setFollowers,
			arr,
			newVisibilityData,
			currentCtaData.cta_id,
			"visiblity",
			"/api/updateCtaVisiblity"
		);
	};

	const mutateCtaName = async (newName) => {
		await mutateCtaValue(
			ctaName,
			setCtaName,
			newName,
			newName,
			currentCtaData.cta_id,
			"cta",
			"/api/updateCtaName"
		);
	};

	const mutateCtaSnoozed = async (ctaId, time) => {
		let newSnoozeObject;
		if (currentCtaData.snooze) {
			newSnoozeObject = {
				...currentCtaData.snooze,
				[myEmail]: time,
			};
		} else {
			newSnoozeObject = {
				[myEmail]: time,
			};
		}
		if (ctaId) {
			await mutateCtaValue(
				null,
				null,
				newSnoozeObject,
				newSnoozeObject,
				ctaId,
				"snooze",
				"/api/actionItems/snooze"
			);
		}
	};

	const mutateReadStatus = async (newReadStatus) => {
		// Get User Email
		if (!myEmail) {
			return;
		}

		// Clone/Init variables
		let currentCtaCopy = cloneDeep(currentCtaData);
		let currentUnreadList = cloneDeep(currentCtaCopy.visiblity.unread);
		let newVisibilityData;

		if (newReadStatus === false) {
			// If marking as unread, add to list
			if (ctaUnread) return;
			newVisibilityData = {
				...currentCtaCopy.visiblity,
				unread: currentUnreadList.concat(myEmail),
			};
		} else {
			// If marking as read, remove from list
			if (!ctaUnread) return;
			let emailIndex = currentUnreadList.indexOf(myEmail);
			currentUnreadList.splice(emailIndex, 1);
			newVisibilityData = {
				...currentCtaCopy.visiblity,
				unread: currentUnreadList,
			};
		}

		// Set local state
		currentCtaCopy.visiblity.unread = currentUnreadList;
		setCurrentCtaData(currentCtaCopy);

		if (newReadStatus === false) {
			history.push(Routes.ACTION_ITEMS_PAGE);
		}

		setCtaUnread(!newReadStatus);

		// Mutate
		await mutateCtaValue(
			currentCtaCopy.visiblity,
			null,
			newVisibilityData,
			newVisibilityData,
			currentCtaData.cta_id,
			"visiblity",
			"/api/updateCtaVisiblity"
		);
	};

	const assignToSelf = () => {
		let myUserObject = userOptions.find((user) => user.email === myEmail);
		if (myUserObject) {
			mutateAssigned(myUserObject);
		}
	};

	const mutateCtaResolution = async (
		problem,
		stepsTaken,
		resolution,
		customerEffect,
		notes,
		resolved = false
	) => {
		// Create new Resolution report to send back
		let newResReport = {
			...currentCtaData.resolution_report,
			final: {
				problem: problem,
				steps_taken: stepsTaken,
				resolution: resolution,
				health_effect: customerEffect,
				notes: notes,
			},
		};

		// Init promise for sending report
		let reportPromise = mutateCtaValue(
			currentCtaData.resolution_report,
			null,
			newResReport,
			newResReport,
			currentCtaData.cta_id,
			"resolution_report",
			"/api/resolutionReport/update"
		);

		// If report is also being resolve, init promise for resolution
		let resolutionPromise;
		if (resolved) {
			resolutionPromise = mutateCtaValue(
				false,
				null,
				true,
				true,
				currentCtaData.cta_id,
				"resolved",
				"/api/resolutionReport/resolve"
			);

			// Await both promises in parallel
			await Promise.all([reportPromise, resolutionPromise]);
		} else {
			// Otherwise, just await report promise
			await Promise.all([reportPromise]);
		}
	};

	const mutateSalesforceStage = async (stage) => {
		let tmp = cloneDeep(currentCtaData.type);

		tmp.data.StageName = stage.ApiName;
		await mutateCtaValue(
			currentCtaData.type,
			null,
			tmp,
			tmp,
			currentCtaData.cta_id,
			"type",
			"/api/salesforce/updateStage"
		);
	};

	const createNewNote = async () => {
		let newNoteId = uuidv4();
		let newNote = {
			id: newNoteId,
			company_id: currentCtaData.company_id,
			cta_id: currentCtaData.cta_id,
			title: "",
			emoji: "📝️",
			body: null,
			time_created: generateCreatedAt(),
		};
		let currentNotesCopy = cloneDeep(currentCtaData.notes);
		currentNotesCopy.push(newNote);
		mutateCtaValue(
			currentCtaData.notes,
			null,
			currentNotesCopy,
			newNote,
			currentCtaData.cta_id,
			"notes",
			"/api/notes/createNote",
			true
		);
		history.push({
			pathname: `${Routes.NOTES_BASE_ROUTE}/${newNoteId}`,
			state: { note: newNote },
		});
	};

	const deleteNote = async (ctaId, companyId, noteId) => {
		// Remove from CTAs
		let allCtaDataCopy = cloneDeep(allCtaData.data);
		for (let i = 0; i < allCtaDataCopy.length; i++) {
			if (allCtaDataCopy[i].cta_id === ctaId) {
				for (let j = 0; j < allCtaDataCopy[i].notes.length; j++) {
					if (allCtaDataCopy[i].notes[j].id === noteId) {
						allCtaDataCopy[i].notes.splice(j, 1);
					}
				}
			}
		}

		// Remove from accounts
		let allAccountsCopy = cloneDeep(accountsData.data);
		for (let i = 0; i < allAccountsCopy.length; i++) {
			if (allAccountsCopy[i].company_id === companyId) {
				for (let j = 0; j < allAccountsCopy[i].notes.length; j++) {
					if (allAccountsCopy[i].notes[j].id === noteId) {
						allAccountsCopy[i].notes.splice(j, 1);
					}
				}
			}
		}

		// Mutate CTAs and Accounts
		mutateCta({ data: allCtaDataCopy }, false);
		mutateAccount({ data: allAccountsCopy }, false);

		// Delete remotely
		await requestUpdate("/api/notes/deleteNote", { id: noteId });

		// Validate both CTAs and Accounts
		mutateCta();
		mutateAccount();
	};

	// Navigation

	const onClickBack = () => {
		if (goBackToSearch) {
			history.push(Routes.SEARCH_PAGE);
		} else if (backPage) {
			history.push(backPage);
		} else {
			history.push(Routes.ACTION_ITEMS_PAGE);
		}
	};

	const onClickUp = () => {
		let newIndex;
		if (filteredData && selectedIndex !== null) {
			if (selectedIndex === 0) {
				newIndex = filteredData.data.length - 1;
			} else {
				newIndex = selectedIndex - 1;
			}
			if (newIndex < filteredData.length) {
				setSelectedIndex(newIndex);
				history.push(
					`${Routes.CTA_BASE_ROUTE}/${filteredData.data[newIndex].cta_id}/todo`
				);
			}
		}
	};

	const onClickDown = () => {
		let newIndex;
		if (filteredData && selectedIndex !== null) {
			if (selectedIndex === filteredData.data.length - 1) {
				newIndex = 0;
			} else {
				newIndex = selectedIndex + 1;
			}
			if (newIndex < filteredData.length) {
				setSelectedIndex(newIndex);
				history.push(
					`${Routes.CTA_BASE_ROUTE}/${filteredData.data[newIndex].cta_id}/todo`
				);
			}
		}
	};

	// Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "j":
					callWithoutModifiers(event, () => onClickDown());
					break;
				case "k":
					callWithoutModifiers(event, () => onClickUp());
					callWithModifiers(
						event,
						() => setActionCommandKOpen(true),
						["cmd"]
					);
					break;
				case "s":
					callWithoutModifiers(event, () =>
						setStatusDropdownOpen(true)
					);
					break;
				case "p":
					callWithoutModifiers(event, () =>
						setPriorityDropdownOpen(true)
					);
					break;
				case "r":
					callWithoutModifiers(event, () => setNameInputOpen(true));
					break;
				case "a":
					callWithoutModifiers(event, () =>
						setAssignedDropdownOpen(true)
					);
					break;
				case "f":
					callWithoutModifiers(event, () =>
						setFollowerDropdownOpen(true)
					);
					break;
				case "i":
					callWithoutModifiers(event, () => assignToSelf());
					break;
				case "u":
					callWithoutModifiers(event, () =>
						mutateReadStatus(ctaUnread)
					);
					break;
				case "Escape":
					if (!paletteOpen) {
						callWithoutModifiers(event, () => onClickBack());
					}
					break;
				case "h":
					callWithoutModifiers(event, () =>
						setSnoozeCommandOpen(true)
					);
					break;
				case "n":
					callWithoutModifiers(event, () => createNewNote());
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[selectedIndex, filteredData, paletteOpen, backPage, goBackToSearch]
	);

	// Commands

	let assignarr = userOptions.slice();
	if (assignarr.length > 0) {
		if (assignarr[0].email !== "unassigned")
			assignarr.unshift({
				email: "unassigned",
				icon: <UserIcon user={null} />,
				value: "Unassigned",
			});
	}

	const navigationCommands = getNavigationCommands(history);
	const statusCommands = getStatusCommands(mutateStatus);
	const priorityCommands = getPriorityCommands(mutatePriority);
	const assigneeCommands = getAssigneeCommands(assignarr, mutateAssigned);
	const actionItemCommands = getActionItemsCommands(
		setStatusCommandOpen,
		setPriorityCommandOpen,
		assignToSelf,
		setNameInputOpen,
		setAssigneeCommandOpen,
		mutateReadStatus,
		ctaUnread,
		setSnoozeCommandOpen,
		createNewNote
	);

	const salesforceCommands = getSalesforceCommands(
		currentCtaData?.type?.data?.Id
	);

	if (ctaError) return <div>failed to load</div>;

	if (ctaLoading || chatLoading) {
		return (
			<div className="flex flex-row h-screen">
				<NavBar
					onClickBack={onClickBack}
					onClickDown={onClickDown}
					onClickUp={onClickUp}
					verticalArrowsVisible={false}
				/>
				<div className="flex-grow h-screen" />
				<FillableSidebar />
			</div>
		);
	}

	return (
		<div className="flex flex-row h-screen w-full">
			<NavBar
				onClickBack={onClickBack}
				onClickDown={onClickDown}
				onClickUp={onClickUp}
			/>
			<div
				className="flex flex-col flex-grow h-screen"
				style={{ pointerEvents: disabled ? "none" : "auto" }}
			>
				<CtaHeader
					tabs={headerTabs}
					title={currentCtaData?.company_name}
					subtitle={ctaName}
					type={currentCtaData?.type}
					logo={currentCtaData?.company_clearbit_info?.logo}
					grade={currentCtaData?.grade}
					activePage={match.params.page}
					stage={currentCtaData?.stage}
					currentCtaData={currentCtaData}
					skipLastTab
					onClickCommandK={() => setActionCommandKOpen(true)}
					onClickSnooze={() => setSnoozeCommandOpen(true)}
					details={details}
					setDetails={setDetails}
					mutateDetails={mutateDetails}
					onClickResolve={() => setResolveFormOpen(true)}
					mutateSalesforceStage={mutateSalesforceStage}
					resolved={resolved}
				/>
				<div className="flex-grow mb-4 mr-6 overflow-hidden">
					<Switch>
						<PrivateRoute
							path={`${Routes.CTA_BASE_ROUTE}/:cid/todo`}
							component={Todo}
						/>
						<PrivateRoute
							path={`${Routes.CTA_BASE_ROUTE}/:cid/chat`}
							component={Chat}
						/>
						<PrivateRoute
							path={`${Routes.CTA_BASE_ROUTE}/:cid/timeline`}
							component={SubGrid}
						/>
						<Route
							path={`${Routes.CTA_BASE_ROUTE}/:cid/notes`}
							render={(props) => (
								<Notes
									notes={currentCtaData?.notes || []}
									onClickNewNote={createNewNote}
									onClickDeleteNote={deleteNote}
									{...props}
								/>
							)}
						/>
						<PrivateRoute
							path={`${Routes.CTA_BASE_ROUTE}/:cid/dataroom`}
							component={DataRoom}
						/>
					</Switch>
				</div>
			</div>

			{/* sidebar */}
			<Sidebar
				ctaData={currentCtaData}
				mutate={mutateCta}
				otherCtaData={otherCtaData}
				status={status}
				setStatus={setStatus}
				mutateStatus={mutateStatus}
				notes={notes}
				setNotes={setNotes}
				mutateNote={mutateNote}
				priority={priority}
				setPriority={setPriority}
				mutatePriority={mutatePriority}
				assigned={assigned}
				setAssigned={setAssigned}
				mutateAssigned={mutateAssigned}
				followers={followers}
				setFollowers={setFollowers}
				mutateFollowers={mutateFollowers}
				userOptions={userOptions}
				mutate={mutateCta}
				onClickProfileButton={() => setResolveFormOpen(true)}
				priorityDropdownOpen={priorityDropdownOpen}
				setPriorityDropdownOpen={setPriorityDropdownOpen}
				statusDropdownOpen={statusDropdownOpen}
				setStatusDropdownOpen={setStatusDropdownOpen}
				assignedDropdownOpen={assignedDropdownOpen}
				setAssignedDropdownOpen={setAssignedDropdownOpen}
				followerDropdownOpen={followerDropdownOpen}
				setFollowerDropdownOpen={setFollowerDropdownOpen}
			/>

			{/* Modals/Command Palettes */}
			<ModalContainer top="30%">
				{actionCommandKOpen && (
					<CommandK
						options={
							currentCtaData?.type?.data?.Id
								? actionItemCommands
										.concat(salesforceCommands)
										.concat(navigationCommands)
								: actionItemCommands.concat(navigationCommands)
						}
						setOpen={setActionCommandKOpen}
						variant="cta"
						header={`${currentCtaData?.company_name}${
							currentCtaData?.cta ? " - " + ctaName : ""
						}`}
					/>
				)}
				{statusCommandOpen && (
					<CommandK
						options={statusCommands}
						setOpen={setStatusCommandOpen}
						variant="actionItems"
						placeholder="Set a new status"
					/>
				)}
				{priorityCommandOpen && (
					<CommandK
						options={priorityCommands}
						setOpen={setPriorityCommandOpen}
						variant="actionItems"
						placeholder="Set a new priority"
					/>
				)}
				{assigneeCommandOpen && (
					<CommandK
						options={assigneeCommands}
						setOpen={setAssigneeCommandOpen}
						variant="actionItems"
						placeholder="Assign CTA to new user"
					/>
				)}
				{snoozeCommandOpen && (
					<CommandK
						options={snoozeCommands}
						header={`Snooze: ${currentCtaData?.company_name}${
							currentCtaData?.cta ? " - " + ctaName : ""
						}`}
						setOpen={setSnoozeCommandOpen}
						variant="cta"
						placeholder="Try: 1 minute, 3 hours, 5 days..."
						customQuery={snoozeQuery}
						setCustomQuery={setSnoozeQuery}
					/>
				)}
			</ModalContainer>
			<ModalContainer top="40%">
				{nameInputOpen && (
					<CommandK
						setOpen={setNameInputOpen}
						variant="input"
						placeholder="What would you like to rename the cta?"
						onEnterInput={mutateCtaName}
					/>
				)}
			</ModalContainer>
			<div
				className="flex flex-col items-center"
				style={{
					position: "absolute",
					left: "50%",
					transform: "translate(-50%, 0px)",
					height: "100vh",
					width: "600px",
					overflowY: "scroll",
					overflowX: "visible",
					pointerEvents: resolveFormOpen ? "auto" : "none",
				}}
			>
				{resolveFormOpen && (
					<ResolveFormModal
						title={`${currentCtaData.company_name} ${currentCtaData.cta}`}
						setModalOpen={setResolveFormOpen}
						resReport={currentCtaData.resolution_report.final}
						onClickSave={mutateCtaResolution}
						onClickResolve={(
							problem,
							stepsTaken,
							resolution,
							customerEffect,
							notes
						) => {
							mutateCtaResolution(
								problem,
								stepsTaken,
								resolution,
								customerEffect,
								notes,
								true
							);
							onClickDown();
						}}
						resolved={resolved}
					/>
				)}
			</div>
			{currentCtaData?.type?.type === "expansion" && !salesforceEnabled && (
				<ModalContainer top="30%">
					<BlockPagePopup
						click={() =>
							history.push(`${Routes.SETTINGS_BASE_ROUTE}/local`)
						}
						title="Please enable your Salesforce integration to view this page"
						buttonTitle={`Go to Settings ->`}
					/>
				</ModalContainer>
			)}
		</div>
	);
});

export default Cta;

import React, { useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";

import { callWithoutModifiers } from "../../utils";
import { UserIcon } from "..";
import GridRow from "./GridRow";

const GridPlaceholder = ({ message }) => {
	return (
		<div className="flex justify-center items-center w-full h-full text-gray-400 italic">
			{message}
		</div>
	);
};

const SectionHeader = ({ heading, annotation }) => (
	<div className="flex flex-row justify-start items-center w-full h-5 p-4 bg-lightgray text-xs">
		<p className="text-quartiaryText mr-1">{heading}</p>
		<p className="text-tertiaryText">{annotation}</p>
	</div>
);

const MainGrid = ({
	data,
	selectedId,
	setSelectedId,
	variant = "actionItems",
	onPressUpArrow,
	onPressDownArrow,
	onPressEnter,
	setCommandKOpen,
	setSnoozeOpen,
	setAsigneesOpen,
	hoverEnabled = true,
	loading = false,
	error = false,
	onLeaveHover = false,
	fullSize = true,
	showPlaceholders = true,
	rowIdsOffset = 0,
	userIcons = {},
}) => {
	const [cursorEnabled, setCursorEnabled] = useState(false);

	const email = localStorage.getItem("user_email");

	// Event Handlers

	const onHover = (index) => {
		if (hoverEnabled && cursorEnabled) {
			setSelectedId(index);
		}
	};

	const selectRow = () => {
		if (onPressEnter && hoverEnabled) {
			onPressEnter();
		}
	};

	const usingCurrentGrid = () => {
		if (variant === "snippets") {
			return data && data?.length > selectedId && 0 <= selectedId;
		} else if (variant === "templates") {
			return data && data?.length > selectedId && 0 <= selectedId;
		} else if (variant === "playbooks") {
			return (
				data && data.playbooks?.length > selectedId && 0 <= selectedId
			);
		} else {
			return data && data.data?.length > selectedId && 0 <= selectedId;
		}
	};

	// Hot Keys

	useHotkeys(
		"*",
		(event) => {
			if (!usingCurrentGrid()) {
				return;
			}
			switch (event.key) {
				case "ArrowUp":
					if (onPressUpArrow)
						callWithoutModifiers(event, () => {
							setCursorEnabled(false);
							onPressUpArrow();
						});
					break;
				case "ArrowDown":
					if (onPressDownArrow)
						callWithoutModifiers(event, () => {
							setCursorEnabled(false);
							onPressDownArrow();
						});
					break;
				case "Enter":
					callWithoutModifiers(event, () => {
						setCursorEnabled(false);
						selectRow();
					});
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[selectedId]
	);

	// Placeholder Components (Loading, Error, etc.)

	if (showPlaceholders) {
		if (!data || loading) return <GridPlaceholder message="Loading Data" />;

		if (error)
			return (
				<GridPlaceholder message="An error occured while loading data" />
			);
		if (data && data.length === 0 && variant === "snippets")
			return <GridPlaceholder message="No User Snippets Created" />;

		if (data && data.length === 0 && variant === "templates")
			return <GridPlaceholder message="No Templates Created" />;

		if (data && data.length === 0)
			return <GridPlaceholder message="List Empty" />;
	}

	return (
		<div
			id="main-grid"
			className={fullSize ? "flex-grow h-full overflow-scroll" : ""}
			onMouseMove={(e) => {
				if (e.movementX !== 0 || e.movementY !== 0) {
					setCursorEnabled(true);
				}
			}}
		>
			{(variant === "actionItems" ||
				variant === "allCtas" ||
				variant === "accounts" ||
				variant === "support") && (
				<>
					{data &&
						data.data?.map((item, index) => {
							// Define UserIcon to be displayed as "Assignee" icon in ActionItems
							let assignee = null;
							if (
								(variant === "actionItems" ||
									variant === "allCtas") &&
								item?.visiblity?.assigned_to?.length > 0
							) {
								let email = item.visiblity.assigned_to[0];
								if (userIcons && email in userIcons) {
									assignee = userIcons[email].icon;
								}
							} else {
								assignee = <UserIcon />;
							}
							return (
								<div key={index}>
									{/* Section heading for Action Items MainGrid */}
									{item.sortType &&
										variant === "actionItems" &&
										(index === 0 ||
											item.sortType !==
												data.data[index - 1]
													.sortType) && (
											<SectionHeader
												heading={item.sortType}
												annotation={
													data.sortedAmounts[
														item.sortType
													]
												}
											/>
										)}
									{(variant === "actionItems" ||
										variant === "allCtas") && (
										<GridRow
											rowId={index + rowIdsOffset}
											active={selectedId === index}
											grade={item.grade}
											status={item.status}
											assignee={assignee}
											columnTwoTitle={item.company_name}
											columnThreeTitle={item.cta}
											timestamp={item.last_updated}
											variant={variant}
											unread={item?.visiblity?.unread}
											onHover={() => onHover(index)}
											onClick={selectRow}
											data={data}
											setCommandKOpen={setCommandKOpen}
											setSnoozeOpen={setSnoozeOpen}
											setAsigneesOpen={setAsigneesOpen}
											email={email}
											cursorEnabled={cursorEnabled}
										/>
									)}
									{variant === "accounts" && (
										<GridRow
											rowId={index + rowIdsOffset}
											active={selectedId === index}
											grade={item.grade}
											columnTwoTitle={item.company_name}
											variant={variant}
											onHover={() => onHover(index)}
											onClick={selectRow}
											cursorEnabled={cursorEnabled}
										/>
									)}
									{variant === "support" && (
										<GridRow
											rowId={index + rowIdsOffset}
											active={selectedId === index}
											columnTwoTitle={
												item.ticket_data?.subject
											}
											columnThreeTitle={
												item.ticket_data?.status
											}
											variant={variant}
											onHover={() => onHover(index)}
											onClick={selectRow}
											cursorEnabled={cursorEnabled}
										/>
									)}
								</div>
							);
						})}
				</>
			)}
			{variant === "playbooks" && (
				<>
					{data?.playbooks.map((item, index) => (
						<div key={index}>
							<GridRow
								rowId={index + rowIdsOffset}
								active={selectedId === index}
								columnTwoTitle={item.name}
								variant={variant}
								onHover={() => onHover(index)}
								onLeaveHover={
									onLeaveHover ? () => onHover(null) : null
								}
								onClick={selectRow}
								data={data}
								cursorEnabled={cursorEnabled}
							/>
						</div>
					))}
				</>
			)}
			{variant === "snippets" && (
				<>
					{data?.map((item, index) => (
						<div key={index}>
							<GridRow
								rowId={index + rowIdsOffset}
								active={selectedId === index}
								columnTwoTitle={
									item.title ? item.title : "Untitled"
								}
								columnThreeTitle={
									item.body.blocks[0]
										? item.body.blocks[0].text + "..."
										: ""
								}
								variant={variant}
								onHover={() => onHover(index)}
								onLeaveHover={
									onLeaveHover ? () => onHover(null) : null
								}
								onClick={selectRow}
								data={item}
								cursorEnabled={cursorEnabled}
							/>
						</div>
					))}
				</>
			)}
			{variant === "templates" && (
				<>
					{data?.map((item, index) => (
						<div key={index}>
							<GridRow
								rowId={index + rowIdsOffset}
								active={selectedId === index}
								columnTwoTitle={
									item.title ? item.title : "Untitled"
								}
								variant={variant}
								onHover={() => onHover(index)}
								onLeaveHover={
									onLeaveHover ? () => onHover(null) : null
								}
								onClick={selectRow}
								data={item}
								cursorEnabled={cursorEnabled}
							/>
						</div>
					))}
				</>
			)}
		</div>
	);
};

export default MainGrid;

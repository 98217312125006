import { useEffect, useRef, useState } from "react";
import { BsSquare, BsSquareFill } from "react-icons/bs";

import { useOutsideAlerter } from "../../hooks";

import { IoIosAdd } from "react-icons/io";

const Tags = ({
	options,
	setOpen,
	open = false,
	onSelect,
	keyCommand,
	users,
	selectedUser,
	tags,
	hasTag,
	newTag,
}) => {
	let selected = users[selectedUser];
	let inputRef = useRef(null);
	let dropdownRef = useRef(null);
	useOutsideAlerter(dropdownRef, () => setOpen(false));

	const [selectedIndex, setSelectedIndex] = useState(null);
	const [query, setQuery] = useState("");
	const [colorSelect, setColorSelect] = useState(false);
	useEffect(() => {
		if (open && options.length != 0) {
			setSelectedIndex(0);
			inputRef.current.focus();
		} else {
			setQuery("");
		}
	}, [open]);
	const colors = [
		{ color: "bg-red-400", value: "red" },
		{ color: "bg-blue-400", value: "blue" },
		{ color: "bg-green-400", value: "green" },
		{ color: "bg-yellow-400", value: "yellow" },
		{ color: "bg-black-400", value: "black" },
		{ color: "bg-gray-400", value: "gray" },
		{ color: "bg-pink-400", value: "pink" },
		{ color: "bg-indigo-400", value: "indigo" },
	];
	useEffect(() => {
		if (open) {
			// getSelectedIndexFromStatus();
			// inputRef.current.focus();
		} else {
			setQuery("");
		}
	}, [open]);

	useEffect(() => {
		let sel = options[selectedIndex];
		console.log(
			options
				.filter((option) => filterByQuery(option.value))
				.includes(options[selectedIndex])
		);
		if (
			!options
				.filter((option) => filterByQuery(option.value))
				.includes(options[selectedIndex])
		) {
			setSelectedIndex(
				options.indexOf(
					options.filter((option) => filterByQuery(option.value))[0]
				)
			);
		} else {
		}
	}, [query]);

	const filterByQuery = (input) => {
		let queryLength = query.length;
		if (
			query
				.toLowerCase()
				.localeCompare(input.toLowerCase().slice(0, queryLength)) === 0
		) {
			return true;
		}
		let words = input.split(" ");
		for (var i = 0; i < words.length; i++) {
			if (words[i].toLowerCase().startsWith(query.toLowerCase())) {
				return true;
			}
		}
	};

	const onDownArrow = () => {
		if (selectedIndex === null) {
			setSelectedIndex(0);
		} else {
			let newIndex = selectedIndex + 1;
			setSelectedIndex(
				newIndex %
					options.filter((option) => filterByQuery(option.value))
						.length
			);
		}
	};

	const onUpArrow = () => {
		if (selectedIndex === null || selectedIndex === 0) {
			setSelectedIndex(
				options.filter((option) => filterByQuery(option.value)).length -
					1
			);
		} else {
			setSelectedIndex((idx) => idx - 1);
		}
	};

	const onEnter = () => {
		if (selectedIndex === null) {
			console.log("sel is null");
		} else {
			console.log(selectedIndex, "selected");
			if (options[selectedIndex]) {
				onSelect(options[selectedIndex]);
			}
		}
		//onSelect();
		setOpen(false);
	};

	const onKeyDown = (e) => {
		if (e.key === "ArrowDown") {
			console.log("adfsdas");
			onDownArrow();
		} else if (e.key === "ArrowUp") {
			onUpArrow();
		} else if (e.key === "Enter" && selectedIndex !== null) {
			onEnter();
		}
	};
	console.log(selected.tags);
	return (
		<div id="dropdown-container" className="absolute flex flex-row ">
			{/* Dropdown header - Section that's always visible */}
			{!open && (
				<div
					id="dropdown-header"
					className="flex flex-row  justify-start items-center"
				>
					<div
						id="indicator-section"
						className="hover:shadow-md  cursor-pointer"
						onClick={() => {
							setOpen(true);
							setColorSelect(false);
						}}
					>
						<IoIosAdd></IoIosAdd>
					</div>
				</div>
			)}

			{/* Dropdown body - Section that appears when header is clicked */}

			{open && !colorSelect && (
				<div
					id="dropdown-body"
					className="flex flex-col bg-white absolute rounded-md shadow-md ml-22 py-2 w-44"
					ref={dropdownRef}
				>
					<span className="flex flex-row justify-between items-center py-1 px-3 mb-1 w-full">
						<input
							className="text-xs whitespace-nowrap outline-none flex-grow w-3/4 pr-1"
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							placeholder={`Set Tags...`}
							ref={inputRef}
							onKeyDown={onKeyDown}
						/>
						{keyCommand && (
							<span className="flex justify-center items-center w-5 h-5 rounded-sm bg-gray-200 text-xs text-black">
								{keyCommand}
							</span>
						)}
					</span>

					{options
						.filter((option) => filterByQuery(option.value))
						.map((option, index) => (
							<div
								className={`flex flex-row items-center w-full py-0.5  px-1 cursor-pointer ${
									index === selectedIndex ? "bg-gray-100" : ""
								}`}
								key={index}
								onClick={() => {
									onSelect(option);
									setOpen(false);
								}}
								onMouseEnter={() => setSelectedIndex(index)}
							>
								{hasTag(selected, option) == -1 ? (
									<BsSquare
										size={22}
										className="py-0 pr-2 text-green-400"
									></BsSquare>
								) : (
									<BsSquareFill
										size={22}
										className="py-0 pr-2 text-green-400"
									></BsSquareFill>
								)}

								<div
									className={` justify-center ml-0.5 my-auto justify-self-center ${option.color}  h-1.5 w-1.5   rounded-full flex-none`}
								></div>

								<p className="text-gray-900 text-xs pl-2 whitespace-nowrap">
									{option.value}
								</p>
							</div>
						))}
					{query.length > 0 && (
						<div
							className={`flex flex-row items-center w-full py-0.5  px-1 hover:bg-gray-100 cursor-pointer`}
							onClick={() => {
								setColorSelect(true);
							}}
						>
							<p className="text-gray-900 text-xs pl-2  whitespace-nowrap">
								Add new tag: {query}
							</p>
						</div>
					)}
				</div>
			)}
			{open && colorSelect && (
				<div
					id="dropdown-body"
					className="flex flex-col bg-white absolute rounded-md shadow-md ml-22 py-2 w-44"
					ref={dropdownRef}
				>
					<span className="text-xs whitespace-nowrap text-center outline-none mb-2 flex-grow w-3/4 pr-1">
						Select Color for {query}
					</span>
					{colors.map((option, index) => (
						<div
							className={`flex flex-row items-center w-full py-0.5  px-1 cursor-pointer `}
							onClick={() => {
								newTag(option.color, query);
								setOpen(false);
							}}
						>
							<div
								className={` justify-center ml-2 my-auto justify-self-center ${option.color}  h-1.5 w-1.5   rounded-full flex-none`}
							></div>

							<p className="text-gray-900 text-xs pl-2 whitespace-nowrap">
								{option.value}
							</p>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default Tags;

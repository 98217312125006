import React, { useState, useEffect } from "react";
import IntegrationLogos from "./IntegrationLogos";

const SettingsButton = ({ integration_type, connected }) => {
	const [loading, setLoading] = useState(false);
	const [connectorStatus, setConnectorStatus] = useState(connected.status);

	async function handleClick() {
		setLoading(true);
		const url = `/api/connections/connect?connectionType=${integration_type}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		const { endpoint } = await res.json();
		if (endpoint == "AlreadyExists") {
			setConnectorStatus("incomplete");
		} else {
			window.open(endpoint, "_blank");
		}
		setLoading(false);
	}

	async function reConnect() {
		setLoading(true);
		const url = `/api/connections/deleteConnector?id=${connected.id}`;
		const res = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		const { code } = await res.json();
		if (code == "Success") {
			handleClick();
		}
	}

	if (loading) {
		return (
			<button
				className={"animate-pulse my-8 mx-3 overflow-hidden shadow-md"}
				onClick={handleClick}
			>
				<div className="grid grid-cols-4">
					<div className={"content-center mx-auto block"}>
						{IntegrationLogos[integration_type]}
					</div>

					<p className={"flex items-center"}>
						{integration_type.toUpperCase()}
					</p>
				</div>
			</button>
		);
	} else {
		if (connectorStatus == "incomplete" || connectorStatus == "broken") {
			return (
				<button
					className={
						"transform transition duration-500 hover:scale-105 hover:shadow-md my-8 mx-3 overflow-hidden rounded-md border-2 border-red-400"
					}
					onClick={reConnect}
				>
					<div className="grid grid-cols-4">
						<div className={"content-center mx-auto block"}>
							{IntegrationLogos[integration_type]}
						</div>
						<div className="flex items-center col-span-2">
							<div className="text-left">
								<p>{integration_type.toUpperCase()}</p>
								<p className="text-xs text-red-400 font-light">
									Reconnect Integration
								</p>
							</div>
						</div>
					</div>
				</button>
			);
		} else if (connectorStatus == "connected") {
			return (
				<div
					className={
						"transform transition duration-500 hover:scale-105 hover:shadow-md my-8 mx-3 overflow-hidden rounded-md border-2 border-green-400"
					}
				>
					<div className="grid grid-cols-4">
						<div className={"content-center mx-auto block"}>
							{IntegrationLogos[integration_type]}
						</div>
						<div className="flex items-center">
							<div className="text-left">
								<p>{integration_type.toUpperCase()}</p>
								<p className="text-xs text-green-400 font-light">
									Connected
								</p>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<button
					className={
						"transform transition duration-500 hover:scale-105 hover:shadow-md py-2 my-8 mx-3 overflow-hidden rounded-md border-2 border-gray-200"
					}
					onClick={handleClick}
				>
					<div className="grid grid-cols-4">
						<div className={"content-center mx-auto block"}>
							{IntegrationLogos[integration_type]}
						</div>

						<p className={"flex items-center"}>
							{integration_type.toUpperCase()}
						</p>
					</div>
				</button>
			);
		}
	}
};

export default SettingsButton;

import React, { useContext, useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import cloneDeep from "lodash.clonedeep";

import fetchSupportData from "../components/data/getSupportTicketData";
import {
	getNavigationCommands,
	getActionItemsCommands,
	getAssigneeCommands,
	getPriorityCommands,
	getSnoozeCommands,
	getStatusCommands,
	getSalesforceCommands,
	getRemoveSplitCommands,
	getCustomizationCommands,
	getZendeskCommands,
} from "../commands";
import {
	CommandK,
	CreateCtaModal,
	MainGrid,
	Menu,
	ModalContainer,
	PageHeader,
	Sidebar,
	BlockPagePopup,
} from "../components";
import {
	getCtaData,
	getSellerantUsers,
	getUserInfo,
	requestPost,
	requestUpdate,
} from "../requests";
import {
	callWithModifiers,
	callWithoutModifiers,
	filterSplitData,
	getIconOptionsFromUsers,
	handleCaughtError,
	getUserSplits,
	removeSplitListHelper,
} from "../utils";
import { priorities, sourcePages } from "../constants";
import { CtaSplitContext } from "../context";
import { useScroll } from "../hooks";
import Routes from "../Routes";

const ActionItems = withRouter(({ match }) => {
	const {
		selectedSplit,
		setSelectedSplit,
		splitSettings,
		setSplitSettings,
	} = useContext(CtaSplitContext);

	const [currentCtaData, setCurrentCtaData] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [splitTitles, setSplitTitles] = useState(null);

	const [ctaName, setCtaName] = useState("");
	const [status, setStatus] = useState("");
	const [priority, setPriority] = useState("");
	const [assigned, setAssigned] = useState([]);
	const [userOptions, setUserOptions] = useState([]);
	const [userOptionsObject, setUserOptionsObject] = useState(null);
	const [ctaUnread, setCtaUnread] = useState(null);

	const [snoozeQuery, setSnoozeQuery] = useState("");
	const [snoozeCommands, setSnoozeCommands] = useState([]);

	const [actionCommandKOpen, setActionCommandKOpen] = useState(false);
	const [nameInputOpen, setNameInputOpen] = useState(false);
	const [statusCommandOpen, setStatusCommandOpen] = useState(false);
	const [priorityCommandOpen, setPriorityCommandOpen] = useState(false);
	const [assigneeCommandOpen, setAssigneeCommandOpen] = useState(false);
	const [snoozeCommandOpen, setSnoozeCommandOpen] = useState(false);
	const [createModalOpen, setCreateModalOpen] = useState(false);
	const [commandsEnabled, setCommandsEnabled] = useState(true);

	const [removeSplitCommandOpen, setRemoveSplitCommandOpen] = useState(null);
	const [currentSplits, setCurrentSplits] = useState(null);

	const [gPressed, setGPressed] = useState(false);
	const [paletteOpen, setPaletteOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const [zendeskEnabled, setZendeskEnabled] = useState(true);

	const {
		selectedIndex,
		setSelectedIndex,
		onPressUpArrow,
		onPressDownArrow,
	} = useScroll(
		filteredData ? filteredData.length : 0,
		menuOpen || paletteOpen
	);

	const history = useHistory();

	const myEmail = localStorage.getItem("user_email");
	const userId = localStorage.getItem("user_id");

	// Hook Data

	const {
		data: zendeskData,
		loading: zendeskDataloading,
		error: zendeskDataError,
	} = fetchSupportData();

	const {
		data: allCtaData,
		isLoading: ctaLoading,
		isError: ctaError,
		mutateCta,
	} = getCtaData();

	const {
		data: sellerantUsers,
		loading: usersLoading,
		error: usersError,
		mutate: mutateUsers,
	} = getSellerantUsers();

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	// Split data filtering

	useEffect(() => {
		if (userData && userData.email) {
			localStorage.setItem("user_email", userData.email);
		}
	}, [userData]);

	useEffect(() => {
		if (
			userData &&
			splitSettings &&
			allCtaData &&
			!ctaError &&
			!ctaLoading
		) {
			filterSplitData(
				userData,
				splitSettings,
				selectedSplit,
				allCtaData,
				setFilteredData,
				setSplitTitles,
				match.url === Routes.ALL_CTA_PAGE,
				match.url === Routes.ACTION_ITEMS_PAGE,
				zendeskData,
				match.url === Routes.ACTION_ITEMS_PAGE
			);
		}
	}, [allCtaData, userData, selectedSplit, splitSettings]);

	// Effects

	useEffect(() => {
		setZendeskEnabled(paragon.getUser()?.integrations?.zendesk?.enabled);
	}, [paragon]);

	useEffect(() => {
		if (userData) {
			if (match.url === Routes.ACTION_ITEMS_PAGE) {
				setSplitSettings(userData?.settings.action_items_split);
			} else {
				setSplitSettings(userData?.settings.all_cta_split);
			}
		}
	}, [userData, match.url]);

	useEffect(() => {
		if (match.url === Routes.ACTION_ITEMS_PAGE) {
			setSnoozeCommands(
				getSnoozeCommands(
					currentCtaData?.cta_id,
					mutateCtaSnoozed,
					snoozeQuery
				)
			);
		}
	}, [snoozeQuery, currentCtaData]);

	useEffect(() => {
		if (!snoozeCommandOpen) {
			setSnoozeQuery("");
		}
	}, [snoozeCommandOpen]);

	useEffect(() => {
		if (!filteredData) {
			return;
		}
		let currentIndex = selectedIndex;
		if (currentIndex === null) {
			if (match.path === Routes.CTA_PAGE) {
				for (let i = 0; i < filteredData.length; i++) {
					if (
						filteredData.data[i].cta_id.toString() ===
						match.params.cid
					) {
						currentIndex = i;
						setSelectedIndex(i);
						break;
					}
				}
			} else {
				currentIndex = 0;
				setSelectedIndex(0);
			}
		}
		if (currentIndex > filteredData.length - 1) {
			setSelectedIndex(0);
			currentIndex = 0;
		}
		let currentCta;
		if (filteredData.data) {
			currentCta = filteredData.data[currentIndex];
		}
		if (selectedSplit !== "support") {
			if (currentCta) {
				setCurrentCtaData(currentCta);
				setCtaName(currentCta.cta);
				setCtaUnread(currentCta?.visiblity?.unread?.includes(myEmail));
				setCommandsEnabled(true);
			} else {
				setCurrentCtaData(null);
				setCtaName(null);
				setCtaUnread(null);
				setCommandsEnabled(false);
			}
		}
	}, [selectedIndex, filteredData]);

	useEffect(() => {
		if (userOptions && currentCtaData?.visiblity?.assigned_to) {
			setAssigned(
				userOptions.find(
					(user) =>
						user.email === currentCtaData?.visiblity?.assigned_to[0]
				)
			);
		}
	}, [currentCtaData, userOptions]);

	useEffect(() => {
		if (!usersLoading && !usersError) {
			let users = getIconOptionsFromUsers(sellerantUsers.data);
			setUserOptions(users);
			let usersObject = {};
			users.map((user) => (usersObject[user.email] = user));
			setUserOptionsObject(usersObject);
		}
	}, [sellerantUsers]);

	useEffect(() => {
		if (sellerantUsers && !usersLoading && !usersError) {
			if (match.url in sourcePages) {
				setCurrentSplits(
					getUserSplits(
						sellerantUsers,
						userId,
						sourcePages[match.url],
						false
					)
				);
			}
		}
	}, [match.url, sellerantUsers]);

	useEffect(() => {
		if (
			actionCommandKOpen ||
			nameInputOpen ||
			statusCommandOpen ||
			priorityCommandOpen ||
			assigneeCommandOpen ||
			snoozeCommandOpen ||
			createModalOpen ||
			removeSplitCommandOpen
		) {
			setPaletteOpen(true);
		} else {
			setPaletteOpen(false);
		}
	}, [
		actionCommandKOpen,
		nameInputOpen,
		statusCommandOpen,
		priorityCommandOpen,
		assigneeCommandOpen,
		snoozeCommandOpen,
		createModalOpen,
		removeSplitCommandOpen,
	]);

	// Mutations

	const getIndexOfPriority = (priorityString) => {
		for (let i = 0; i < priorities.length; i++) {
			if (
				priorities[i].value.toLowerCase() ===
				priorityString.toLowerCase()
			) {
				return i + 1;
			}
		}
	};

	const updateLocalCtas = (ctaId, attr, newValue) => {
		let allCtaDataCopy = cloneDeep(allCtaData.data);
		for (let i = 0; i < allCtaDataCopy.length; i++) {
			if (allCtaDataCopy[i].cta_id === ctaId) {
				allCtaDataCopy[i][attr] = newValue;
				return { data: allCtaDataCopy };
			}
		}
	};

	const mutateCtaValue = async (
		currentValue,
		setValue,
		newValue,
		newNoteBody,
		ctaId,
		attr,
		endpoint
	) => {
		let previousValue = currentValue;
		if (previousValue === newValue) {
			return;
		}
		if (setValue) setValue(newValue);
		let newCtas = updateLocalCtas(ctaId, attr, newNoteBody);
		try {
			mutateCta(newCtas, false);
			await requestUpdate(endpoint, {
				cta_id: ctaId,
				note_body: newNoteBody,
			});
			mutateCta();
		} catch (err) {
			handleCaughtError(err);
			if (setValue) setValue(previousValue);
		}
	};

	const mutateStatus = async (newStatus) => {
		if (!commandsEnabled) return;
		await mutateCtaValue(
			status,
			setStatus,
			newStatus,
			newStatus,
			currentCtaData.cta_id,
			"status",
			"/api/updateCtaStatus"
		);
	};

	const mutatePriority = async (newPriority) => {
		if (!commandsEnabled) return;
		let newPriorityIndex = getIndexOfPriority(newPriority);
		await mutateCtaValue(
			priority,
			setPriority,
			newPriority,
			newPriorityIndex,
			currentCtaData.cta_id,
			"priority",
			"/api/updatePriority"
		);
	};

	const mutateAssigned = async (newAssignee) => {
		if (!commandsEnabled) return;
		let newVisibilityData = {
			...currentCtaData.visiblity,
			assigned_to: [newAssignee.email],
		};
		await mutateCtaValue(
			assigned,
			setAssigned,
			newAssignee,
			newVisibilityData,
			currentCtaData.cta_id,
			"visiblity",
			"/api/updateCtaVisiblity"
		);
	};

	const mutateCtaName = async (newName) => {
		if (!commandsEnabled) return;
		await mutateCtaValue(
			ctaName,
			setCtaName,
			newName,
			newName,
			currentCtaData.cta_id,
			"cta",
			"/api/updateCtaName"
		);
	};

	const mutateReadStatus = async (newReadStatus) => {
		if (!myEmail || !commandsEnabled) {
			return;
		}

		// Clone/Init variables
		let currentCtaCopy = cloneDeep(currentCtaData);
		let currentUnreadList = cloneDeep(currentCtaCopy.visiblity.unread);
		let newVisibilityData;

		if (newReadStatus === false) {
			// If marking as unread, add to list
			if (ctaUnread) return;
			newVisibilityData = {
				...currentCtaCopy.visiblity,
				unread: currentUnreadList.concat(myEmail),
			};
		} else {
			// If marking as read, remove from list
			if (!ctaUnread) return;
			let emailIndex = currentUnreadList.indexOf(myEmail);
			currentUnreadList.splice(emailIndex, 1);
			newVisibilityData = {
				...currentCtaCopy.visiblity,
				unread: currentUnreadList,
			};
		}

		// Set local state
		currentCtaCopy.visiblity.unread = currentUnreadList;
		setCurrentCtaData(currentCtaCopy);

		// Mutate
		await mutateCtaValue(
			currentCtaCopy.visiblity,
			null,
			newVisibilityData,
			newVisibilityData,
			currentCtaData.cta_id,
			"visiblity",
			"/api/updateCtaVisiblity"
		);
	};

	const assignToSelf = () => {
		if (!commandsEnabled) return;
		let myUserObject = userOptions.find((user) => user.email === myEmail);
		if (myUserObject) {
			mutateAssigned(myUserObject);
		}
	};

	const mutateCtaSnoozed = async (ctaId, time) => {
		if (!commandsEnabled) return;
		let newSnoozeObject;
		if (currentCtaData.snooze) {
			newSnoozeObject = {
				...currentCtaData.snooze,
				[myEmail]: time,
			};
		} else {
			newSnoozeObject = {
				[myEmail]: time,
			};
		}
		if (ctaId) {
			await mutateCtaValue(
				null,
				null,
				newSnoozeObject,
				newSnoozeObject,
				ctaId,
				"snooze",
				"/api/actionItems/snooze"
			);
		}
	};

	const createNewCta = async (
		ctaTitle,
		ctaDescription,
		company,
		type,
		playbook,
		assigned
	) => {
		// Skipping Expansion CTAs for now, until endpoint is fixed
		if (type.value === "Expansion Opportunity") return;
		let newCta = {
			title: ctaTitle,
			description: ctaDescription,
			company_id: company.id,
			type: type.value,
			playbookSlug: playbook.playbook.slug,
			assigned: assigned?.email,
		};
		await requestPost("/api/actionItems/createCTA", newCta);
		setCreateModalOpen(false);
	};

	const removeSplit = async (splitName, pageKey, pageName) => {
		const { usersCopy, mergedSettings } = removeSplitListHelper(
			pageName,
			sellerantUsers.data,
			splitName,
			pageKey,
			userId
		);
		// Mutate Sellerant Users with new changes
		try {
			mutateUsers({ data: usersCopy }, false);
			await requestUpdate("/api/updateSellerantUsers", {
				attr: "settings",
				newValue: mergedSettings,
			});
			mutateUsers();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	// Navigation

	const onPressTab = () => {
		if (selectedSplit && splitSettings) {
			let sel;
			for (let i = 0; i < splitSettings.length; i++) {
				if (splitSettings[i].slug == selectedSplit) {
					sel = splitSettings[i];
				}
			}
			let indx = splitSettings.indexOf(sel);
			if (indx >= splitSettings.length - 1) {
				setSelectedSplit(splitSettings[0].slug);
			} else {
				setSelectedSplit(
					splitSettings[splitSettings.indexOf(sel) + 1].slug
				);
			}
		}
	};

	const onPressEnter = () => {
		if (menuOpen || paletteOpen) {
			return;
		}
		if (
			selectedSplit == "support" &&
			filteredData !== null &&
			selectedIndex < filteredData.data.length
		) {
			history.push(
				`${Routes.ZENDESK_BASE_ROUTE}/${filteredData.data[selectedIndex].sellerant_id}/ticket`
			);
		} else {
			if (filteredData !== null && selectedIndex < filteredData.length) {
				history.push(
					`${Routes.CTA_BASE_ROUTE}/${filteredData.data[selectedIndex].cta_id}/todo`
				);
			}
		}
	};

	const onPressSearch = () => {
		if (match.url in sourcePages) {
			history.push(
				`${Routes.SEARCH_PAGE}?source=${sourcePages[match.url]}`
			);
		} else {
			history.push(
				`${Routes.SEARCH_PAGE}?source=${
					sourcePages[Routes.ACTION_ITEMS_PAGE]
				}`
			);
		}
	};

	// Hot Keys

	useHotkeys(
		"*",
		(event) => {
			if (!commandsEnabled && event.key !== "Tab" && event.key !== "/") {
				return;
			}
			if (paletteOpen) return;
			switch (event.key) {
				case "/":
					callWithoutModifiers(event, () => onPressSearch());
					setGPressed(false);
					break;
				case "Tab":
					event.preventDefault();
					callWithoutModifiers(event, () => onPressTab());
					setGPressed(false);
					break;
				case "a":
					if (!gPressed) {
						callWithoutModifiers(event, () =>
							setAssigneeCommandOpen(true)
						);
					}
					setGPressed(false);
					break;
				case "c":
					if (!gPressed) {
						callWithoutModifiers(event, () =>
							setCreateModalOpen(true)
						);
					}
					setGPressed(false);
					break;
				case "g":
					callWithoutModifiers(event, () => setGPressed(true));
					break;
				case "h":
					if (match.url === Routes.ACTION_ITEMS_PAGE) {
						callWithoutModifiers(event, () =>
							setSnoozeCommandOpen(true)
						);
					}
					break;
				case "i":
					if (!gPressed) {
						callWithoutModifiers(event, () => assignToSelf());
					}
					setGPressed(false);
					break;
				case "k":
					callWithModifiers(
						event,
						() => setActionCommandKOpen(true),
						["cmd"]
					);
					break;
				case "p":
					if (!gPressed) {
						callWithoutModifiers(event, () =>
							setPriorityCommandOpen(true)
						);
					}
					setGPressed(false);
					break;
				case "r":
					callWithoutModifiers(event, () => setNameInputOpen(true));
					break;
				case "s":
					if (!gPressed) {
						callWithoutModifiers(event, () =>
							setStatusCommandOpen(true)
						);
					}
					setGPressed(false);
					break;
				case "u":
					if (!gPressed) {
						callWithoutModifiers(event, () =>
							mutateReadStatus(ctaUnread)
						);
					}
					setGPressed(false);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[filteredData, commandsEnabled, paletteOpen, match.url]
	);

	// Commands

	const navigationCommands = getNavigationCommands(history);
	const statusCommands = getStatusCommands(mutateStatus);
	const priorityCommands = getPriorityCommands(mutatePriority);
	const assigneeCommands = getAssigneeCommands(userOptions, mutateAssigned);
	const actionItemCommands = getActionItemsCommands(
		setStatusCommandOpen,
		setPriorityCommandOpen,
		assignToSelf,
		setNameInputOpen,
		setAssigneeCommandOpen,
		mutateReadStatus,
		ctaUnread,
		match.url === Routes.ACTION_ITEMS_PAGE && setSnoozeCommandOpen
	);

	const zenCommands = getZendeskCommands(
		filteredData?.data
			? filteredData?.data[selectedIndex]?.ticket_data?.id
			: null
	);

	const sfCommands = getSalesforceCommands(currentCtaData?.type?.data?.Id);

	const customizationCommands = getCustomizationCommands(
		setRemoveSplitCommandOpen
	);
	const removeSplitCommands = getRemoveSplitCommands(
		currentSplits,
		removeSplit
	);

	const defaultCommands = actionItemCommands
		.concat(customizationCommands)
		.concat(navigationCommands);

	const salesforceCommands = actionItemCommands
		.concat(sfCommands)
		.concat(customizationCommands)
		.concat(navigationCommands);

	const zendeskCommands = actionItemCommands
		.concat(zenCommands)
		.concat(customizationCommands)
		.concat(navigationCommands);

	return (
		<div className="flex flex-row h-screen">
			{/* Page Body (Header + Grid) */}

			<div className="flex flex-col flex-grow h-full">
				<div className="flex flex-row flex-none w-full py-5">
					<div className="w-20 flex-none">
						<Menu userData={userData} setMenuOpen={setMenuOpen} />
					</div>
					<div className="flex-grow">
						<PageHeader
							onClickEdit={() => setCreateModalOpen(true)}
							editButtonTitle="Add New Call To Action"
							searchButtonTitle="Search Action Items"
							onClickSearch={onPressSearch}
							splits={splitTitles}
							setSelectedSplit={setSelectedSplit}
							selectedSplit={selectedSplit}
						/>
					</div>
				</div>

				{/* Grid */}
				<MainGrid
					data={filteredData}
					selectedId={selectedIndex}
					setSelectedId={setSelectedIndex}
					variant={
						selectedSplit === "support"
							? "support"
							: match.url === Routes.ACTION_ITEMS_PAGE
							? "actionItems"
							: "allCtas"
					}
					onPressUpArrow={onPressUpArrow}
					onPressDownArrow={onPressDownArrow}
					onPressEnter={onPressEnter}
					hoverEnabled={!menuOpen && !paletteOpen}
					setCommandKOpen={setActionCommandKOpen}
					setSnoozeOpen={setSnoozeCommandOpen}
					setAsigneesOpen={setAssigneeCommandOpen}
					loading={ctaLoading || zendeskDataloading}
					error={ctaError}
					userIcons={userOptionsObject}
				/>
			</div>

			{/* Sidebar */}
			<Sidebar ctaData={currentCtaData} variant="actionItems" />

			{/* Create CTA Modal */}
			<ModalContainer top="25%">
				{createModalOpen && (
					<CreateCtaModal
						setModalOpen={setCreateModalOpen}
						userOptions={userOptions}
						onClickCreate={createNewCta}
					/>
				)}
			</ModalContainer>

			{/* Command Palettes */}
			<ModalContainer top="30%">
				{actionCommandKOpen && (
					<CommandK
						options={
							filteredData?.data[selectedIndex]?.ticket_data?.id
								? zendeskCommands
								: currentCtaData?.type?.data?.Id
								? salesforceCommands
								: defaultCommands
						}
						setOpen={setActionCommandKOpen}
						variant="cta"
						header={`${currentCtaData?.company_name}${
							currentCtaData?.cta ? " - " + ctaName : ""
						}`}
					/>
				)}
				{statusCommandOpen && (
					<CommandK
						options={statusCommands}
						setOpen={setStatusCommandOpen}
						variant="actionItems"
						placeholder="Set a new status"
					/>
				)}
				{priorityCommandOpen && (
					<CommandK
						options={priorityCommands}
						setOpen={setPriorityCommandOpen}
						variant="actionItems"
						placeholder="Set a new priority"
					/>
				)}
				{assigneeCommandOpen && (
					<CommandK
						options={assigneeCommands}
						setOpen={setAssigneeCommandOpen}
						variant="actionItems"
						placeholder="Assign CTA to new user"
					/>
				)}
				{snoozeCommandOpen &&
					match.url === Routes.ACTION_ITEMS_PAGE && (
						<CommandK
							options={snoozeCommands}
							header={`Snooze: ${currentCtaData?.company_name}${
								currentCtaData?.cta ? " - " + ctaName : ""
							}`}
							setOpen={setSnoozeCommandOpen}
							variant="cta"
							placeholder="Try: 1 minute, 3 hours, 5 days..."
							customQuery={snoozeQuery}
							setCustomQuery={setSnoozeQuery}
						/>
					)}
				{removeSplitCommandOpen && (
					<CommandK
						setOpen={setRemoveSplitCommandOpen}
						options={removeSplitCommands}
						placeholder="Which split do you want to remove?"
					/>
				)}
			</ModalContainer>

			<ModalContainer top="40%">
				{nameInputOpen && (
					<CommandK
						setOpen={setNameInputOpen}
						variant="input"
						placeholder="What would you like to rename the cta?"
						onEnterInput={mutateCtaName}
					/>
				)}
			</ModalContainer>
			{selectedSplit === "support" && !zendeskEnabled && (
				<ModalContainer top="30%">
					<BlockPagePopup
						click={() =>
							history.push(`${Routes.SETTINGS_BASE_ROUTE}/local`)
						}
						title="Please enable your Zendesk integration to view this page"
						buttonTitle={`Go to Settings ->`}
					/>
				</ModalContainer>
			)}
		</div>
	);
});

export default ActionItems;

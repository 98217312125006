import React, { useEffect, useState, useRef } from "react";
import ChatBar from "./ChatBar";
import InputBar from "./InputBar";
import { useHotkeys } from "react-hotkeys-hook";
const ChatBox = ({
	data,
	userData,
	submitComment,
	setData,
	userNameDatabase,
}) => {
	let inputBarRef = useRef(null);
	let scrollbox = useRef(null);

	const [currChatBar, setCurrChatBar] = useState(null);
	const [currScroll, setCurrScroll] = useState(1);
	const [inputBarComment, setInputBarComment] = useState("");
	
	useEffect(() => {
		//used to set scroll height. Changes onLoad and newMessage (functionally)
		if (data !== undefined) {
			
			if (scrollbox) {
				if (scrollbox.current.scrollHeight !== currScroll) {
			
					setCurrScroll(scrollbox.current.scrollHeight);
				}
			}
		}
		console.log("USERNAME DATABASE", userNameDatabase)
	}, [data]);

	useEffect(() => {
		//This timeout is to deal with timing bug. Doesn't immediately append so needs a slight delay. Can be improved
		setTimeout(function () {
			updateScroll();
		}, 10);
	}, [currScroll]);
	function updateScroll() {
	
		if (scrollbox) {
			var element = scrollbox.current;
			element.scrollTop = element.scrollHeight;
		}
	}

	const onKeyDown = (e) => {
		//Submit internal note using enter key
		if (e.key === "Enter") {
		
			submitComment(inputBarComment, false);
			inputBarRef.current.value = " ";
			setCurrChatBar(null);
			updateScroll();
		}
	};

	if (data !== undefined) {
		return (
			<>
				<div className="h-screen w-full">
					<div className=" h-3/4 w-11/12  mx-auto border border-gray-200 shadow-sm border-opacity-50 rounded-lg">
						<div
							className="w-full mt-2 h-5/6 overflow-scroll "
							ref={scrollbox}
						>
							{/* {updateScroll()} */}
							{data.ticket_comments.output.comments.map(
								(item) => {
									return (
										<ChatBar
											submitComment={submitComment}
											author={item.author_id}
											body={item.body}
											id={item.id}
											created={item.created_at}
											isPublic={item.public}
											userData={userData}
											commentData={data}
											selected={setCurrChatBar}
											displayReplyBox={currChatBar}
											userNameDatabase={userNameDatabase}
											updateScroll={updateScroll}
										></ChatBar>
									);
								}
							)}
						</div>

						<div className="pt-8 flex mx-auto w-full relative bg-opacity-0 bg-white border-t-2 border-opacity-10 border-gray-200 z-20">
							<InputBar
								inputref={inputBarRef}
								onKeyDown={onKeyDown}
								setInputBarComment={setInputBarComment}
							></InputBar>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <div>Loading</div>;
	}
};
export default ChatBox;

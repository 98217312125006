import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { settingsSplits } from "../constants";
import { getFetcher, getUserInfo, requestPost } from "../requests";
import { handleCaughtError } from "../utils";

const SettingsContext = React.createContext();

const SettingsContextWrapper = ({ children }) => {
	const [waiting, setWaiting] = useState(true);
	const [backPage, setBackPage] = useState(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [splitIndex, setSplitIndex] = useState(null);
	const [connected, setConnected] = useState([]);
	const [nylasData, setNylasData] = useState(null);
	const [splits, setSplits] = useState(settingsSplits.slice(0, 3));

	let { page } = useParams();

	// Data Hooks

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	// Effects

	useEffect(() => {
		setSplitIndex(
			settingsSplits.findIndex((split) => split.id.toLowerCase() === page)
		);
	}, [page]);

	useEffect(() => {
		if (userData) {
			getNylasStatus();
			if (userData.admin) {
				setSplits(settingsSplits);
			} else {
				setSplits(settingsSplits.slice(0, 2));
			}
		}
	}, [userData]);

	useEffect(() => {
		if (userData) {
			getConnectedIntegrations();
		} else {
			setWaiting(true);
		}
	}, [userData]);

	// Mutations/Requests

	const getNylasStatus = async () => {
		try {
			const data = await getFetcher("/api/email/fetchAuthUrl");
			setNylasData(data);
		} catch (err) {
			handleCaughtError(err);
		}
	};

	const getConnectedIntegrations = async () => {
		if (paragon.getUser()) {
			setConnected(paragon.getUser().integrations);
			setWaiting(false);
		} else {
			try {
				let res = await requestPost("/api/generateParagonJWT", {
					userEmail: userData.email,
				});

				await paragon.authenticate(
					"8e2ac7c3-6825-4ab2-8c86-6e148dd00ecf",
					res
				);
				let user = await paragon.getUser();
				setConnected(user.integrations);
				setWaiting(false);
			} catch (err) {
				handleCaughtError(err);
			}
		}
	};

	return (
		<SettingsContext.Provider
			value={{
				waiting,
				setWaiting,
				backPage,
				setBackPage,
				menuOpen,
				setMenuOpen,
				splitIndex,
				setSplitIndex,
				connected,
				setConnected,
				nylasData,
				setNylasData,
				splits,
				setSplits,

				userData,
				userDataLoading,
				userDataError,

				getNylasStatus,
				getConnectedIntegrations,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
};

export { SettingsContext, SettingsContextWrapper };

import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import CtaHeader from "../components/CtaComponents/Header";
import SubGrid from "../components/SharedComponents/SubGrid/SubGrid";
import {
	getCtaData,
	getSellerantUsers,
	requestPost,
	requestUpdate,
} from "../requests";
import {
	Sidebar,
	NavBar,
	FillableSidebar,
	SidebarZendesk,
	ModalContainer,
	CommandK,
} from "../components";
import cloneDeep from "lodash.clonedeep";
import ViewTickets from "../components/ZendeskChat/ZendeskComponents/Tickets";
import Routes from "../Routes";
import fetchSupportData from "../components/data/getSupportTicketData";
import ZendeskHeader from "../components/ZendeskChat/ZendeskComponents/ZendeskHeader";
import { mutate } from "swr";
import { getNavigationCommands, getZendeskCommands } from "../commands";
import { callWithModifiers, callWithoutModifiers } from "../utils";

const createHeaderTabs = (ctaData) => {
	return [
		{
			name: "Ticket",
			url: `/zendesk/${ctaData.cta_id}/ticket`,
			page: "ticket",
		},
		{
			name: "Timeline",
			url: `/zendesk/${ctaData.cta_id}/timeline`,
			page: "timeline",
		},
		{
			name: "Account Profile ↗",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${ctaData.company_id}/overview`,
		},
	];
};

const ZendeskTicket = withRouter(({ match, history }) => {
	const [ctaData, setCtaData] = useState(null);
	const [headerTabs, setHeaderTabs] = useState([]);
	const [timelineData, setTimelineData] = useState(null);
	const [ticketTitle, setTicketTitle] = useState("Loading...");
	const [currZendeskId, setCurrZendeskId] = useState(null);
	const [ticketIndex, setTicketIndex] = useState(null);
	const [currTicket, setCurrTicket] = useState(null);
	const [currZendeskData, setCurrZendeskData] = useState(null);
	const [currCompanyId, setCurrCompanyId] = useState(null);
	const [otherTicketData, setOtherTicketData] = useState(null);

	// command K
	const [actionCommandKOpen, setActionCommandKOpen] = useState(false);

	// Hook Data

	const { data, isLoading, isError, mutateCta } = getCtaData();

	const {
		data: zendeskData,
		loading: ctaDataLoading,
		error: ctaDataError,
	} = fetchSupportData();

	const getTickets = async (id) => {
		setCurrCompanyId(id);
		let otherTickets = await requestTickets(id);
		setOtherTicketData(otherTickets);
	};
	const requestTickets = async (id) => {
		const otherTickets = await requestUpdate(
			"/api/zendesk/getOtherZendeskTickets",
			{ id }
		);
		return otherTickets;
	};

	// Effects

	useEffect(() => {
		if (data && !isLoading && !isError) {
			for (let i = 0; i < data.length; i++) {
				let currentCta = data.data[i];
				if (currentCta.cta_id.toString() === match.params.cid) {
					setCtaData(currentCta);
					setTimelineData(currentCta.timeline);
					setHeaderTabs(createHeaderTabs(currentCta));
					// console.log(currentCta.company_id);
					// if (currentCta?.company_id) {
					// 	getTickets(currentCta?.company_id);
					// } else {
					// 	setCurrCompanyId(null);
					// 	setOtherTicketData(null);
					// }
					break;
				}
				setCurrZendeskId(match.params.cid);
			}
		}
	}, [data, match.params.cid]);

	useEffect(() => {
		if (ctaDataError) {
			// console.log("ERROR YOUR SO SCREWED", ctaDataError);
		}
		setCurrZendeskData(zendeskData);
	}, [zendeskData]);

	useEffect(() => {
		if (currZendeskData && currZendeskData.ticketData !== undefined) {
			for (let i in currZendeskData.ticketData) {
				if (
					currZendeskData.ticketData[i].sellerant_id ===
					parseInt(match.params.cid)
				) {
					setTicketTitle(
						currZendeskData.ticketData[i].ticket_data.raw_subject
					);
					setCurrTicket(currZendeskData.ticketData[i]);
					setTicketIndex(parseInt(i));
				}
			}
		}
	}, [currZendeskData, currZendeskId, match.params.cid]);

	// Navigation
	//	todo: send back to support page
	const onClickBack = () => {
		history.push(Routes.ACTION_ITEMS_PAGE);
	};

	const onClickUp = () => {
		let selectedIndex;

		if (zendeskData && ticketIndex !== null) {
			if (ticketIndex === 0) {
				selectedIndex = zendeskData.ticketData.length - 1;
			} else {
				selectedIndex = ticketIndex - 1;
			}

			history.push(
				`${Routes.ZENDESK_BASE_ROUTE}/${zendeskData.ticketData[selectedIndex].sellerant_id}/ticket`
			);
			setCurrZendeskId(
				zendeskData.ticketData[selectedIndex].sellerant_id
			);
		}
	};

	const onClickDown = () => {
		let selectedIndex;
		if (zendeskData && ticketIndex !== null) {
			if (ticketIndex === zendeskData.ticketData.length - 1) {
				selectedIndex = 0;
			} else {
				selectedIndex = ticketIndex + 1;
			}
			history.push(
				`${Routes.ZENDESK_BASE_ROUTE}/${zendeskData.ticketData[selectedIndex].sellerant_id}/ticket`
			);
			setCurrZendeskId(
				zendeskData.ticketData[selectedIndex].sellerant_id
			);
		}
	};

	// Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "j":
					callWithoutModifiers(event, () => onClickDown());
					break;
				case "k":
					callWithoutModifiers(event, () => onClickUp());
					callWithModifiers(
						event,
						() => setActionCommandKOpen(true),
						["cmd"]
					);
					break;
				case "e":
					requestPost("/api/actionItems/dismissCTA", { cta_id });
					onClickDown();
					break;
				case "Escape":
					onClickBack();
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[match.params.cid]
	);

	if (isError) return <div>failed to load</div>;

	if (isLoading) {
		return (
			<div className="flex flex-row h-screen">
				<NavBar
					onClickBack={onClickBack}
					onClickDown={onClickDown}
					onClickUp={onClickUp}
					verticalArrowsVisible={false}
				/>
				<div className="flex-grow h-screen" />
				<Sidebar ctaData={null} />
			</div>
		);
	}
	const mutateTicketData = (data) => {
		var tmp = cloneDeep(currZendeskData);

		if (tmp) {
			for (let i in tmp.ticketData) {
				if (tmp.ticketData[i].sellerant_id === data.sellerant_id) {
					tmp.ticketData[i] = data;
				}
			}
		}

		mutate("/api/support/getSupportTickets", tmp, false);
		setCurrZendeskData(tmp);

		mutate("/api/support/getSupportTickets");
	};
	const mutateStatus = async (newStatus) => {
		let updatedTicket = cloneDeep(currTicket);
		updatedTicket.ticket_data.status = newStatus;
		mutateTicketData(updatedTicket);
		await requestUpdate("/api/zendesk/updateStatus", {
			status: newStatus,
			ticketId: currZendeskId,
		});
	};
	const mutatePriority = async (newPriority) => {
		let updatedTicket = cloneDeep(currTicket);
		updatedTicket.ticket_data.priority = newPriority;
		mutateTicketData(updatedTicket);
		await requestUpdate("/api/zendesk/updateZendeskPriority", {
			priority: newPriority,
			ticketId: currZendeskId,
		});
	};

	// all of clarke added will rearrange
	const [assigned, setAssigned] = useState("");
	const [status, setStatus] = useState("");
	const [priority, setPriority] = useState("");
	const [followers, setFollowers] = useState([]);
	const [priorityDropdownOpen, setPriorityDropdownOpen] = useState(false);
	const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
	const [assignedDropdownOpen, setAssignedDropdownOpen] = useState(false);
	const [followerDropdownOpen, setFollowerDropdownOpen] = useState(false);

	const makeFirstLetterBig = (word) => {
		if (word) {
			return word.charAt(0).toUpperCase() + word.slice(1);
		}
		return null;
	};

	useEffect(() => {
		if (currTicket) {
			let ticketUsers = currTicket?.ticket_users?.data.output.users;
			if (ticketUsers?.length > 0) {
				setAssigned(
					ticketUsers?.filter((user) => {
						if (user.id === currTicket.ticket_data.assignee_id) {
							return user;
						}
					})[0]
				);
				let statusRaw = currTicket.ticket_data?.status;
				setStatus(makeFirstLetterBig(statusRaw));
				let priorityRaw = currTicket.ticket_data?.priority;
				setPriority(makeFirstLetterBig(priorityRaw));
				if (currTicket?.company_id) {
					getTickets(currTicket?.company_id);
				} else {
					setCurrCompanyId(null);
					setOtherTicketData(null);
				}
				// followers last
			}
		}
	}, [currTicket]);
	console.log(currTicket);
	//Commands
	const navigationCommands = getNavigationCommands(history);
	const zenCommands = getZendeskCommands(
		currTicket?.ticket_id ? currTicket.ticket_id : null
	);
	return (
		<div className="flex flex-row h-screen">
			<NavBar
				onClickBack={onClickBack}
				onClickDown={onClickDown}
				onClickUp={onClickUp}
			/>

			<div className="flex flex-col flex-grow h-screen">
				<ZendeskHeader title={ticketTitle}></ZendeskHeader>
				<CtaHeader
					hideInformation={true}
					tabs={headerTabs}
					title={ctaData?.company_name}
					subtitle={ctaData?.cta}
					grade={ctaData?.grade}
					activePage={match.params.page}
					stage={ctaData?.stage}
					skipLastTab
				/>

				{/*TODO: swap out the TODO component with the ticket component  */}

				{match.params.page === "timeline" ? (
					<div className="h-full">
						<SubGrid
							data={timelineData.notes}
							variant={"timeline"}
						/>
					</div>
				) : (
					<ViewTickets
						mutateTicketData={mutateTicketData}
						zendeskData={currTicket}
					></ViewTickets>
				)}
			</div>

			<SidebarZendesk
				ctaData={ctaData}
				zendeskData={currTicket}
				assigned={assigned}
				status={status}
				mutateStatus={mutateStatus}
				mutatePriority={mutatePriority}
				priority={priority}
				priorityDropdownOpen={priorityDropdownOpen}
				setPriorityDropdownOpen={setPriorityDropdownOpen}
				statusDropdownOpen={statusDropdownOpen}
				setStatusDropdownOpen={setStatusDropdownOpen}
				assignedDropdownOpen={assignedDropdownOpen}
				setAssignedDropdownOpen={setAssignedDropdownOpen}
				followerDropdownOpen={followerDropdownOpen}
				setFollowerDropdownOpen={setFollowerDropdownOpen}
				otherTicketData={otherTicketData}
				companyId={currCompanyId}
				// userOptions={userOptions}
			/>
			<ModalContainer top="30%">
				{actionCommandKOpen && (
					<CommandK
						options={zenCommands.concat(navigationCommands)}
						setOpen={setActionCommandKOpen}
						variant="actionItems"
						header={""}
					/>
				)}
			</ModalContainer>
		</div>
	);
});

export default ZendeskTicket;

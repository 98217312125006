import React from "react";
import { useHistory } from "react-router";

import { ResolveButton } from ".";
import { Grade } from "..";
import Routes from "../../Routes";

const SidebarProfile = ({
	name,
	title,
	grade = null,
	onClickButton,
	variant,
	stage = "Resolve",
	ctaData,
}) => {
	let history = useHistory();

	if (variant === "cta" || variant === "account") {
		return (
			<div
				id="sidebar-header-section"
				className="flex flex-row w-full mb-5 "
			>
				<span
					id="title-section"
					className="flex relative flex-row items-center h-8 w-full"
				>
					{ctaData?.company_clearbit_info?.logo && (
						<img
							src={`${ctaData?.company_clearbit_info?.logo}`}
							className="rounded left-0 h-8 w-8"
						></img>
					)}

					<p
						className={`ml-2 ${
							ctaData?.company_name?.length > 20
								? "h5"
								: ctaData?.company_name?.length > 10
								? "h4"
								: "h3"
						} w-48 truncate`}
					>
						{ctaData?.company_name}
					</p>
					<div className="ml-3 absolute right-0">
						{ctaData?.grade && (
							<Grade grade={ctaData?.grade} size={32} text="md" />
						)}
					</div>
					<div className="right-0 absolute">
						{/* <ResolveButton
							onClick={onClickButton}
							className="right-0"
						/> */}
					</div>
				</span>
			</div>
		);
	}
	if (variant === "actionItems") {
		return (
			<div
				id="sidebar-header-section"
				className="flex flex-row w-full  mb-5"
			>
				{/* Display Grade if provided in props */}

				{grade && <Grade grade={grade} size={48} text="lg" />}

				{/* Name and title displayed to the right of the picture/grade */}
				<div
					id="info-container"
					className="flex flex-col flex-grow justify-center mx-3"
				>
					<span
						id="title-section"
						className="flex flex-row justify-between items-center h-1/2 w-48"
					>
						<p className="text-base truncate ">{name}</p>
					</span>
					<span
						id="subtitle-section"
						className="flex justify-between items-center h-1/2 w-48"
					>
						{title && (
							<p className="text-gray-500 text-xs font-light">
								{title}
							</p>
						)}
						{variant === "actionItems" && (
							<ResolveButton
								label={stage}
								variant={stage}
								onClick={() =>
									history.push(
										`${Routes.SEARCH_PAGE}/stage:${stage}?source=actionItems`
									)
								}
							/>
						)}
					</span>
				</div>
			</div>
		);
	} else {
		return (
			<div
				id="sidebar-header-section"
				className="flex flex-row w-full  mb-5"
			>
				{/* Display Grade if provided in props */}

				{grade && <Grade grade={grade} size={48} text="lg" />}

				{/* Name and title displayed to the right of the picture/grade */}
				<div
					id="info-container"
					className="flex flex-col flex-grow justify-center mx-3"
				>
					<span
						id="title-section"
						className="flex flex-row justify-between items-center h-1/2 w-48"
					>
						<p className="text-base truncate ">{name}</p>
					</span>
					<span
						id="subtitle-section"
						className="flex justify-between items-center h-1/2 w-48"
					>
						{title && (
							<p className="text-gray-500 text-xs font-light">
								{title}
							</p>
						)}
					</span>
				</div>
			</div>
		);
	}
};
export default SidebarProfile;

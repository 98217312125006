import { useEffect, useRef, useState } from "react";
import { UserIcon } from "..";
import { useOutsideAlerter } from "../../hooks";
import CommandIcon from "./CommandIcon";

const SidebarDropdown = ({
	header = null,
	title,
	icon,
	status,
	options,
	setOpen,
	open = false,
	onSelect,
	keyCommand,
	variant = "sidebar",
	bodyStyle = null,
	onPressTab,
	onPressEnter,
	fixedHeaderWidth = false,
}) => {
	let inputRef = useRef(null);
	let dropdownRef = useRef(null);

	useOutsideAlerter(dropdownRef, () => setOpen(false));

	const [query, setQuery] = useState("");
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [filteredData, setFilteredData] = useState(options);
	const [localStatus, setLocalStatus] = useState(status);
	const [localOptions, setLocalOptions] = useState(options);

	if (variant == "assigned" && options[0]?.email != "unassigned") {
		options.unshift({
			email: "unassigned",
			icon: <UserIcon user={null} />,
			value: "Unassigned",
		});
	}

	// Effects

	useEffect(() => {
		if (open) {
			inputRef.current.focus();
			if (status) {
				getSelectedIndexFromStatus();
			}
		} else {
			setQuery("");
		}
	}, [open]);

	useEffect(() => {
		setLocalStatus(status);
	}, [status]);

	useEffect(() => {
		setLocalOptions(options);
		setFilteredData(options);
	}, [options]);

	useEffect(() => {
		setSelectedIndex(0);
		if (localOptions) {
			setFilteredData(
				localOptions.filter((option) => filterByQuery(option.value))
			);
		}
	}, [query]);

	// Filter Functions

	const getSelectedIndexFromStatus = () => {
		for (let i = 0; i < localOptions.length; i++) {
			if (localOptions[i].value.toLowerCase() === status.toLowerCase()) {
				setSelectedIndex(i);
				break;
			}
		}
	};

	const filterByQuery = (input) => {
		let queryLength = query.length;
		if (
			query
				.toLowerCase()
				.localeCompare(input.toLowerCase().slice(0, queryLength)) === 0
		) {
			return true;
		}
		let words = input.split(" ");
		for (let i = 0; i < words.length; i++) {
			if (words[i].toLowerCase().startsWith(query.toLowerCase())) {
				return true;
			}
		}
	};

	// Navigation

	const onDownArrow = () => {
		if (filteredData.length > 0) {
			if (selectedIndex === null) {
				setSelectedIndex(0);
			} else {
				let newIndex = selectedIndex + 1;
				setSelectedIndex(newIndex % filteredData.length);
			}
		}
	};

	const onUpArrow = () => {
		if (filteredData.length > 0) {
			if (selectedIndex === null || selectedIndex === 0) {
				setSelectedIndex(filteredData.length - 1);
			} else {
				setSelectedIndex((idx) => idx - 1);
			}
		}
	};

	const onEnter = () => {
		if (selectedIndex !== null && selectedIndex < filteredData.length) {
			if (variant === "assigned" || variant === "createCta") {
				onSelect(filteredData[selectedIndex]);
			} else {
				onSelect(filteredData[selectedIndex].value);
			}
			setOpen(false);
		}
	};

	// Hot Keys

	const onKeyDown = (e) => {
		if (e.key === "ArrowDown") {
			onDownArrow();
		} else if (e.key === "ArrowUp") {
			onUpArrow();
		} else if (e.key === "Enter") {
			onEnter();
			if (onPressEnter) onPressEnter(e);
		} else if (e.key === "Tab" && onPressTab) {
			onPressTab(e);
		}
	};

	return (
		<div id="dropdown-container">
			{/* Dropdown header - Section that's always visible */}

			{header}

			{!header && (
				<div
					id="dropdown-header"
					className="flex flex-row justify-start items-center"
				>
					<div
						id="title-section"
						className={
							fixedHeaderWidth ? "w-1/4" : "pr-2 flex-none"
						}
					>
						<p className="body-small-gray">{title}</p>
					</div>
					<div
						id="indicator-section"
						className={`flex flex-row items-center flex-grow mr-4 px-2 py-3 rounded text-gray-900 cursor-pointer hover:bg-hovergray ${
							variant == "sidebar" || variant == "assigned"
								? "w-2/3 h-3"
								: "ml-1 h-5"
						}`}
						onClick={() => setOpen(true)}
					>
						{icon && (
							<span className="flex flex-row justify-center w-4 mr-2">
								{icon}
							</span>
						)}
						<p className="body-small-black truncate">
							{localStatus}
						</p>
					</div>
				</div>
			)}

			{/* Dropdown body - Section that appears when header is clicked */}
			<div className="relative">
				{open && (
					<div
						id="dropdown-body"
						className="flex flex-col bg-white rounded-md absolute z-50 right-56 top-1 shadow pt-1 w-48"
						style={bodyStyle}
						ref={dropdownRef}
					>
						<span className="flex flex-row justify-between items-center py-1 px-3 mb-1 w-full bg-white">
							<input
								className="text-xs whitespace-nowrap outline-none flex-grow w-3/4 pr-1"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								placeholder={`Set ${title}...`}
								ref={inputRef}
								onKeyDown={onKeyDown}
							/>
							{keyCommand && (
								<CommandIcon keyCommand={keyCommand} />
							)}
						</span>

						<div
							className="h-full w-full overflow-scroll overflow-x-hidden"
							style={{ maxHeight: "120px" }}
						>
							{filteredData &&
								filteredData.map((option, index) => (
									<div
										className={`flex flex-row items-center w-full py-1 px-3 cursor-pointer 
										${index === selectedIndex ? "bg-gray-100" : ""}
										${index === filteredData.length - 1 ? "mb-1" : ""}
										`}
										key={index}
										onClick={() => {
											{
												variant === "assigned" ||
												variant === "createCta"
													? onSelect(option)
													: onSelect(option.value);
											}
											if (onPressEnter) onPressEnter();
											setOpen(false);
										}}
										onMouseEnter={() =>
											setSelectedIndex(index)
										}
									>
										{option.icon && (
											<span className="flex flex-row justify-center w-4">
												{option.icon}
											</span>
										)}
										<p className="text-gray-900 text-xs pl-2 whitespace-nowrap truncate">
											{option.value}
										</p>
									</div>
								))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default SidebarDropdown;

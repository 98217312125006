import React, { useEffect, useRef, useState } from "react";
import { BsPerson } from "react-icons/bs";

import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import TaskButton from "./TaskButton";
import TodoDatePicker from "./TodoDatePicker";
import { SmallDropdown } from "../..";
import { callWithModifiers } from "../../../utils";

const AddTaskPopup = ({
	setOpen,
	onClickSave,
	userOptions,
	isAssignable = true,
}) => {
	let modalRef = useRef(null);
	let titleRef = useRef(null);
	let descriptRef = useRef(null);

	const [titleText, setTaskTitle] = useState("");
	const [descriptText, setTaskDescript] = useState("");
	const [currentDate, setCurrentDate] = useState(null);
	const [assigneeMenuOpen, setAssigneeMenuOpen] = useState(false);
	const [currentAssignee, setCurrentAssignee] = useState("");

	useOutsideAlerter(modalRef, () => setOpen(false));

	// Effects

	useEffect(() => {
		titleRef.current.style.height = "0px";
		const scrollHeight = titleRef.current.scrollHeight;
		titleRef.current.style.height = scrollHeight + "px";
	}, [titleText]);

	useEffect(() => {
		descriptRef.current.style.height = "0px";
		const scrollHeight = descriptRef.current.scrollHeight;
		descriptRef.current.style.height = scrollHeight + "px";
	}, [descriptText]);

	// Event Handlers

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(
				e,
				() => {
					submitTheTodo();
				},
				["cmd"]
			);
		}
	};

	const submitTheTodo = () => {
		onClickSave(
			titleText,
			descriptText,
			currentDate,
			currentAssignee.email
		);
		setOpen(false);
	};

	return (
		<div ref={modalRef} style={{ bottom: "2vh" }} className="absolute z-50">
			<div className="flex flex-col flex-grow justify-start border border-gray-100 rounded-md z-50 cloud-shadow bg-white">
				<div className="w-full p-3 flex-grow">
					<textarea
						className="text-sm flex-grow w-full rounded font-normal text-gray-900"
						onChange={(e) => setTaskTitle(e.target.value)}
						onKeyDown={onKeyDown}
						placeholder={"Task title..."}
						ref={titleRef}
						rows={0}
						style={{ resize: "none" }}
						value={titleText}
					/>
				</div>

				<div className="w-full px-3 pb-3">
					<textarea
						className="text-xs flex-grow w-full rounded font-normal text-gray-900"
						onChange={(e) => setTaskDescript(e.target.value)}
						onKeyDown={onKeyDown}
						placeholder={"Add a description..."}
						ref={descriptRef}
						rows={0}
						style={{ resize: "none" }}
						value={descriptText}
					/>
				</div>

				<div className="flex flex-row p-3 w-full">
					<div className="mr-2">
						<TodoDatePicker
							setSelectedDate={setCurrentDate}
							selectedDate={currentDate}
							newTodo={true}
						/>
					</div>
					<div className="mr-2">
						{isAssignable && (
							<SmallDropdown
								header={
									<TaskButton
										label={
											currentAssignee.value ?? (
												<>
													<BsPerson
														className="mr-1"
														size={14}
													/>{" "}
													Assignee
												</>
											)
										}
										onClick={() =>
											setAssigneeMenuOpen(true)
										}
										colorClasses="bg-gray-100 hover:bg-gray-200"
									/>
								}
								options={userOptions}
								setOpen={setAssigneeMenuOpen}
								open={assigneeMenuOpen}
								onSelect={(user) => {
									setCurrentAssignee(user);
								}}
								status={currentAssignee}
								variant="assigned"
								bodyStyle={{ right: "-75px", top: "-100px" }}
								title="assignee"
							/>
						)}
					</div>
					<TaskButton
						label="Save"
						colorClasses="bg-blue-400 hover:bg-blue-500 text-white"
						onClick={submitTheTodo}
						onKeyDown={onKeyDown}
					/>
				</div>
			</div>
		</div>
	);
};

export default AddTaskPopup;

import React from "react";

import { ReactComponent as Med1 } from "../../assets/med1.svg";
import { ReactComponent as Med2 } from "../../assets/med2.svg";
import { ReactComponent as Med3 } from "../../assets/med3.svg";

const MediumPriorityIcon = () => (
	<div className="flex items-end">
		<Med1 />
		<Med2 />
		<Med3 />
	</div>
);

export default MediumPriorityIcon;

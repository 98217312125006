import { useState } from "react";

import { isInViewport, scrollRow } from "../utils";

const useScroll = (listLength, blocked = false) => {
	const [selectedIndex, setSelectedIndex] = useState(null);

	const onPressUpArrow = () => {
		if (blocked) {
			return;
		}
		if (selectedIndex !== null) {
			if (selectedIndex === 0) {
				setSelectedIndex(listLength - 1);
				scrollRow(document.getElementById(listLength - 1), false);
			} else {
				setSelectedIndex((idx) => idx - 1);
				let row = document.getElementById(selectedIndex - 1);
				if (!isInViewport(row, true)) {
					scrollRow(row);
				}
			}
		} else {
			setSelectedIndex(listLength - 1);
			scrollRow(document.getElementById(listLength), false);
		}
	};

	const onPressDownArrow = () => {
		if (blocked) {
			return;
		}
		if (selectedIndex !== null) {
			if (selectedIndex === listLength - 1) {
				setSelectedIndex(0);
				let row = document.getElementById(0);
				if (!isInViewport(row, false)) {
					scrollRow(row);
				}
			} else {
				setSelectedIndex((idx) => idx + 1);
				let row = document.getElementById(selectedIndex + 1);
				if (!isInViewport(row, true)) {
					scrollRow(row, false);
				}
			}
		} else {
			setSelectedIndex(0);
			let row = document.getElementById(0);
			if (!isInViewport(row, false)) {
				scrollRow(row);
			}
		}
	};

	return {
		selectedIndex,
		setSelectedIndex,
		onPressUpArrow,
		onPressDownArrow,
	};
};

export default useScroll;

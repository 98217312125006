import { ReactComponent as Zendesk } from "../assets/zendesk.svg";
export default (Id, tenant_url = "d3v-sellerant.zendesk.com") => [
	{
		icon: <Zendesk className="-ml-1" style={{ width: 18, height: 18 }} />,
		title: "Open in Zendesk",

		command() {
			window
				.open(`https://${tenant_url}/agent/tickets/${Id}`, "_blank")
				.focus();
		},
	},
];

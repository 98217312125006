import React, { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router-dom";

import { integrations } from "../constants";
import { callWithModifiers } from "../utils";
import { getNavigationCommands } from "../commands";
import { getFetcher, getUserInfo } from "../requests";
import getFiveTranIntegrations from "../requests/swr/getFiveTranIntegrations";

import {
	CommandK,
	FillableSidebar,
	Menu,
	ModalContainer,
	PageHeader,
} from "../components";
import IntegrationButton from "../components/IntegrationComponents/IntegrationButton";
import { Contactless } from "@material-ui/icons";

const Integrations = () => {
	// const [alreadyConnected, setConneted] = useState(false);

	const [navCommandsOpen, setNavCommandsOpen] = useState(false);

	const history = useHistory();

	// Data Hooks

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	const {
		data: fiveTranData,
		loading: fiveTranDataLoading,
		error: fiveTranError,
	} = getFiveTranIntegrations();

	const getConnectionData = (service, connectionData) => {
		if (connectionData == false) {
			return "loading";
		} else {
			let output = connectionData.items.filter(
				(item) => item.service === service
			);
			if (output.length == 0) {
				//return error if we cant find it
				return "notConnected";
			} else {
				//return setup state and id
				return {
					status: output[0].status.setup_state,
					id: output[0].id,
				};
			}
		}
	};

	// Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "k":
					callWithModifiers(event, () => setNavCommandsOpen(true), [
						"cmd",
					]);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	// Commands

	const navigationCommands = getNavigationCommands(history);

	if (fiveTranDataLoading == true) {
		//application is loading
		return (
			<div className="flex flex-col flex-grow h-full">
				{/* Grid */}
				<div className="px-16 pt-4 overflow-scroll">
					<p>Loading...</p>
				</div>

				{/* Modals */}
				<ModalContainer top="30%">
					{navCommandsOpen && (
						<CommandK
							options={navigationCommands}
							setOpen={setNavCommandsOpen}
							variant="actionItems"
							placeholder="Go to..."
						/>
					)}
				</ModalContainer>
			</div>
		);
	} else {
		return (
			<div className="flex flex-col flex-grow h-full">
				{/* Grid */}
				<div className="px-16 pt-4 overflow-scroll">
					{integrations.map((item, itemIndex) => (
						<div key={itemIndex}>
							<p className="pl-4 font-bold pt-2 text-lg">
								{item.title}
							</p>
							<div className="grid grid-cols-4 gap-x-6">
								{item.integrations.map(
									(integration, intIndex) => (
										<IntegrationButton
											key={intIndex}
											integration_type={integration}
											connected={getConnectionData(
												integration,
												fiveTranData
											)}
										/>
									)
								)}
							</div>
						</div>
					))}
				</div>

				{/* Modals */}
				<ModalContainer top="30%">
					{navCommandsOpen && (
						<CommandK
							options={navigationCommands}
							setOpen={setNavCommandsOpen}
							variant="actionItems"
							placeholder="Go to..."
						/>
					)}
				</ModalContainer>
			</div>
		);
	}
};

export default Integrations;

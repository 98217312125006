import React, { useEffect, useState } from "react";
import useSWR from "swr";
import fetch from "unfetch";
import { BsArrowReturnLeft } from "react-icons/bs";
import ChatUserName from "../renderUserName";
import { comment } from "postcss";
import ReplyBox from "../ReplyBox";
import { BsArrow90DegLeft } from "react-icons/bs";
import InternalComment from "./InternalComment";

const ExternalMessage = ({
	setShowReply,
	selected,
	isPrivate,
	setUserName,
	commentData,
	id,
	userNameDatabase,
	userData,
	setUserEmail,
	userName,
	body,
	displayReplyBox,
	timeToDisplay,
	isTooLong,
	setShowReplyTextInput,
	submitComment,
	ogCommentData,
	updateScroll,
	showReplyTextInput,
	showReply,
	timeStamp,
	userEmail,
}) => {
	return displayReplyBox !== id ? (
		<>
			<div className="py-2 w-full grid grid-cols-1 mt-2 cursor">
				<div
					onClick={() => {
						setShowReply(true);
						selected(id);
					}}
					className={
						"shadow-sm text-gray-400 hover:text-black duration-200  bg-opacity-20  w-11/12 cursor-pointer font-light border-opacity-60 border border-gray-200 flex-wrap rounded-md py-1 grid grid-cols-3 break-words mx-auto hover:border-gray-200  focus:opacity-100"
					}
				>
					<div className="col-span-2 py-2 pl-2 flex flex-inline">
						<ChatUserName
							isPrivate={false}
							setUserName={setUserName}
							commentData={commentData}
							id={id}
							userNameDatabase={userNameDatabase}
							userData={userData}
							setUserEmail={setUserEmail}
						></ChatUserName>
						<span className="pl-4 my-auto text-black text-opacity-100 z-10 cursor-pointer">
							{userName}
						</span>
						{isTooLong ? (
							<span className="ml-12 my-auto">
								{body.slice(0, 50)}
								<span className="pl-6 text-gray-400 hover:text-gray-400 ">
									{" ..."}
								</span>
							</span>
						) : (
							<span className="ml-12 my-auto">{body}</span>
						)}

						{displayReplyBox === id ? (
							<div className="my-auto pl-4 text-gray-400">
								&lt;{userEmail}&gt;
							</div>
						) : null}
					</div>
					<div className="flex flex-inline left-92">
						<button
							onClick={() => {
								setShowReply(true);
								selected(id);
							}}
							className="focus:outline-none pl-8"
						>
							<BsArrow90DegLeft size={16} className="text-left" />
						</button>

						<span className="text-right m-4 text-gray-400 text-sm my-auto">
							{timeToDisplay}
						</span>
					</div>
				</div>
			</div>
		</>
	) : (
		<div className="py-2 grid grid-cols-1 w-full mt-2">
			<div
				className={
					"shadow-sm bg-white bg-opacity-100 w-11/12 border border-gray-200 duration-100 border-opacity-50 flex-wrap rounded-lg py-1 grid grid-cols-3 break-words mx-auto hover:border-opacity-40 opacity-100 hover:opacity-100 focus:opacity-100"
				}
			>
				<div className="pt-2 pl-2 col-span-2 flex flex-inline md:opacity-100">
					<ChatUserName
						isPrivate={false}
						setUserName={setUserName}
						commentData={commentData}
						id={id}
						selected={selected}
						userNameDatabase={userNameDatabase}
						userData={userData}
						setUserEmail={setUserEmail}
					></ChatUserName>
					<span
						className="pl-4 my-auto text-gray-500 cursor-pointer"
						onClick={() => {
							selected(null);
						}}
					>
						{userName}
					</span>
				</div>
				<div className="flex flex-inline">
					<BsArrow90DegLeft
						className="text-gray-600 my-auto cursor-pointer "
						onClick={() => {
							setShowReplyTextInput(!showReplyTextInput);
						}}
					/>
					<span className="text-right m-4 text-gray-400 text-sm my-auto">
						{timeStamp.toUpperCase()}
					</span>
				</div>

				<div className="col-span-1"></div>
				<div></div>

				<button
					onClick={() => {
						setShowReply(true);
						selected(id);
					}}
					className="focus:outline-none ml-56"
				>
					<BsArrowReturnLeft size={16} className="text-right" />
				</button>
				<div className="col-span-3 w-full -mt-12">
					{showReply ? (
						<ReplyBox
							submitComment={submitComment}
							commentData={commentData}
							ogCommentData={body}
							selected={displayReplyBox}
							setSelected={selected}
							id={id}
							userNameDatabase={userNameDatabase}
							updateScroll={updateScroll}
							showReplyTextInput={showReplyTextInput}
						></ReplyBox>
					) : null}
				</div>
			</div>
		</div>
	);
};
export default ExternalMessage;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import { getNavigationCommands } from "../commands";
import { CommandK, Menu, ModalContainer } from "../components";
import { callWithModifiers } from "../utils";
import { getUserInfo } from "../requests";

import Header from "../components/SalesforceComponents/Header";
import { getSalesMeta } from "../requests";
import OpportunityGrid from "../components/SalesforceComponents/OpportunityGrid";
import AccountsGrid from "../components/SalesforceComponents/AccountsGrid";
import ContactsGrid from "../components/SalesforceComponents/ContactsGrid";
import OpportunityKanban from "../components/SalesforceComponents/OpportunitiesKanban";
import STAGES_CONFIG from "../components/SalesforceComponents/OppStageConfig";

const kanbanColumns = STAGES_CONFIG.map((stage) => ({
	...stage,
	opportunities: [],
}));

const colDataCreator = (columns, defaultColumns) => {
	return columns.map((col) => {
		return {
			...col,
			Header: col.label,
			key: col.name,
			accessor: col.name,
			active: defaultColumns.includes(col.name),
			sortType: "alphanumeric",
		};
	});
};

const SalesClient = () => {
	const history = useHistory();

	const fetchColumnsMeta = async (table, defaultColumns) => {
		const tableMeta = await getSalesMeta(table);
		return colDataCreator(tableMeta?.output?.fields, defaultColumns);
	};

	const [navCommandsOpen, setNavCommandsOpen] = useState(false);
	const [activeTable, setActiveTable] = useState("Opportunity");

	// This Grid layout flag will be used to manage Opportunities tab.
	const [activateGridLayout, setActivateGridLayout] = useState(false);

	const [searchText, setSearchText] = useState("");
	const [appColumns, setAppColumns] = useState(() => {
		return {
			Account: [],
			Contact: [],
			Opportunity: [],
		};
	});

	useEffect(() => {
		fetchColumnsMeta("Account", [
			"Name",
			"Website",
			"NumberOfEmployees",
			"Description",
			"LastActivityDate",
		]).then((res) => {
			setAppColumns((prevData) => ({
				...prevData,
				Account: res,
			}));
		});

		fetchColumnsMeta("Contact", ["Name", "Phone", "Email", "Title"]).then(
			(res) => {
				setAppColumns((prevData) => ({
					...prevData,
					Contact: res,
				}));
			}
		);

		fetchColumnsMeta("Opportunity", [
			"Name",
			"StageName",
			"Amount",
			"CloseDate",
			"OwnerId",
		]).then((res) => {
			setAppColumns((prevData) => ({
				...prevData,
				Opportunity: res,
			}));
		});
	}, []);

	const handleTableChange = (table) => {
		setSearchText("");
		setActiveTable(table);
	};

	const onColumnFlagChange = (key) => {
		setAppColumns((prevState) => {
			const newState = { ...prevState };
			const elementIndex = newState[activeTable].findIndex(
				(element) => element.key === key
			);
			newState[activeTable][elementIndex].active = !newState[activeTable][
				elementIndex
			].active;
			console.log(newState[activeTable][elementIndex]);
			return { ...newState };
		});
	};

	let activeColumns = appColumns[activeTable].filter((col) => col.active);

	const onSwitchLayout = () => {
		setActivateGridLayout((viewFlag) => !viewFlag);
	};

	// Data Hooks
	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	return (
		<div className="flex flex-row h-screen">
			<div className="flex flex-col flex-grow h-full">
				<div className="flex flex-row flex-none w-full py-5">
					<div className="w-20 flex-none">
						<Menu userData={userData} />
					</div>
					<div className="flex-grow">
						<Header
							className="sticky top-0"
							onTableChange={handleTableChange}
							activeTable={activeTable}
							columns={appColumns[activeTable]}
							onColumnFlagChange={onColumnFlagChange}
							onTableSearch={(value) => {
								setSearchText(value);
							}}
							searchText={searchText}
							toggleGridView={onSwitchLayout}
						/>
					</div>
				</div>
				<div className="container mx-16 width-full">
					{activeTable === "Opportunity" &&
						(activateGridLayout ? (
							<OpportunityKanban />
						) : (
							<OpportunityGrid
								columns={activeColumns}
								searchValue={searchText}
							/>
						))}
					{activeTable === "Account" && (
						<AccountsGrid
							columns={activeColumns}
							searchValue={searchText}
						/>
					)}
					{activeTable === "Contact" && (
						<ContactsGrid
							columns={activeColumns}
							searchValue={searchText}
						/>
					)}
				</div>
			</div>

			{/* Command Line */}
			<ModalContainer top="30%">
				{navCommandsOpen && (
					<CommandK
						options={navigationCommands}
						setOpen={setNavCommandsOpen}
						variant="actionItems"
						placeholder="Go to..."
					/>
				)}
			</ModalContainer>
		</div>
	);
};

export default SalesClient;

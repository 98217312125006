import React, { useContext, useState } from "react";
import { Switch, useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import Routes from "../Routes";
import { callWithModifiers } from "../utils";
import { getNavigationCommands } from "../commands";
import {
	CommandK,
	FillableSidebar,
	Menu,
	ModalContainer,
	PrivateRoute,
	Handoffs,
} from "../components";
import { HandoffsContext } from "../context";
import { Templates } from ".";
import SplitHeader from "../components/SettingsComponents/PageHeader";

const HandoffsPage = () => {
	const {
		// waiting,
		// setWaiting,
		// backPage,
		// setBackPage,
		menuOpen,
		setMenuOpen,
		splitIndex,
		setSplitIndex,
		// connected,
		// setConnected,
		// nylasData,
		// setNylasData,
		splits,
		setSplits,

		userData,
		userDataLoading,
		userDataError,

		// getNylasStatus,
		// getConnectedIntegrations,
	} = useContext(HandoffsContext);

	const [navCommandsOpen, setNavCommandsOpen] = useState(false);

	const history = useHistory();

	// Navigation

	const onPressTab = (e) => {
		let newIndex;
		if (splitIndex < splits.length - 1) {
			newIndex = splitIndex + 1;
		} else {
			newIndex = 0;
		}
		let newRoute = splits[newIndex].route;
		history.push(newRoute);
	};

	// // Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Tab":
					event.preventDefault();
					onPressTab(event);
					break;
				case "k":
					callWithModifiers(event, () => setNavCommandsOpen(true), [
						"cmd",
					]);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	// Commands

	const navigationCommands = getNavigationCommands(history);

	return (
		<div className="flex flex-row h-screen">
			{/* Page Body (Header + Grid) */}

			<div className="flex flex-col flex-grow h-full">
				<div className="flex flex-row flex-none w-full pt-5">
					<div className="w-20 flex-none">
						<Menu userData={userData} setMenuOpen={setMenuOpen} />
					</div>
					<div className="flex flex-row items-center flex-grow">
						<SplitHeader splitIndex={splitIndex} splits={splits} />
					</div>
				</div>

				{/* Sub Pages */}
				<Switch>
					<PrivateRoute
						path={Routes.HANDOFFS_PAGE}
						component={Handoffs}
					/>
					<PrivateRoute
						path={Routes.TEMPLATES_PAGE}
						component={Templates}
					/>
				</Switch>
			</div>

			{/* Sidebar */}
			<FillableSidebar></FillableSidebar>

			{/* Command Line */}
			<ModalContainer top="30%">
				{navCommandsOpen && (
					<CommandK
						options={navigationCommands}
						setOpen={setNavCommandsOpen}
						variant="actionItems"
						placeholder="Go to..."
					/>
				)}
			</ModalContainer>
		</div>
	);
};

export default HandoffsPage;

import useSWR from "swr";

import getFetcher from "./getFetcher";

const getTemplates = () => {
	const { data, error, mutate } = useSWR(
		"/api/handoffs/getTemplates",
		getFetcher
	);

	return {
		data: data,
		loading: !error && !data,
		error: error,
		mutate,
	};
};

export default getTemplates;

import React, { useState } from "react";

import { ReactComponent as SellerantLogo } from "../assets/LanternLogo.svg";
import { authUser, requestPost } from "../requests";

const Login = () => {
	const [email, setEmail] = useState("");

	const paragonAuth = async (email) => {
		try {
			let res = await requestPost("/api/generateParagonJWT", {
				userEmail: email,
			});

			await paragon.authenticate(
				"8e2ac7c3-6825-4ab2-8c86-6e148dd00ecf",
				res
			);
		} catch (err) {}
	};
	const onClickLogin = () => {
		localStorage.setItem("user_email", email);
		paragonAuth(email);
		authUser(email);
	};

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			onClickLogin();
		}
	};

	return (
		<div className="grid h-screen">
			<div className="bg-gray-100 flex">
				<div className="grid place-items-center m-auto">
					<div className="px-6 py-10 bg-white rounded-lg shadow-md lg:shadow-lg">
						<div>
							<div className="flex align-items-center px-40">
								<SellerantLogo className="object-scale-down" />
							</div>

							<p className="block text-4xl font-semibold pt-5 text-center">
								Onboarding Portal
							</p>
						</div>
						<div className="mt-10">
							<label className="block text-xs font-semibold text-gray-600 uppercase">
								E-mail
							</label>
							<input
								type="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								placeholder="example@sellerant.io"
								autoComplete="email"
								className="block w-full py-3 px-1 mt-2 text-gray-800 appearance-none border-b-2 border-gray-100 focus:outline-none focus:border-gray-200"
								onKeyDown={onKeyDown}
							/>
							<button
								onClick={onClickLogin}
								className="w-full py-3 mt-10 bg-gray-800 rounded-sm font-medium text-white uppercase focus:outline-none hover:bg-gray-700 hover:shadow-none"
							>
								SSO Login
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;

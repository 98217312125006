import { CgProfile } from "react-icons/cg";

const getColorFromUser = (user) => {
	let firstCode = user.charCodeAt(0) - 65;
	let lastCode = user.charCodeAt(1) - 65;
	let det = firstCode + lastCode;
	switch (true) {
		case det < 5:
			return "bg-userpink";
		case det < 10:
			return "bg-userorange";
		case det < 12:
			return "bg-userteal";
		case det < 16:
			return "bg-userpurple";
		case det < 19:
			return "bg-userred";
		case det < 25:
			return "bg-userblue";
		case det < 30:
			return "bg-usermagenta";
		case det < 40:
			return "bg-userlime";
		case det < 45:
			return "bg-usergreen";
		case det <= 50:
			return "bg-userblue";
		default:
			break;
	}
};

const UserIcon = ({ user, onClick, clickable }) => {
	return (
		<>
			{user ? (
				<div
					onClick={onClick}
					className={`flex justify-center items-center text-white font-light text-small-as-fuck text-sem h-4 w-4 rounded-full flex-none 
						${clickable && "cursor-pointer"}
						${getColorFromUser(user)}
					`}
				>
					{user}
				</div>
			) : (
				<CgProfile
					className={`text-gray-500  ${
						clickable && "cursor-pointer hover:text-blue-400"
					}`}
					onClick={onClick}
					size={16}
				/>
			)}
		</>
	);
};

export default UserIcon;

import React, { useState, useEffect, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import {
	Menu,
	PageHeader,
	Sidebar,
	MainGrid,
	CommandK,
	ModalContainer,
} from "../components";
import {
	callWithModifiers,
	callWithoutModifiers,
	filterSplitData,
	getUserSplits,
	handleCaughtError,
	removeSplitListHelper,
} from "../utils";
import {
	getAllAccountData,
	getUserInfo,
	getSellerantUsers,
	requestUpdate,
} from "../requests";
import {
	getCustomizationCommands,
	getNavigationCommands,
	getRemoveSplitCommands,
} from "../commands";
import { AccountSplitContext } from "../context";
import { useScroll } from "../hooks";
import Routes from "../Routes";
import { sourcePages } from "../constants";

const AllAccounts = withRouter(({ match }) => {
	const {
		selectedSplit,
		setSelectedSplit,
		splitSettings,
		setSplitSettings,
	} = useContext(AccountSplitContext);

	const [filteredData, setFilteredData] = useState(false);
	const [sellerantUserData, setSellerantUserData] = useState(null);
	const [splitTitles, setSplitTitles] = useState(null);
	const [currentSplits, setCurrentSplits] = useState(null);

	const [removeSplitCommandOpen, setRemoveSplitCommandOpen] = useState(null);
	const [navCommandsOpen, setNavCommandsOpen] = useState(false);
	const [gPressed, setGPressed] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);

	const {
		selectedIndex,
		setSelectedIndex,
		onPressUpArrow,
		onPressDownArrow,
	} = useScroll(filteredData ? filteredData.length : 0, menuOpen);

	const history = useHistory();

	const userId = localStorage.getItem("user_id");

	// Data Hooks

	const {
		data: accountsData,
		isLoading: accountsLoading,
		isError: accountsError,
	} = getAllAccountData();

	const {
		data: sellerantUsers,
		loading: usersLoading,
		error: usersError,
		mutate: mutateUsers,
	} = getSellerantUsers();

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	// Split data filtering

	useEffect(() => {
		if (
			accountsData &&
			!accountsError &&
			!accountsLoading &&
			splitSettings
		) {
			filterSplitData(
				userData,
				splitSettings,
				selectedSplit,
				accountsData,
				setFilteredData,
				setSplitTitles,
				true,
				true,
				null
			);
		}
	}, [accountsData, userData, selectedSplit, splitSettings]);

	// Effects

	useEffect(() => {
		if (userData) {
			setSplitSettings(userData?.settings.account_split);
		}
	}, [userData, match.url]);

	useEffect(() => {
		if (!usersLoading && !usersError) {
			setSellerantUserData(sellerantUsers);
		}
	}, [sellerantUsers]);

	useEffect(() => {
		if (sellerantUsers && !usersLoading && !usersError) {
			if (match.url in sourcePages) {
				setCurrentSplits(
					getUserSplits(
						sellerantUsers,
						userId,
						sourcePages[match.url],
						false
					)
				);
			}
		}
	}, [match.url, sellerantUsers]);

	// Mutations

	const removeSplit = async (splitName, pageKey, pageName) => {
		const { usersCopy, mergedSettings } = removeSplitListHelper(
			pageName,
			sellerantUsers.data,
			splitName,
			pageKey,
			userId
		);
		// Mutate Sellerant Users with new changes
		try {
			mutateUsers({ data: usersCopy }, false);
			await requestUpdate("/api/updateSellerantUsers", {
				attr: "settings",
				newValue: mergedSettings,
			});
			mutateUsers();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	// Navigation

	const onPressTab = () => {
		if (selectedSplit && splitSettings) {
			let sel;
			for (let i = 0; i < splitSettings.length; i++) {
				if (splitSettings[i].slug == selectedSplit) {
					sel = splitSettings[i];
				}
			}
			let indx = splitSettings.indexOf(sel);
			if (indx >= splitSettings.length - 1) {
				setSelectedSplit(splitSettings[0].slug);
			} else {
				setSelectedSplit(
					splitSettings[splitSettings.indexOf(sel) + 1].slug
				);
			}
		}
	};

	const onPressEnter = () => {
		if (menuOpen) {
			return;
		}
		if (selectedIndex !== null && selectedIndex < filteredData.length) {
			history.push(
				`${Routes.ACCOUNT_BASE_ROUTE}/${filteredData.data[selectedIndex].company_id}/overview`
			);
		}
	};

	const onPressSearch = () => {
		history.push(`${Routes.SEARCH_PAGE}?source=accounts`);
	};

	// Hot Keys

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "k":
					callWithModifiers(event, () => setNavCommandsOpen(true), [
						"cmd",
					]);
					setGPressed(false);
					break;
				case "/":
					callWithoutModifiers(event, () => onPressSearch());
					setGPressed(false);
					break;
				case "Tab":
					event.preventDefault();
					callWithoutModifiers(event, () => onPressTab());
					setGPressed(false);
					break;
				case "g":
					callWithoutModifiers(event, () => setGPressed(true));
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	// Commands

	const navigationCommands = getNavigationCommands(history);
	const customizationCommands = getCustomizationCommands(
		setRemoveSplitCommandOpen
	);
	const removeSplitCommands = getRemoveSplitCommands(
		currentSplits,
		removeSplit
	);

	return (
		<div className="flex flex-row h-screen">
			<div className="flex flex-col flex-grow h-full">
				{/* Menu + Page Header */}
				<div className="flex flex-row flex-none w-full py-5">
					<div className="w-20 flex-none">
						<Menu userData={userData} setMenuOpen={setMenuOpen} />
					</div>
					<div className="flex-grow">
						<PageHeader
							searchButtonTitle="Search Accounts"
							onClickSearch={onPressSearch}
							splits={splitTitles}
							setSelectedSplit={setSelectedSplit}
							selectedSplit={selectedSplit}
						/>
					</div>
				</div>

				{/* Grid */}
				<MainGrid
					data={filteredData}
					selectedId={selectedIndex}
					setSelectedId={setSelectedIndex}
					variant="accounts"
					onPressUpArrow={onPressUpArrow}
					onPressDownArrow={onPressDownArrow}
					onPressEnter={onPressEnter}
					hoverEnabled={!menuOpen}
					setCommandKOpen={setNavCommandsOpen}
					loading={accountsLoading || usersLoading || userDataLoading}
					error={accountsError || usersError || userDataError}
				/>
			</div>

			{/* Sidebar */}
			<Sidebar
				ctaData={
					selectedIndex !== null &&
					selectedIndex < filteredData.length
						? filteredData.data[selectedIndex]
						: null
				}
				variant="accounts"
				userData={sellerantUserData}
			/>

			<ModalContainer top="30%">
				{navCommandsOpen && (
					<CommandK
						options={navigationCommands.concat(
							customizationCommands
						)}
						setOpen={setNavCommandsOpen}
						variant="actionItems"
						placeholder="Go to..."
					/>
				)}
				{removeSplitCommandOpen && (
					<CommandK
						setOpen={setRemoveSplitCommandOpen}
						options={removeSplitCommands}
						placeholder="Which split do you want to remove?"
					/>
				)}
			</ModalContainer>
		</div>
	);
});

export default AllAccounts;

const pages = ["Inbox Page", "All CTAs Page", "Accounts Page"];

export default (splitCommand) => {
	return pages.map((page) => ({
		title: page,
		icon: null,
		command() {
			splitCommand(page);
		},
	}));
};

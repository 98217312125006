import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import Routes from "../Routes";

const SearchContext = React.createContext();

const pagesToCancelBack = [
	Routes.ACTION_ITEMS_PAGE,
	Routes.ALL_CTA_PAGE,
	Routes.ALL_ACCOUNTS_PAGE,
];

const sourcePages = {
	actionItems: Routes.ACTION_ITEMS_PAGE,
	accounts: Routes.ALL_ACCOUNTS_PAGE,
	allCta: Routes.ALL_CTA_PAGE,
};

const SearchContextWrapper = withRouter(({ location, children }) => {
	const [query, setQuery] = useState("");
	const [goBackToSearch, setGoBackToSearch] = useState(false);
	const [sourcePage, setSourcePage] = useState(null);

	useEffect(() => {
		// Track when to go back to Search page from CTA and Account pages
		if (goBackToSearch && pagesToCancelBack.includes(location.pathname)) {
			setGoBackToSearch(false);
			setQuery("");
		} else if (
			!goBackToSearch &&
			location.pathname.slice(0, 10) === "/searchCta"
		) {
			setGoBackToSearch(true);
		}

		// Track which page (Action Items or Accounts) to return to from Search view
		if (location.pathname.slice(0, 10) === "/searchCta") {
			let source = new URLSearchParams(location.search).get("source");
			if (source in sourcePages) {
				setSourcePage(sourcePages[source]);
			}
		}
	}, [location]);

	return (
		<SearchContext.Provider
			value={{
				query,
				setQuery,
				goBackToSearch,
				setGoBackToSearch,
				sourcePage,
				setSourcePage,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
});

export { SearchContext, SearchContextWrapper };

import React, { useRef } from "react";
import { IoClose } from "react-icons/io5";

import { useOutsideAlerter } from "../../hooks";

const ConfirmModal = ({ title, onClick, setModalOpen, buttonLabel }) => {
	let modalRef = useRef(null);
	useOutsideAlerter(modalRef, () => setModalOpen(false));

	return (
		<div
			className="relative flex flex-col justify-start items-center p-6 w-96 rounded-lg shadow-xl border border-gray-100 bg-white z-10"
			ref={modalRef}
		>
			<div
				className="absolute top-0 right-0 p-4"
				onClick={() => setModalOpen(false)}
			>
				<IoClose
					className="text-gray-400 hover:text-gray-500 cursor-pointer"
					size={20}
				/>
			</div>
			<p className="m-3 font-bold text-base text-gray-600 text-center">
				{title}
			</p>
			<button
				className="px-5 my-3 rounded whitespace-nowrap h-8 text-center text-white text-sm bg-red-500"
				onClick={onClick}
			>
				{buttonLabel}
			</button>
		</div>
	);
};

export default ConfirmModal;


import fetch from "unfetch";

const submitZendeskComment = async (comment, commentStatus, ticketId, recipients, removeFrom, oldEmailCCs, author_id, userEmail) => {

    const url = `/api/zendesk/submitZendeskTicket`;
    console.log("DEALING W RECIPS\n\n\n", author_id)
    const res = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization:
                'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJKb2huRG9lIiwiaXNzIjoxNjIzMjcyNjMzLCJleHAiOjE2MjMzNTkwMzMsImlhdCI6MTYyMzI3MjYzM30.ecac9ld9MTH3GkEbjsdx_gmjVcNtqL55IXkcIq82fBHHK823f_OFGfJfZjUvC6PduQruYYece54DOVSM02UKWC5klVDQbZYGXIHQpwew_wagO0RtEvfcyHOZblkiR0AAurrqQv3tQcqA_Oh02Vmc4_fJXiJSCpQleWYnvsWLglqQunf74_xkpxTU_HWHb4bHJ_Nw1HGtazcKQCeMJxBBDo8_-2qmdYSWRtlje9LUpu2f-3rVl6ZE7gIVUbl68WM2gd-A6ndMBNGC0xZ2TDK7eJA2sWunk-VKna7eSOQT-YksnBBjXMT6jyVHyN1g9R3ImDyryFCwfTlChxjSZsEY3rXlLHpiRjUDOkC_RHbVQ7dyg7TrG4hbftde2Wr8icrx-OCPOFzI9UStEZk8ztmq8o7a0NEoRHMHfxHYn9aaP0Ju0eBPagCppU-WTiLvhAYnkG6FFY480AfnX9mNf-ma_H90AsVYri5LTXx5zvmdDhhxqZrHliYH4kLvh-9vAXru-D9P6JKe_SY0HgJJ70VBOUzzlUbKGFWa8mDzkia7JMMQL9pU03gE45Mj8OQ19wCo0Iu6ljLjXHWgbW5U_qwIA7DZAVCZ0au_sBmWXCscsrIh8bAuW_WCsXN6xaXxf2bFdof-I0iHw5_LGB59Ena49N47ibXdJeyP-e6RV97v-jQ'
            ,
        },
        body: JSON.stringify({ comment: comment, commentStatus: commentStatus, ticketId: ticketId, recipients: recipients, removeFrom: removeFrom, author_id: author_id, userEmail: userEmail }),
    });
    console.log(res)
    if (res.ok) {
        return;
    }
};


export default submitZendeskComment;
import React from "react";

const baseGradeStyle =
	"flex justify-center items-center rounded-full font-semibold ";

const gradeStyles = {
	A: "bg-brightergreen  text-green-500",
	B: "bg-yellowgreen  text-green-500",
	C: "bg-brightyellow text-yellow-400",
	D: "bg-brighterred text-red-600",
	default: "bg-gray-200 text-gray-800",
};

const Grade = ({ grade, size = 24, text = "sm", style }) => {
	return (
		<div className="flex-none">
			{grade !== null && (
				<div
					className={
						baseGradeStyle +
						gradeStyles[grade in gradeStyles ? grade : "default"] +
						` text-${text}`
					}
					style={{
						height: `${size}px`,
						width: `${size}px`,
						...style,
					}}
				>
					{grade}
				</div>
			)}
		</div>
	);
};

export default Grade;

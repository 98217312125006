import React, { useContext, useEffect, useState } from "react";
import {
	Chat,
	Channel,
	Thread,
	Window,
	MessageList,
	MessageInput,
} from "stream-chat-react";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";

import { CtaContext } from "../../context";

const ChatComponent = () => {
	const {
		ctaLoading,
		ctaError,
		currentCtaData,
		chatData,
		chatLoading,
		chatError,
	} = useContext(CtaContext);

	const [chatClient, setChatClient] = useState(null);
	const [channel, setChannel] = useState(null);

	useEffect(() => {
		const initChat = async () => {
			const client = StreamChat.getInstance(
				process.env.REACT_APP_STREAM_CHAT_KEY
			);

			await client.connectUser(
				{
					id: chatData.id,
					name: chatData.name,
				},
				chatData.token
			);

			setChatClient(client);
			setChannel(
				client.channel("messaging", currentCtaData.cta_id, {
					name: "Chat Test Name",
				})
			);
		};
		if (currentCtaData) {
			initChat();
		}
	}, [currentCtaData]);

	if (chatLoading || ctaLoading || !chatClient || !channel || !chatData)
		return <div>Chat Loading...</div>;

	if (chatError || ctaError) return <div>Error Loading Chat</div>;

	return (
		<Chat client={chatClient} theme="messaging light">
			<Channel channel={channel}>
				<Window>
					<MessageList />
					<MessageInput />
				</Window>
				<Thread />
			</Channel>
		</Chat>
	);
};

export default ChatComponent;

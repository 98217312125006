const TaskButton = ({
	icon,
	label,
	onClick,
	colorClasses = "bg-gray-200 hover:bg-gray-300 text-gray-800",
}) => {
	return (
		<button
			className={`flex justify-center items-center h-8 px-3 rounded text-sm truncate ${colorClasses}`}
			onClick={onClick}
		>
			{icon && <span className="mr-1">{icon}</span>}
			{label}
		</button>
	);
};

export default TaskButton;

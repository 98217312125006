import React from "react";

const sidebarWidth = "360px";

const FillableSidebar = ({ title, children }) => {
	return (
		<div
			style={{ width: sidebarWidth }}
			className="h-full bg-lightgray flex-none shadow-inner"
		>
			<div className="px-8 pt-5 font-sans">
				<p className="text-xl font-semi-bold pb-10">{title}</p>
				<div className="text-sm">{children}</div>
			</div>
		</div>
	);
};

export default FillableSidebar;

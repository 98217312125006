import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import Routes from "../Routes";

const Auth = () => {
	const history = useHistory();
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const access_token = urlParams.get("access_token");
		localStorage.setItem("access_token", access_token);
		history.push(Routes.ACTION_ITEMS_PAGE);
	}, []);

	return null;
};

export default Auth;

import axios from "axios";
import { handleCaughtError } from "../../utils";

import bounceIfTokenError from "../bounceIfTokenError";

export default async (url) => {
	try {
		let res = await axios.get(url, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});

		return res.data;
	} catch (err) {
		handleCaughtError(err);
		bounceIfTokenError(err);
	}
};

import { statuses } from "../constants";

export default (statusCommand) => {
	return statuses.map((status) => ({
		title: status.value,
		icon: status.icon,
		command() {
			statusCommand(status.value);
		},
	}));
};

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import {
	SidebarStatistic,
	SidebarInfoSection,
	SidebarProfile,
	ProblemsMenu,
	CheckBoxMenu,
} from ".";
import { icons, priorities, statuses } from "../../constants";
import { SmallDropdown, UserIcon, Grade, Overview } from "..";

const sidebarWidth = "360px";

const Sidebar = withRouter(
	({
		history,
		ctaData,
		variant = "cta",
		status,
		setStatus,
		mutateStatus,
		setNotes,
		notes,
		mutateNote,
		priority,
		setPriority,
		mutatePriority,
		assigned,
		mutateAssigned,
		followers,
		mutateFollowers,
		userOptions,
		mutate,
		onClickProfileButton,
		otherCtaData,
		priorityDropdownOpen,
		setPriorityDropdownOpen,
		statusDropdownOpen,
		setStatusDropdownOpen,
		assignedDropdownOpen,
		setAssignedDropdownOpen,
		followerDropdownOpen,
		setFollowerDropdownOpen,
	}) => {
		const [nextSteps, setNextSteps] = useState("");
		const [DUR, setDUR] = useState("");
		const [usage, setUsage] = useState("");

		// Effects

		useEffect(() => {
			if (ctaData) {
				setNextSteps(ctaData.next_step ?? "");

				if (variant === "cta") {
					if (typeof ctaData.priority === "number") {
						let priorityIndex = ctaData.priority - 1;
						setPriority(priorities[priorityIndex].value);
					} else if (typeof ctaData.priority === "string") {
						setPriority(ctaData.priority);
					}

					// setNotes(ctaData.notes ?? "");

					if (typeof ctaData.status === "number") {
						let statusIndex = ctaData.status - 1;
						setStatus(statuses[statusIndex].value);
					} else if (typeof ctaData.status === "string") {
						setStatus(ctaData.status);
					}
				}

				if (ctaData.contract_end) {
					const oneDay = 24 * 60 * 60 * 1000;
					const end = new Date(ctaData.contract_end);
					const today = new Date();
					setDUR(Math.round(Math.abs((today - end) / oneDay)));
				} else {
					setDUR(null);
				}

				if (ctaData.dau && ctaData.purchased_seats) {
					setUsage(
						`${Math.round(
							(ctaData.dau / ctaData.purchased_seats) * 100
						)}`
					);
				} else {
					setUsage("");
				}
			}
		}, [ctaData]);

		// Mutations

		const mutateNextSteps = async (nextStep) => {
			await mutate(
				nextSteps,
				setNextSteps,
				nextStep,
				nextStep,
				ctaData.cta_id,
				"next_step",
				"/api/updateNextStep"
			);
		};

		// Sidebar Body

		if (!ctaData) {
			return (
				<div
					style={{ width: sidebarWidth }}
					className="h-full bg-lightgray p-6 flex-none"
				/>
			);
		}

		return (
			<div
				style={{ width: sidebarWidth }}
				className="h-full bg-lightgray py-8 px-9 flex-none shadow-inner "
			>
				<SidebarProfile
					name={ctaData?.company_name}
					title={variant === "accounts" ? ctaData?.stage : null}
					onClickButton={onClickProfileButton}
					variant={variant}
					stage={ctaData?.stage}
					grade={
						(variant === "actionItems" || variant === "accounts") &&
						ctaData?.grade
							? ctaData?.grade
							: null
					}
					ctaData={ctaData}
				/>

				{variant === "cta" && (
					<>
						<ProblemsMenu
							otherCtaData={otherCtaData}
							ctaData={ctaData}
							history={history}
						/>
						<div
							id="dropdowns"
							className="flex flex-col mt-5 mb-5 p-2 bg-sidebarmenugray rounded gap-y-2"
						>
							<div id="sidebar-status-section" className="ml-3">
								<SmallDropdown
									title="Status"
									icon={status ? icons[status] : null}
									options={statuses}
									status={status}
									open={statusDropdownOpen}
									setOpen={setStatusDropdownOpen}
									onSelect={mutateStatus}
									keyCommand="S"
									fixedHeaderWidth
								/>
							</div>
							<div id="sidebar-priority-section" className="ml-3">
								<SmallDropdown
									title="Priority"
									icon={priority ? icons[priority] : null}
									options={priorities}
									status={priority}
									open={priorityDropdownOpen}
									setOpen={setPriorityDropdownOpen}
									onSelect={mutatePriority}
									keyCommand="P"
									fixedHeaderWidth
								/>
							</div>
							<div id="sidebar-assigned-section" className="ml-3">
								<SmallDropdown
									title="Assigned"
									icon={
										<UserIcon
											user={
												assigned
													? assigned.iconLabel
													: null
											}
										/>
									}
									options={userOptions}
									status={
										assigned ? assigned.value : "Unassigned"
									}
									open={assignedDropdownOpen}
									setOpen={setAssignedDropdownOpen}
									onSelect={mutateAssigned}
									keyCommand="A"
									variant="assigned"
									fixedHeaderWidth
								/>
							</div>
							<div
								id="sidebar-followers-section"
								className="ml-3"
							>
								<CheckBoxMenu
									options={userOptions}
									selected={followers}
									open={followerDropdownOpen}
									setOpen={setFollowerDropdownOpen}
									keyCommand="F"
									onSelect={mutateFollowers}
								/>
							</div>
						</div>
					</>
				)}
				{(variant === "cta" || notes) && (
					<div id="sidebar-notes-section" className="mb-5">
						<SidebarInfoSection
							title="Notes"
							content={notes}
							onBlur={mutateNote}
							readOnly={
								variant === "actionItems" ||
								variant === "accounts"
							}
						/>
					</div>
				)}

				{variant === "cta" && ctaData?.scorecard?.scores.length > 0 && (
					<div
						id="scores"
						className="grid grid-cols-3 grid-rows-2 p-2 mb-5 bg-sidebarmenugray rounded "
					>
						{ctaData?.scorecard?.scores.map((score, index) => (
							<div
								key={index}
								className="flex col-span-1 place-self-center flex-col mb-2 h-16 flex-grow-0 w-full"
							>
								<div className="mt-2 self-center justify-self-center">
									<Grade
										grade={score.grade}
										size={28}
										text="md"
									/>
								</div>
								<p className="body-small-gray mt-1 mb-1 justify-self-center text-center w-full">
									{score.name}
								</p>
							</div>
						))}
					</div>
				)}
				{variant === "actionItems" && (
					<div
						id="sidebar-deatils-actionItems"
						className="mt-5 mb-5 bg-sidebarmenugray rounded "
					>
						<SidebarInfoSection
							title="Details"
							content={ctaData.cta_summary}
							readOnly={true}
						/>
					</div>
				)}
				{variant === "actionItems" && ctaData.scorecard && (
					<div
						id="sidebar-grades-actionItems"
						className="mb-5 bg-sidebarmenugray rounded "
					>
						<Overview data={ctaData} variant="actionItems" />
					</div>
				)}
				{variant === "cta" ? (
					<div
						id="sidebar-stats-section"
						className="flex flex-col mt-5 mb-3 bg-sidebarmenugray rounded p-2 gap-y-3"
					>
						<div className="flex flex-row  justify-center  mt-2 mb-3">
							<SidebarStatistic
								title="ARR"
								figure={
									ctaData?.arr ? `$${ctaData?.arr}k` : "-"
								}
							/>
							<SidebarStatistic
								title="Usage"
								figure={usage ? `${usage}%` : "-"}
							/>

							<SidebarStatistic
								title="DAU"
								figure={ctaData?.dau ?? "-"}
							/>
						</div>
					</div>
				) : (
					<div
						id="sidebar-stats-section"
						className="flex flex-col mt-4 mb-4 bg-sidebarmenugray rounded p-2 gap-y-3"
					>
						<div className="flex flex-row justify-center 0  mb-3">
							<SidebarStatistic
								title="ARR"
								figure={
									ctaData?.arr ? `$${ctaData?.arr}k` : "-"
								}
							/>
							<SidebarStatistic
								title="Usage"
								figure={usage ? `${usage}%` : "-"}
							/>
							<SidebarStatistic
								title="DAU"
								figure={ctaData?.dau ?? "-"}
							/>
						</div>
					</div>
				)}
			</div>
		);
	}
);

export default Sidebar;

import useSWR from "swr";

import getFetcher from "./getFetcher";

const getPlaybooks = () => {
	const { data, error, mutate } = useSWR("/api/getPlaybooks", getFetcher);

	return {
		data: data,
		loading: !error && !data,
		error: error,
		mutate,
	};
};

export default getPlaybooks;

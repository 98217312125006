import React, { useContext, useEffect, useRef, useState } from "react";
import { IoWarningOutline } from "react-icons/io5";
import { CtaContext } from "../../../context";
import { ProgressBar } from "../../";
import SimpleTodoList from "../../SharedComponents/SimpleTodoList/SimpleTodoList";
import { FileDrop } from "react-file-drop";
import cloneDeep from "lodash.clonedeep";

const DataRoom = () => {
	const { currentCtaData, dataRoomTodos, setDataRoomTodos } = useContext(
		CtaContext
	);
	let bodyInputRef = useRef(null);
	const [progressIndex, setProgressIndex] = useState(2);
	const [presentationUrl, setPresentationUrl] = useState("");
	// dummy data

	const fileTypes = ["JPG", "PNG", "GIF"];
	let progressPhases = [
		"Handoff to CSM",
		"Kickoff Call",
		"Second Call",
		"Check-in",
		"Deployed",
	];
	console.log(dataRoomTodos);
	const onCreateTodo = (title, desc, date) => {
		let newtd = {};
		newtd.id = 6094;
		newtd.name = title;
		newtd.body = {};
		newtd.body.description = desc;
		newtd.due_date = date;
		newtd.completed = false;
		let tmp = cloneDeep(dataRoomTodos);
		tmp.todo_list.todos.push(newtd);
		setDataRoomTodos(tmp);
	};
	return (
		<div className="flex flex-col flex-grow mx-6 mb-5 overflow-auto  ">
			{/* Warning */}

			<div className=" h-12  bg-warning-yellow rounded-sm items-center relative flex flex-row">
				<IoWarningOutline
					size={24}
					className="ml-6 text-warning-sign"
				></IoWarningOutline>
				<p className="body-md-semibold ml-2">
					Warning: The information in this section is visible to the
					customer
				</p>
				<button
					className="body-md text-brand-regular right-4 absolute"
					onClick={() => {
						navigator.clipboard.writeText(
							`start/${dataRoomTodos.url}`
						);
					}}
				>
					Copy Link
				</button>
			</div>

			{/* progress container */}

			<div className="flex justify-center items-center flex-none mt-4 w-full h-24 bg-lightgray rounded ">
				<ProgressBar
					height={40}
					width={900}
					items={progressPhases}
					progress={progressIndex}
					variant="scorecard"
					setProgress={(index) => {
						if (index === progressIndex) return;
						setProgressIndex(index);
					}}
				/>
			</div>

			{/* todo container */}

			<div className="flex flex-col overflow-scroll max-h-72 bg-lightgray rounded mt-6">
				<SimpleTodoList
					title={"Todo List:"}
					items={dataRoomTodos?.todo_list?.todos}
					onUpdateTodo={() => {
						console.log("update");
					}}
					onCreateTodo={onCreateTodo}
				/>
				<div className="flex flex-row mt-4 items-center ml-8 relative mb-6">
					<p className="h5">Upload your presentation:</p>

					<input
						className="text-center body-small-gray absolute right-16 h-8 w-2/3 bg-sidebarmenugray border-gray-200 border rounded-lg"
						onChange={(e) => setPresentationUrl(e.target.value)}
						placeholder={"Embed a presentation link"}
						ref={bodyInputRef}
						rows={0}
						value={presentationUrl}
					/>
				</div>
				{/* <div className="flex   rounded bg-lightgray w-full h-36 mt-6">
					<div className="flex flex-row self-center my-2">
						<p className="h5">Upload your presentation</p>
						<div>
							<FileDrop
							className="flex flex-row h-24 w-40 border items-center justify-center  border-gray-300 border-dashed mt-4"
							onDragOver={() => console.log("file")}
						>
							<p className="flex  body-xsmall-semibold-gray">
								Drop files here
							</p>
						</FileDrop>
						</div>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default DataRoom;

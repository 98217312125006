import React, { useEffect, useRef, useState } from "react";
import {
	convertFromRaw,
	convertToRaw,
	Editor,
	EditorState,
	getDefaultKeyBinding,
	KeyBindingUtil,
	RichUtils,
} from "draft-js";
import {
	AiOutlineBold,
	AiOutlineItalic,
	AiOutlineUnderline,
	AiOutlineStrikethrough,
	AiOutlineUnorderedList,
	AiOutlineOrderedList,
} from "react-icons/ai";
import { ImQuotesRight } from "react-icons/im";
import { BiCodeAlt } from "react-icons/bi";

import "./editor.css";
import { getBlockStyle, getSelectionCoords, getSelectionRange } from "./utils";

const iconSize = 18;

// Custom overrides
const styleMap = {
	CODE: {
		backgroundColor: "rgba(0, 0, 0, 0.05)",
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		padding: 2,
	},
	STRIKE: {
		textDecoration: "line-through",
	},
};

const INLINE_STYLES = [
	{ label: "Bold", icon: <AiOutlineBold size={iconSize} />, style: "BOLD" },
	{
		label: "Italic",
		icon: <AiOutlineItalic size={iconSize} />,
		style: "ITALIC",
	},
	{
		label: "Underline",
		icon: <AiOutlineUnderline size={iconSize} />,
		style: "UNDERLINE",
	},
	{
		label: "Strikethrough",
		icon: <AiOutlineStrikethrough size={iconSize} />,
		style: "STRIKE",
	},
];

const BLOCK_TYPES = [
	{
		label: "UL",
		icon: <AiOutlineUnorderedList size={iconSize} />,
		style: "unordered-list-item",
	},
	{
		label: "OL",
		icon: <AiOutlineOrderedList size={iconSize} />,
		style: "ordered-list-item",
	},
	{
		label: "Blockquote",
		icon: <ImQuotesRight size={iconSize} />,
		style: "blockquote",
	},
	{
		label: "Code Block",
		icon: <BiCodeAlt size={iconSize} />,
		style: "code-block",
	},
];

const RichTextEditor = ({
	body,
	setBody,
	bodyLoaded = true,
	style,
	editorStyle,
	placeholder,
	readOnly,
	onBlur,
	keyHandlerFunction,
	cursorPositionFunction,
}) => {
	const editorRef = useRef(null);
	const sizeRef = useRef(null);

	const [loaded, setLoaded] = useState(false);
	const [inlineToolbarOpen, setInlineToolbarOpen] = useState(false);
	const [inlineToolbarPosition, setInlineToolbarPosition] = useState(null);
	const [editorState, setEditorState] = useState(
		body
			? EditorState.createWithContent(convertFromRaw(body))
			: EditorState.createEmpty()
	);

	useEffect(() => {
		if (bodyLoaded && body && !loaded) {
			setEditorState(
				body
					? EditorState.createWithContent(convertFromRaw(body))
					: EditorState.createEmpty()
			);
			setLoaded(true);
		}
	}, [bodyLoaded, body]);

	// If the user changes block type before entering any text, we can
	// either style the placeholder or hide it.
	let className = "RichEditor-editor";
	let contentState = editorState.getCurrentContent();
	if (!contentState.hasText()) {
		if (contentState.getBlockMap().first().getType() !== "unstyled") {
			className += " RichEditor-hidePlaceholder";
		}
	}

	const onChange = (editorState) => {
		if (!editorState.getSelection().isCollapsed()) {
			const selectionRange = getSelectionRange();
			if (!selectionRange) {
				setInlineToolbarOpen(false);
				return;
			}
			const selectionCoords = getSelectionCoords(selectionRange);
			setInlineToolbarOpen(true);

			// Calculate where to render style controls in the window (to prevent hidden overflow)
			let editorWidth = window.getComputedStyle(sizeRef.current).width;

			let top = selectionCoords.offsetTop;
			let left = selectionCoords.offsetLeft;

			editorWidth = parseInt(
				editorWidth.substring(0, editorWidth.length - 2)
			);
			if (selectionCoords.offsetLeft < 0) {
				left = 0;
			} else if (selectionCoords.offsetLeft + 300 > editorWidth) {
				left = editorWidth - 300;
			}
			if (selectionCoords.offsetTop < 0) {
				if (
					selectionCoords.offsetTop >= -40 &&
					selectionCoords.offsetTop <= -20
				) {
					top = selectionCoords.offsetTop = 40;
				} else {
					top = selectionCoords.offsetTop * -1 - 40;
				}
			}
			setInlineToolbarPosition({
				top,
				left,
			});
		} else {
			setInlineToolbarOpen(false);
		}
		setEditorState(editorState);
		setBody(convertToRaw(editorState.getCurrentContent()));
	};

	const handleKeyCommand = (command, editorState) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			onChange(newState);
			return true;
		}
		return false;
	};

	const mapKeyToEditorCommand = (e) => {
		if (e.keyCode === 9 /* TAB */) {
			const newEditorState = RichUtils.onTab(
				e,
				editorState,
				4 /* maxDepth */
			);
			if (newEditorState !== editorState) {
				onChange(newEditorState);
			}
			return;
		}
		if (
			(KeyBindingUtil.usesMacOSHeuristics() ||
				KeyBindingUtil.hasCommandModifier()) &&
			e.keyCode === 186
		) {
			if (keyHandlerFunction) {
				keyHandlerFunction();
				cursorPositionFunction(editorState.getSelection().getEndKey());
				return;
			}
		}
		return getDefaultKeyBinding(e);
	};

	const toggleInlineStyle = (inlineStyle) => {
		onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
	};

	const toggleBlockType = (blockType) => {
		onChange(RichUtils.toggleBlockType(editorState, blockType));
	};

	return (
		<div
			className="border border-gray-100 rounded h-full bg-white"
			style={style}
		>
			<div className="RichEditor-root" style={editorStyle} ref={sizeRef}>
				{inlineToolbarOpen && (
					<StyleControls
						editorState={editorState}
						onToggleInline={toggleInlineStyle}
						onToggleBlock={toggleBlockType}
						position={inlineToolbarPosition}
					/>
				)}
				<div
					id="editor-container"
					className={className}
					onClick={() => editorRef.current.focus()}
				>
					<Editor
						blockStyleFn={getBlockStyle}
						customStyleMap={styleMap}
						editorState={editorState}
						handleKeyCommand={handleKeyCommand}
						keyBindingFn={mapKeyToEditorCommand}
						onChange={onChange}
						placeholder={placeholder ?? "Draft an email..."}
						ref={editorRef}
						spellCheck={true}
						readOnly={readOnly}
						onBlur={onBlur}
					/>
				</div>
			</div>
		</div>
	);
};

const StyleControls = ({
	editorState,
	position,
	onToggleInline,
	onToggleBlock,
}) => {
	const currentStyle = editorState.getCurrentInlineStyle();
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<>
			<div
				className="RichEditor-controls flex flex-row flex-shrink border border-gray-200 shadow py-2 px-1 rounded"
				style={position}
			>
				<div className="flex flex-row flex-shrink pr-2 border-r border-gray-200">
					{INLINE_STYLES.map((type) => (
						<StyleButton
							key={type.label}
							active={currentStyle.has(type.style)}
							label={type.label}
							icon={type.icon}
							onToggle={onToggleInline}
							style={type.style}
						/>
					))}
				</div>
				<div className="flex flex-row items-center flex-shrink pl-2">
					{BLOCK_TYPES.map((type) => (
						<StyleButton
							key={type.label}
							active={type.style === blockType}
							label={type.label}
							icon={type.icon}
							onToggle={onToggleBlock}
							style={type.style}
						/>
					))}
				</div>
			</div>
		</>
	);
};

const StyleButton = ({ active, onToggle, style, icon }) => {
	let className = "RichEditor-styleButton";
	if (active) {
		className += " RichEditor-activeButton";
	}

	const handleMouseDown = (e) => {
		e.preventDefault();
		onToggle(style);
	};

	return (
		<span className={className} onMouseDown={handleMouseDown}>
			{icon}
		</span>
	);
};

export default RichTextEditor;

import React from "react";

const ZendeskHeader = ({ title }) => {
	return (
		<div className="mt-12 w-full flex ">
			<div className="flex mx-auto justify-start relative items-end w-11/12">
				<div className=" text-3xl font-bold">{title}</div>
			</div>
		</div>
	);
};
export default ZendeskHeader;

import React, { useContext, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router-dom";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { MainGrid, CommandK, ModalContainer } from "../components";
import { createTemplate, getTemplates } from "../requests";
import { getNavigationCommands } from "../commands";
import { HandoffsContext } from "../context";
import {
	callWithModifiers,
	callWithoutModifiers,
	handleCaughtError,
} from "../utils";
import { useScroll } from "../hooks";
import Routes from "../Routes";

const Templates = () => {
	const { menuOpen } = useContext(HandoffsContext);

	const [navCommandsOpen, setNavCommandsOpen] = useState(false);
	const [localTemplatesData, setLocalTemplatesData] = useState(null);
	const history = useHistory();

	// Data Hooks
	const {
		data: templatesData,
		isLoading: templatesIsLoading,
		isError: templatesError,
		mutate,
	} = getTemplates();

	const {
		selectedIndex,
		setSelectedIndex,
		onPressUpArrow,
		onPressDownArrow,
	} = useScroll(localTemplatesData ? localTemplatesData.length : 0, menuOpen);

	// Effects
	useEffect(() => {
		if (templatesData) {
			setLocalTemplatesData(templatesData.templates);
		}
	}, [templatesData]);

	useEffect(() => {
		if (menuOpen) {
			setSelectedIndex(null);
		}
	}, [menuOpen]);

	// Navigation
	const onPressEnter = () => {
		if (menuOpen) {
			return;
		}
		if (selectedIndex !== null) {
			history.push(
				`${Routes.EDIT_HANDOFFS_BASE_ROUTE}/${localTemplatesData[selectedIndex].id}`
			);
		}
	};

	// Mutations

	const mutateOnNewTemplate = async (newTemplates) => {
		try {
			mutate({ templates: newTemplates }, false);
			await createTemplate(newTemplates);
			mutate();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	const onCreateTemplate = () => {
		let newTemplateId = uuidv4().toString();
		let newTemplate = {
			title: "New Template",
			id: newTemplateId,
			rowsForTemplate: [{ name: "", salesforce: "" }],
		};
		let newTemplates = cloneDeep(localTemplatesData);
		newTemplates.push(newTemplate);
		setLocalTemplatesData(newTemplates);
		mutateOnNewTemplate(newTemplates);
		history.push(`${Routes.EDIT_HANDOFFS_BASE_ROUTE}/${newTemplateId}/`);
	};

	// Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "k":
					callWithModifiers(event, () => setNavCommandsOpen(true), [
						"cmd",
					]);
					break;
				case "t":
					callWithoutModifiers(event, () => onCreateTemplate());
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	// Commands

	const navigationCommands = getNavigationCommands(history);

	return (
		<>
			{/* Page Body (Grid) */}
			<div className="flex flex-col flex-grow h-full">
				<span className=" flex flex-row w-full h-9 pl-20 flex flex-row items-center rounded-none text-gray-400">
					<span className="text-sm font-medium pr-1">Hit </span>
					<svg
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							y="0.5"
							width="18"
							height="18"
							rx="2"
							fill="#F5F6FB"
						/>
						<path
							d="M6.00244 6.35937H8.51807V13.5H9.48682V6.35937H12.0024V5.5H6.00244V6.35937Z"
							fill="#27282B"
						/>
					</svg>

					<span className="text-sm pl-1 font-medium">
						to create a template!
					</span>
				</span>
				{/* Grid*/}
				<MainGrid
					data={localTemplatesData}
					selectedId={selectedIndex}
					setSelectedId={setSelectedIndex}
					variant="templates"
					onPressUpArrow={onPressUpArrow}
					onPressDownArrow={onPressDownArrow}
					onPressEnter={onPressEnter}
					hoverEnabled={!menuOpen}
					loading={templatesIsLoading}
					error={templatesError}
					onLeaveHover
				/>
			</div>

			{/* Command Line */}
			<ModalContainer top="30%">
				{navCommandsOpen && (
					<CommandK
						options={navigationCommands}
						setOpen={setNavCommandsOpen}
						variant="actionItems"
						placeholder="Go to..."
					/>
				)}
			</ModalContainer>
		</>
	);
};

export default Templates;

import { NPS } from "../components";

const userTypes = {
	executive_sponsor: "Executive Sponsor",
	champion: "Champion",
	power_user: "Power User",
};

export default (users, select) => {
	return users?.map((user) => {
		let userRole = user.role;
		if (userRole in userTypes) {
			userRole = userTypes[userRole];
		}
		return {
			title: `${user.first_name} ${user.last_name}`,
			subtitle: userRole,
			nps: <NPS score={user.nps ? user.nps : "NA"} />,
			command() {
				select(user);
			},
		};
	});
};

import React, { useEffect, useState } from "react";
import useSWR from "swr";
import fetch from "unfetch";
import { BsArrowReturnLeft } from "react-icons/bs";
import ChatUserName from "../renderUserName";
import { comment } from "postcss";
import ReplyBox from "../ReplyBox";
import { BsArrow90DegLeft } from "react-icons/bs";

const InternalComment = ({
	setUserEmail,
	setUserName,
	commentData,
	id,
	userNameDatabase,
	userData,
	userName,
	body,
}) => {
	return (
		<>
			<div className="w-11/12 mx-auto">
				<div className="pt-6 pl-2 flex flex-inline">
					<ChatUserName
						setUserEmail={setUserEmail}
						isPrivate={true}
						setUserName={setUserName}
						commentData={commentData}
						id={id}
						userNameDatabase={userNameDatabase}
						userData={userData}
					></ChatUserName>
					<div className="text-xs my-auto pl-2">{userName}</div>
				</div>

				<div className=" py-2 pl-2 w-1/2 flex flex-wrap break-words">
					{/* added a padding of one to this, basically just made it easier to see it "inline" because of how light the above box border is */}
					<span className=" px-4 rounded-xl py-2 bg-gray-200 bg-opacity-50 font-sans tex-t-sm ">
						{body}
					</span>
				</div>
			</div>
		</>
	);
};

export default InternalComment;

import Routes from "../Routes";

export default (err) => {
	if (err.response.status === 401) {
		localStorage.removeItem("access_token");
		window.location.href = Routes.LOGIN_PAGE;
	} else {
		throw err;
	}
};

import React, { useEffect, useState } from "react";
import Grade from "../Grade";

import ProgressBar from "../ProgressBar/ProgressBar";

const phaseItems = [
	"Onboarding",
	"Adopting",
	"Deployed",
	"Renewal Approaching",
	"Renewal",
];

const rows = [
	[0, 3],
	[3, 6],
];

const Overview = ({ data, onClickStage, variant }) => {
	const [overviewItems, setOverviewItems] = useState([]);
	const [progressIndex, setProgressIndex] = useState(
		phaseItems.indexOf(data.stage)
	);

	useEffect(() => {
		if (data) {
			let DUR;
			if (data.contract_end) {
				const oneDay = 24 * 60 * 60 * 1000;
				const end = new Date(data.contract_end);
				const today = new Date();
				DUR = Math.round(Math.abs((today - end) / oneDay));
			} else {
				DUR = null;
			}

			const stats = [
				{ name: "NPS", grade: data.nps },
				{ name: "Days Until Renewal", grade: DUR },
			];

			if (data.scorecard) {
				setOverviewItems(data.scorecard.scores.concat(stats));
			} else {
				setOverviewItems([]);
			}
			if (data.stage) {
				setProgressIndex(phaseItems.indexOf(data.stage));
			} else {
				setProgressIndex(null);
			}
		}
	}, [data]);

	return (
		<div className="w-full h-full">
			{variant === "actionItems" && overviewItems ? (
				<>
					<div className="flex flex-col justify-center items-center w-full bg-sidbarmenugray rounded py-4">
						{overviewItems &&
							rows.map((row, rowIndex) => (
								<div
									className="flex flex-row justify-evenly w-full h-1/2"
									key={rowIndex + JSON.stringify(data)}
								>
									{overviewItems
										.slice(row[0], row[1])
										.map((item, index) => (
											<div
												className="flex flex-col justify-center items-center h-full w-40 p-2"
												key={
													index + JSON.stringify(item)
												}
											>
												<span>
													<Grade
														grade={
															item.grade ?? "-"
														}
														size={35}
														text="m"
														variant="number"
													/>
												</span>
												<p className="body-xsmall-semibold-gray text-center mt-3">
													{item.name}
												</p>
											</div>
										))}
								</div>
							))}
					</div>
				</>
			) : (
				<>
					<div className="flex justify-center items-center w-full h-32 bg-lightgray rounded mb-5">
						<ProgressBar
							items={phaseItems}
							height={40}
							progress={progressIndex}
							setProgress={(index) => {
								if (index === progressIndex) return;
								onClickStage(phaseItems[index]);
								setProgressIndex(index);
							}}
							variant="scorecard"
						/>
					</div>

					<div className="flex flex-col justify-center items-center w-full h-80 bg-lightgray rounded py-4">
						{overviewItems.length === 6 &&
							rows.map((row, rowIndex) => (
								<div
									className="flex flex-row justify-evenly w-full h-1/2"
									key={rowIndex + JSON.stringify(data)}
								>
									{overviewItems
										.slice(row[0], row[1])
										.map((item, index) => (
											<div
												className="flex flex-col justify-center items-center h-full w-40"
												key={
													index + JSON.stringify(item)
												}
											>
												<Grade
													grade={item.grade ?? "-"}
													size={55}
													text="2xl"
													variant="number"
												/>
												<p className="text-sm mt-3">
													{item.name}
												</p>
											</div>
										))}
								</div>
							))}
					</div>
				</>
			)}
		</div>
	);
};

export default Overview;

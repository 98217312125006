import React, { Component, useState, useEffect } from "react";
import Row from "./Row";

function MainGrid(props) {
	// const { data, isLoading, isError } = GetCtaData()
	const [selected, setSelected] = useState(props.initSelect);
	//move up and down via arrow keys
	useEffect(() => {
		setSelected(props.initSelect);
	}, [props.initSelect]);
	//get props
	function handleLanguage(langValue) {
		setSelected(langValue);
		//props.setLanguage(langValue);
	}

	if (props.data == false) {
		return <div>loading</div>;
	}

	return (
		<div>
			{/* content */}
			{/* add underlines: divide-y divide-gray-200 */}
			<div className="">
				{props.data.map((item) => {
					//check to see if undefined

					return (
						<div onMouseEnter={(e) => handleLanguage(item.cta_id)}>
							<Row
								selected={selected}
								data={props.data}
								user={props.user}
								key={item.cta_id}
								id={item.cta_id}
								company_name={item.cta}
								notification={item.notification}
								timestamp={item.last_updated}
							></Row>
						</div>
					);
				})}
			</div>
		</div>
	);
}

MainGrid.propTypes = {};

export default MainGrid;

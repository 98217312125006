import React, { useRef } from "react";

const BlockPagePopup = ({ title, click, buttonTitle }) => {
	let modalRef = useRef(null);
	return (
		<div
			ref={modalRef}
			className="relative flex flex-col justify-start items-center p-6 w-96 rounded-lg shadow-xl border border-gray-100 bg-white z-10"
		>
			<p className="m-3 font-bold text-base text-gray-600 text-center">
				{title}
			</p>
			<button
				className="px-5 my-3 rounded whitespace-nowrap  h-8 text-center text-white text-sm bg-red-500"
				onClick={click}
			>
				{buttonTitle}
			</button>
		</div>
	);
};

export default BlockPagePopup;

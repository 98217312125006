import useSWR from "swr";

import getFetcher from "./getFetcher";

const getFiveTranIntegrations = () => {
	const { data, error, mutate } = useSWR(
		"/api/connections/getIntegrations",
		getFetcher
	);

	return {
		data: data,
		loading: !error && !data,
		error: error,
		mutate,
	};
};

export default getFiveTranIntegrations;

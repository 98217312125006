import { useRef, useState, useEffect } from "react";

const SidebarInfoSection = ({
	title,
	content,
	onBlur,
	readOnly = false,
	variant,
}) => {
	let textAreaRef = useRef(null);
	const [text, setText] = useState(content);
	const [isFocused, setIsFocused] = useState(false);

	useEffect(() => {
		if (!readOnly) {
			setText(content);
		}
	}, [content]);

	useEffect(() => {
		if (!readOnly) {
			textAreaRef.current.style.height = "0px";
			const scrollHeight = textAreaRef.current.scrollHeight;
			textAreaRef.current.style.height = scrollHeight + "px";
		}
	}, [text]);

	const onKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			textAreaRef.current.blur();
		}
	};
	let color = "bg-sidebarmenugray";
	if (variant === "cta") color = "bg-lightgray";

	return (
		<>
			{/* If not read-only, display interactable text area on blue background */}

			{!readOnly && (
				<div
					className={`${color} w-full  rounded p-3 cursor-pointer`}
					onClick={() => textAreaRef.current.focus()}
				>
					<p className="body-small-semibold-gray mb-2">{title}:</p>
					<textarea
						ref={textAreaRef}
						className={`body-small-black w-full ${color} outline-none ${
							isFocused ? "cursor-text" : "cursor-pointer"
						}`}
						rows={1}
						style={{ resize: "none" }}
						onChange={(e) => setText(e.target.value)}
						onBlur={() => {
							onBlur(text);
							setIsFocused(false);
						}}
						value={text}
						placeholder={`Add ${title}...`}
						onFocus={() => setIsFocused(true)}
						onKeyDown={onKeyDown}
					/>
				</div>
			)}

			{/* If read-only, on display text on transparant background */}

			{readOnly && (
				<div className={`${color} w-full y p-3 rounded`}>
					<p className="body-small-semibold-gray mb-2">{title}:</p>
					<p className="body-small-black w-full ">{content}</p>
				</div>
			)}
		</>
	);
};

export default SidebarInfoSection;

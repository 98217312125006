import { HiCheckCircle } from "react-icons/hi";
import { GiCommercialAirplane } from "react-icons/gi";
import { IoNotificationsCircleOutline } from "react-icons/io5";

import Routes from "./Routes";
import {
	InProgressIcon,
	BlockedIcon,
	LowPriorityIcon,
	MediumPriorityIcon,
	HighPriorityIcon,
	WaitingIcon,
} from "./components/Icons";

const icons = {
	Low: <LowPriorityIcon />,
	Medium: <MediumPriorityIcon />,
	High: <HighPriorityIcon />,
	New: <IoNotificationsCircleOutline size={16} className="text-green-500" />,
	"In Progress": <InProgressIcon />,
	"Waiting on Customer": <WaitingIcon />,
	Blocked: <BlockedIcon />,
	Done: <HiCheckCircle size={16} className="text-indigo-500" />,
	Planned: <GiCommercialAirplane size={16} className="text-indigo-500" />,

	Open: <IoNotificationsCircleOutline size={16} className="text-green-500" />,
	Pending: <WaitingIcon />,
	Solved: <HiCheckCircle size={16} className="text-indigo-500" />,
};

const priorities = [
	{
		value: "Low",
		icon: icons["Low"],
	},
	{
		value: "Medium",
		icon: icons["Medium"],
	},
	{
		value: "High",
		icon: icons["High"],
	},
];

const todoStatuses = [
	{
		value: "Planned",
		icon: icons["Planned"],
	},
	{
		value: "In Progress",
		icon: icons["In Progress"],
	},
	{
		value: "Done",
		icon: icons["Done"],
	},
	{
		value: "Waiting on Customer",
		icon: icons["Waiting on Customer"],
	},
	{
		value: "Blocked",
		icon: icons["Blocked"],
	},
];

const statuses = [
	{
		value: "New",
		icon: icons["New"],
	},
	{
		value: "In Progress",
		icon: icons["In Progress"],
	},
	{
		value: "Waiting on Customer",
		icon: icons["Waiting on Customer"],
	},
	{
		value: "Blocked",
		icon: icons["Blocked"],
	},
];

const zendeskStatuses = [
	{
		value: "Open",
		icon: icons["Open"],
	},
	{
		value: "Pending",
		icon: icons["Pending"],
	},
	{
		value: "Solved",
		icon: icons["Solved"],
	},
];

const ctaTypes = [
	{
		value: "Onboarding",
	},
	{
		value: "Churn Risk",
	},
	{
		value: "Expansion Opportunity",
	},
];

const effectOptions = [
	{
		title: "Positive - Increased Trust",
		value: "positive",
	},
	{
		title: "Neutral - Resolved and no lasting impact",
		value: "neutral",
	},
	{
		title: "Negative - Decreased Trust",
		value: "negative",
	},
];

const navigationOptions = [
	{
		type: "Navigation",
		title: "Inbox",
		route: Routes.ACTION_ITEMS_PAGE,
		keyCommand: "GI",
	},
	{
		type: "Navigation",
		title: "Accounts",
		route: Routes.ALL_ACCOUNTS_PAGE,
		keyCommand: "GA",
	},
	{
		type: "Navigation",
		title: "All CTAs",
		route: Routes.ALL_CTA_PAGE,
		keyCommand: "GC",
	},
	// {
	// 	type: "Navigation",
	// 	title: "Integrations",
	// 	route: Routes.INTEGRATIONS_PAGE,
	// 	keyCommand: "GN",
	// },
	{
		type: "Navigation",
		title: "Playbooks",
		route: Routes.PLAYBOOKS_PAGE,
		keyCommand: "GP",
	},
	{
		type: "Navigation",
		title: "Snippets",
		route: Routes.SNIPPETS_PAGE,
		keyCommand: "GE",
	},
	{
		type: "Navigation",
		title: "Sales",
		route: Routes.SALES_PAGE,
		keyCommand: "GS",
	},
	{
		type: "Navigation",
		title: "Handoffs",
		route: Routes.HANDOFFS_PAGE,
		keyCommand: "GH",
	},
	{
		type: "Navigation",
		title: "Settings",
		route: Routes.SETTINGS_PAGE,
		keyCommand: "GX",
	},
];

const integrations = [
	{
		title: "Support",
		integrations: [
			"zendesk",
			"zendesk_chat",
			"kustomer",
			"intercom",
			"freshdesk",
		],
	},
	{
		title: "Analytics",
		integrations: [
			"amplitude",
			"mixpanel",
			"heap",
			"googl_analytics",
			"splunk",
			"segment",
		],
	},
	{
		title: "Sales",
		integrations: ["salesforce"],
	},
	{
		title: "Issue Tracking",
		integrations: ["jira", "asana"],
	},
	{
		title: "Finance",
		integrations: [
			"quickbooks",
			"netsuite_suiteanalytics",
			"stripe",
			"xero",
		],
	},
	{
		title: "Other",
		integrations: ["s3", "gcs"],
	},
];

const settingsSplits = [
	{
		title: "Settings",
		route: Routes.SETTINGS_PAGE,
		id: "local",
	},
	{
		title: "Playbooks",
		route: Routes.PLAYBOOKS_PAGE,
		id: "playbooks",
	},
	{
		title: "Snippets",
		route: Routes.SNIPPETS_PAGE,
		id: "snippets",
	},
	{
		title: "Integrations",
		route: Routes.INTEGRATIONS_PAGE,
		id: "integrations",
	},
];
const handoffsSplits = [
	{
		title: "Handoffs",
		route: Routes.HANDOFFS_PAGE,
		id: "local",
	},
	{
		title: "Templates",
		route: Routes.TEMPLATES_PAGE,
		id: "templates",
	},
];

const splitPages = {
	"Inbox Page": {
		split: "action_items_split",
		route: Routes.ACTION_ITEMS_PAGE,
	},
	"All CTAs Page": { split: "all_cta_split", route: Routes.ALL_CTA_PAGE },
	"Accounts Page": {
		split: "account_split",
		route: Routes.ALL_ACCOUNTS_PAGE,
	},
};

const pages = {
	account_split: { page: "All Accounts", pageName: "Accounts Page" },
	all_cta_split: {
		page: "All CTAs",
		pageName: "All CTAs Page",
	},
	action_items_split: {
		page: "Action Items",
		pageName: "Inbox Page",
	},
};

const urlToPage = {
	actionItems: "action_items_split",
	allCta: "all_cta_split",
	accounts: "account_split",
};

const sourcePages = {
	[Routes.ACTION_ITEMS_PAGE]: "actionItems",
	[Routes.ALL_ACCOUNTS_PAGE]: "accounts",
	[Routes.ALL_CTA_PAGE]: "allCta",
};

export {
	icons,
	priorities,
	todoStatuses,
	statuses,
	ctaTypes,
	effectOptions,
	navigationOptions,
	integrations,
	settingsSplits,
	splitPages,
	pages,
	urlToPage,
	sourcePages,
	zendeskStatuses,
	handoffsSplits,

};

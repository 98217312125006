import { useEffect, useRef, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { IoClose } from "react-icons/io5";
import draftToHtml from "draftjs-to-html";
import { icons, priorities, statuses } from "../../../constants";
import { SmallDropdown, UserIcon, CommandK } from "../..";
import { callWithModifiers, callWithoutModifiers } from "../../../utils";
import { IoIosAdd } from "react-icons/io";
import ModalContainer from "../../Modals/ModalContainer";
import checkmark from "../../../assets/checkmark.svg";
import TodoDatePicker from "../../CtaComponents/Todo/TodoDatePicker";
import { requestUpdate } from "../../../requests";

const TodoItem = ({
	todoId,
	status,
	description,
	date,
	title,
	completed,
	onUpdateTodo,
	creatingTodo = false,
	isCurrentTodo,
}) => {
	let bodyInputRef = useRef(null);

	const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
	const [commandKOpen, setCommandKOpen] = useState(false);
	const [sentNotificationOpen, setSentNotificationOpen] = useState(false);

	const [localDate, setLocalDate] = useState(date ? new Date(date) : null);
	const [todoDescription, setTodoDescription] = useState(description);
	const [todoStatus, setTodoStatus] = useState(status);
	const [localCompleted, setLocalCompleted] = useState(completed);
	const [draftRecipients, setDraftRecipients] = useState([]);

	// Effects

	useEffect(() => {
		setLocalDate(date ? new Date(date) : null);
		setTodoDescription(description);
		setTodoStatus(status);
	}, [todoId]);

	// useEffect(() => {
	// 	if (todoStatus !== "Done") {
	// 		bodyInputRef.current.style.height = "0px";
	// 		const scrollHeight = bodyInputRef.current.scrollHeight;
	// 		bodyInputRef.current.style.height = scrollHeight + "px";
	// 	}
	// }, [todoDescription, todoStatus]);

	// Event Handlers

	const onKeyDownBody = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(e, () => bodyInputRef.current.blur(), ["cmd"]);
		}
	};

	const onBodyBlur = () => {
		onUpdateTodo(todoId, "body", { description: todoDescription });
	};

	const onChangeSelectedDate = (date) => {
		setLocalDate(date);
		if (!creatingTodo) {
			onUpdateTodo(todoId, "due_date", date);
		}
	};

	return (
		<div className="flex flex-row w-full">
			{/* If todo is not being created, show status dropdown */}
			{!creatingTodo && (
				<div id="todo-status-section" className="py-1.5">
					{localCompleted ? (
						<div
							onClick={() => setLocalCompleted(false)}
							className="mt-0.5 h-4 w-4 flex content-center justify-center mr-2 rounded bg-blue-600 justify-items-center items-center"
						>
							<img className="mr-0.5" src={checkmark}></img>
						</div>
					) : (
						<div
							onClick={() => setLocalCompleted(true)}
							className="mt-0.5  h-4 w-4 rounded mr-2 border border-gray-200"
						></div>
					)}
				</div>
			)}

			{/* Otherwise */}
			<div className="flex flex-col justify-center py-1.5 pr-5 w-full">
				{/* Top Row */}
				<div className="flex flex-row w-full justify-between mb-2">
					{/* Left Side of List Item */}
					<span className="flex flex-row justify-start">
						{creatingTodo && (
							<div style={{ height: "14px", width: "14px" }} />
						)}

						{/* Title */}

						<p className="text-sm font-light">{title}</p>

						{/* Date Picker */}
						<span className="place-self-center">
							<TodoDatePicker
								selectedDate={localDate}
								setSelectedDate={onChangeSelectedDate}
							/>
						</span>
					</span>
				</div>

				{/* Todo Description Box */}

				<span className="my-1 w-full shadow-none">
					<textarea
						className="text-xs bg-lightgray flex-grow w-full rounded font-normal text-gray-900"
						onChange={(e) => setTodoDescription(e.target.value)}
						onKeyDown={onKeyDownBody}
						placeholder={"Todo description..."}
						ref={bodyInputRef}
						rows={0}
						value={todoDescription}
						onBlur={onBodyBlur}
						style={{
							resize: "none",
							display: todoStatus == "Done" ? "none" : null,
						}}
					/>
				</span>

				<ModalContainer top="30%">
					{commandKOpen && (
						<CommandK
							options={recipientCommands}
							setOpen={setCommandKOpen}
							variant="account"
							placeholder="Choose a recipient"
						/>
					)}
				</ModalContainer>
			</div>
		</div>
	);
};

export default TodoItem;

import * as Sentry from "@sentry/react";
import cloneDeep from "lodash.clonedeep";
import Fuse from "fuse.js";

import { pages, splitPages, urlToPage } from "./constants";
import { UserIcon } from "./components";

const dropRecord = (CtaId, data) => {
	let output = data;
	output.data = output.data.filter((jsonObject) => {
		return jsonObject.cta_id !== CtaId;
	});
	output.length -= 1;

	return output;
};

const getIconOptionsFromUsers = (users) =>
	users.map((user) => ({
		userId: user.user_id,
		email: user.email,
		value: `${user.first_name} ${user.last_name}`,
		iconLabel:
			user.first_name[0].toUpperCase() + user.last_name[0].toUpperCase(),
		icon: (
			<UserIcon
				user={
					user.first_name[0].toUpperCase() +
					user.last_name[0].toUpperCase()
				}
			/>
		),
	}));

const filterSnooze = (ctaData, email) => {
	// Only show items that are followed or assigned to the user
	let data = [];
	const currentDate = Date.now();
	for (let i = 0; i < ctaData.length; i++) {
		let visibility = ctaData[i].visiblity;
		let snoozeObject = ctaData[i].snooze;

		// Skip resolved CTAs
		if (ctaData[i].resolved) {
			continue;
		}

		// Skip CTAs that are not followed by or assigned to the user (keep if CTA has no assignee yet though)
		if (
			!visibility.assigned_to?.includes(email) &&
			!visibility.followed_by?.includes(email) &&
			!(visibility.assigned_to?.length === 0)
		) {
			continue;
		}

		// Skip snoozed items
		if (
			snoozeObject &&
			email in snoozeObject &&
			snoozeObject[email] > currentDate
		) {
			continue;
		}

		// If met all criteria so far, add to list
		data.push(ctaData[i]);
	}
	return data;
};

const getResultLength = (filter, data, userData, returnSnoozed) => {
	const options = {
		threshold: 0.4,
		sortFn: (a, b) => {
			return b.item.last_updated - a.item.last_updated;
		},
		useExtendedSearch: true,
		keys: [
			"cta",
			"company_name",
			"company_id",
			"cta",
			"grade",
			"cta_summary",
			"owner.csm_owner",
			"visiblity.assigned_to",
			"visiblity.followed_by",
		],
	};
	let searchData;
	if (!returnSnoozed) {
		searchData = filterSnooze(data, userData.email);
	} else {
		searchData = data;
	}
	const fuse2 = new Fuse(searchData, options);
	return fuse2.search(filter).length;
};

const cleanSearchResults = (data) => {
	return data.map((item) => item.item);
};

const callWithoutModifiers = (event, func) => {
	if (!event.metaKey && !event.shiftKey && !event.ctrlKey && !event.altKey) {
		func();
	}
};

const callWithModifiers = (
	event,
	func,
	modifiers = ["cmd", "alt", "ctrl", "shift"]
) => {
	if (
		((event.metaKey && modifiers.includes("cmd")) ||
			(!event.metaKey && !modifiers.includes("cmd"))) &&
		((event.altKey && modifiers.includes("alt")) ||
			(!event.altKey && !modifiers.includes("alt"))) &&
		((event.ctrlKey && modifiers.includes("ctrl")) ||
			(!event.ctrlKey && !modifiers.includes("ctrl"))) &&
		((event.shiftKey && modifiers.includes("shift")) ||
			(!event.shiftKey && !modifiers.includes("shift")))
	) {
		func();
	}
};

const isNumeric = (str) => {
	if (typeof str !== "string") return false;
	return !isNaN(str) && !isNaN(parseFloat(str));
};

const compareAssignment = (item) => {
	if (item.unassigned) return 1;
	if (item.assigned) return 2;
	if (item.following) return 3;
	return Infinity; // move to end
};

const filterSplitData = (
	userData,
	splitSettings,
	selectedSplit,
	allCtaData,
	setFilteredData,
	setSplitTitles,
	returnSnoozed = false,
	sortedByDate = true,
	zendeskData = null,
	sortedByAssigned = false
) => {
	let output = [];

	for (let i = 0; i < splitSettings.length; i++) {
		let item = splitSettings[i];
		let data;
		if (selectedSplit == "support") {
			data = zendeskData?.ticketData;
			const res = { data: data, length: data?.length };
			setFilteredData(res);
			if (!setSplitTitles) break;
		} else if (selectedSplit === item.slug) {
			const options = {
				threshold: 0.4,
				sortFn: (a, b) => {
					return b.item.last_updated - a.item.last_updated;
				},
				useExtendedSearch: true,
				keys: [
					"cta",
					"company_name",
					"company_id",
					"cta",
					"grade",
					"cta_summary",
					"owner.csm_owner",
					"visiblity.assigned_to",
					"visiblity.followed_by",
				],
			};

			let fuseData;
			if (returnSnoozed) {
				fuseData = allCtaData.data;
			} else {
				fuseData = filterSnooze(allCtaData.data, userData.email);
			}

			const fuse = new Fuse(fuseData, options);
			data = fuse.search(item.filter);
			data = cleanSearchResults(data);

			// Sort by date
			if (sortedByDate) {
				data.sort(
					(a, b) =>
						Date.parse(b.last_updated) - Date.parse(a.last_updated)
				);
			}

			// Sort by assignment (unassigned => assigned to me => followed by me)
			let sortedAmounts = {
				Assigned: 0,
				Unassigned: 0,
				Following: 0,
			};
			if (sortedByAssigned) {
				data.forEach((element, index) => {
					let assigned = element.visiblity.assigned_to?.includes(
						userData.email
					);
					let unassigned = assigned
						? false
						: element.visiblity.assigned_to.length === 0;
					let following = element.visiblity.followed_by?.includes(
						userData.email
					);
					data[index].assigned = assigned;
					data[index].unassigned = unassigned;
					data[index].following = following;
					let sortType = assigned
						? "Assigned"
						: unassigned
						? "Unassigned"
						: "Following";
					data[index].sortType = sortType;
					sortedAmounts[sortType] += 1;
				});
				data.sort(
					(a, b) => compareAssignment(a) - compareAssignment(b)
				);
			}

			const res = { data: data, length: data.length, sortedAmounts };
			setFilteredData(res);
			if (!setSplitTitles) break;
		}
		if (setSplitTitles) {
			if (item.slug == "support") {
				item.length = zendeskData?.ticketData?.length;
			} else {
				item.length = getResultLength(
					item.filter,
					allCtaData.data,
					userData,
					returnSnoozed
				);
			}
			output.push(item);
		}
	}
	if (setSplitTitles) setSplitTitles(output);
};

const isInViewport = (row, direction) => {
	if (!row) {
		return;
	}
	let rowRect = row.getBoundingClientRect();
	let vWidth = window.innerWidth || document.documentElement.clientWidth;
	let vHeight = window.innerHeight || document.documentElement.clientHeight;
	const elementFromPointForm = (x, y) => {
		return document.elementFromPoint(x, y);
	};

	//true is up
	if (direction) {
		if (rowRect.right < 0 || rowRect.left > vWidth || rowRect.bottom < 0)
			return false;

		return (
			row.contains(elementFromPointForm(rowRect.left, rowRect.top)) ||
			row.contains(elementFromPointForm(rowRect.right, rowRect.top))
		);
	} else {
		if (rowRect.bottom < 0 || rowRect.top > vHeight) return false;

		return (
			row.contains(elementFromPointForm(rowRect.right, rowRect.bottom)) ||
			row.contains(elementFromPointForm(rowRect.left, rowRect.bottom))
		);
	}
};

const scrollRow = (row, scrollUp = true) => {
	if (row) {
		row.scrollIntoView({
			behavior: "smooth",
			block: scrollUp ? "start" : "end",
			inline: "nearest",
		});
	}
};

const handleCaughtError = (
	err,
	messagePrefix = "Handled Error:",
	sentry = true
) => {
	if (sentry && process.env.VERCEL_ENV !== "development") {
		Sentry.captureException(err);
	}
	console.error(`${messagePrefix} ${err.message}`);
};

const consoleHelper = (...messages) => {
	if (process.env.VERCEL_ENV === "development") return;
	console.log(...messages);
};

const getUserSplits = (sellerantUsers, userId, urlPage, searchOverride) => {
	let currentPage = urlToPage[urlPage];
	let usersCopy = cloneDeep(sellerantUsers.data);
	let userSplits = [];
	let user = usersCopy.find((user) => user.user_id === userId);

	if (!user) {
		return [];
	}

	let splitsOnPage = user.settings;
	for (let page in splitsOnPage) {
		if (page in pages && (page === currentPage || searchOverride)) {
			let usefulSplits = splitsOnPage[page]
				.filter((split) => split.protected === false)
				.map((split) => ({
					...split,
					...pages[page],
					pageKey: page,
				}));
			userSplits = userSplits.concat(usefulSplits);
		}
	}
	return userSplits;
};

const removeSplitListHelper = (pageName, users, splitName, pageKey, userId) => {
	let usersCopy = cloneDeep(users);
	let currentUser = usersCopy.find((user) => user.user_id === userId);
	let currentUserIndex = usersCopy.findIndex(
		(user) => user.user_id === userId
	);

	let splitList = currentUser.settings[pageKey].filter(
		(split) => split.slug !== splitName
	);

	let selectedSplitPage = splitPages[pageName].split;
	currentUser.settings[selectedSplitPage] = splitList;
	let mergedSettings = currentUser.settings;
	usersCopy[currentUserIndex] = currentUser;
	return { usersCopy, mergedSettings };
};

const generateCreatedAt = () => {
	let now = new Date();
	let month = `${now.getMonth()}`;
	if (month.length === 1) {
		month = `0${month}`;
	}
	let day = `${now.getDate() + 1}`;
	if (day.length === 1) {
		day = `0${day}`;
	}
	return `${now.getFullYear()}-${month}-${day}`;
};

export {
	dropRecord,
	getIconOptionsFromUsers,
	filterSnooze,
	getResultLength,
	cleanSearchResults,
	callWithoutModifiers,
	callWithModifiers,
	isNumeric,
	filterSplitData,
	isInViewport,
	scrollRow,
	handleCaughtError,
	consoleHelper,
	getUserSplits,
	removeSplitListHelper,
	generateCreatedAt,
};

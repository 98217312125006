import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import Routes from "../Routes";

const CtaSplitContext = React.createContext();

const CtaSplitContextWrapper = withRouter(({ location, children }) => {
	const [selectedSplit, setSelectedSplit] = useState(null);
	const [splitSettings, setSplitSettings] = useState(null);
	const [backPage, setBackPage] = useState(null);

	useEffect(() => {
		if (
			location.pathname === Routes.ACTION_ITEMS_PAGE ||
			location.pathname === Routes.ALL_CTA_PAGE
		) {
			setBackPage(location.pathname);
		}
	}, [location, selectedSplit]);

	useEffect(() => {
		if (splitSettings) {
			if (!selectedSplit) {
				setSelectedSplit(splitSettings[0].slug);
			} else {
				let currentSplit = splitSettings.find(
					(split) => split.slug === selectedSplit
				);
				if (!currentSplit) {
					setSelectedSplit(splitSettings[0].slug);
				}
			}
		}
	}, [splitSettings]);

	return (
		<CtaSplitContext.Provider
			value={{
				selectedSplit,
				setSelectedSplit,
				splitSettings,
				setSplitSettings,
				backPage,
			}}
		>
			{children}
		</CtaSplitContext.Provider>
	);
});

export { CtaSplitContext, CtaSplitContextWrapper };

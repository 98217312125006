const CompletePhaseButton = ({ label, colorClass, onClick, active = true }) => (
	<button
		onClick={active ? onClick : () => null}
		className={`flex justify-center items-center rounded font-medium text-xs ${
			active
				? `${colorClass} cursor-pointer`
				: "text-gray-400 cursor-default"
		}`}
	>
		{label}
	</button>
);

export default CompletePhaseButton;

const SidebarStatistic = ({ title, figure }) => {
	return (
		<div className="flex flex-col  items-center w-1/2">
			<p className=" mb-1 body-small-gray">{title}</p>
			<p className="text-lg body-small-black">{figure}</p>
		</div>
	);
};

export default SidebarStatistic;

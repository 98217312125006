import React from "react";
import { BiArrowBack, BiChevronUp, BiChevronDown } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

const NavBar = ({
	onClickBack,
	onClickUp,
	onClickDown,
	verticalArrowsVisible = true,
	backIsCloseButton = false,
}) => {
	return (
		<div className="flex flex-col flex-none items-center w-24 h-full">
			<button
				className="flex justify-center items-center flex-none w-10 h-10 mt-6 bg-white hover:bg-gray-50 rounded-full outline-none border border-gray-200"
				onClick={onClickBack}
			>
				{backIsCloseButton ? (
					<IoClose className="text-gray-400" />
				) : (
					<BiArrowBack className="text-gray-400" />
				)}
			</button>
			{verticalArrowsVisible && (
				<div className="flex flex-col items-center flex-none mt-6 h-20 w-10 rounded-full bg-white border border-gray-200 overflow-hidden">
					<button
						className="flex justify-center items-center w-full h-1/2 hover:bg-gray-50 active:bg-gray-100 outline-none"
						onClick={onClickUp}
					>
						<BiChevronUp className="text-gray-400" size={30} />
					</button>
					<button
						className="flex justify-center items-center w-full h-1/2 hover:bg-gray-50 active:bg-gray-100 outline-none"
						onClick={onClickDown}
					>
						<BiChevronDown className="text-gray-400" size={30} />
					</button>
				</div>
			)}
		</div>
	);
};

export default NavBar;

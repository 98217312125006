import React, { Component, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router";
import CommandPalette from "react-command-palette";
import useSWR, { mutate } from "swr";
function Row(props) {
	const [navigate, setNavigate] = useState(false);

	const commands = [
		{
			name: "Mute [DEVELOPMENT]",
			command() {
				console.log("Mute");
			},
		},
		{
			name: "Assign to [DEVELOPMENT]",
			command() {
				console.log("Assign to Command Executed");
			},
		},
	];

	// here is the important part
	if (navigate) {
		return <Redirect to={"/cta/" + props.id + "/todo"} push={true} />;
	}
	//if current row is selected
	if (props.id == props.selected) {
		return (
			<div className="grid grid-cols-12 bg-gray-100 cursor-pointer">
				{/* unread indicator */}
				<div
					onClick={() => setNavigate(true)}
					className="grid grid-cols-8"
				>
					<div className="bg-purple-300 mr-2.5"></div>
					<div className="col-span-7 py-2">
						<div className="mr-1.5 py-2">
							{/* <ReadIndicator unread={unread}></ReadIndicator> */}
						</div>
					</div>
				</div>

				<div
					onClick={() => setNavigate(true)}
					className="py-2 col-start-2 col-end-5"
				>
					<div className="flex items-left">
						<div className="pr-4">
							<div className="text-sm font-medium text-gray-900">
								{props.company_name}
							</div>
						</div>
					</div>
				</div>
				<div
					onClick={() => setNavigate(true)}
					className="py-2 col-start-5 col-end-9 flex items-left"
				>
					{/* <RenderGrade grade={props.grade}></RenderGrade> */}
					<div className="ml-3 text-sm text-left text-gray-900">
						{props.cta}
					</div>
				</div>
				<div
					onClick={() => setNavigate(true)}
					className="py-2 col-start-9 col-span-3"
				>
					{/* <RenderGrade grade={props.grade}></RenderGrade> */}

					<div className="italic text-sm text-gray-500">
						{props.notification}
					</div>
				</div>
				<div className="py-2 col-start-12 text-sm text-gray-500 float-left">
					<div className="rounded-md border-gray-300 w-10 text-gray-400 border-2 hover:bg-purple-300 hover:text-gray-800">
						<CommandPalette
							commands={commands}
							closeOnSelect={true}
							hotKeys={"command+k"}
							header={
								<p className="pb-2">
									Sellerant Command: {props.company_name}{" "}
									{props.cta}
								</p>
							}
							trigger={<p className="text-center text-xs">⌘K</p>}
						>
							{" "}
						</CommandPalette>
					</div>
				</div>
			</div>
		);
	}
	//default row styling
	else {
		return (
			<div
				onClick={() => setNavigate(true)}
				className="grid grid-cols-12 py-2"
			>
				{/* unread indicator */}

				<div className="col-start-2 col-end-5">
					<div className="flex items-left">
						<div className="pr-4">
							<div className="text-sm font-medium text-gray-900">
								{props.company_name}
							</div>
						</div>
					</div>
				</div>
				<div className="col-start-5 col-end-8 flex items-left">
					{/* <RenderGrade grade={props.grade}></RenderGrade> */}
					<div className="ml-3 text-sm text-left text-gray-900">
						{props.cta}
					</div>
				</div>
				<div className="col-start-9 col-span-2">
					{/* <RenderGrade grade={props.grade}></RenderGrade> */}

					<div className="italic text-sm text-gray-500">
						{props.notification}
					</div>
				</div>
				<div className="col-start-12 text-sm text-gray-500"></div>
			</div>
		);
	}
}

export default Row;

import { BsClock, BsFillBarChartFill, BsPencil } from "react-icons/bs";
import { IoMailUnreadOutline } from "react-icons/io5";
import {
	AiOutlinePieChart,
	AiOutlineUser,
	AiOutlineUserAdd,
} from "react-icons/ai";
import { CgNotes } from "react-icons/cg";

export default (
	setStatusesOpen,
	setPrioritiesOpen,
	assignToSelf,
	setNameInputOpen,
	setAssigneeCommandOpen,
	mutateReadStatus,
	ctaUnread,
	setSnoozeCommandOpen,
	createNewNote
) => {
	let defaultOptions = [
		{
			icon: <AiOutlineUserAdd className="text-gray-600" size={18} />,
			title: "Assign to...",
			keyCommand: "A",
			command() {
				setAssigneeCommandOpen(true);
			},
		},
		{
			icon: <AiOutlineUser className="text-gray-600" size={18} />,
			title: "Assign to me...",
			keyCommand: "I",
			command() {
				assignToSelf();
			},
		},
		{
			icon: <AiOutlinePieChart className="text-gray-600" size={18} />,
			title: "Set status...",
			keyCommand: "S",
			command() {
				setStatusesOpen(true);
			},
		},
		{
			icon: <BsFillBarChartFill className="text-gray-600" size={18} />,
			title: "Set priority...",
			keyCommand: "P",
			command() {
				setPrioritiesOpen(true);
			},
		},
		{
			icon: <BsPencil className="text-gray-600" size={18} />,
			title: "Rename CTA...",
			keyCommand: "R",
			command() {
				setNameInputOpen(true);
			},
		},
		{
			icon: <IoMailUnreadOutline className="text-gray-600" size={18} />,
			title: ctaUnread ? "Mark Read..." : "Mark Unread...",
			keyCommand: "U",
			command() {
				mutateReadStatus(ctaUnread);
			},
		},
	];

	// If snooze is being used, add to list of options before return
	if (setSnoozeCommandOpen) {
		defaultOptions = defaultOptions.concat({
			icon: <BsClock className="text-gray-600" size={18} />,
			title: "Snooze CTA...",
			keyCommand: "H",
			command() {
				setSnoozeCommandOpen(true);
			},
		});
	}

	if (createNewNote) {
		defaultOptions = defaultOptions.concat({
			icon: <CgNotes className="text-gray-600" size={18} />,
			title: "Create note...",
			keyCommand: "N",
			command() {
				createNewNote();
			},
		});
	}

	return defaultOptions;
};

import axios from "axios";
import { handleCaughtError } from "../utils";

import bounceIfTokenError from "./bounceIfTokenError";

const requestUpdate = async (url, body) => {
	try {
		let res = await axios.patch(url, body, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		return res.data;
	} catch (err) {
		handleCaughtError(err);
		bounceIfTokenError(err);
	}
};

export default requestUpdate;

import { useRef, useEffect } from "react";

import { callWithModifiers } from "../utils";

const ResponsiveTextArea = ({
	title,
	text,
	setText,
	placeholder = "...",
	titleStyle = null,
	bodyStyle = null,
	onKeyDown,
	variant,
	readOnly = false,
}) => {
	let textAreaRef = useRef(null);

	useEffect(() => {
		textAreaRef.current.style.height = "0px";
		const scrollHeight = textAreaRef.current.scrollHeight;
		textAreaRef.current.style.height = scrollHeight + "px";
	}, [text]);

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(e, () => textAreaRef.current.blur(), ["shift"]);
		}
		if (onKeyDown) onKeyDown(e);
	};

	return (
		<>
			<div
				className="flex flex-row justify-start items-center w-full mb-2 text-sm"
				style={titleStyle}
			>
				{title && <p>{title}</p>}
			</div>
			<textarea
				className={`text-xs flex-grow w-full  outline-none rounded font-normal ${
					variant === "tableForm"
						? "text-sm text-primaryText py-1"
						: "text-gray-900 shadow py-2 px-3"
				}`}
				onChange={(e) => setText(e.target.value)}
				onKeyDown={handleKeyPress}
				placeholder={placeholder}
				ref={textAreaRef}
				rows={1}
				style={{ ...bodyStyle, resize: "none" }}
				value={text}
				readOnly={readOnly}
			/>
		</>
	);
};

export default ResponsiveTextArea;

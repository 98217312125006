import { BsArrowRightShort } from "react-icons/bs";

import { navigationOptions } from "../constants";

export default (history) => {
	return navigationOptions.map((option) => ({
		type: option.type,
		title: `Go to ${option.title}`,
		icon: <BsArrowRightShort className="text-gray-600" size={24} />,
		keyCommand: option.keyCommand,
		command() {
			history.push(option.route);
		},
	}));
};

import { BiPlus, BiMinus } from "react-icons/bi";

export default (setSplitNameInputOpen, setListOfSplitsPagesOpen) => [
	{
		icon: <BiPlus className="text-gray-600" size={18} />,
		title: "Create New Split",
		keyCommand: null,
		command() {
			setSplitNameInputOpen(true);
		},
	},
	{
		icon: <BiMinus className="text-gray-600" size={18} />,
		title: "Remove A Split",
		keyCommand: null,
		command() {
			setListOfSplitsPagesOpen(true);
		},
	},
];

const APP_BASE_ROUTE = "";
const ACCOUNT_BASE_ROUTE = `${APP_BASE_ROUTE}/accounts`;
const CTA_BASE_ROUTE = `${APP_BASE_ROUTE}/cta`;
const EDIT_HANDOFFS_BASE_ROUTE = `${APP_BASE_ROUTE}/editHandoffs`;
const EDIT_PLAYBOOK_BASE_ROUTE = `${APP_BASE_ROUTE}/editPlaybook`;
const EDIT_SNIPPET_BASE_ROUTE = `${APP_BASE_ROUTE}/editSnippet`;
const HANDOFFS_BASE_ROUTE = `${APP_BASE_ROUTE}/handoffs`;
const SALES_BASE_ROUTE = `${APP_BASE_ROUTE}/sales`;
const SEARCH_BASE_ROUTE = `${APP_BASE_ROUTE}/searchCta`;
const SETTINGS_BASE_ROUTE = `${APP_BASE_ROUTE}/settings`;
const NOTES_BASE_ROUTE = `${CTA_BASE_ROUTE}/note`;
const ONBOARDING_BASE_ROUTE = `${APP_BASE_ROUTE}/start`;
const ZENDESK_BASE_ROUTE = `${APP_BASE_ROUTE}/zendesk`;

const Routes = {
	ACCOUNT_BASE_ROUTE,
	APP_BASE_ROUTE,
	CTA_BASE_ROUTE,
	EDIT_PLAYBOOK_BASE_ROUTE,
	SEARCH_BASE_ROUTE,
	ZENDESK_BASE_ROUTE,
	SALES_BASE_ROUTE,
	SETTINGS_BASE_ROUTE,
	ONBOARDING_BASE_ROUTE,
	HANDOFFS_BASE_ROUTE,
	EDIT_SNIPPET_BASE_ROUTE,
	EDIT_HANDOFFS_BASE_ROUTE,
	NOTES_BASE_ROUTE,

	ACCOUNT_PAGE: `${ACCOUNT_BASE_ROUTE}/:cid/:page`,

	ACTION_ITEMS_PAGE: `${CTA_BASE_ROUTE}/open`,

	ALL_ACCOUNTS_PAGE: `${ACCOUNT_BASE_ROUTE}/all`,

	ALL_CTA_PAGE: `${CTA_BASE_ROUTE}/all`,

	AUTH_REDIRECT_PAGE: `${APP_BASE_ROUTE}/auth/`,

	CTA_PAGE: `${CTA_BASE_ROUTE}/:cid/:page`,

	EDIT_PLAYBOOK_PAGE: `${EDIT_PLAYBOOK_BASE_ROUTE}/:id`,

	INTEGRATIONS_PAGE: `${SETTINGS_BASE_ROUTE}/integrations`,

	LOGIN_PAGE: `${APP_BASE_ROUTE}/login`,

	NOTE_PAGE: `${NOTES_BASE_ROUTE}/:id`,

	PLAYBOOKS_PAGE: `${SETTINGS_BASE_ROUTE}/playbooks`,

	SNIPPETS_PAGE: `${SETTINGS_BASE_ROUTE}/snippets`,

	EDIT_SNIPPET_PAGE: `${EDIT_SNIPPET_BASE_ROUTE}/:id`,

	SALES_PAGE: `${SALES_BASE_ROUTE}`,

	SEARCH_PAGE: `${SEARCH_BASE_ROUTE}`,

	SEARCH_PAGE_WITH_FILTER: `${SEARCH_BASE_ROUTE}/:filter`,

	SETTINGS_PAGE: `${SETTINGS_BASE_ROUTE}/local`,

	HANDOFFS_PAGE: `${HANDOFFS_BASE_ROUTE}/local`,

	TEMPLATES_PAGE: `${HANDOFFS_BASE_ROUTE}/templates`,

	EDIT_HANDOFF_PAGE: `${EDIT_HANDOFFS_BASE_ROUTE}/:id`,

	ZENDESK_PAGE: `${ZENDESK_BASE_ROUTE}/:cid/:page`,

	CUSTOMER_DATAROOM_PAGE: `${ONBOARDING_BASE_ROUTE}/:cid/`,

	SALES_PAGE: `${SALES_BASE_ROUTE}`,
};

export default Routes;

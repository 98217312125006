import { useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { useHotkeys } from "react-hotkeys-hook";

import { useOutsideAlerter } from "../../hooks";
import { callWithModifiers } from "../../utils";

const TextArea = ({ title, value, setValue, onKeyDown, placeholder }) => (
	<>
		<p className="mt-3 font-medium text-base text-gray-600 text-center">
			{title}
		</p>
		<textarea
			className="w-full my-2 pl-2 rounded border border-gray-200 text-sm flex-grow font-normal text-gray-900 pt-3 pb-2 outline-none"
			style={{ resize: "none" }}
			onChange={(e) => setValue(e.target.value)}
			placeholder={placeholder}
			value={value}
			rows={3}
			onKeyDown={onKeyDown}
		/>
	</>
);

const CompletePhaseModal = ({
	setModalOpen,
	onClickSave,
	reportProblem,
	reportNextSteps,
	reportNotes,
	completed,
}) => {
	let modalRef = useRef(null);

	const [problem, setProblem] = useState(reportProblem || "");
	const [nextSteps, setNextSteps] = useState(reportNextSteps || "");
	const [notes, setNotes] = useState(reportNotes || "");

	useOutsideAlerter(modalRef, () => setModalOpen(false));

	const submitForm = (completed) => {
		onClickSave(problem, nextSteps, notes, completed);
		setModalOpen(false);
	};

	const onKeyDown = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(e, () => submitForm(true), ["cmd"]);
		}
	};

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Enter":
					callWithModifiers(event, () => submitForm(true), ["cmd"]);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	return (
		<div
			ref={modalRef}
			className="relative flex flex-col justify-start items-start p-5 w-96 rounded-lg shadow-xl border border-gray-100 bg-white z-50"
			style={{ width: "600px" }}
		>
			<div
				className="absolute top-0 right-0 p-5"
				onClick={() => setModalOpen(false)}
			>
				<IoClose
					className="text-gray-400 hover:text-gray-500 cursor-pointer"
					size={20}
				/>
			</div>

			<p className="mb-1 font-semibold text-base text-gray-600 text-center">
				Phase Rundown
			</p>

			<TextArea
				title="Problem"
				value={problem}
				setValue={setProblem}
				placeholder="What's the problem...?"
				onKeyDown={onKeyDown}
			/>

			<TextArea
				title="Next Steps"
				value={nextSteps}
				setValue={setNextSteps}
				placeholder="What are the next steps...?"
				onKeyDown={onKeyDown}
			/>

			<TextArea
				title="Notes"
				value={notes}
				setValue={setNotes}
				placeholder="Additional notes..."
				onKeyDown={onKeyDown}
			/>

			{!completed && (
				<span className="flex flex-row mt-5">
					<button
						className="flex justify-center items-center px-3 h-8 rounded bg-purple-100 text-indigo-500 font-medium text-sm mr-4"
						onClick={() => submitForm(false)}
					>
						Save
					</button>
					<button
						className="flex justify-center items-center px-3 h-8 rounded bg-indigo-600 text-white font-medium text-sm"
						onClick={() => submitForm(true)}
					>
						Complete Phase
					</button>
				</span>
			)}
		</div>
	);
};

export default CompletePhaseModal;

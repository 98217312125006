import useSWR from "swr";
import axios from "axios";

import bounceIfTokenError from "../bounceIfTokenError";
import { handleCaughtError } from "../../utils";

const getChatData = () => {
	const userId = localStorage.getItem("user_id");
	const userName = localStorage.getItem("full_name");

	const fetcher = async (url) => {
		try {
			let res = await axios.post(
				url,
				{
					email: userId,
					name: userName,
				},
				{
					headers: {
						"Content-Type": "application/json",
						authorization: `Bearer ${localStorage.getItem(
							"access_token"
						)}`,
					},
				}
			);
			return res.data;
		} catch (err) {
			handleCaughtError(err);
			bounceIfTokenError(err);
		}
	};

	const { data, error } = useSWR(userId ? "/api/chat/auth" : null, fetcher);

	return {
		data: data,
		loading: !error && !data,
		error: error,
	};
};

export default getChatData;

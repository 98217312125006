import { useEffect, useState, useRef } from "react";

const SubmitExternalComment = ({
	bodyText,
	sendTo,
	deleteFromSend,
	currTicketUsers,
	setSelected,
	updateScroll,
	bgColor,
	submitComment,
}) => {
	return (
		// Submit comment div. Because this is a PUBLIC reply and NOT an interal comment, the reply field will always be set to True.
		<div
			className={
				"w-full focus:animation-pulse focus:outline-none bg-" +
				bgColor +
				" rounded-lg"
			}
		>
			<div className="w-full text-left pb-2 pl-7 ">
				<button
					onClick={() => {
						// You MUST specify who you are removing from send.
						//CurrTicketUsers is used because we must track who was added and removed against the ORIGINAL comment list.
						submitComment(
							bodyText,
							true,
							sendTo,
							deleteFromSend,
							currTicketUsers
						);
						setSelected(null);
						updateScroll();
						// Update Scroll shifts the document down to the bottom
						// TOCONSIDER: Change so multiple responses can be open at once?
					}}
					className="font-medium bg-indigo-100 px-4 py-2  hover:bg-indigo-200  hover:bg-opacity-90  text-white rounded-md duration-200 font-sans-Roboto  font-sans text-indigo-600 text-opacity-100 focus:outline-none"
				>
					Submit
				</button>
			</div>
		</div>
	);
};
export default SubmitExternalComment;

const ModalContainer = ({ top = "35%", children }) => (
	<div
		className="z-50"
		style={{
			position: "absolute",
			left: "50%",
			top,
			transform: "translate(-50%, -50px)",
		}}
	>
		{children}
	</div>
);

export default ModalContainer;

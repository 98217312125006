import { ReactComponent as S3Logo } from "../../assets/connector_logos/connector_amazon-s3-symbol.svg";
import { ReactComponent as AmplitudeLogo } from "../../assets/connector_logos/connector_amplitude-symbol.svg";
import { ReactComponent as MixpanelLogo } from "../../assets/connector_logos/connector_mixpanel-symbol.svg";
import { ReactComponent as HeapLogo } from "../../assets/connector_logos/connector_heap-symbol.svg";
import { ReactComponent as GoogleAnalyticsLogo } from "../../assets/connector_logos/connector_google-analytics-symbol.svg";
import { ReactComponent as SplunkLogo } from "../../assets/connector_logos/connector_splunk-symbol.svg";
import { ReactComponent as SegmentLogo } from "../../assets/connector_logos/connector_segment-symbol.svg";
import { ReactComponent as ZendeskLogo } from "../../assets/connector_logos/connector_zendesk-symbol.svg";
import { ReactComponent as ZendeskChatLogo } from "../../assets/connector_logos/connector_zendesk-chat-symbol.svg";
import { ReactComponent as KustomerLogo } from "../../assets/connector_logos/connector_kustomer-symbol.svg";
import { ReactComponent as IntercomLogo } from "../../assets/connector_logos/connector_intercom-symbol.svg";
import { ReactComponent as FreshdeskLogo } from "../../assets/connector_logos/connector_freshdesk-symbol.svg";
import { ReactComponent as JiraLogo } from "../../assets/connector_logos/connector_jira-symbol.svg";
import { ReactComponent as AsanaLogo } from "../../assets/connector_logos/connector_asana-symbol.svg";
import { ReactComponent as SalesforceLogo } from "../../assets/connector_logos/connector_salesforce-symbol.svg";
import { ReactComponent as QuickbooksLogo } from "../../assets/connector_logos/connector_quickbooks-online-symbol.svg";
import { ReactComponent as NetSuiteLogo } from "../../assets/connector_logos/connector_netsuite-suiteanalytics-symbol.svg";
import { ReactComponent as StripeLogo } from "../../assets/connector_logos/connector_stripe-symbol.svg";
import { ReactComponent as XeroLogo } from "../../assets/connector_logos/connector_xero-symbol.svg";
import { ReactComponent as GCSLogo } from "../../assets/connector_logos/connector_google-cloud-storage-symbol.svg";

const IntegrationLogos = {
	s3: <S3Logo></S3Logo>,
	amplitude: <AmplitudeLogo></AmplitudeLogo>,
	mixpanel: <MixpanelLogo></MixpanelLogo>,
	heap: <HeapLogo></HeapLogo>,
	google_analytics: <GoogleAnalyticsLogo></GoogleAnalyticsLogo>,
	splunk: <SplunkLogo></SplunkLogo>,
	segment: <SegmentLogo></SegmentLogo>,
	zendesk: <ZendeskLogo></ZendeskLogo>,
	zendesk_chat: <ZendeskChatLogo></ZendeskChatLogo>,
	kustomer: <KustomerLogo></KustomerLogo>,
	intercom: <IntercomLogo></IntercomLogo>,
	freshdesk: <FreshdeskLogo></FreshdeskLogo>,
	jira: <JiraLogo></JiraLogo>,
	asana: <AsanaLogo></AsanaLogo>,
	salesforce: <SalesforceLogo></SalesforceLogo>,
	quickbooks: <QuickbooksLogo></QuickbooksLogo>,
	netsuite_suiteanalytics: <NetSuiteLogo></NetSuiteLogo>,
	stripe: <StripeLogo></StripeLogo>,
	xero: <XeroLogo></XeroLogo>,
	gcs: <GCSLogo></GCSLogo>,
};

export default IntegrationLogos;

import React from "react";

const ViewBox = (props) => {
	if (props.data == false) {
		return (
			<div>
				<p>Mouse over each item to view</p>
			</div>
		);
	}
	if (props.variant == "timeline") {
		return (
			<div className="mx-4 my-5">
				<p className="text-lg font-medium pb-3 cursor-default">
					{props.data.name}:
				</p>
				{props.data.body.map((item) => (
					<p className="pb-1 font-light cursor-default">{item}</p>
				))}
			</div>
		);
	} else {
		return (
			<div className="mx-4 my-5">
				<p className="text-lg font-medium pb-3 cursor-default">
					{props.data.name}:
				</p>
				<p className="pb-1 font-light cursor-default">
					{props.data.description}
				</p>
			</div>
		);
	}
};

export default ViewBox;

import React, { useState, useEffect } from "react";
import PeopleIcon from "./PeopleIcon";
import Grade from "../../Grade";
import { IoMdClose } from "react-icons/io";
import SidebarCheckboxMenu from "../../Sidebar/SidebarCheckboxMenu.js";
import { IoIosAdd } from "react-icons/io";
import { DraftModal } from "../..";
import SubGrid from "../../SharedComponents/SubGrid/SubGrid";
import Tags from "../../SharedComponents/Tags";
import useSWR, { mutate } from "swr";
import { useHotkeys } from "react-hotkeys-hook";
import ModalContainer from "../../Modals/ModalContainer";
import { handleCaughtError } from "../../../utils";

function People(props) {
	const [viewMore, setViewMore] = useState(false);
	const [tagDropDownOpen, setTagDropDownOpen] = useState(false);
	const [draftModalOpen, setDraftModalOpen] = useState(false);
	const [selectedMoment, setSelectedMoment] = useState(null);
	const [executiveSponsor, setExecutiveSponsor] = useState([]);
	const [champion, setChampion] = useState([]);
	const [powerUser, setPowerUser] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [users, setUsers] = useState(props.userData);
	const [tags, setTags] = useState(props.tags);
	const [draft, setDraft] = useState(null);

	useEffect(() => {
		if (props.userData) {
			setUsers(props.userData);
		}
	}, [props.userData]);

	useEffect(() => {
		if (users) {
			const newExec = users.filter(
				(user) => user.role == "executive_sponsor"
			);
			const newChamp = users.filter((user) => user.role == "champion");
			const newPowerUser = users.filter(
				(user) => user.role == "power_user"
			);
			setExecutiveSponsor(newExec);
			setChampion(newChamp);
			setPowerUser(newPowerUser);
		}
	}, [users]);

	useEffect(() => {
		setTags(props.tags);
	}, [props.tags]);

	let action = {
		action: false,
	};
	let moments = [
		{
			name: "Okta Integration Follow Up",
			type: "Zoom",
			date: "10/10/10",
			description:
				" Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci veli ",
		},
		{
			name: "Re: Okta Integration Not Working",
			type: "Email",
			date: "10/10/10",
			description:
				" Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci veli ",
		},
		{
			name: "Upsell Campaing #2",
			type: "Marketo",
			date: "10/10/10",
			description:
				" Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci veli ",
		},
		{
			name: "Okta Integration Not Working",
			type: "Ticket",
			date: "10/10/10",
			description:
				" Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci veli ",
		},
	];

	const requestUpdate = async (endpoint, body) => {
		const res = await fetch(endpoint, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			body: JSON.stringify(body),
		});
		if (!res.ok) {
			const error = new Error(
				`An error occurred during POST to ${endpoint}.`
			);
			error.info = res;
			error.status = res.status;
			throw error;
		}
		return res.json();
	};
	useHotkeys("*", (event) => {
		switch (event.key) {
			case "t":
				setTagDropDownOpen(true);

				break;

			default:
				break;
		}
	});

	const newTag = (color, value) => {
		console.log(tags, "adjbajdb");
		if (tags) {
			if (!tags.some((tag) => tag.value === value)) {
				let nt = { value: value, color: color };
				let tmp = JSON.parse(JSON.stringify(tags));
				let t = { tags: tmp };
				tmp.push(nt);
				setTags(tmp);
				try {
					mutate("/api/getTags", tmp, false);

					mutate(
						"/api/getTags",
						requestUpdate("/api/updateTenantTags", {
							tags: t,
							id: localStorage.getItem("user_id"),
						})
					);
					mutateTags(nt);
				} catch (err) {
					handleCaughtError(err);
				}
			}
		}
	};

	// close menu on create new tag, on select dont.
	// create tag endpoint is being weird.
	// x on tag always

	const hasTag = (user, tag) => {
		// console.log(user.tags.tags, tag);
		if (user.tags?.tags) {
			for (let i = 0; i < user.tags.tags.length; i++) {
				if (user.tags.tags[i].value == tag.value) {
					console.log(i, "in func");
					return i;
				}
			}
		}
		return -1;
	};

	const mutateTags = async (newTag) => {
		let newUsers = JSON.parse(JSON.stringify(users));
		console.log(newTag);
		if (newTag.selected != null) {
			delete newTag.selected;
		}

		if (!newUsers[selectedUser].tags) {
			newUsers[selectedUser].tags = { tags: [] };
		}
		if (hasTag(users[selectedUser], newTag) > -1) {
			newUsers[selectedUser].tags.tags.splice(
				hasTag(users[selectedUser], newTag),
				1
			);
			console.log("remove");
		} else {
			console.log("adding");
			newUsers[selectedUser].tags.tags.push(newTag);
		}
		console.log(newUsers[selectedUser].tags.tags);
		setUsers(newUsers);
		try {
			mutate("/api/getCompanyUsers", newUsers, false);

			mutate(
				"/api/getCompanyUsers",
				requestUpdate("/api/updateUserTags", {
					tags: newUsers[selectedUser].tags,
					id: newUsers[selectedUser].id,
				})
			);
		} catch (err) {
			handleCaughtError(err);
		}
	};
	console.log(props.userData);
	if (users.length == 0) {
		return <div>No Users Found</div>;
	} else if (viewMore) {
		return (
			<div className="h-full w-full flex flex-col ">
				<div className="ml-10 flex h-1/5   w-full ">
					<button
						className="ml-9 w-5 h-5 mt-3 "
						onClick={() => {
							setViewMore(false);
						}}
					>
						<IoMdClose
							className="text-gray-400  font-light justify-self-end "
							size={25}
						></IoMdClose>
					</button>

					<div className="   flex flex-col  w-1/2 h-5/6">
						<div className="w-9/10 mr-2.5  ml-1 h-full mt-4 flex flex-row  place-self-start border">
							<div className="ml-6 h-1/2 align-self-center place-content-center place-self-center w-1/6  ">
								<PeopleIcon
									data={users[selectedUser]}
									variant="right"
								></PeopleIcon>
							</div>
							<div className="w-1/2 h-full flex gap-y-2.5  place-content-center flex-col ">
								<p className=" ">sknfksnfkjs</p>
								<p className="font-light">sklnfewj</p>
							</div>
							<div className="flex flex-col  right-0 h-full w-1/3 justify-evenly items-end ">
								<button
									className="font-extralight w-full  hover:rounded hover:bg-blue-50 hover:bg-opacity-75 mr-4 text-sm"
									onClick={() => {
										let tmpDraft = JSON.parse(
											JSON.stringify(
												props.gptEmails.catchUp[
													Math.floor(
														Math.random() *
															(props.gptEmails
																?.catchUp
																.length -
																1)
													)
												]
											)
										);
										tmpDraft.recipient =
											users[selectedUser]?.email;
										setDraft(tmpDraft);

										setDraftModalOpen(true);
									}}
								>
									<p className="mr-2 text-right">Check In</p>
								</button>
								<button
									className="font-extralight w-full  hover:rounded hover:bg-blue-50 hover:bg-opacity-75 rounded-md mr-4 text-sm"
									onClick={() => {
										let tmpDraft = JSON.parse(
											JSON.stringify(
												props.gptEmails.congratulate[
													Math.floor(
														Math.random() *
															(props.gptEmails
																?.congratulate
																.length -
																1)
													)
												]
											)
										);
										tmpDraft.recipient =
											users[selectedUser]?.email;

										setDraft(tmpDraft);

										setDraftModalOpen(true);
									}}
								>
									<p className="mr-2 text-right">
										Congratulate
									</p>
								</button>
								<button
									className="font-extralight w-full hover:rounded hover:bg-blue-50 hover:bg-opacity-75 mr-4 text-sm"
									onClick={() => {
										let tmpDraft = JSON.parse(
											JSON.stringify(
												props.gptEmails?.problem[
													Math.floor(
														Math.random() *
															(props.gptEmails
																?.problem
																.length -
																1)
													)
												]
											)
										);
										tmpDraft.recipient =
											users[selectedUser]?.email;
										setDraft(tmpDraft);

										setDraftModalOpen(true);
									}}
								>
									<p className="mr-2 text-right">
										Address a Problem
									</p>
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="ml-20">
					<SubGrid data={moments} selected={selectedMoment}></SubGrid>
				</div>

				<ModalContainer top="50%">
					{draftModalOpen && (
						<DraftModal
							actionBody={draft}
							setModalOpen={setDraftModalOpen}
							variant="cta"
							onClickSave={(recipient, subject, body) =>
								onSaveEmail({
									action: true,
									body,
									recipient,
									subject,
								})
							}
						/>
					)}
				</ModalContainer>
			</div>
		);
	} else {
		return (
			<div className="flex flex-rows relative justify-center justify-items-center gap-x-8 h-8/10  ">
				<div className="bg-lightgray rounded grid h-9/10 mb-20  w-120">
					<div className="h-full   flex justify-start  gap-y-8 pb-40 flex-col mt-6  ">
						{/* Sponsor div */}
						<div className="   ">
							<p className="px-4">Executive Sponsor</p>
							<div className=" flex justify-start ml-4 w-5/6 items-center my-1.5 h-full flex-row ">
								{executiveSponsor.length > 0 && (
									<PeopleIcon
										click={setSelectedUser}
										data={executiveSponsor[0]}
										index={users.indexOf(
											executiveSponsor[0]
										)}
										assign={props.assign}
									></PeopleIcon>
								)}
								{executiveSponsor.length < 1 && (
									<div
										onClick={() => {
											props.setCommandKOpen(true);
											props.setAssignType(
												"executive_sponsor"
											);
										}}
									>
										<PeopleIcon></PeopleIcon>
									</div>
								)}
							</div>
						</div>
						{/* Champion div */}
						<div className="row-span-1 ">
							<p className="px-4 ">Champions</p>
							<div className=" flex gap-x-auto ml-4 w-5/6 gap-16 items-center my-1.5 h-full  flex-row ">
								{champion.map((c) => (
									<div>
										<PeopleIcon
											data={c}
											click={setSelectedUser}
											index={users.indexOf(c)}
											assign={props.assign}
										></PeopleIcon>
									</div>
								))}
								{champion.length < 2 && (
									<div
										onClick={() => {
											props.setCommandKOpen(true);
											props.setAssignType("champion");
										}}
									>
										<PeopleIcon></PeopleIcon>
									</div>
								)}
							</div>
						</div>
						{/* User div */}
						<div className="row-span-1  ">
							<p className="px-4">Power Users</p>
							<div className=" flex gap-x-auto ml-4 w-5/6 gap-16 flex-grow items-center my-1.5 h-full flex-row ">
								{powerUser.map((c) => (
									<div>
										<PeopleIcon
											data={c}
											click={setSelectedUser}
											index={users.indexOf(c)}
											assign={props.assign}
										></PeopleIcon>
									</div>
								))}
								{powerUser.length < 3 && (
									<div
										onClick={() => {
											props.setCommandKOpen(true);
											props.setAssignType("power_user");
											// PRINTING SHIT IN COMMAND FUNC
										}}
									>
										<PeopleIcon></PeopleIcon>
									</div>
								)}

								{/* <PeopleIcon
									name="Kaari "
									grade={7}
								></PeopleIcon>
								<PeopleIcon name="Calvin "></PeopleIcon>
								<PeopleIcon></PeopleIcon> */}
							</div>
						</div>
					</div>
				</div>
				<div className="bg-lightgray rounded h-9/10  mr-6  flex flex-col items-center  w-120">
					{/* Header */}
					{users[selectedUser] && (
						<div className=" h-full   flex flex-col items-center w-full">
							<div className=" flex  flex-row  w-9/10 relative mt-6 h-1/4">
								<div className="   col-span-2 justify-self-center  ">
									<PeopleIcon
										data={users[selectedUser]}
										variant="top"
									></PeopleIcon>
								</div>
								<div className=" w-full h-full items-center justify-items-start  ml-16 grid grid-rows-3   absolute    ">
									<p>
										{users[selectedUser].first_name}{" "}
										{users[selectedUser].last_name}
									</p>
									<p className="font-light text-base">
										{users[selectedUser].job_title}
									</p>
									<p className="font-light">
										Last Contact: 12d ago by Jared
									</p>
								</div>
							</div>
							<div className=" w-2/3 flex-row flex mb-4 gap-y-1 flex-wrap ml-7 mt-0.5 h-16">
								{users[selectedUser].tags &&
									users[selectedUser].tags.tags.map((tag) => (
										<div className="  h-5 flex w-22 mr-1  rounded max-w-1/2 border-gray-100  items-center gap-x-0 text-center   border">
											<div
												className={` justify-center ml-0.5 my-auto justify-self-center ${
													tag.color
														? tag.color
														: "bg-red-200"
												}  h-1.5 w-1.5   rounded-full flex-none`}
											></div>
											<p className="font-light  text-smaller text-center truncate ml-1 mr-1">
												{" "}
												{tag.value}
											</p>
											<button
												onClick={() => {
													mutateTags(tag);
												}}
												className="  ml-0.5 mr-1"
											>
												<IoMdClose className="text-gray-500  text-small-as-fuck"></IoMdClose>
											</button>
										</div>
									))}
								<div className="w-4 align-self-center mt-0.5 h-4 flex justify-center items-center  ">
									<Tags
										title="asds"
										icon={null}
										options={tags}
										users={users}
										selectedUser={selectedUser}
										status=""
										open={tagDropDownOpen}
										setOpen={setTagDropDownOpen}
										onSelect={mutateTags}
										keyCommand="F"
										selected={users[selectedUser]}
										hasTag={hasTag}
										newTag={newTag}
									/>
								</div>
							</div>
							{/* Grades */}
							<div className="mt-2 h-1/4 w-9/10 flex   flex-cols justify-self-center justify-between  ">
								{users[selectedUser].score &&
									Object.entries(
										users[selectedUser].score
									).map((item) => (
										<div className="flex flex-col place-items-center flex-none w-1/4">
											<Grade
												size={41}
												grade={item[1]}
											></Grade>
											<p className="justify-self-center whitespace-nowrap font-light text-sm">
												{item[0]}
											</p>
										</div>
									))}
							</div>
							{/* Summary + Moments */}
							<div className=" grid grid-rows-2 w-9/10 mt-8 justify-self-center ">
								<div className="h-full w-full row-span-2 mr-2 font-light">
									{users[selectedUser].description}
								</div>
							</div>
							<div className="h-full  w-9/10 justify-self-center items-start flex flex-col mt-8 row-span-1">
								<p className="mb-3">Moments:</p>
								{moments.map((moment) => (
									<div
										onClick={() => {
											setViewMore(true);
											setSelectedMoment(moment);
										}}
										className="cursor-pointer flex flex-start justify-self-center  gap-x-3 hover:rounded hover:bg-blue-100 hover:bg-opacity-75  flex-rows"
									>
										<p className="font-light">
											{moment.name}
										</p>
										<div
											className={`flex justify-center place-self-center items-center  h-1 w-1 bg-gray-300 rounded-full flex-none`}
										></div>
										<p className="text-gray-400 font-light">
											{moment.type}
										</p>
										<div
											className={`flex justify-center place-self-center items-center  h-1 w-1 bg-gray-300 rounded-full flex-none`}
										></div>
										<p className="font-light text-gray-400">
											{moment.date}
										</p>
									</div>
								))}
								<button
									className="mt-4 font-light text-sm underline"
									onClick={() => {
										setViewMore(true);
									}}
								>
									View More
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
export default People;

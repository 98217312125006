import { useEffect, useState } from "react";
import { BiChevronRight, BiChevronDown, BiPlus } from "react-icons/bi";

import { ModalContainer, CompletePhaseModal, HollowButton } from "../..";
import AddTaskPopup from "./AddTaskPopup";
import TodoItem from "./TodoItem";

const TodoPhase = ({
	phaseNumber,
	currentPhase,
	title,
	items,
	completed,
	userOptions,
	buttonVisible,
	onUpdateTodo,
	onCreateTodo,
	onUpdatePhase,
	phaseReport,
	companyUserData,
	userSnippets,
}) => {
	const [expanded, setExpanded] = useState(currentPhase === phaseNumber);
	const [creatingTodo, setCreatingTodo] = useState(false);
	const [completeModalOpen, setCompleteModalOpen] = useState(false);

	useEffect(() => {
		setExpanded(currentPhase === phaseNumber);
	}, [currentPhase, phaseNumber]);

	return (
		<div className="flex flex-col items-start w-full light-bg-2 rounded p-4 flex-none">
			<span className="flex flex-row items-center mb-1 h-6">
				<button
					className="flex flex-row items-center cursor-pointer mr-3"
					onClick={() => setExpanded((exp) => !exp)}
				>
					{expanded ? (
						<BiChevronDown size={22} />
					) : (
						<BiChevronRight size={22} />
					)}
					<p
						className={`text-sm ml-1 truncate ${
							completed ? "text-gray-400" : "text-black"
						}`}
					>
						{title}
					</p>
				</button>
				{buttonVisible && (
					<HollowButton
						label={
							completed ? "View Phase Report" : "Complete Phase"
						}
						colorClass={
							completed ? "text-green-700" : "text-indigo-500"
						}
						onClick={() => setCompleteModalOpen(true)}
					/>
				)}
			</span>
			{expanded && (
				<>
					{items?.map((item, index) => (
						<TodoItem
							key={item.id}
							todoId={item.id}
							status={item.status}
							title={item.name}
							action={item.take_action}
							description={item.body.description}
							date={item.due_date}
							userSnippets={userSnippets}
							assignee={userOptions.find(
								(user) => user.email === item.assigned_to
							)}
							userOptions={userOptions}
							onUpdateTodo={onUpdateTodo}
							isCurrentTodo={
								index ===
								items.findIndex(
									(todo) => todo.status !== "Done"
								)
							}
							companyUserData={companyUserData}
						/>
					))}
					<div className="pl-5 ml-0.5 mt-1">
						<div
							className="flex flew-row items-center text-gray-400 hover:text-gray-500 cursor-pointer"
							onClick={() => setCreatingTodo(true)}
						>
							<BiPlus size={20} />
							<p className="text-sm ml-2 font-normal">Add Task</p>
						</div>
						{creatingTodo && (
							<div className="relative -top-1 z-50">
								<AddTaskPopup
									userOptions={userOptions}
									setOpen={setCreatingTodo}
									onClickSave={onCreateTodo}
								/>
							</div>
						)}
					</div>
				</>
			)}
			<ModalContainer top="25%">
				{completeModalOpen && (
					<CompletePhaseModal
						onClickSave={onUpdatePhase}
						setModalOpen={setCompleteModalOpen}
						reportProblem={phaseReport.problem}
						reportNextSteps={phaseReport.next_steps}
						reportNotes={phaseReport.notes}
						completed={completed}
					/>
				)}
			</ModalContainer>
		</div>
	);
};

export default TodoPhase;

import { CgNotes } from "react-icons/cg";

export default (createNewNote) => {
	if (createNewNote) {
		return [
			{
				icon: <CgNotes className="text-gray-600" size={18} />,
				title: "Create note...",
				keyCommand: "N",
				command() {
					createNewNote();
				},
			},
		];
	}

	return [];
};

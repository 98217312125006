import React, { useEffect, useState } from "react";
import { FiClock } from "react-icons/fi";

import { icons } from "../../constants";
import { ReadIndicator, TimeStamp } from ".";
import { Grade } from "..";

const GridRow = ({
	rowId,
	active,
	grade,
	status,
	assignee,
	columnTwoTitle,
	columnThreeTitle,
	columnFourTitle,
	timestamp,
	variant,
	unread = false,
	onHover,
	onLeaveHover,
	onClick,
	email,
	setCommandKOpen,
	setSnoozeOpen,
	setAsigneesOpen,
	cursorEnabled,
}) => {
	const [unreadLocal, setUnreadLocal] = useState(unread);
	const [date, setDate] = useState("");

	useEffect(() => {
		if (timestamp) {
			let newDate = new Date(timestamp);
			let month = newDate.toLocaleString("default", { month: "short" });
			let day = newDate.getDate();
			setDate(`${month} ${day}`);
		}
	}, [timestamp]);

	useEffect(() => {
		setUnreadLocal(unread);
	}, [unread]);

	return (
		<div
			id={rowId}
			onMouseEnter={cursorEnabled ? onHover : null}
			onMouseLeave={onLeaveHover}
			className={`flex flex-row cursor-pointer h-11 border-b border-gray-50
					 ${active ? "bg-gray-100" : null}`}
		>
			{/* Column 1 - Read Indicator + Status */}
			<div
				onClick={onClick}
				className="flex flex-row flex-none justify-center items-center h-full w-20 min-w-20"
			>
				<div className="flex justify-center items-center w-1/2 h-full">
					{(variant === "actionItems" || variant === "allCtas") && (
						<ReadIndicator
							unread={unreadLocal ?? []}
							email={email}
						/>
					)}
				</div>
				{grade && (
					<Grade
						grade={grade}
						size={16}
						style={{ fontSize: "10px" }}
					/>
				)}
			</div>

			<div className="grid grid-cols-12 flex-grow w-full h-full max-h-full">
				{/* Column 2 */}
				<div
					onClick={onClick}
					className="col-start-1 col-end-4 flex flex-row justify-start items-center h-full truncate"
				>
					<p className="text-sm font-medium text-gray-900 truncate">
						{columnTwoTitle}
					</p>
				</div>

				{/* Column 3 */}
				<div
					onClick={onClick}
					className="col-start-4 col-end-9 flex flex-row justify-start items-center"
				>
					<div className="flex justify-center items-center h-full w-5">
						{status && icons[status]}
					</div>
					<p
						className={`text-sm pl-2 truncate ${
							variant === "snippets"
								? "text-secondaryText"
								: "text-gray-900"
						}`}
					>
						{columnThreeTitle}
					</p>
				</div>

				{/* Column 4 */}
				<div
					onClick={onClick}
					className="col-start-9 col-end-11 flex flex-row justify-start items-center"
				>
					{columnFourTitle && (
						<p className="italic text-sm text-gray-500 truncate">
							{columnFourTitle}
						</p>
					)}
				</div>

				{/* Column 5 */}
				<div className="col-start-11 col-end-13 flex flex-row justify-end items-center text-sm text-gray-500 pr-3">
					{/* Command K Button */}
					{active && setCommandKOpen && (
						<div
							className="flex justify-center items-center flex-none h-7 w-7 rounded-full hover:bg-gray-200"
							onClick={() => setCommandKOpen(true)}
						>
							<p className="text-xs mt-0.5">⌘K</p>
						</div>
					)}
					{/* Snooze Button */}
					{active && setSnoozeOpen && (
						<div
							className="flex justify-center items-center flex-none h-7 w-7 rounded-full hover:bg-gray-200"
							onClick={() => setSnoozeOpen(true)}
						>
							<FiClock size={16} />
						</div>
					)}

					{/* Timestamp */}
					{!active && <TimeStamp date={date} />}

					{/* Assignee Icon */}
					{assignee && (
						<div
							className={`flex justify-center items-center flex-none h-7 w-7 rounded-full ${
								setAsigneesOpen && "hover:bg-gray-200"
							}`}
							onClick={
								setAsigneesOpen
									? () => setAsigneesOpen(true)
									: () => null
							}
						>
							{assignee}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default GridRow;

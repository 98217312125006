import { BiMinus } from "react-icons/bi";

export default (setListOfSplitsPagesOpen) => {
	return [
		{
			icon: <BiMinus className="text-gray-600" size={18} />,
			title: "Remove A Split",
			type: "Customization",
			keyCommand: null,
			command() {
				setListOfSplitsPagesOpen(true);
			},
		},
	];
};

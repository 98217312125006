import React from "react";

import { ReactComponent as Blocked } from "../../assets/Blocked.svg";

const BlockedIcon = () => (
	<div className="flex justify-center items-center flex-none relative h-4 w-4">
		<Blocked />
	</div>
);

export default BlockedIcon;

const STAGES_CONFIG = [
	{
		Id: "01J3t00000Om7MCEAZ",
		MasterLabel: "Prospecting",
		ApiName: "Prospecting",
		IsActive: true,
		SortOrder: 1,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 10,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MDEAZ",
		MasterLabel: "Qualification",
		ApiName: "Qualification",
		IsActive: true,
		SortOrder: 2,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 10,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MJEAZ",
		MasterLabel: "Needs Analysis",
		ApiName: "Needs Analysis",
		IsActive: true,
		SortOrder: 3,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 20,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MFEAZ",
		MasterLabel: "Value Proposition",
		ApiName: "Value Proposition",
		IsActive: true,
		SortOrder: 4,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 50,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MIEAZ",
		MasterLabel: "Id. Decision Makers",
		ApiName: "Id. Decision Makers",
		IsActive: true,
		SortOrder: 5,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 60,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MLEAZ",
		MasterLabel: "Perception Analysis",
		ApiName: "Perception Analysis",
		IsActive: true,
		SortOrder: 6,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 70,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MEEAZ",
		MasterLabel: "Proposal/Price Quote",
		ApiName: "Proposal/Price Quote",
		IsActive: true,
		SortOrder: 7,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 75,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MKEAZ",
		MasterLabel: "Negotiation/Review",
		ApiName: "Negotiation/Review",
		IsActive: true,
		SortOrder: 8,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 90,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MHEAZ",
		MasterLabel: "Closed Won",
		ApiName: "Closed Won",
		IsActive: true,
		SortOrder: 9,
		IsClosed: true,
		IsWon: true,
		ForecastCategory: "Closed",
		ForecastCategoryName: "Closed",
		DefaultProbability: 100,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		Id: "01J3t00000Om7MGEAZ",
		MasterLabel: "Closed Lost",
		ApiName: "Closed Lost",
		IsActive: true,
		SortOrder: 10,
		IsClosed: true,
		IsWon: false,
		ForecastCategory: "Omitted",
		ForecastCategoryName: "Omitted",
		DefaultProbability: 0,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
];

const RAW_DATA = [
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MCEAZ",
		},
		Id: "01J3t00000Om7MCEAZ",
		MasterLabel: "Prospecting",
		ApiName: "Prospecting",
		IsActive: true,
		SortOrder: 1,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 10,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MGEAZ",
		},
		Id: "01J3t00000Om7MGEAZ",
		MasterLabel: "Closed Lost",
		ApiName: "Closed Lost",
		IsActive: true,
		SortOrder: 10,
		IsClosed: true,
		IsWon: false,
		ForecastCategory: "Omitted",
		ForecastCategoryName: "Omitted",
		DefaultProbability: 0,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MDEAZ",
		},
		Id: "01J3t00000Om7MDEAZ",
		MasterLabel: "Qualification",
		ApiName: "Qualification",
		IsActive: true,
		SortOrder: 2,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 10,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MJEAZ",
		},
		Id: "01J3t00000Om7MJEAZ",
		MasterLabel: "Needs Analysis",
		ApiName: "Needs Analysis",
		IsActive: true,
		SortOrder: 3,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 20,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MFEAZ",
		},
		Id: "01J3t00000Om7MFEAZ",
		MasterLabel: "Value Proposition",
		ApiName: "Value Proposition",
		IsActive: true,
		SortOrder: 4,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 50,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MIEAZ",
		},
		Id: "01J3t00000Om7MIEAZ",
		MasterLabel: "Id. Decision Makers",
		ApiName: "Id. Decision Makers",
		IsActive: true,
		SortOrder: 5,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 60,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MLEAZ",
		},
		Id: "01J3t00000Om7MLEAZ",
		MasterLabel: "Perception Analysis",
		ApiName: "Perception Analysis",
		IsActive: true,
		SortOrder: 6,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 70,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MEEAZ",
		},
		Id: "01J3t00000Om7MEEAZ",
		MasterLabel: "Proposal/Price Quote",
		ApiName: "Proposal/Price Quote",
		IsActive: true,
		SortOrder: 7,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 75,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MKEAZ",
		},
		Id: "01J3t00000Om7MKEAZ",
		MasterLabel: "Negotiation/Review",
		ApiName: "Negotiation/Review",
		IsActive: true,
		SortOrder: 8,
		IsClosed: false,
		IsWon: false,
		ForecastCategory: "Pipeline",
		ForecastCategoryName: "Pipeline",
		DefaultProbability: 90,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
	{
		attributes: {
			type: "OpportunityStage",
			url:
				"/services/data/v52.0/sobjects/OpportunityStage/01J3t00000Om7MHEAZ",
		},
		Id: "01J3t00000Om7MHEAZ",
		MasterLabel: "Closed Won",
		ApiName: "Closed Won",
		IsActive: true,
		SortOrder: 9,
		IsClosed: true,
		IsWon: true,
		ForecastCategory: "Closed",
		ForecastCategoryName: "Closed",
		DefaultProbability: 100,
		Description: null,
		CreatedById: "0053t000006YFMhAAO",
		CreatedDate: "2020-08-02T22:30:19.000+0000",
		LastModifiedById: "0053t000006YFMhAAO",
		LastModifiedDate: "2020-08-02T22:30:19.000+0000",
		SystemModstamp: "2020-08-02T22:30:19.000+0000",
	},
];

export default STAGES_CONFIG;

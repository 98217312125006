import { priorities } from "../constants";

export default (priorityCommand) => {
	return priorities.map((priority) => ({
		title: priority.value,
		icon: priority.icon,
		command() {
			priorityCommand(this.title);
		},
	}));
};

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import {
	SidebarStatistic,
	SidebarInfoSection,
	ProblemsMenu,
	CheckBoxMenu,
} from ".";
import { icons, priorities, statuses, zendeskStatuses } from "../../constants";
import { SmallDropdown, UserIcon, Grade, Overview } from "..";
import TicketsMenu from "./TicketsMenu";

const sidebarWidth = "360px";

const SidebarZendesk = withRouter(
	({
		history,
		ctaData,
		zendeskData,
		variant = "zendesk",
		onClickProfileButton,
		otherCtaData,
		otherTicketData,
		companyId,
		assigned,
		followers,
		status,
		mutateStatus,
		mutatePriority,
		priority,
		priorityDropdownOpen,
		setPriorityDropdownOpen,
		statusDropdownOpen,
		setStatusDropdownOpen,
		assignedDropdownOpen,
		setAssignedDropdownOpen,
		followerDropdownOpen,
		setFollowerDropdownOpen,
	}) => {
		const [name, setName] = useState("");
		const [bgColor, setBgColor] = useState("bg-white");
		const [userId, setUserId] = useState("");
		const [allTicketUsers, setAllTicketUsers] = useState([]);
		const [usage, setUsage] = useState("");
		const [DUR, setDUR] = useState("");
		// maybe return the user and set the icon and stuff? gonna have to make some calls for this
		// Effects

		useEffect(() => {
			if (zendeskData) {
				// lets get name first
				let ticketUsers = zendeskData?.ticket_users?.data.output.users;
				setAllTicketUsers(ticketUsers);
				let submitterId = zendeskData?.ticket_data?.submitter_id;
				let submitter = ticketUsers.filter((user) => {
					if (user.id === submitterId) {
						return user.name;
					}
				});
				if (submitter.length > 0) {
					setName(submitter[0].name);
				}
				setUserIcon(ticketUsers, submitterId);
			}
			if (ctaData) {
				if (ctaData.dau && ctaData.purchased_seats) {
					setUsage(
						`${Math.round(
							(ctaData.dau / ctaData.purchased_seats) * 100
						)}`
					);
				} else {
					setUsage("");
				}
			}
		}, [ctaData, zendeskData]);

		const setUserIcon = (ticketUsers, submitterId) => {
			for (let i in ticketUsers) {
				if (
					ticketUsers[i].id === submitterId ||
					ticketUsers[i].email === submitterId
				) {
					let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
					let initials = [...ticketUsers[i].name.matchAll(rgx)] || [];

					initials = (
						(initials.shift()?.[1] || "") +
						(initials.pop()?.[1] || "")
					).toUpperCase();

					var c = initials.charCodeAt(0) * (8 * initials.length);

					var color;
					switch (true) {
						case c < 600:
							color = "bg-green-400";
							break;
						case c < 700:
							color = "bg-blue-400";
							break;
						case c < 800:
							color = "bg-yellow-500";
							break;
						case c < 900:
							color = "bg-black";
							break;
						case c < 1000:
							color = "bg-indigo-800";
							break;
						case c < 1200:
							color = "bg-red-600";
							break;
						case c < 1500:
							color = "bg-red-200";
							break;
						default:
							color = "bg-green-400";
					}
					setBgColor(color);

					setUserId(initials);
				}
			}
		};
		const miniIcons = (ticketUsers, submitterId) => {
			for (let i in ticketUsers) {
				if (
					ticketUsers[i].id === submitterId ||
					ticketUsers[i].email === submitterId
				) {
					let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
					let initials = [...ticketUsers[i].name.matchAll(rgx)] || [];

					initials = (
						(initials.shift()?.[1] || "") +
						(initials.pop()?.[1] || "")
					).toUpperCase();

					var c = initials.charCodeAt(0) * (8 * initials.length);

					var color;
					switch (true) {
						case c < 600:
							color = "bg-green-400";
							break;
						case c < 700:
							color = "bg-blue-400";
							break;
						case c < 800:
							color = "bg-yellow-500";
							break;
						case c < 900:
							color = "bg-black";
							break;
						case c < 1000:
							color = "bg-indigo-800";
							break;
						case c < 1200:
							color = "bg-red-600";
							break;
						case c < 1500:
							color = "bg-red-200";
							break;
						default:
							color = "bg-green-400";
					}
					return (
						<div className={color + " w-4 rounded-full h-4 flex"}>
							<span className=" my-auto mx-auto text-small-as-fuck text-white">
								{initials}
							</span>
						</div>
					);
				}
			}
		};

		// Mutations

		// Sidebar Body

		if (!ctaData) {
			return (
				<div
					style={{ width: sidebarWidth }}
					className="h-full bg-lightgray p-6 flex-none"
				/>
			);
		}

		return (
			<div
				style={{ width: sidebarWidth }}
				className="h-full bg-lightgray py-8 px-9 flex-none shadow-inner "
			>
				<div
					id="sidebar-header-section"
					className="flex flex-col w-full pb-10 pt-6"
				>
					<div className="flex relative flex-row items-center h-8 w-full">
						<span id="title-section" className="grid grid-cols-20">
							<span className="col-start-1 col-end-3">
								{/* need to make this user icon */}
								<div
									className={
										bgColor + " w-14 rounded-full h-14 flex"
									}
								>
									<span className=" my-auto mx-auto text-lg text-white">
										{userId}
									</span>
								</div>
							</span>
							<span className="flex flex-col col-start-3 col-end-12">
								<p
									className={`ml-2 ${
										name?.length > 20
											? "h5"
											: name?.length > 10
											? "h4"
											: "h3"
									} w-48 truncate pb-1`}
								>
									{name}
								</p>
								<span className="flex flex-row items-center pl-2 pb-1">
									<span>
										{ctaData?.company_clearbit_info
											?.logo && (
											<img
												src={`${ctaData?.company_clearbit_info?.logo}`}
												className="object-contain rounded left-0 h-4 w-4"
											></img>
										)}
									</span>
									<span className="pl-1 text-sm">
										{ctaData?.company_name}
									</span>
								</span>
								<span className="flex flex-row items-center pl-2">
									<span>
										{ctaData?.grade && (
											<Grade
												grade={ctaData?.grade}
												size={18}
												text="xs"
											/>
										)}
									</span>
									<span className="pl-1 text-sm">
										{ctaData?.stage}
									</span>
								</span>
							</span>
						</span>
					</div>
				</div>
				<TicketsMenu
					otherTicketData={otherTicketData}
					ticketData={zendeskData}
					history={history}
					companyId={companyId}
				/>
				<div
					id="dropdowns"
					className="flex flex-col mt-5 mb-5 p-2 bg-sidebarmenugray rounded gap-y-3"
				>
					<div id="sidebar-status-section" className="ml-3">
						<SmallDropdown
							title="Status"
							icon={status ? icons[status] : null}
							options={zendeskStatuses}
							status={status}
							open={statusDropdownOpen}
							setOpen={setStatusDropdownOpen}
							onSelect={mutateStatus}
							keyCommand="S"
							fixedHeaderWidth
						/>
					</div>
					<div id="sidebar-priority-section" className="ml-3">
						<SmallDropdown
							title="Priority"
							icon={priority ? icons[priority] : null}
							options={priorities}
							status={priority}
							open={priorityDropdownOpen}
							setOpen={setPriorityDropdownOpen}
							onSelect={mutatePriority}
							keyCommand="P"
							fixedHeaderWidth
						/>
					</div>
					<div id="sidebar-assigned-section" className="ml-3">
						<SmallDropdown
							title="Assigned"
							icon={miniIcons(allTicketUsers, assigned?.id)}
							// options={userOptions}
							status={assigned ? assigned.name : "Unassigned"}
							// open={assignedDropdownOpen}
							setOpen={() => {
								console.log("open");
							}}
							// onSelect={mutateAssigned}
							// keyCommand="A"
							// variant="assigned"
							fixedHeaderWidth
						/>
					</div>
					<div id="sidebar-followers-section" className="ml-3">
						<CheckBoxMenu
						// options={userOptions}
						// selected={followers}
						// open={followerDropdownOpen}
						// setOpen={setFollowerDropdownOpen}
						// keyCommand="F"
						// onSelect={mutateFollowers}
						/>
					</div>
				</div>

				{ctaData?.scorecard?.scores.length > 0 && (
					<div
						id="scores"
						className="grid grid-cols-3 grid-rows-2 p-2 mb-5 bg-sidebarmenugray rounded "
					>
						{ctaData?.scorecard?.scores.map((score, index) => (
							<div
								key={index}
								className="flex col-span-1 place-self-center flex-col mb-2 h-16 flex-grow-0 w-full"
							>
								<div className="mt-2 self-center justify-self-center">
									<Grade
										grade={score.grade}
										size={28}
										text="md"
									/>
								</div>
								<p className="body-small-gray mt-1 mb-1 justify-self-center text-center w-full">
									{score.name}
								</p>
							</div>
						))}
					</div>
				)}

				<div
					id="sidebar-stats-section"
					className="flex flex-col mt-5 mb-3 bg-sidebarmenugray rounded p-2 gap-y-3"
				>
					<div className="flex flex-row  justify-center  mt-2 mb-3">
						<SidebarStatistic
							title="ARR"
							figure={ctaData?.arr ? `$${ctaData?.arr}k` : "-"}
						/>
						<SidebarStatistic
							title="Usage"
							figure={usage ? `${usage}%` : "-"}
						/>

						<SidebarStatistic
							title="DAU"
							figure={ctaData?.dau ?? "-"}
						/>
					</div>
				</div>
			</div>
		);
	}
);

export default SidebarZendesk;

import useSWR from "swr";

import getFetcher from "./getFetcher";

const getCtaData = () => {
	const { data, error, mutate } = useSWR(
		"/api/actionItems/getCTA",
		getFetcher
	);

	return {
		data: data,
		loading: !error && !data,
		error: error,
		mutateCta: mutate,
	};
};

export default getCtaData;

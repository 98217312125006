import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from "react-router-dom";
import { SWRConfig } from "swr";

import App from "./App";
import Routes from "./Routes";
import "./css/tailwind.output.css";
import { ErrorBoundary } from "./errors";

// Routing History
const history = createBrowserHistory();

// Sentry
const tracesSampleRate = 0.2;

Sentry.init({
	dsn: process.env.SENTRY_DSN,
	environment: process.env.VERCEL_ENV,
	tracesSampleRate,
	integrations: [
		new Integrations.BrowserTracing({
			routingInstrumentation: Sentry.reactRouterV5Instrumentation(
				history
			),
		}),
	],
});

ReactDOM.render(
	<SWRConfig
		value={{
			refreshInterval: 5000,
		}}
	>
		<Router history={history}>
			<ErrorBoundary location="root" fallbackRoute={Routes.LOGIN_PAGE}>
				<App />
			</ErrorBoundary>
		</Router>
	</SWRConfig>,
	document.getElementById("root")
);

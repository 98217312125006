import React from "react";

import { ReactComponent as Waiting } from "../../assets/waiting.svg";

const WaitingIcon = () => (
	<div className="flex justify-center items-center w-4 h-4">
		<Waiting />
	</div>
);

export default WaitingIcon;

import React, { useState, useEffect } from "react";
import TableRow from "./TableRow";
import cloneDeep from "lodash.clonedeep";
import { callWithModifiers } from "../../utils";
import { useHotkeys } from "react-hotkeys-hook";

const TableForm = ({
	data,
	rowUpdate,
	dropDownData,
	variant = "template",
	formDataUpdate,
}) => {
	const [tableData, setTableData] = useState(null);

	// Event Handlers
	useEffect(() => {
		setTableData(data);
	}, [data]);

	const addRow = async () => {
		let newTable = cloneDeep(tableData);
		let newRow = { name: "", salesforce: "" };
		newTable.push(newRow);
		setTableData(newTable);
		rowUpdate("new", null, null);
	};
	// previewHandoff
	return (
		<>
			{variant === "template" && (
				<div id="table-form" className="flex flex-col flex-grow">
					<TableRow variant={"header"} />
					{tableData &&
						tableData.map((item, index) => (
							<div key={index}>
								<TableRow
									data={item}
									rowUpdate={rowUpdate}
									rowIndex={index}
									dropDownData={
										dropDownData ? dropDownData : item.data
									}
									variant={variant}
								/>
							</div>
						))}
					<div
						className={`flex flex-row text-sm text-secondaryText items-center cursor-pointer border-b border-gray-200 h-10 `}
					>
						<button
							onClick={addRow}
							className="flex flex-none items-center hover:text-userpurple focus-within:text-userpurple h-full"
						>
							+ New Row
						</button>
					</div>
				</div>
			)}
			{variant === "previewHandoff" && (
				<div id="table-form" className="flex flex-col flex-grow">
					<TableRow variant={"header-preview"} />
					{tableData &&
						tableData.map((item, index) => (
							<div key={index}>
								<TableRow
									data={item}
									rowUpdate={rowUpdate}
									rowIndex={index}
									variant={variant}
								/>
							</div>
						))}
					<div
						className={`flex flex-row text-sm text-secondaryText items-center cursor-pointer border-b border-gray-200 h-10 `}
					>
						<button
							onClick={addRow}
							className="flex flex-none items-center hover:text-userpurple focus-within:text-userpurple h-full"
						>
							+ New Row
						</button>
					</div>
				</div>
			)}
			{variant === "handoffLanding" && (
				<div id="table-form" className="flex flex-col flex-grow">
					{tableData &&
						tableData.map((item, index) => (
							<div key={index}>
								<TableRow
									data={item}
									rowUpdate={formDataUpdate}
									rowIndex={index}
									variant={variant}
								/>
							</div>
						))}
				</div>
			)}
		</>
	);
};

export default TableForm;

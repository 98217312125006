import React, { useEffect, useState } from "react";
import useSWR from "swr";
import fetch from "unfetch";
import { BsArrowReturnLeft } from "react-icons/bs";
import ChatUserName from "./renderUserName";
import { comment } from "postcss";
import ReplyBox from "./ReplyBox";
import { BsArrow90DegLeft } from "react-icons/bs";
import InternalComment from "./Messages/InternalComment";
import ExternalMessage from "./Messages/ExternalMessage";
const ChatBar = ({
	id,
	selected,
	displayReplyBox,
	commentData,
	updateScroll,
	body,
	created,
	isPublic,
	userData,
	submitComment,

	userNameDatabase,
}) => {
	const [showReply, setShowReply] = useState(false);
	const [userName, setUserName] = useState("Loading.");
	const [userEmail, setUserEmail] = useState("<Loading...>");
	const [isTooLong, setIsTooLong] = useState(true);

	const [showReplyTextInput, setShowReplyTextInput] = useState(false);
	const [timeToDisplay, setTimeToDisplay] = useState("Loading...");
	//convert time stamp into appropriate format
	//definitely a better way to do this

	var days = [
		"Sunday",
		"Monday",
		"Tuesday",
		"Wednesday",
		"Thursday",
		"Friday",
		"Saturday",
	];
	var d = new Date(created);
	var dayName = d.getDay();
	let months = [
		"Jan",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"Jul",
		"Aug",
		"Sept",
		"Nov",
		"Oct",
		"Dec",
	];
	var now = new Date(created);
	var str = now.getDate();
	var month = now.getMonth();
	let timeStamp = months[month - 1] + " " + str;

	const bodyToDisplay = (body) => {
		if (body.length > 45) {
			return true;
		} else {
			return false;
		}
	};
	const checkTime = (time) => {
		var ourDate = new Date();
		var pastDate = ourDate.getDate() - 7;
		if (
			pastDate > time.getDate() - 7 &&
			time.getMonth() === ourDate.getMonth() &&
			ourDate.getDate() - 7 > 0
		) {
			return true;
		} else {
			return false;
		}
	};
	useEffect(() => {
		let recentMessage = checkTime(now);
		if (recentMessage === true) {
			var ourDate = new Date();
			let newTime = ourDate.getDate() - now.getDate() + "d";
			setTimeToDisplay(newTime);
		} else {
			setTimeToDisplay(timeStamp);
		}
	}, [created]);
	useEffect(() => {
		setIsTooLong(bodyToDisplay(body));
	}, [body]);

	useEffect(() => {
		if (displayReplyBox !== id) {
			setShowReplyTextInput(false);
		}
	}, [displayReplyBox]);
	return isPublic ? (
		//Determines whether or not it is an internal comment
		// If its NOT an internal comment, are we displaying the reply box component.
		<>
			<ExternalMessage
				setShowReply={setShowReply}
				setShowReplyTextInput={setShowReplyTextInput}
				selected={selected}
				isPrivate={false}
				setUserName={setUserName}
				commentData={commentData}
				id={id}
				userNameDatabase={userNameDatabase}
				userData={userData}
				setUserEmail={setUserEmail}
				userName={userName}
				displayReplyBox={displayReplyBox}
				timeToDisplay={timeToDisplay}
				isTooLong={isTooLong}
				body={body}
				submitComment={submitComment}
				ogCommentData={body}
				updateScroll={updateScroll}
				showReplyTextInput={showReplyTextInput}
				showReply={showReply}
				timeStamp={timeStamp}
				userEmail={userEmail}
			></ExternalMessage>
		</>
	) : (
		//Case of an internal comment. No replies to internal comments.
		<>
			<InternalComment
				setUserEmail={setUserEmail}
				setUserName={setUserName}
				commentData={commentData}
				id={id}
				userNameDatabase={userNameDatabase}
				userData={userData}
				body={body}
				userName={userName}
			></InternalComment>
		</>
	);
};
export default ChatBar;

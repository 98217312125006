export default (userSplits, remove) => {
	return userSplits?.map((userSplit) => ({
		title: userSplit.title,
		subtitle: userSplit.page,
		icon: null,
		command() {
			remove(userSplit.slug, userSplit.pageKey, userSplit.pageName);
		},
	}));
};

import React from "react";

const ReadIndicator = ({ unread, email }) => {
	return (
		<>
			{email && unread && unread.includes(email) ? (
				<div className="rounded-full float-right h-1.5 w-1.5 bg-blue-300" />
			) : null}
		</>
	);
};

export default ReadIndicator;

import axios from "axios";
import { handleCaughtError } from "../utils";

import bounceIfTokenError from "./bounceIfTokenError";

const createTemplate = async (templates) => {
	try {
		return await axios.post(
			"/api/handoffs/createTemplate",
			{
				templates,
			},
			{
				headers: {
					"Content-Type": "application/json",
					authorization: `Bearer ${localStorage.getItem(
						"access_token"
					)}`,
				},
			}
		);
	} catch (err) {
		handleCaughtError(err);
		bounceIfTokenError(err);
	}
};

export default createTemplate;

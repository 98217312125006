import React, { useEffect, useRef, useState } from "react";
import { callWithModifiers, callWithoutModifiers } from "../../utils";
import TemplateDropdown from "../Dropdowns/TemplateDropdown";
import ResponsiveTextArea from "../ResponsiveTextArea";

const TableRow = ({ data, variant, rowUpdate, rowIndex, dropDownData }) => {
	let nameRef = useRef(null);
	let textInputRef = useRef(null);
	const [localName, setLocalName] = useState("");
	const [dropDownOpen, setDropDownOpen] = useState(false);
	const [localData, setLocalData] = useState(null);
	const [dropDownChoice, setDropDownChoice] = useState("");
	const [salesForceTextBox, setSalesForceTextBox] = useState("");

	const onKeyDownName = (e) => {
		if (e.key === "Enter") {
			callWithoutModifiers(e, () => nameRef.current.blur());
		}
	};
	const onNameBlur = () => {
		rowUpdate("name", localName, rowIndex);
	};

	const onKeyDownTextBox = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(e, () => onTextBoxBlur, ["cmd"]);
		}
	};
	const onTextBoxBlur = (e) => {
		if (e.key === "Enter") {
			rowUpdate("salesforce", salesForceTextBox, rowIndex);
		}
	};

	const onDropdownChange = (newValue, id, icon) => {
		if (variant === "handoffLanding") {
			rowUpdate(newValue, id, rowIndex, icon);
		} else {
			rowUpdate("salesforce", newValue, rowIndex);
		}
	};

	useEffect(() => {
		if (data) {
			setLocalName(data.name);
			setDropDownChoice(
				data?.salesforce ? data.salesforce : data.selected
			);
			setSalesForceTextBox(data?.salesforce ? data.salesforce : "");
			setLocalData(data);
		}
	}, [data]);

	return (
		<>
			{variant === "handoffLanding" && (
				<>
					<div
						className={`flex flex-row cursor-default text-md text-secondaryText items-center border-b w-1/2 border-gray-200 h-12 `}
					>
						<div className="grid grid-cols-10 flex-grow w-1/2 h-full max-h-full">
							<div className="col-start-1 col-end-4 justify-start flex flex-row  items-center truncate">
								{localData?.name}
							</div>
							<div className="col-start-4 col-end-10 flex flex-none text-sm text-secondaryText justify-start items-center ml-4 h-full">
								<TemplateDropdown
									title={
										dropDownChoice
											? dropDownChoice
											: `Set ${localData?.name}`
									}
									placeholder={`Set ${localData?.name}`}
									options={
										dropDownData
											? dropDownData
											: localData?.data
									}
									open={dropDownOpen}
									setOpen={setDropDownOpen}
									variant={variant}
									onSelect={setDropDownChoice}
									onChange={onDropdownChange}
									dropDownDataVariant={data.variant}
									icon={localData?.icon}
								/>
							</div>
						</div>
					</div>
				</>
			)}
			{(variant === "header" || variant === "header-preview") && (
				<div
					className={`flex flex-row cursor-default text-md text-secondaryText items-center border-t border-b border-gray-200 h-10 `}
				>
					<div className="grid grid-cols-12 flex-grow w-full h-full max-h-full">
						<div className="col-start-1 col-end-5 flex flex-row items-center border-r border-gray-200 truncate">
							<svg
								width="19"
								height="12"
								viewBox="0 0 19 12"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M2.97301 11L3.64418 8.86222H7.15909L7.83026 11H10.4751L7.03977 0.818182H3.76349L0.328125 11H2.97301ZM4.23082 6.9929L5.35938 3.38352H5.43892L6.57244 6.9929H4.23082ZM13.7241 11.1293C14.788 11.1293 15.5238 10.7166 15.9414 9.93608H16.0011V11H18.288V5.80966C18.288 4.19389 16.8512 3.2642 14.9073 3.2642C12.854 3.2642 11.6907 4.2983 11.4968 5.69034L13.739 5.76989C13.8434 5.28267 14.2461 4.98438 14.8874 4.98438C15.484 4.98438 15.8619 5.27273 15.8619 5.7848V5.80966C15.8619 6.27699 15.3548 6.37642 14.0522 6.49077C12.506 6.62003 11.2134 7.19176 11.2134 8.8821C11.2134 10.3935 12.2624 11.1293 13.7241 11.1293ZM14.4748 9.53835C13.913 9.53835 13.5153 9.26989 13.5153 8.76278C13.5153 8.2706 13.9031 7.9723 14.5941 7.8679C15.0465 7.80327 15.6033 7.70384 15.8768 7.55966V8.28551C15.8768 9.03125 15.2504 9.53835 14.4748 9.53835Z"
									fill="#A4A6B4"
								/>
							</svg>
							<span className="ml-2">Field Name</span>
						</div>
						<div className="col-start-5 col-end-12 justify-start flex flex-row  items-center ml-4 truncate">
							<svg
								width="20"
								height="15"
								viewBox="0 0 20 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.32279 2.02734C8.96781 1.35526 9.86581 0.938452 10.859 0.938452C12.1792 0.938452 13.3311 1.67463 13.9444 2.76751C14.4775 2.52934 15.0675 2.39686 15.6883 2.39686C18.0695 2.39686 20 4.34415 20 6.74614C20 9.14842 18.0695 11.0957 15.6883 11.0957C15.3977 11.0957 15.1137 11.0667 14.839 11.0111C14.2989 11.9746 13.2695 12.6256 12.088 12.6256C11.5934 12.6256 11.1256 12.5114 10.7091 12.3083C10.1615 13.5963 8.88576 14.4994 7.39886 14.4994C5.85043 14.4994 4.53077 13.5196 4.02422 12.1456C3.80285 12.1926 3.5735 12.2171 3.33818 12.2171C1.49459 12.2171 0 10.7071 0 8.84415C0 7.59572 0.67151 6.50569 1.66923 5.9225C1.46382 5.44985 1.34957 4.9282 1.34957 4.37976C1.34957 2.23731 3.08889 0.50056 5.23419 0.50056C6.49373 0.50056 7.61311 1.09942 8.32279 2.02734Z"
									fill="#A4A6B4"
								/>
							</svg>

							{variant === "header-preview" ? (
								<span className="ml-2">Salesforce Value</span>
							) : (
								<span className="ml-2">
									Select Salesforce Field
								</span>
							)}
						</div>
					</div>
				</div>
			)}
			{variant === "template" && (
				<div
					className={`flex flex-row text-sm items-center  border-b border-gray-200 h-10 `}
				>
					<div className="grid grid-cols-12 flex-grow w-full h-full max-h-full">
						<div className=" col-start-1 col-end-5 border-r border-gray-200 h-full items-center truncate">
							<input
								className="text-sm whitespace-nowrap outline-none h-full overflow flex-grow truncate"
								value={localName}
								onChange={(e) => setLocalName(e.target.value)}
								placeholder="Type Here..."
								ref={nameRef}
								onKeyDown={onKeyDownName}
								onBlur={onNameBlur}
							/>
						</div>
						<div className="col-start-5 col-end-12 flex flex-none text-sm text-secondaryText justify-start items-center ml-4 h-full">
							<TemplateDropdown
								title={dropDownChoice}
								options={dropDownData}
								open={dropDownOpen}
								setOpen={setDropDownOpen}
								onSelect={setDropDownChoice}
								onChange={onDropdownChange}
							/>
						</div>
					</div>
				</div>
			)}
			{variant === "previewHandoff" && (
				<div
					className={`flex flex-row flex-grow text-sm items-start border-b border-gray-200`}
				>
					<div className="grid grid-cols-12 flex-grow w-full h-full max-h-full self-start">
						<div className=" flex flex-row col-start-1 col-end-5 border-r border-gray-200 h-full truncate">
							<input
								className="text-sm whitespace-nowrap outline-none h-full overflow flex-grow truncate"
								value={localName}
								onChange={(e) => setLocalName(e.target.value)}
								placeholder="Type Here..."
								ref={nameRef}
								onKeyDown={onKeyDownName}
								onBlur={onNameBlur}
							/>
						</div>
						<div className="col-start-5 col-end-12 flex flex-none text-sm text-primaryText justify-start items-center ml-4 h-full">
							<div
								ref={textInputRef}
								className="text-sm whitespace-nowrap outline-none h-full overflow flex-grow truncate"
							>
								<ResponsiveTextArea
									text={salesForceTextBox}
									placeholder={"Start Typing Here..."}
									setText={setSalesForceTextBox}
									onKeyDown={onKeyDownTextBox}
									variant={"tableForm"}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default TableRow;

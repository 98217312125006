import { useEffect, useState } from "react";

const FadingModal = ({ message, fadeSeconds = 3, setOpen }) => {
	const [opacity, setOpacity] = useState("1");

	useEffect(() => {
		setTimeout(() => {
			setOpacity("0");
		}, 500);
		setTimeout(() => {
			setOpen(false);
		}, fadeSeconds * 1000);
	}, []);

	return (
		<div
			className="flex flex-row justify-center items-center p-5 text-white rounded-md"
			style={{
				backgroundColor: "rgba(0, 0, 0, 0.24)",
				pointerEvents: "none",
				transition: `opacity ${fadeSeconds}s`,
				opacity,
			}}
		>
			{message}
		</div>
	);
};

export default FadingModal;

import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { IoClose } from "react-icons/io5";

import { ctaTypes } from "../../constants";
import { SmallDropdown, UserIcon } from "..";
import { useOutsideAlerter } from "../../hooks";
import { getAllAccountData, getPlaybooks } from "../../requests";
import { callWithModifiers, callWithoutModifiers } from "../../utils";

const TextInputSection = forwardRef(
	(
		{
			title,
			value,
			setValue,
			focused,
			setFocused,
			onPressTab,
			onPressEnter,
		},
		ref
	) => (
		<div className="flex flex-row items-center flex-none w-full pt-3 pb-1">
			<p className="body-small-gray font-normal pr-2">{title}: </p>
			<input
				ref={ref}
				className={`flex-grow outline-none body-small-black py-1 pl-2 rounded ${
					!focused && "hover:bg-hovergray"
				}`}
				onChange={(e) => setValue(e.target.value)}
				value={value}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
				onKeyDown={(e) => {
					if (e.key === "Tab") onPressTab(e);
					if (e.key === "Enter") onPressEnter(e);
				}}
			/>
		</div>
	)
);

const CreateCtaModal = ({ setModalOpen, onClickCreate, userOptions }) => {
	let modalRef = useRef(null);
	let titleRef = useRef(null);
	let descriptionRef = useRef(null);

	const [ctaTitle, setCtaTitle] = useState("");
	const [ctaDescription, setCtaDescription] = useState("");
	const [company, setCompany] = useState(null);
	const [type, setType] = useState(null);
	const [playbook, setPlaybook] = useState(null);
	const [assigned, setAssigned] = useState(null);

	const [companyOptions, setCompanyOptions] = useState(null);
	const [playbookOptions, setPlaybookOptions] = useState(null);

	const [companyDropdownOpen, setCompanyDropdownOpen] = useState(false);
	const [typeDropdownOpen, setTypeDropdownOpen] = useState(false);
	const [playbookDropdownOpen, setPlaybookDropdownOpen] = useState(false);
	const [assignedDropdownOpen, setAssignedDropdownOpen] = useState(false);
	const [titleFocused, setTitleFocused] = useState(false);
	const [descriptionFocused, setDescriptionFocused] = useState(false);

	const [errorMessage, setErrorMessage] = useState("");

	useOutsideAlerter(modalRef, () => setModalOpen(false));

	// Data Hooks

	const {
		data: playbookData,
		isLoading: playbookIsLoading,
		isError: playbookError,
	} = getPlaybooks();

	const {
		data: accountData,
		loading: accountsLoading,
		error: accountsError,
	} = getAllAccountData();

	// Effects

	useEffect(() => {
		// Timeout used to avoid key command used to open this modal also entering first letter ("c" in this case)
		setTimeout(() => {
			titleRef.current.focus();
		}, 200);
	}, []);

	useEffect(() => {
		if (playbookData) {
			let playbooks = playbookData.playbooks.map((item) => ({
				value: item.name,
				playbook: item,
			}));
			setPlaybookOptions(playbooks);
		}
	}, [playbookData]);

	useEffect(() => {
		if (accountData) {
			let accounts = accountData.data.map((item) => ({
				value: item.company_name,
				id: item.company_id,
			}));
			setCompanyOptions(accounts);
		}
	}, [accountData]);

	// Navigation

	const onPressTab = (event) => {
		if (titleFocused) {
			event.preventDefault();
			titleRef.current.blur();
			descriptionRef.current.focus();
		} else if (descriptionFocused) {
			event.preventDefault();
			descriptionRef.current.blur();
			setCompanyDropdownOpen(true);
		} else if (companyDropdownOpen) {
			event.preventDefault();
			setCompanyDropdownOpen(false);
			setTypeDropdownOpen(true);
		} else if (typeDropdownOpen) {
			event.preventDefault();
			setTypeDropdownOpen(false);
			setPlaybookDropdownOpen(true);
		} else if (playbookDropdownOpen) {
			event.preventDefault();
			setPlaybookDropdownOpen(false);
			setAssignedDropdownOpen(true);
		} else if (assignedDropdownOpen) {
			event.preventDefault();
			setAssignedDropdownOpen(false);
		}
	};

	const onPressEnter = (e = null) => {
		if (e && e.metaKey) {
			onSubmit();
			return;
		}
		if (titleFocused) {
			titleRef.current.blur();
			if (!ctaDescription) {
				descriptionRef.current.focus();
			}
		} else if (descriptionFocused) {
			descriptionRef.current.blur();
			if (!company) {
				setCompanyDropdownOpen(true);
			}
		} else if (companyDropdownOpen && !type) {
			setCompanyDropdownOpen(false);
			setTypeDropdownOpen(true);
		} else if (typeDropdownOpen && !playbook) {
			setTypeDropdownOpen(false);
			setPlaybookDropdownOpen(true);
		} else if (playbookDropdownOpen && !assigned) {
			setPlaybookDropdownOpen(false);
			setAssignedDropdownOpen(true);
		} else if (assignedDropdownOpen) {
			setAssignedDropdownOpen(false);
		}
	};

	// Submission

	const checkForValidSubmission = () => {
		if (!ctaTitle) {
			setErrorMessage("A title is required to create a new CTA");
			return false;
		} else if (!ctaDescription) {
			setErrorMessage("A description is required to create a new CTA");
			return false;
		} else if (!company) {
			setErrorMessage("You must select a company to create a new CTA");
			return false;
		} else if (!type) {
			setErrorMessage("You must select a type to create a new CTA");
			return false;
		} else if (!playbook) {
			setErrorMessage("You must select a playbook to create a new CTA");
			return false;
		}
		return true;
	};

	const onSubmit = () => {
		setErrorMessage("");
		let valid = checkForValidSubmission();
		if (valid) {
			onClickCreate(
				ctaTitle,
				ctaDescription,
				company,
				type,
				playbook,
				assigned
			);
		}
	};

	// Hot Keys

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Tab":
					callWithoutModifiers(event, () => onPressTab(event));
					break;
				case "Enter":
					callWithModifiers(event, () => onSubmit(), ["cmd"]);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	return (
		<div
			className="flex flex-col flex-grow justify-start items-center p-5 rounded-lg shadow-xl border border-gray-100 bg-white z-10"
			style={{ width: "500px" }}
			ref={modalRef}
		>
			{/* Close Button */}
			<div
				className="absolute top-0 right-0 p-5"
				onClick={() => setModalOpen(false)}
			>
				<IoClose
					className="text-gray-400 hover:text-gray-500 cursor-pointer"
					size={20}
				/>
			</div>

			{/* Modal Header */}
			<div className="flex justify-start items-center flex-none w-full">
				<p className="pr-2 pb-3 font-bold text-base text-gray-600">
					Create New Call To Action
				</p>
			</div>

			{/* Cta Title Field */}
			<TextInputSection
				title="Cta Title"
				value={ctaTitle}
				setValue={setCtaTitle}
				focused={titleFocused}
				setFocused={setTitleFocused}
				ref={titleRef}
				onPressTab={onPressTab}
				onPressEnter={onPressEnter}
			/>

			{/* Cta Description Field */}
			<div className="mt-1 w-full">
				<TextInputSection
					title="Description"
					value={ctaDescription}
					setValue={setCtaDescription}
					focused={descriptionFocused}
					setFocused={setDescriptionFocused}
					ref={descriptionRef}
					onPressTab={onPressTab}
					onPressEnter={onPressEnter}
				/>
			</div>

			{/* Company Dropdown */}
			<div className="w-full pt-3">
				<SmallDropdown
					options={companyOptions}
					open={companyDropdownOpen}
					setOpen={setCompanyDropdownOpen}
					status={company?.value}
					title="Company"
					onSelect={setCompany}
					variant="createCta"
					bodyStyle={{ top: "0px", left: "65px" }}
					onPressTab={onPressTab}
					onPressEnter={onPressEnter}
				/>
			</div>

			{/* Type Dropdown */}
			<div className="w-full pt-3">
				<SmallDropdown
					options={ctaTypes}
					open={typeDropdownOpen}
					setOpen={setTypeDropdownOpen}
					status={type?.value}
					title="Type"
					onSelect={setType}
					variant="createCta"
					bodyStyle={{ top: "0px", left: "40px" }}
					onPressTab={onPressTab}
					onPressEnter={onPressEnter}
				/>
			</div>

			{/* Playbook Dropdown */}
			<div className="w-full pt-3">
				<SmallDropdown
					options={playbookOptions}
					open={playbookDropdownOpen}
					setOpen={setPlaybookDropdownOpen}
					status={playbook?.value}
					title="Playbook"
					onSelect={setPlaybook}
					variant="createCta"
					bodyStyle={{ top: "0px", left: "65px" }}
					onPressTab={onPressTab}
					onPressEnter={onPressEnter}
				/>
			</div>

			{/* Assigned To Dropdown */}
			<div className="w-full pt-3">
				<SmallDropdown
					icon={assigned && <UserIcon user={assigned.iconLabel} />}
					options={userOptions}
					open={assignedDropdownOpen}
					setOpen={setAssignedDropdownOpen}
					status={assigned?.value}
					title="Assigned To"
					onSelect={setAssigned}
					variant="assigned"
					bodyStyle={{ top: "0px", left: "80px" }}
					onPressTab={onPressTab}
					onPressEnter={onPressEnter}
				/>
			</div>

			<div className="flex flex-row justify-between items-center w-full  mt-2">
				{/* Error Message */}

				<p className="text-xs text-red-400">{errorMessage}</p>

				{/* Create Button */}
				<button
					className="px-5 rounded border whitespace-nowrap h-8 border-gray-400 self-end"
					onClick={onSubmit}
				>
					<p className="text-center text-gray-500 text-sm">Create</p>
				</button>
			</div>
		</div>
	);
};

export default CreateCtaModal;

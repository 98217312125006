import { useEffect, useState } from "react";

import ProgressBarArrow from "./ProgressBarArrow";

const ProgressBar = ({
	items,
	width = 800,
	height = 50,
	progress,
	setProgress,
	variant,
}) => {
	const [progressIndex, setProgressIndex] = useState(progress);

	useEffect(() => {
		setProgressIndex(progress);
	}, [progress]);

	if (variant === "scorecard") {
		return (
			<div className="flex flex-row justify-center">
				{items.map((item, index) => (
					<ProgressBarArrow
						label={`${item}`}
						width={180}
						height={height}
						key={index}
						status={
							progress === index
								? "active"
								: progressIndex === index
								? "hoverActive"
								: progress > index
								? "done"
								: index > progressIndex
								? "waiting"
								: index < progressIndex
								? "hoverDone"
								: "done"
						}
						onMouseEnter={() => setProgressIndex(index)}
						onMouseLeave={() => setProgressIndex(progress)}
						onClick={() => setProgress(index)}
					/>
				))}
			</div>
		);
	} else {
		return (
			<div className="flex flex-row justify-center">
				{items.map((item, index) => (
					<ProgressBarArrow
						label={`${
							index === progressIndex
								? variant === "scorecard"
									? item
									: item.ApiName
								: ""
						}`}
						width={
							index === progressIndex
								? 180
								: width / (items.length * 1.1)
						}
						height={height}
						key={index}
						status={
							progress === index
								? "active"
								: progressIndex === index
								? "hoverActive"
								: progress > index
								? "done"
								: index > progressIndex
								? "waiting"
								: index < progressIndex
								? "hoverDone"
								: "done"
						}
						onMouseEnter={() => setProgressIndex(index)}
						onMouseLeave={() => setProgressIndex(progress)}
						onClick={() => setProgress(index)}
					/>
				))}
			</div>
		);
	}
};

export default ProgressBar;

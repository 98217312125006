const getNpsColor = (score) => {
	switch (true) {
		case score <= 6:
			return "text-red-500";
		case score <= 8:
			return "text-yellow-400";
		case score <= 10:
			return "text-green-400";
		default:
			break;
	}
};

const NPS = ({ score }) => {
	return <p className={`text-sm pl-2 ${getNpsColor(score)}`}>NPS={score}</p>;
};

export default NPS;

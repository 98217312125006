import React, { useState } from "react";

const InputBar = ({ onKeyDown, inputref, setInputBarComment }) => {
	return (
		<input
			id="text-input"
			ref={inputref}
			onKeyDown={onKeyDown}
			onChange={(e) => setInputBarComment(e.target.value)}
			type="text"
			placeholder="Leave a comment..."
			className=" w-11/12 text-left border-2 px-3 mx-auto border-gray-100 font-light rounded-lg py-2 focus:outline-none border-opacity-100 "
		></input>
	);
};
export default InputBar;

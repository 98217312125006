import { format } from "date-fns";
import { isNumeric } from "../utils";

// Date Getters/Formatters

const formatLocal = (date) => {
	let today = getCalendarDate(new Date());
	let hour = date.getHours() >= 12 ? "PM" : "AM";
	if (today === getCalendarDate(date)) {
		return `Today, ${format(date, "h:mm")} ${hour}`;
	}
	return `${format(date, "E. MMM dd, h:mm")} ${hour}`;
};

const getCalendarDate = (date) => {
	return date.getDate() + date.getMonth() + date.getFullYear();
};

const addMinutes = (date, minutes) => {
	return new Date(date.getTime() + minutes * 60000);
};

const addHours = (date, hours) => {
	let result = new Date(date);
	result.setHours(result.getHours() + hours);
	return result;
};

const addDays = (date, days) => {
	let result = new Date(date);
	result.setDate(result.getDate() + days);
	result.setHours(8);
	result.setMinutes(0);
	result.setMilliseconds(0);
	return result;
};

const getThisWeekend = (date) => {
	let datesWeekday = date.getDay();
	if (datesWeekday === 6 || datesWeekday === 7) {
		if (date.getHours() > 8) {
			return addDays(date, 1);
		} else {
			let yesterday = new Date(date).setDate(date.getDate() - 1);
			return addDays(yesterday, 1);
		}
	} else {
		let daysDiff = 6 - datesWeekday;
		return addDays(date, daysDiff);
	}
};

const getNextMonday = (date) => {
	let datesWeekday = date.getDay();
	let daysDiff;
	if (datesWeekday === 0) {
		daysDiff = 1;
	} else if (datesWeekday === 1) {
		daysDiff = 7;
	} else {
		daysDiff = 8 - datesWeekday;
	}
	return addDays(date, daysDiff);
};

const unitOptions = ["minutes", "hours", "days"];

const getUnitsFromString = (str) => {
	if (str === "") {
		return null;
	}
	for (let i = 0; i < unitOptions.length; i++) {
		let strLen = str.length;
		if (
			str
				.toLowerCase()
				.localeCompare(
					unitOptions[i].toLowerCase().slice(0, strLen)
				) === 0
		) {
			return i;
		}
	}
	return null;
};

export default (ctaId, snoozeFunction, currentQuery, navigate = null) => {
	let now = new Date();

	// Parse number/units from query
	let unitsIndex = null;
	let queryNumber = null;
	let querySplit = currentQuery.split(" ");
	for (let i = 0; i < querySplit.length; i++) {
		let current = querySplit[i];
		if (isNumeric(current)) {
			queryNumber = parseInt(current);
		} else if (unitsIndex === null) {
			unitsIndex = getUnitsFromString(current);
		}
	}

	// Define default return options
	let baseResults = [
		{
			title: "Until tomorrow",
			datetime: addDays(now, 1).getTime(),
			timestamp: formatLocal(addDays(now, 1)),
			command() {
				snoozeFunction(ctaId, this.datetime);
				navigate && navigate();
			},
		},
		{
			title: "Until this weekend",
			datetime: getThisWeekend(now).getTime(),
			timestamp: formatLocal(getThisWeekend(now)),
			command() {
				snoozeFunction(ctaId, this.datetime);
				navigate && navigate();
			},
		},
		{
			title: "Until next week",
			datetime: getNextMonday(now).getTime(),
			timestamp: formatLocal(getNextMonday(now)),
			command() {
				snoozeFunction(ctaId, this.datetime);
				navigate && navigate();
			},
		},
	];

	if (!queryNumber) return baseResults;

	// If query contains number, define suggestions
	let minutes;
	let hours;
	let days;
	if (queryNumber === 1) {
		minutes = "1 minute";
		hours = "1 hour";
		days = "1 day";
	} else {
		minutes = `${queryNumber} minutes`;
		hours = `${queryNumber} hours`;
		days = `${queryNumber} days`;
	}

	const suggestions = [
		{
			title: `For ${minutes}`,
			datetime: addMinutes(now, queryNumber).getTime(),
			timestamp: formatLocal(addMinutes(now, queryNumber)),
			command() {
				snoozeFunction(ctaId, this.datetime);
				navigate && navigate();
			},
		},
		{
			title: `For ${hours}`,
			datetime: addHours(now, queryNumber).getTime(),
			timestamp: formatLocal(addHours(now, queryNumber)),
			command() {
				snoozeFunction(ctaId, this.datetime);
				navigate && navigate();
			},
		},
		{
			title: `For ${days}`,
			datetime: addDays(now, queryNumber).getTime(),
			timestamp: formatLocal(addDays(now, queryNumber)),
			command() {
				snoozeFunction(ctaId, this.datetime);
				navigate && navigate();
			},
		},
	];

	// If units are found in query ("minute", "hour", "day"), return the single corresponding option
	if (unitsIndex !== null) {
		return [suggestions[unitsIndex]];
	}

	// Otherwise, return all suggestions
	return suggestions;
};

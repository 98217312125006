import React, { useContext, useState } from "react";
import { Switch, useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import Routes from "../Routes";
import { callWithModifiers } from "../utils";
import { getNavigationCommands } from "../commands";
import {
	CommandK,
	FillableSidebar,
	Menu,
	ModalContainer,
	PrivateRoute,
} from "../components";
import { SettingsContext } from "../context";
import { Playbooks, Integrations, Snippets } from ".";
import SplitHeader from "../components/SettingsComponents/PageHeader";
import Settings from "../components/SettingsComponents/Settings";
import { settingsSplits } from "../constants";

const SettingsPage = () => {
	const {
		waiting,
		setWaiting,
		backPage,
		setBackPage,
		menuOpen,
		setMenuOpen,
		splitIndex,
		setSplitIndex,
		connected,
		setConnected,
		nylasData,
		setNylasData,
		splits,
		setSplits,

		userData,
		userDataLoading,
		userDataError,

		getNylasStatus,
		getConnectedIntegrations,
	} = useContext(SettingsContext);

	const [navCommandsOpen, setNavCommandsOpen] = useState(false);

	const history = useHistory();

	// Navigation

	const onPressTab = (e) => {
		let newIndex;
		if (splitIndex < splits.length - 1) {
			newIndex = splitIndex + 1;
		} else {
			newIndex = 0;
		}
		let newRoute = splits[newIndex].route;
		history.push(newRoute);
	};

	// Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Tab":
					event.preventDefault();
					onPressTab(event);
					break;
				case "k":
					callWithModifiers(event, () => setNavCommandsOpen(true), [
						"cmd",
					]);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	// Commands

	const navigationCommands = getNavigationCommands(history);

	return (
		<div className="flex flex-row h-screen">
			{/* Page Body (Header + Grid) */}

			<div className="flex flex-col flex-grow h-full">
				<div className="flex flex-row flex-none w-full py-5">
					<div className="w-20 flex-none">
						<Menu userData={userData} setMenuOpen={setMenuOpen} />
					</div>
					<div className="flex flex-row items-center flex-grow">
						<div className="pr-6 text-lg font-sans font-semibold truncate cursor-pointer focus:outline-none select-none text-black">
							Integrations
						</div>
					</div>
				</div>

				{/* Sub Pages */}
				<Integrations></Integrations>
			</div>

			{/* Sidebar */}
			<FillableSidebar title="Instructions">
				<p className="pb-5">
					Please connect all SaaS tools your organization uses.
				</p>
				<p className="pb-5">
					Click on an integration, wait a few seconds, and an
					authorization window will open in a new tab.
				</p>
				<p>
					If you have any questions or would like an integration
					added, please contact connect@sellerant.io.
				</p>
			</FillableSidebar>

			{/* Command Line
			<ModalContainer top="30%">
				{navCommandsOpen && (
					<CommandK
						options={navigationCommands}
						setOpen={setNavCommandsOpen}
						variant="actionItems"
						placeholder="Go to..."
					/>
				)}
			</ModalContainer> */}
		</div>
	);
};

export default SettingsPage;

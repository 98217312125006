import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import {
	getChatData,
	getCtaData,
	getSellerantUsers,
	getUserInfo,
} from "../requests";
import { filterSplitData, getIconOptionsFromUsers } from "../utils";
import { CtaSplitContext } from "./CtaSplitContext";
import Routes from "../Routes";

const CtaContext = React.createContext();

const createHeaderTabs = (ctaData) => {
	let tabs = [
		{
			name: "To-Do",
			url: `${Routes.CTA_BASE_ROUTE}/${ctaData.cta_id}/todo`,
			page: "todo",
		},
		{
			name: "Chat",
			url: `${Routes.CTA_BASE_ROUTE}/${ctaData.cta_id}/chat`,
			page: "chat",
		},
		{
			name: "Timeline",
			url: `${Routes.CTA_BASE_ROUTE}/${ctaData.cta_id}/timeline`,
			page: "timeline",
		},
		{
			name: "Notes",
			url: `${Routes.CTA_BASE_ROUTE}/${ctaData.cta_id}/notes`,
			page: "notes",
		},
		{
			name: "Account Profile ↗",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${ctaData.company_id}/overview?source=cta&cta=${ctaData.cta_id}`,
		},
	];

	if (ctaData.type.type === "Onboarding") {
		tabs.splice(2, 0, {
			name: "Dataroom",
			url: `${Routes.CTA_BASE_ROUTE}/${ctaData.cta_id}/dataroom`,
			page: "dataroom",
		});
	}

	return tabs;
};

const CtaContextWrapper = withRouter(({ location, match, children }) => {
	const {
		backPage,
		selectedSplit,
		setSelectedSplit,
		splitSettings,
		setSplitSettings,
	} = useContext(CtaSplitContext);

	const [filteredData, setFilteredData] = useState(null);
	const [currentCtaData, setCurrentCtaData] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const [details, setDetails] = useState("");
	const [ctaName, setCtaName] = useState("");
	const [assigned, setAssigned] = useState("");
	const [followers, setFollowers] = useState([]);
	const [ctaUnread, setCtaUnread] = useState(null);
	const [otherCtaData, setOtherCtaData] = useState(null);

	const [users, setUsers] = useState(null);
	const [userOptions, setUserOptions] = useState([]);
	const [userSnippets, setUserSnippets] = useState(null);

	const [headerTabs, setHeaderTabs] = useState([]);
	const [dataRoomTodos, setDataRoomTodos] = useState([]);
	const [timelineData, setTimelineData] = useState(null);
	const [currentReport, setCurrentReport] = useState(null);
	const [currentTodoList, setCurrentTodoList] = useState({
		phases: [],
		current_phase: 0,
	});

	const myEmail = localStorage.getItem("user_email");
	const userId = localStorage.getItem("user_id");

	// Hook Data

	const {
		data: allCtaData,
		isLoading: ctaLoading,
		isError: ctaError,
		mutateCta,
	} = getCtaData();

	const {
		data: sellerantUsers,
		loading: usersLoading,
		error: usersError,
	} = getSellerantUsers();

	const {
		data: chatData,
		loading: chatLoading,
		error: chatError,
	} = getChatData();

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	// Split data filtering

	useEffect(() => {
		if (
			userData &&
			allCtaData &&
			!ctaError &&
			!ctaLoading &&
			splitSettings
		) {
			filterSplitData(
				userData,
				splitSettings,
				selectedSplit,
				allCtaData,
				setFilteredData,
				null,
				backPage === Routes.ALL_CTA_PAGE,
				backPage === Routes.ACTION_ITEMS_PAGE,
				null,
				backPage === Routes.ACTION_ITEMS_PAGE
			);
		}
	}, [userData, selectedSplit, allCtaData, splitSettings]);

	const findCurrentCta = (data) => {
		for (let i = 0; i < data.length; i++) {
			let currentCta = data.data[i];
			if (currentCta.cta_id.toString() === match.params.cid) {
				setCurrentCtaData(currentCta);
				setSelectedIndex(i);
				setCtaName(currentCta.cta);
				setDetails(currentCta.cta_summary);
				setCurrentTodoList(currentCta.todo_list);
				setCurrentReport(currentCta.resolution_report);
				setTimelineData(currentCta.timeline);
				setDataRoomTodos(currentCta.customer_facing);
				setHeaderTabs(createHeaderTabs(currentCta));
				setCtaUnread(currentCta.visiblity.unread.includes(myEmail));
				return true;
			}
		}
		return false;
	};

	// Effects

	useEffect(() => {
		if (allCtaData && allCtaData.length > 0 && currentCtaData) {
			let arr = allCtaData.data.filter(
				(cta) => cta.company_id == currentCtaData.company_id
			);
			setOtherCtaData(arr);
		}
	}, [allCtaData, currentCtaData]);

	useEffect(() => {
		if (
			match.path === Routes.CTA_PAGE &&
			splitSettings === null &&
			userData
		) {
			let tempSplitSettings = userData?.settings.action_items_split;
			if (tempSplitSettings.length > 0) {
				setSplitSettings(tempSplitSettings);
				setSelectedSplit(tempSplitSettings[0].slug);
			}
		}
	}, [match, userData]);

	useEffect(() => {
		let foundInFiltered = false;
		if (selectedSplit && filteredData) {
			foundInFiltered = findCurrentCta(filteredData);
		}
		if (allCtaData && !foundInFiltered) {
			findCurrentCta(allCtaData);
		}
	}, [allCtaData, filteredData, match.params.cid]);

	useEffect(() => {
		if (userOptions && currentCtaData?.visiblity) {
			setAssigned(
				userOptions.find(
					(user) =>
						user.email === currentCtaData?.visiblity?.assigned_to[0]
				)
			);
			let arr = [];
			let followedBy = currentCtaData.visiblity.followed_by;
			for (let i = 0; i < followedBy.length; i++) {
				for (let j = 0; j < userOptions.length; j++) {
					if (followedBy[i] == userOptions[j].email)
						arr.push(userOptions[j]);
				}
			}
			setFollowers(arr);
		}
	}, [currentCtaData, userOptions]);

	useEffect(() => {
		if (users) {
			let snippets = users?.filter((u) => u.user_id === userId);
			if (snippets.length > 0) {
				setUserSnippets(snippets[0].snippets.snippets);
			}
		}
	}, [users]);

	useEffect(() => {
		if (!usersLoading && !usersError) {
			setUsers(sellerantUsers.data);
			setUserOptions(getIconOptionsFromUsers(sellerantUsers.data));
		}
	}, [sellerantUsers]);

	return (
		<CtaContext.Provider
			value={{
				currentCtaData,
				setCurrentCtaData,
				selectedIndex,
				setSelectedIndex,

				details,
				setDetails,
				ctaName,
				setCtaName,
				assigned,
				setAssigned,
				followers,
				setFollowers,
				ctaUnread,
				setCtaUnread,
				otherCtaData,
				setOtherCtaData,

				timelineData,
				setTimelineData,
				currentTodoList,
				setCurrentTodoList,
				currentReport,
				setCurrentReport,
				dataRoomTodos,
				setDataRoomTodos,

				users,
				setUsers,
				userOptions,
				setUserOptions,
				userSnippets,

				headerTabs,
				setHeaderTabs,

				allCtaData,
				ctaLoading,
				ctaError,
				mutateCta,

				chatData,
				chatLoading,
				chatError,

				filteredData,
				setFilteredData,
			}}
		>
			{children}
		</CtaContext.Provider>
	);
});

export { CtaContext, CtaContextWrapper };

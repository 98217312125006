export const getSelectionRange = () => {
	const selection = window.getSelection();
	if (selection.rangeCount === 0) return null;
	return selection.getRangeAt(0);
};

export const getSelectionCoords = (selectionRange) => {
	const editorBounds = document
		.getElementById("editor-container")
		.getBoundingClientRect();
	const rangeBounds = selectionRange.getBoundingClientRect();
	const rangeWidth = rangeBounds.right - rangeBounds.left;

	// 300px is width of inline toolbar
	const offsetLeft =
		rangeBounds.left - editorBounds.left + rangeWidth / 2 - 300 / 2;

	// 35 px is height of inline toolbar - 15 is for extra space
	const offsetTop = rangeBounds.top - editorBounds.top - 35 - 15;

	return { offsetLeft, offsetTop };
};

export const getBlockStyle = (block) => {
	switch (block.getType()) {
		case "blockquote":
			return "RichEditor-blockquote";
		default:
			return null;
	}
};

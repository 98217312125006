import { useEffect, useRef, useState } from "react";
import { UserIcon } from "..";
import { useOutsideAlerter } from "../../hooks";
import { consoleHelper } from "../../utils";
import CommandIcon from "./CommandIcon";

const TemplateDropdown = ({
	title,
	status,
	options,
	setOpen,
	open = false,
	onSelect,
	onChange,
	onPressTab,
	onPressEnter,
	variant,
	placeholder,
	dropDownDataVariant,
	icon,
}) => {
	let inputRef = useRef(null);
	let dropdownRef = useRef(null);

	useOutsideAlerter(dropdownRef, () => setOpen(false));

	const [query, setQuery] = useState("");
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [filteredData, setFilteredData] = useState(options);
	const [localOptions, setLocalOptions] = useState(options);

	// Effects
	useEffect(() => {
		if (open) {
			inputRef.current.focus();
			if (status) {
				getSelectedIndexFromStatus();
			}
		} else {
			setQuery("");
		}
	}, [open]);

	useEffect(() => {
		setLocalOptions(options);
		setFilteredData(options);
	}, [options]);

	useEffect(() => {
		setSelectedIndex(0);
		if (localOptions) {
			if (dropDownDataVariant === "assigned") {
				setFilteredData(
					localOptions.filter((option) => filterByQuery(option.value))
				);
			} else if (dropDownDataVariant === "template") {
				setFilteredData(
					localOptions.filter((option) => filterByQuery(option.title))
				);
			} else if (dropDownDataVariant === "account") {
				setFilteredData(
					localOptions.filter((option) =>
						filterByQuery(option.company_name)
					)
				);
			} else {
				setFilteredData(
					localOptions.filter((option) => filterByQuery(option))
				);
			}
		}
	}, [query]);

	// Filter Functions

	const getSelectedIndexFromStatus = () => {
		for (let i = 0; i < localOptions.length; i++) {
			if (localOptions[i].value.toLowerCase() === status.toLowerCase()) {
				setSelectedIndex(i);
				break;
			}
		}
	};

	const filterByQuery = (input) => {
		let queryLength = query.length;
		if (
			query
				.toLowerCase()
				.localeCompare(input.toLowerCase().slice(0, queryLength)) === 0
		) {
			return true;
		}
		let words = input.split(" ");
		for (let i = 0; i < words.length; i++) {
			if (words[i].toLowerCase().startsWith(query.toLowerCase())) {
				return true;
			}
		}
	};

	// Navigation

	const onDownArrow = () => {
		if (filteredData.length > 0) {
			if (selectedIndex === null) {
				setSelectedIndex(0);
			} else {
				let newIndex = selectedIndex + 1;
				setSelectedIndex(newIndex % filteredData.length);
			}
		}
	};

	const onUpArrow = () => {
		if (filteredData.length > 0) {
			if (selectedIndex === null || selectedIndex === 0) {
				setSelectedIndex(filteredData.length - 1);
			} else {
				setSelectedIndex((idx) => idx - 1);
			}
		}
	};

	const onEnter = () => {
		if (selectedIndex !== null && selectedIndex < filteredData.length) {
			if (dropDownDataVariant === "assigned") {
				onSelect(filteredData[selectedIndex].value);
			} else if (dropDownDataVariant === "template") {
				onSelect(filteredData[selectedIndex].title);
			} else if (dropDownDataVariant === "account") {
				onSelect(filteredData[selectedIndex].company_name);
			} else {
				onSelect(filteredData[selectedIndex]);
			}
			setOpen(false);
		}
	};

	// Hot Keys

	const onKeyDown = (e) => {
		if (e.key === "ArrowDown") {
			onDownArrow();
		} else if (e.key === "ArrowUp") {
			onUpArrow();
		} else if (e.key === "Enter") {
			onEnter();
			if (onPressEnter) onPressEnter(e);
		} else if (e.key === "Tab" && onPressTab) {
			onPressTab(e);
		}
	};

	return (
		<div id="dropdown-container">
			<div
				id="dropdown-header"
				className="flex flex-row justify-start items-center cursor-default"
			>
				<div id="title-section" className="pr-2 flex-none  ">
					{title ? (
						<>
							{variant === "handoffLanding" ? (
								<button
									onClick={() => {
										setOpen(true);
									}}
									className={
										"text-primaryText hover:text-secondaryText focus-within:text-secondaryText rounded cursor-pointer"
									}
								>
									<span
										className={
											"py-1 px-4 flex flex-row items-center"
										}
									>
										{icon && (
											<span className="flex flex-row justify-center w-4 mr-2">
												{icon}
											</span>
										)}
										<span className={"pr-2"}>{title}</span>
										<svg
											width="10"
											height="7"
											viewBox="0 0 10 7"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1 0.730764L5 6.26923L9 0.730764"
												stroke="#84888D"
												strokeWidth="1.45"
												strokeLinecap="round"
												strokeLinejoin="round"
											/>
										</svg>
									</span>
								</button>
							) : (
								<button
									onClick={() => {
										setOpen(true);
									}}
									className={
										"text-primaryText bg-light-bg-2 hover:bg-hovergray focus-within:bg-hovergray rounded cursor-pointer"
									}
								>
									<span className={"py-1 px-4"}>{title}</span>
								</button>
							)}
						</>
					) : (
						<button
							className="text-sm text-secondaryText cursor-pointer hover:text-userpurple focus-within:text-userpurple"
							onClick={() => {
								setOpen(true);
							}}
						>
							Click to enter field from Salesforce
						</button>
					)}
				</div>
			</div>

			{/* Dropdown body - Section that appears when header is clicked */}
			<div className="relative">
				{open && (
					<div
						id="dropdown-body"
						className="flex flex-col bg-white rounded-md absolute z-10 top-1 shadow pt-1 w-48"
						ref={dropdownRef}
					>
						<span className="flex flex-row justify-between items-center py-1 px-3 mb-1 w-full bg-white">
							{variant === "handoffLanding" ? (
								<input
									className="text-xs text-primaryText whitespace-nowrap outline-none flex-grow w-3/4 pr-1"
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									placeholder={placeholder}
									ref={inputRef}
									onKeyDown={onKeyDown}
								/>
							) : (
								<input
									className="text-xs text-primaryText whitespace-nowrap outline-none flex-grow w-3/4 pr-1"
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									placeholder={`Salesforce Data...`}
									ref={inputRef}
									onKeyDown={onKeyDown}
								/>
							)}
						</span>

						<div
							className="h-full w-full overflow-scroll overflow-x-hidden"
							style={{ maxHeight: "120px" }}
						>
							{filteredData &&
								filteredData.map((option, index) => (
									<div
										className={`flex flex-row items-center w-full py-1 px-3 cursor-pointer 
										${index === selectedIndex ? "bg-gray-100" : ""}
										${index === filteredData.length - 1 ? "mb-1" : ""}
										`}
										key={index}
										onClick={() => {
											onSelect(
												option?.company_name
													? option.company_name
													: option?.title
													? option.title
													: option?.value
													? option?.value
													: option
											);
											if (onChange) {
												option?.company_name
													? onChange(
															option.company_name,
															option.crm_id
													  )
													: option?.title
													? onChange(
															option.title,
															option.id
													  )
													: option?.value
													? onChange(
															option?.value,
															null,
															option.icon
													  )
													: onChange(option);
											}
											if (onPressEnter) onPressEnter();
											setOpen(false);
										}}
										onMouseEnter={() =>
											setSelectedIndex(index)
										}
									>
										{option.icon && (
											<span className="flex flex-row justify-center w-4">
												{option.icon}
											</span>
										)}
										<p className="text-gray-900 text-xs pl-2 whitespace-nowrap truncate">
											{option?.company_name
												? option.company_name
												: option?.title
												? option.title
												: option?.value
												? option.value
												: option}
										</p>
									</div>
								))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TemplateDropdown;

import { useEffect, useState } from "react";
import { BiChevronRight, BiChevronDown, BiPlus } from "react-icons/bi";

import { ModalContainer, CompletePhaseModal, HollowButton } from "../..";
import AddTaskPopup from "../../CtaComponents/Todo/AddTaskPopup";
import TodoItem from "./SimpleTodoItem";

const SimpleTodoList = ({
	title,
	items,

	onUpdateTodo,
	onCreateTodo,
}) => {
	const [expanded, setExpanded] = useState(true);
	const [creatingTodo, setCreatingTodo] = useState(false);
	const [completeModalOpen, setCompleteModalOpen] = useState(false);

	return (
		<div className="flex flex-col items-start w-full light-bg-2 rounded p-4 flex-none">
			<span className="flex flex-row items-center mb-1 h-6">
				<button
					className="flex flex-row items-center cursor-pointer mr-3"
					onClick={() => setExpanded((exp) => !exp)}
				>
					{expanded ? (
						<BiChevronDown size={22} />
					) : (
						<BiChevronRight size={22} />
					)}
					<p className={`text-sm ml-1 ${"text-black"}`}>{title}</p>
				</button>
			</span>
			{expanded && (
				<>
					{items?.map((item, index) => (
						<TodoItem
							key={item.id}
							todoId={item.id}
							title={item.name}
							description={item.body.description}
							completed={item.completed}
							date={item.due_date}
							onUpdateTodo={onUpdateTodo}
							index={index}
						/>
					))}
					<div className="pl-5 ml-0.5 mt-1">
						<div
							className="flex flew-row items-center text-gray-400 hover:text-gray-500 cursor-pointer"
							onClick={() => setCreatingTodo(true)}
						>
							<BiPlus size={20} />
							<p className="text-sm ml-2 font-normal">Add Task</p>
						</div>
						{creatingTodo && (
							<div className="relative -top-1 z-50">
								<AddTaskPopup
									isAssignable={false}
									setOpen={setCreatingTodo}
									onClickSave={onCreateTodo}
								/>
							</div>
						)}
					</div>
				</>
			)}
		</div>
	);
};

export default SimpleTodoList;

import { useRef } from "react";
import { NimblePicker } from "emoji-mart";
import data from "emoji-mart/data/apple.json";
import "emoji-mart/css/emoji-mart.css";

import { useOutsideAlerter } from "../hooks";

const defaultPickerStyle = {
	position: "absolute",
	top: "35px",
	right: "0px",
	zIndex: "1000",
};

const EmojiPicker = ({
	open,
	setOpen,
	onClick,
	chosenEmoji,
	style,
	buttonSize = 16,
	pickerStyle = defaultPickerStyle,
}) => {
	const pickerRef = useRef(null);
	useOutsideAlerter(pickerRef, () => {
		setOpen(false);
	});

	return (
		<span ref={pickerRef} style={style}>
			{open && (
				<NimblePicker
					data={data}
					onSelect={(emoji) => {
						onClick(emoji);
						setOpen(false);
					}}
					style={pickerStyle}
					emoji="bar_chart"
					emojiSize={22}
					perLine={7}
					showPreview={false}
					showSkinTones={false}
					title=""
				/>
			)}
			<span
				className="cursor-pointer hover:bg-hovergray rounded"
				style={{ fontSize: `${buttonSize}px` }}
				onClick={() => setOpen(true)}
			>
				{chosenEmoji}
			</span>
		</span>
	);
};

export default EmojiPicker;

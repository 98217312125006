import React, { useEffect, useRef, useState } from "react";

import { useOutsideAlerter } from "../../hooks";
import CommandIcon from "./CommandIcon";

const searchBarHeight = 50;
const headerSectionHeight = 30;
const resultsSectionHeight = 350;

const CommandResult = ({
	icon,
	title,
	subtitle,
	nps,
	time,
	keyCommand,
	active = false,
	onMouseEnter,
	onClick,
	cursorEnabled,
}) => {
	return (
		<div
			onMouseEnter={cursorEnabled ? onMouseEnter : null}
			onClick={cursorEnabled ? onClick : null}
			className={`flex flex-row items-center w-full p-2 cursor-pointer ${
				active && "bg-light-bg-1"
			}`}
		>
			<span className="flex flex-row items-center flex-grow h-full">
				{icon && (
					<div className="flex justify-center items-center h-full w-8">
						{icon}
					</div>
				)}
				<p className="body-regular-small pl-1">{title}</p>
				{subtitle && (
					<p className="text-sm text-gray-400 pl-2">{subtitle}</p>
				)}
			</span>
			{keyCommand &&
				keyCommand.split("").map((command, index) => {
					return (
						<React.Fragment key={index}>
							{index > 0 && (
								<p className="flex justify-center items-center h-5 text-xs text-black mx-2">
									then
								</p>
							)}
							<CommandIcon keyCommand={command} />
						</React.Fragment>
					);
				})}
			<span className="pr-1">{nps}</span>
			<span className="pr-1 text-gray-400 text-sm">{time}</span>
		</div>
	);
};

const CommandK = ({
	options,
	header,
	setOpen,
	variant,
	placeholder = "Type a command or search...",
	onEnterInput,
	customQuery = null,
	setCustomQuery,
}) => {
	let inputRef = useRef(null);
	let modalRef = useRef(null);

	const [query, setQuery] = useState("");
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [filteredData, setFilteredData] = useState(options);
	const [cursorEnabled, setCursorEnabled] = useState(false);

	let searchSectionHeight;
	let commandLineHeight;
	searchSectionHeight =
		variant === "cta"
			? searchBarHeight + headerSectionHeight
			: searchBarHeight;
	commandLineHeight = searchSectionHeight + resultsSectionHeight;

	useOutsideAlerter(modalRef, () => setOpen(false));

	// Effects

	useEffect(() => {
		// Timeout used to avoid key command used to open this modal also entering first letter
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 200);
	}, []);

	useEffect(() => {
		if (setCustomQuery) {
			setFilteredData(options);
		}
	}, [options]);

	useEffect(() => {
		if (variant !== "input") {
			let results = options.filter((option) => titleSearch(option.title));
			setFilteredData(results);
			if (results.length === 0) {
				setSelectedIndex(null);
			} else {
				setSelectedIndex(0);
			}
		}
	}, [query]);

	// Filter functions

	const titleSearch = (title) => {
		let queryLength = query.length;
		if (
			query
				.toLowerCase()
				.localeCompare(title.toLowerCase().slice(0, queryLength)) === 0
		) {
			return true;
		}
		let words = title.split(" ");
		for (let i = 0; i < words.length; i++) {
			if (words[i].toLowerCase().startsWith(query.toLowerCase())) {
				return true;
			}
		}
	};

	const handleChange = (e) => {
		if (setCustomQuery) {
			setCustomQuery(e.target.value);
		} else {
			setQuery(e.target.value);
		}
	};

	// Navigation

	const onDownArrow = () => {
		if (filteredData.length > 0) {
			if (selectedIndex === null) {
				setSelectedIndex(0);
			} else {
				let newIndex = selectedIndex + 1;
				setSelectedIndex(newIndex % filteredData.length);
			}
		}
	};

	const onUpArrow = () => {
		if (filteredData.length > 0) {
			if (selectedIndex === null || selectedIndex === 0) {
				setSelectedIndex(filteredData.length - 1);
			} else {
				setSelectedIndex((idx) => idx - 1);
			}
		}
	};

	const onEnter = () => {
		if (selectedIndex !== null && selectedIndex < filteredData.length) {
			filteredData[selectedIndex].command();
			setOpen(false);
		}
	};

	// Hot Keys

	const onKeyDown = (e) => {
		switch (e.key) {
			case "Escape":
				e.preventDefault();
				setOpen(false);
				break;
			case "ArrowUp":
				e.preventDefault();
				if (variant !== "input") {
					onUpArrow();
				}
				break;
			case "ArrowDown":
				e.preventDefault();
				if (variant !== "input") {
					onDownArrow();
				}
				break;
			case "Enter":
				e.preventDefault();
				if (variant === "input") {
					if (customQuery) {
						onEnterInput(customQuery);
						setOpen(false);
					} else if (query) {
						onEnterInput(query);
						setOpen(false);
					}
				} else if (selectedIndex !== null) {
					onEnter();
				}
				break;
			default:
				break;
		}
	};

	return (
		<div
			ref={modalRef}
			onMouseMove={() => setCursorEnabled(true)}
			id="command-line-body"
			className="rounded-xl  cloud-shadow border-gray-100  bg-white"
			style={{
				width: variant === "cta" ? "700px" : "700px",
				maxHeight: `${commandLineHeight}px`,
			}}
		>
			<div
				id="search-section"
				className="flex flex-col justify-evenly w-full border-b border-gray-100 py-2"
				style={{ height: `${searchSectionHeight}px` }}
			>
				{variant === "cta" && (
					<div
						className="flex flex-row items-center pl-4 w-full"
						style={{ height: `${headerSectionHeight}px` }}
					>
						<span className="flex justify-center items-center h-5 px-2 rounded-sm bg-light-bg-2 body-xxs ">
							{header}
						</span>
					</div>
				)}
				<input
					className="pl-4 w-full body-regular-large text-black focus:outline-none"
					value={setCustomQuery ? customQuery : query}
					onChange={handleChange}
					placeholder={placeholder}
					ref={inputRef}
					onKeyDown={onKeyDown}
				/>
			</div>
			<div
				id="results-section"
				className={`flex flex-col items-center w-full overflow-y-scroll ${
					filteredData && filteredData.length > 0 && "py-2"
				}`}
				style={{
					maxHeight: `${
						commandLineHeight - searchSectionHeight - 1
					}px`,
				}}
			>
				{filteredData &&
					filteredData.map((item, index) => (
						<React.Fragment key={index}>
							{item.type &&
								(index === 0 ||
									item.type !==
										filteredData[index - 1].type) && (
									<p className="flex flex-row justify-start items-center w-full h-5 text-xs text-gray-500 p-4">
										{item.type}
									</p>
								)}
							<CommandResult
								icon={item.icon}
								title={item.title}
								subtitle={item.subtitle}
								nps={item.nps}
								time={item.timestamp}
								keyCommand={item.keyCommand}
								active={index === selectedIndex}
								onMouseEnter={() => setSelectedIndex(index)}
								onClick={onEnter}
								cursorEnabled={cursorEnabled}
							/>
						</React.Fragment>
					))}
			</div>
		</div>
	);
};

export default CommandK;

import axios from "axios";
import { handleCaughtError } from "../utils";

import bounceIfTokenError from "./bounceIfTokenError";

const getCompanyUsers = async (company_id) => {
	try {
		const url = `/api/getCompanyUsers?company_id=${company_id}`;
		let res = await axios.get(url, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});

		return res.data.users;
	} catch (err) {
		handleCaughtError(err);
		bounceIfTokenError(err);
	}
};

export default getCompanyUsers;

import { useEffect, useRef, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { IoClose } from "react-icons/io5";
import draftToHtml from "draftjs-to-html";

import { SmallDropdown, UserIcon, CommandK } from "../..";
import { callWithModifiers, callWithoutModifiers } from "../../../utils";
import { icons, todoStatuses } from "../../../constants";
import {
	getRecipientCommands,
	getUserSnippetsCommands,
} from "../../../commands";
import ModalContainer from "../../Modals/ModalContainer";
import DraftModal from "../../Modals/DraftModal";
import TodoDatePicker from "./TodoDatePicker";
import { requestUpdate } from "../../../requests";

const TodoItem = ({
	todoId,
	status,
	title,
	action,
	description,
	date,
	assignee,
	userOptions,
	onUpdateTodo,
	creatingTodo = false,
	isCurrentTodo,
	companyUserData,
	userSnippets,
}) => {
	let titleInputRef = useRef(null);
	let bodyInputRef = useRef(null);

	const [draftModalOpen, setDraftModalOpen] = useState(
		isCurrentTodo && action?.action
	);
	const [assigneeMenuOpen, setAssigneeMenuOpen] = useState(false);
	const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);
	const [commandKOpen, setCommandKOpen] = useState(false);
	const [sentNotificationOpen, setSentNotificationOpen] = useState(false);
	const [snippetsOpen, setSnippetsOpen] = useState(false);

	const [localTitle, setLocalTitle] = useState(title);
	const [editingTitle, setEditingTitle] = useState(creatingTodo);
	const [localAssignee, setLocalAssignee] = useState(assignee);
	const [localDate, setLocalDate] = useState(date ? new Date(date) : null);
	const [todoDescription, setTodoDescription] = useState(description);
	const [todoStatus, setTodoStatus] = useState(status);

	const [draftRecipients, setDraftRecipients] = useState([]);
	const [selectedSnippet, setSelectedSnippet] = useState(null);

	const [currentAction, setCurrentAction] = useState(action);
	// Effects

	useEffect(() => {
		setLocalTitle(title);
		setEditingTitle(creatingTodo);
		setLocalAssignee(assignee);
		setLocalDate(date ? new Date(date) : null);
		setTodoDescription(description);
		setTodoStatus(status);
	}, [todoId]);

	useEffect(() => {
		setDraftModalOpen(isCurrentTodo && currentAction?.action);
	}, [isCurrentTodo]);

	useEffect(() => {
		if (editingTitle) {
			titleInputRef.current.focus();
		}
	}, [editingTitle]);

	useEffect(() => {
		if (
			todoStatus !== "Done" &&
			!draftModalOpen &&
			!currentAction?.action
		) {
			bodyInputRef.current.style.height = "0px";
			const scrollHeight = bodyInputRef.current.scrollHeight;
			bodyInputRef.current.style.height = scrollHeight + "px";
		}
		if (todoStatus === "Done" || currentAction?.sent) {
			setDraftModalOpen(false);
		}
	}, [todoDescription, todoStatus]);

	useEffect(() => {
		let actionBody = cloneDeep(action);

		// Recipients are (currently) mostly saved as objects with key/string pairs, but we need to store them as arrays of those objects
		if (actionBody.action && actionBody?.recipient) {
			// If currently string, make into array

			if (typeof actionBody?.recipient === "string") {
				setDraftRecipients([actionBody?.recipient]);
			} else if (typeof actionBody?.recipient === "object") {
				if (actionBody?.recipient.length === undefined) {
					setDraftRecipients([actionBody?.recipient]);
				} else {
					setDraftRecipients(actionBody?.recipient);
				}
			} else {
				setDraftRecipients([]);
			}
		}
	}, [action]);

	// Event Handlers
	const onPressCmdSemi = () => {
		setSnippetsOpen(true);
		setSelectedSnippet(null);
		//force the state change
	};

	const onKeyDownTitle = (e) => {
		if (e.key === "Enter") {
			callWithoutModifiers(e, () => titleInputRef.current.blur());
		}
	};

	const onKeyDownBody = (e) => {
		if (e.key === "Enter") {
			callWithModifiers(e, () => bodyInputRef.current.blur(), ["cmd"]);
		}
	};

	const onTitleBlur = () => {
		if (localTitle) {
			setEditingTitle(false);
			if (!creatingTodo) {
				onUpdateTodo(todoId, "name", localTitle);
			}
		} else {
			setLocalTitle(title);
		}
	};

	const onBodyBlur = () => {
		onUpdateTodo(todoId, "body", { description: todoDescription });
	};

	const onChangeSelectedDate = (date) => {
		setLocalDate(date);
		if (!creatingTodo) {
			onUpdateTodo(todoId, "due_date", date);
		}
	};

	const onChangeStatus = (status) => {
		setTodoStatus(status);
		onUpdateTodo(todoId, "status", status);
	};

	const onChangeAssignee = (user) => {
		onUpdateTodo(todoId, "assigned_to", user.email);
		setLocalAssignee(user);
	};

	const onAddRecipient = (user) => {
		let recipientsClone = cloneDeep(draftRecipients);
		let newRecipient = {
			email: user.email,
			type: user.role,
			name: `${user.first_name} ${user.last_name}`,
		};
		recipientsClone.push(newRecipient);
		setDraftRecipients(recipientsClone);
		let newAction = {
			...currentAction,
			recipient: recipientsClone,
		};

		onUpdateTodo(todoId, "take_action", newAction);
	};

	const onRemoveRecipient = (email) => {
		let recipientsClone = cloneDeep(draftRecipients);
		let emailIndex = recipientsClone.findIndex(
			(rec) => rec.email === email
		);
		if (emailIndex !== undefined) {
			recipientsClone.splice(emailIndex, 1);
		}
		setDraftRecipients(recipientsClone);
		let newAction = {
			...currentAction,
			recipient: recipientsClone,
		};

		onUpdateTodo(todoId, "take_action", newAction);
	};

	const onSaveEmail = (recipient, subject, body) => {
		let newAction = {
			...currentAction,
			body,
			subject,
			recipient,
		};
		setCurrentAction(newAction);
		onUpdateTodo(todoId, "take_action", newAction);
	};

	const onSendEmail = async (recipient, subject, body) => {
		// Send email
		let convertedBody = draftToHtml(body);
		await requestUpdate("/api/email/sendEmail", {
			body: convertedBody,
			recipient,
			subject,
		});
		// Mutate action sent status
		let newAction = {
			...currentAction,
			sent: true,
		};
		onUpdateTodo(todoId, "take_action", newAction);

		// Mutate todo status to done
		onChangeStatus("Done");

		// Open sent notification
		setDraftModalOpen(false);
		setSentNotificationOpen(true);
	};

	// Commands

	const recipientCommands = getRecipientCommands(
		companyUserData,
		onAddRecipient
	);

	const userSnippetsCommands = getUserSnippetsCommands(
		userSnippets,
		setSelectedSnippet
	);

	return (
		<div className="flex flex-row w-full">
			{/* If todo is not being created, show status dropdown */}
			{!creatingTodo && (
				<div id="todo-status-section" className="py-1.5">
					<SmallDropdown
						icon={todoStatus ? icons[todoStatus] : icons["Planned"]}
						options={todoStatuses}
						open={statusDropdownOpen}
						setOpen={setStatusDropdownOpen}
						onSelect={onChangeStatus}
						bodyStyle={{ left: "5px", top: "0px" }}
						style={{ top: "0px" }}
					/>
				</div>
			)}

			{/* Otherwise */}
			<div className="flex flex-col justify-center py-1.5 pr-5 w-full">
				{/* Top Row */}
				<div className="flex flex-row w-full justify-between mb-2">
					{/* Left Side of List Item */}
					<span className="flex flex-row justify-start">
						{creatingTodo && (
							<div style={{ height: "14px", width: "14px" }} />
						)}

						{/* Title */}
						{!editingTitle && (
							<p
								className="text-sm font-light hover:text-blue-400 cursor-pointer"
								onClick={() => setEditingTitle(true)}
							>
								{localTitle}
							</p>
						)}

						{/* Edit Title Input */}
						{editingTitle && (
							<input
								className="text-sm font-light whitespace-nowrap outline-none flex-grow h-5"
								value={localTitle}
								onChange={(e) => setLocalTitle(e.target.value)}
								placeholder="Task name..."
								ref={titleInputRef}
								onKeyDown={onKeyDownTitle}
								onBlur={onTitleBlur}
							/>
						)}

						{/* Email Button */}
						{!draftModalOpen &&
							currentAction?.action &&
							!editingTitle && (
								<p
									className="text-sm ml-2 text-gray-400 font-light cursor-pointer hover:text-blue-400"
									onClick={() => setDraftModalOpen(true)}
								>
									{currentAction?.sent
										? "View email 📧"
										: "Send email 📧"}
								</p>
							)}

						{/* Data Picker */}
						<span className="place-self-center">
							<TodoDatePicker
								selectedDate={localDate}
								setSelectedDate={onChangeSelectedDate}
							/>
						</span>
					</span>

					{/* Right Side of ListItem */}
					<span className="ml-3">
						{/* Assignee Dropdown */}
						<SmallDropdown
							header={
								<UserIcon
									user={
										localAssignee
											? localAssignee.iconLabel
											: null
									}
									onClick={setAssigneeMenuOpen}
									clickable
								/>
							}
							options={userOptions}
							setOpen={setAssigneeMenuOpen}
							open={assigneeMenuOpen}
							onSelect={onChangeAssignee}
							title="assignee"
							variant="assigned"
							className="h-5"
							bodyStyle={{ right: "0px", top: "0px" }}
						/>
					</span>
				</div>

				{/* Todo Description Box */}
				{!draftModalOpen && !currentAction?.action && (
					<span className="my-1 w-full shadow-none">
						<textarea
							className="text-xs flex-grow w-full rounded font-normal text-gray-900 bg-transparent"
							onChange={(e) => setTodoDescription(e.target.value)}
							onKeyDown={onKeyDownBody}
							placeholder={"Todo description..."}
							ref={bodyInputRef}
							rows={0}
							value={todoDescription}
							onBlur={onBodyBlur}
							style={{
								resize: "none",
								display: todoStatus == "Done" ? "none" : null,
							}}
						/>
					</span>
				)}

				{/* Email Section */}
				{draftModalOpen && (
					<DraftModal
						actionBody={currentAction}
						recipients={draftRecipients}
						setModalOpen={setDraftModalOpen}
						onClickSave={onSaveEmail}
						onClickSend={onSendEmail}
						sendButtonVisible
						floating={false}
						showHeader={false}
						saveButtonVisible={false}
						onAddRecipient={() => setCommandKOpen(true)}
						onRemoveRecipient={onRemoveRecipient}
						readOnly={currentAction?.sent}
						keyHandlerFunction={onPressCmdSemi}
						selectedSnippet={selectedSnippet}
					/>
				)}

				{/* Email Sent Confirmation Message */}
				{sentNotificationOpen && (
					<div className="flex flex-row flex-none justify-center items-center h-10 w-48 cloud-shadow rounded-md">
						<p className="text-brightgreen mr-1 text-xs">
							Email Successfully Sent
						</p>
						<IoClose
							className="text-gray-400 hover:text-gray-500 cursor-pointer"
							size={16}
							onClick={() => setSentNotificationOpen(false)}
						/>
					</div>
				)}

				<ModalContainer top="30%">
					{commandKOpen && (
						<CommandK
							options={recipientCommands}
							setOpen={setCommandKOpen}
							variant="account"
							placeholder="Choose a recipient"
						/>
					)}
				</ModalContainer>
				<ModalContainer top="40%">
					{snippetsOpen && (
						<CommandK
							options={userSnippetsCommands}
							setOpen={setSnippetsOpen}
							placeholder="Which snippet to insert?"
						/>
					)}
				</ModalContainer>
			</div>
		</div>
	);
};

export default TodoItem;

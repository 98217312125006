import useSWR from "swr";

import getFetcher from "./getFetcher";

const getEmails = () => {
	const { data, error } = useSWR("/api/GPTemails", getFetcher);

	return {
		data: data,
		loading: !error && !data,
		error: error,
	};
};

export default getEmails;

import React, { useEffect, useState, useRef } from "react";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { IoEnterOutline } from "react-icons/io5";
import { useHotkeys } from "react-hotkeys-hook";
import { BsArrow90DegLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import EmailSuggestion from "./emailSuggestion";
const EmailCC = ({
	sendTo,
	removeUserFromSend,
	addUserToSend,
	addCC,
	emailInput,
	userNameDatabase,
	didAddEmail,
	setDidAddEmail
}) => {
	const [emailCInput, setEmailInput] = useState([])
	const [matchArr, setMatchArr] = useState(null)
	
	// useEffect(()=>{
	// 	setEmailInput(emailInput.current.value)
	// 	console.log("EMAIL INPUT is", emailCInput)
	// }, [emailInput?.current?.value])
	const onKeyDownFunc = (e) => {
		let arr = []
		//This allows the user to submit via 'commmand + enter'. We use a map to store the keys pressed then check immediately. Might present a challenge if we have a number of key commands (not sure on the aing)
		e = e || event; // to deal with IE
		var inp = String.fromCharCode(e.keyCode);
		if(e.key === "Backspace"){
			emailCInput.pop()
			for(let i in emailCInput){
				arr.push(emailCInput[i])
			}
			setEmailInput(arr)
		
		} else if(/[a-zA-Z0-9-_ ]/.test(inp)) {
			emailCInput.push(e.key)
			for(let i in emailCInput){
				arr.push(emailCInput[i])
			}
			setEmailInput(arr)
			console.log(arr)
		}
		
		
		
	};
	useEffect(()=>{
		let arrOne = []
		if(emailCInput[0] !== undefined && emailCInput[0] !== ""){
			
			for(let i in userNameDatabase){
				let shouldAdd = true;
				for(let j in emailCInput){
					if(userNameDatabase[i].email.charAt(j).toString() === emailCInput[j].toString() && emailCInput[j].indexOf(userNameDatabase[i].email.toString())=== -1 && sendTo.indexOf(userNameDatabase[i].email.toString())===-1){
						
					} else {
						shouldAdd = false;
					}
							
				
				}
				if(shouldAdd){
					arrOne.push(userNameDatabase[i].email)
				}
				
			}
	
			setMatchArr(arrOne)
	
		} else {
			setMatchArr(null)
		}

	}, [emailCInput])
	useEffect(()=>{
		if(didAddEmail){
			console.log("Email added")
			setDidAddEmail(false)
			setEmailInput([])
			setMatchArr(null)
			console.log("MATCH ARR", matchArr)
		}
	}, [didAddEmail])
	return (
		<div className="flex flex-inline flex-wrap ">
			{/* Start loop to print out any CC'd users. This is determined by the SendTo state. Send to is used to track additions and subtractions. 
                            The outer div contains the following fields: 
                                    Replying To: 
                                    Cc
                                                */}
			{sendTo.map((index) => {
				return (
					<div className="bg-gray-200 px-2 bg-opacity-40 rounded-md text-gray-900  mr-2 text-sm my-auto flex py-2 hover:bg-gray-300 hover:bg-opacity-40 cursor-pointer duration-300">
						{index}
						<button
							onClick={() => {
								removeUserFromSend(index);
							}}
							className="pl-2 "
						>
							<AiOutlineClose
								size={14}
								className="text-red-900 md:text-l hover:text-xl hover:text-red-500 sm:text-sm duration-100"
							/>
						</button>
					</div>
				);
			})}
			{/* End loop to print CC'd Users */}

			{/* Input field for new email.  */}
			<div className="flex flex-inline bg-opacity-10 cursor-type mt-2 focus:outline-none border-b border-gray-50 pr-4 hover:animate-pulse focus:animate-none">
				<input
					ref={emailInput}
					onKeyDown={addCC}
					onKeyDown={onKeyDownFunc}
					placeholder="Add New Email"
					className="focus:outline-none text-gray-800 placeholder-gray-200 focus:animate-none "
				></input>
				<EmailSuggestion emailsToDisplay={matchArr} addUserToSend={addUserToSend} setDidAddEmail={setDidAddEmail}></EmailSuggestion>
				<IoEnterOutline
					size={25}
					className=" text-right cursor-pointer my-auto text-gray-200"
					onClick={() => {
						addUserToSend(emailInput.current.value);
					}}
				/>
			</div>
			{/* End input new Email field */}
		</div>
	);
};

export default EmailCC;

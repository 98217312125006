import { match } from "assert";
import React, { useEffect, useState } from "react";

const chatUserName = ({
	userData,
	userNameDatabase,
	commentData,
	id,
	isPrivate,
	setUserName,
	setUserEmail,
}) => {
	let userName = "loading";
	const [userId, setUserId] = useState("NA");
	const [displayEmail, setDisplayEmail] = useState(false);
	const [emailToDisplay, setEmailToDisplay] = useState("Loading...");
	const [userDataPiece, setUserData] = useState(null);
	const [bgColor, setBgColor] = useState("bg-white");
	function validateEmail(email) {
		// used to validate a user email
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	
	useEffect(() => {
		let match;
		if (userNameDatabase.length !== 0) {
			for (let i in commentData.ticket_comments.output.comments) {
				if (
					commentData.ticket_comments.output.comments[i].id === id ||
					validateEmail(
						commentData.ticket_comments.output.comments[i].id
					)
				) {
					match =
						commentData.ticket_comments.output.comments[i]
							.author_id;
				}
			}
			//c

			for (let i in userNameDatabase) {
				if (
					userNameDatabase[i].id === match ||
					userNameDatabase[i].email === match
				) {
					let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
					setUserName(userNameDatabase[i].name);
					setEmailToDisplay(userNameDatabase[i].email);

					setUserEmail(userNameDatabase[i].email);
					let initials =
						[...userNameDatabase[i].name.matchAll(rgx)] || [];

					initials = (
						(initials.shift()?.[1] || "") +
						(initials.pop()?.[1] || "")
					).toUpperCase();

					var c = initials.charCodeAt(0) * (8 * initials.length);

					var color;
					switch (true) {
						case c < 600:
							color = "bg-green-400";
							break;
						case c < 700:
							color = "bg-blue-400";
							break;
						case c < 800:
							color = "bg-yellow-500";
							break;
						case c < 900:
							color = "bg-black";
							break;
						case c < 1000:
							color = "bg-indigo-800";
							break;
						case c < 1200:
							color = "bg-red-600";
							break;
						case c < 1500:
							color = "bg-red-200";
							break;
						default:
							color = "bg-green-400";
					}
					setBgColor(color);

					setUserId(initials);
				}
			}
			// userName = userData.first_name.charAt(0) + userData.last_name.charAt(0)
		}
	}, [userNameDatabase, commentData]);

	return (
		<div
			className={bgColor + " w-6 rounded-full h-6 flex"}
			onMouseEnter={() => {
				setDisplayEmail(true);
			}}
			onMouseLeave={() => {
				setDisplayEmail(false);
			}}
		>
			{" "}
			<span className=" my-auto mx-auto text-sm text-white">
				{userId}
			</span>{" "}
		</div>
	);
};

export default chatUserName;

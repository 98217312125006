import React from "react";

import SellerantLogo from "../assets/Sellerant Logo.png";

const ErrorPage = ({ onClickReturn }) => {
	return (
		<div className="flex flex-col justify-center items-center w-full h-screen">
			<p className="">
				Oops! Something went wrong, we're now looking into it.
			</p>
			<img
				src={SellerantLogo}
				alt=""
				height={100}
				width={100}
				style={{ objectFit: "cover", pointerEvents: "none" }}
				className="my-7"
			/>
			<button
				className="px-5 rounded whitespace-nowrap h-8 text-white text-sm"
				style={{ backgroundColor: "#30C5BD" }}
				onClick={onClickReturn}
			>
				Return to Sellerant
			</button>
		</div>
	);
};

export default ErrorPage;

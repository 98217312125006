import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ViewBox from "./ViewBox";
import { useHotkeys } from "react-hotkeys-hook";
import { callWithoutModifiers } from "../../../utils";

function SubGrid(props) {
	const [selected, setSelected] = useState(props.selected);
	const [data, setData] = useState(props.data);

	if (!data) {
		return (
			<div>
				<p>No Data</p>
			</div>
		);
	}

	const onPressUpArrow = () => {
		if (data.indexOf(selected) == 0) {
			setSelected(data[data.length - 1]);
		} else {
			setSelected(data[data.indexOf(selected) - 1]);
		}
	};

	const onPressDownArrow = () => {
		if (data.indexOf(selected) == data.length - 1) {
			setSelected(data[0]);
		} else {
			setSelected(data[data.indexOf(selected) + 1]);
		}
	};

	//loading

	console.log(selected);

	if (!selected && data?.length > 0) {
		setSelected(data[0]);
	}
	const Grid = ({ select, setSelect }) => {
		useHotkeys("*", (event) => {
			switch (event.key) {
				case "ArrowUp":
					onPressUpArrow();
					break;
				case "ArrowDown":
					onPressDownArrow();
					break;
				default:
					break;
			}
		});

		return (
			<div className="relative  h-full overflow-hidden">
				<div className="flex flex-col border rounded-md shadow-sm w-1/2 pt-5 pb-20 ">
					<div className=" w-11/12 place-self-center ">
						{data.map((item) => (
							<div
								// hover:bg-blue-50 hover:bg-opacity-75 hover:rounded-md
								className={`grid grid-cols-4 py-2 pl-5 ${
									item === select
										? "bg-blue-50 bg-opacity-75 rounded-md"
										: ""
								}`}
								onMouseEnter={() => setSelect(item)}
							>
								<div className="col-span-3 ">
									<p className="font-light cursor-default">
										{item.name}
									</p>
								</div>
								<div className=" relative">
									<p className="font-light text-gray-400 right-7 absolute cursor-default">
										{item.date}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
				{/* outlook style view box */}
				<div className="grid grid-cols-8 w-6/12 pl-10 top-10 right-0 absolute ">
					<div className="col-span-6 rounded-lg cloud-shadow  ">
						<ViewBox
							data={selected}
							variant={props.variant}
						></ViewBox>
					</div>
				</div>
			</div>
		);
	};

	return <Grid select={selected} setSelect={setSelected}></Grid>;
}

export default SubGrid;

import { useEffect, useState, useRef } from "react";

const MessageTextBox = ({
	setBodyText,
	textAreaRef,
	replySize,
	bgColor,
	onKeyDown,
}) => {
	return (
		// Fairly self explanatory, this is the box that the user types in replies.
		// Rows is dynamically read by '\n' so that we can expand and shrink the text area.

		<div className="pt-2 pl-2 pb-2">
			<textarea
				id="replyinput"
				onChange={(e) => setBodyText(e.target.value)}
				onKeyDown={onKeyDown}
				ref={textAreaRef}
				name="Text1"
				cols="40"
				rows={replySize}
				placeholder="Type your reply here...."
				className={
					"bg-" +
					bgColor +
					" bg-opacity-5  duration-1000 mx-auto w-full flex flex-center pt-4 focus:outline-none outline-none font-sans pl-4"
				}
			></textarea>
		</div>
	);
};
export default MessageTextBox;

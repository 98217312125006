import React from "react";
import { withRouter } from "react-router-dom";
import Routes from "../../Routes";

const ProblemsMenu = withRouter(({ otherCtaData, ctaData, history }) => {
	const redirect = (id) => {
		history.push(`${Routes.CTA_BASE_ROUTE}/${id}/todo`);
	};

	const redirectSearch = () => {
		if (ctaData)
			history.push(
				`${Routes.SEARCH_PAGE}/account:${ctaData.company_name}`
			);
	};

	return (
		<div className="bg-sidebarmenugray flex rounded px-2 pt-2 pb-1">
			<div className="flex flex-col justify-start items-start h-full w-full mt-2 mb-2 content-start gap-y-1">
				<p className="ml-2 body-small-semibold-gray">Problems:</p>
				{otherCtaData &&
					otherCtaData
						.slice()
						.sort(
							(a, b) =>
								Date.parse(b.last_updated) -
								Date.parse(a.last_updated)
						)
						.map(
							(cta, index) =>
								index < 3 && (
									<div
										key={index}
										className=" w-11/12 cursor-pointer flex mr-1 h-5 rounded hover:bg-hovergray"
										onClick={() => {
											redirect(cta.cta_id);
										}}
									>
										<p
											className={`${
												ctaData.cta_id == cta.cta_id
													? "body-small-semibold-black"
													: "body-small-gray"
											} ml-2 self-center`}
										>
											{cta.cta ? cta.cta : "Unnamed CTA"}
										</p>
									</div>
								)
						)}
				{otherCtaData?.length > 3 && (
					<div
						className="ml-1 h-5 w-11/12 flex cursor-pointer mr-1 rounded hover:shadow hover:bg-hovergray"
						onClick={() => {
							redirectSearch();
						}}
					>
						<p className="ml-1 self-center  body-small-gray  ">
							View More
						</p>
					</div>
				)}
			</div>
		</div>
	);
});

export default ProblemsMenu;

import axios from "axios";
import { handleCaughtError } from "../utils";

import bounceIfTokenError from "./bounceIfTokenError";

const authUser = async (email) => {
	try {
		const address = email.split("@").pop();
		let res = await axios.get(`/api/sso/auth?domain=${address}`, {
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
		});
		window.location = res.data.endpoint;
	} catch (err) {
		handleCaughtError(err);
		bounceIfTokenError(err);
	}
};

export default authUser;

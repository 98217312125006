import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";

import MainGrid from "../components/AccountComponents/CtaListComponent/MainGrid";
import Overview from "../components/AccountComponents/Overview";
import People from "../components/AccountComponents/People/People";
import SubGrid from "../components/SharedComponents/SubGrid/SubGrid";
import CtaHeader from "../components/CtaComponents/Header";
import {
	Sidebar,
	NavBar,
	FillableSidebar,
	CommandK,
	ModalContainer,
	Notes,
} from "../components";
import {
	callWithModifiers,
	callWithoutModifiers,
	filterSplitData,
	generateCreatedAt,
	handleCaughtError,
} from "../utils";
import {
	getAccountCommands,
	getNavigationCommands,
	getPeopleCommands,
} from "../commands";
import { AccountSplitContext, SearchContext } from "../context";
import Routes from "../Routes";
import {
	getAllAccountData,
	getCompanyUsers,
	getCtaData,
	getEmails,
	getTags,
	getUserInfo,
	requestUpdate,
} from "../requests";

const useQuery = (location) => {
	return new URLSearchParams(location.search);
};

const createHeaderTabs = (companyId) => {
	return [
		{
			name: "Overview",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/overview`,
			page: "overview",
		},
		// {
		// 	name: "Account Profile",
		// 	url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/scorecard`,
		// 	page: "account",
		// },
		{
			name: "CTAs",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/ctas`,
			page: "ctas",
		},
		// {
		// 	name: "Usage",
		// 	url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/usage`,
		// 	page: "usage",
		// },
		{
			name: "Tickets",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/tickets`,
			page: "tickets",
		},
		{
			name: "Notes",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/notes`,
			page: "notes",
		},
		{
			name: "Jira",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/jira`,
			page: "jira",
		},
		{
			name: "People",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/people`,
			page: "people",
		},
		{
			name: "Timeline",
			url: `${Routes.ACCOUNT_BASE_ROUTE}/${companyId}/timeline`,
			page: "timeline",
		},
	];
};

const Account = withRouter(({ location, history, match }) => {
	const { goBackToSearch } = useContext(SearchContext);

	const {
		selectedSplit,
		setSelectedSplit,
		splitSettings,
		setSplitSettings,
	} = useContext(AccountSplitContext);

	const [accountIndex, setAccountIndex] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [currentAccountData, setCurrentAccountData] = useState(null);

	const [headerTabs, setHeaderTabs] = useState([]);
	const [ticketData, setTicketData] = useState([]);
	const [openCta, setOpenCta] = useState([]);
	const [companyUserData, setCompanyUserData] = useState([]);
	const [assignType, setAssignType] = useState(null);

	const [goBackToCta, setGoBackToCta] = useState(false);
	const [sourceCtaId, setSourceCtaId] = useState(null);

	const [commandKOpen, setCommandKOpen] = useState(false);
	const [peopleCommandsOpen, setPeopleCommandsOpen] = useState(false);

	let query = useQuery(location);

	// Data Hooks

	const {
		data: ctaData,
		isLoading: ctaDataLoading,
		isError: ctaDataError,
		mutateCta,
	} = getCtaData();

	const {
		data: tagData,
		isLoading: tagsLoading,
		isError: tagsError,
	} = getTags();

	const {
		data: accountData,
		isLoading: accountDataLoading,
		isError: accountDataError,
		mutate: mutateAccounts,
	} = getAllAccountData();

	const {
		data: gptEmailData,
		isLoading: gptEmailLoading,
		isError: gptEmailError,
	} = getEmails();

	const {
		data: userData,
		loading: userDataLoading,
		error: userDataError,
	} = getUserInfo();

	// Util function (Sort of - only used on this page so not worth putting in utils.js)

	const findCurrentAccount = (data) => {
		// Find Account with match ID
		for (let i = 0; i < data.data.length; i++) {
			if (data.data[i].company_id.toString() === match.params.cid) {
				let currentAccount = data.data[i];

				// Set state with basic account values
				setHeaderTabs(createHeaderTabs(currentAccount.company_id));
				setCurrentAccountData(currentAccount);
				setAccountIndex(i);

				// Set Tickets
				let tickets = currentAccount.support_tickets;
				if (tickets === null) {
					setTicketData([]);
				} else {
					setTicketData(JSON.parse(tickets.ticket_list));
				}
			}
		}
	};

	// Effects

	useEffect(() => {
		if (
			userData &&
			splitSettings &&
			accountData &&
			!accountDataLoading &&
			!accountDataError
		) {
			filterSplitData(
				userData,
				splitSettings,
				selectedSplit,
				accountData,
				setFilteredData,
				null,
				true,
				true,
				null
			);
		}
	}, [userData, splitSettings, accountData, selectedSplit]);

	useEffect(() => {
		let source = query.get("source");
		let cta = query.get("cta");
		if (source && source === "cta") {
			setGoBackToCta(true);
			setSourceCtaId(cta);
		}
	}, [location]);

	useEffect(() => {
		let foundInFiltered = false;
		if (selectedSplit && filteredData) {
			foundInFiltered = findCurrentAccount(filteredData);
		} else if (accountData && !foundInFiltered) {
			findCurrentAccount(accountData);
		}
	}, [accountData, filteredData, match.params.cid]);

	useEffect(() => {
		if (
			match.path === Routes.ACCOUNT_PAGE &&
			splitSettings === null &&
			userData
		) {
			let tempSplitSettings = userData?.settings.account_split;
			if (tempSplitSettings.length > 0) {
				setSplitSettings(tempSplitSettings);
				setSelectedSplit(tempSplitSettings[0].slug);
			}
		}
	}, [match, userData]);

	useEffect(() => {
		if (ctaData) {
			let openCtas = ctaData.data.filter(
				(cta) => cta.company_id.toString() === match.params.cid
			);
			setOpenCta(openCtas);
		}
	}, [ctaData, match.params.cid]);

	useEffect(() => {
		getCompanyUsers(match.params.cid).then((res) => {
			setCompanyUserData(res);
		});
	}, [match.params.cid]);

	// Overview Functions

	const onClickStage = async (stage) => {
		let newAccounts = cloneDeep(filteredData);
		newAccounts.data[accountIndex].stage = stage;
		try {
			mutate(newAccounts, false);
			await requestUpdate("/api/updateAccountStage", {
				company_id: currentAccountData.company_id,
				stage,
			});
			mutate();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	// People Functions

	const assign = async (person, type) => {
		let newUsers = cloneDeep(companyUserData);
		newUsers.forEach((dude) => {
			if (dude.id == person.id) {
				dude.role = type;
				try {
					mutate("/api/getCompanyUsers", newUsers, false);

					mutate(
						"/api/getCompanyUsers",
						requestUpdate("/api/updateRoles", {
							id: dude.id,
							role: dude.role,
						})
					);
				} catch (err) {
					handleCaughtError(err);
				}
			}
		});
		setCompanyUserData(newUsers);
	};

	// Note Functions

	const updateLocalAccounts = (companyId, attr, newValue) => {
		let allAccountsDataCopy = cloneDeep(accountData.data);
		for (let i = 0; i < allAccountsDataCopy.length; i++) {
			if (allAccountsDataCopy[i].company_id === companyId) {
				allAccountsDataCopy[i][attr] = newValue;
				return { data: allAccountsDataCopy };
			}
		}
		return null;
	};

	const mutateAccountValue = async (
		currentValue,
		setValue,
		newValue,
		newNoteBody,
		companyId,
		attr,
		endpoint,
		mutateWithNewValue = false
	) => {
		let previousValue = currentValue;
		if (previousValue === newValue) {
			return;
		}
		if (setValue) setValue(newValue);
		let newAccounts = updateLocalAccounts(
			companyId,
			attr,
			mutateWithNewValue ? newValue : newNoteBody
		);
		if (newAccounts === null) {
			return;
		}
		try {
			mutateAccounts(newAccounts, false);
			await requestUpdate(endpoint, {
				note_body: newNoteBody,
			});
			mutateAccounts();
		} catch (err) {
			handleCaughtError(err);
			if (setValue) setValue(newValue);
		}
	};

	const createNewNote = async () => {
		let newNoteId = uuidv4();
		let newNote = {
			id: newNoteId,
			company_id: currentAccountData.company_id,
			cta_id: null,
			title: "",
			emoji: "📝️",
			body: null,
			time_created: generateCreatedAt(),
		};
		let currentNotesCopy = cloneDeep(currentAccountData.notes);
		currentNotesCopy.push(newNote);
		mutateAccountValue(
			currentAccountData.notes,
			null,
			currentNotesCopy,
			newNote,
			currentAccountData.company_id,
			"notes",
			"/api/notes/createNote",
			true
		);
		history.push({
			pathname: `${Routes.NOTES_BASE_ROUTE}/${newNoteId}`,
			state: { note: newNote },
		});
	};

	const deleteNote = async (ctaId, companyId, noteId) => {
		// Remove from CTAs
		let allCtaDataCopy = cloneDeep(ctaData.data);
		for (let i = 0; i < allCtaDataCopy.length; i++) {
			if (allCtaDataCopy[i].cta_id === ctaId) {
				for (let j = 0; j < allCtaDataCopy[i].notes.length; j++) {
					if (allCtaDataCopy[i].notes[j].id === noteId) {
						allCtaDataCopy[i].notes.splice(j, 1);
					}
				}
			}
		}

		// Remove from accounts
		let allAccountsCopy = cloneDeep(accountData.data);
		for (let i = 0; i < allAccountsCopy.length; i++) {
			if (allAccountsCopy[i].company_id === companyId) {
				for (let j = 0; j < allAccountsCopy[i].notes.length; j++) {
					if (allAccountsCopy[i].notes[j].id === noteId) {
						allAccountsCopy[i].notes.splice(j, 1);
					}
				}
			}
		}

		// Mutate CTAs and Accounts
		mutateCta({ data: allCtaDataCopy }, false);
		mutateAccounts({ data: allAccountsCopy }, false);

		// Delete remotely
		await requestUpdate("/api/notes/deleteNote", { id: noteId });

		// Validate both CTAs and Accounts
		mutateCta();
		mutateAccounts();
	};

	// Navigation

	const onClickBack = () => {
		if (goBackToCta && sourceCtaId) {
			history.push(`${Routes.CTA_BASE_ROUTE}/${sourceCtaId}/todo`);
		} else if (goBackToSearch) {
			history.push(Routes.SEARCH_PAGE);
		} else {
			history.push(Routes.ALL_ACCOUNTS_PAGE);
		}
	};

	const onClickUp = () => {
		let selectedIndex;
		if (filteredData && accountIndex !== null) {
			if (accountIndex === 0) {
				selectedIndex = filteredData.length - 1;
			} else {
				selectedIndex = accountIndex - 1;
			}
			history.push(
				`${Routes.ACCOUNT_BASE_ROUTE}/${filteredData.data[selectedIndex].company_id}/overview`
			);
		}
	};

	const onClickDown = () => {
		let selectedIndex;
		if (filteredData && accountIndex !== null) {
			if (accountIndex === filteredData.length - 1) {
				selectedIndex = 0;
			} else {
				selectedIndex = accountIndex + 1;
			}
			history.push(
				`${Routes.ACCOUNT_BASE_ROUTE}/${filteredData.data[selectedIndex].company_id}/overview`
			);
		}
	};

	// Key Commands

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Escape":
					callWithoutModifiers(event, () => onClickBack());
					break;
				case "k":
					callWithoutModifiers(event, () => onClickUp());
					callWithModifiers(event, () => setCommandKOpen(true), [
						"cmd",
					]);
					break;
				case "j":
					callWithoutModifiers(event, () => onClickDown());
					break;
				case "n":
					callWithoutModifiers(event, () => createNewNote());
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[match.params.cid]
	);

	// Commands

	const accountCommands = getAccountCommands(createNewNote);
	const navigationCommands = getNavigationCommands(history);
	const peopleCommands = getPeopleCommands(
		companyUserData,
		assign,
		assignType
	);

	if (accountDataError) return <div>failed to load</div>;

	if (!currentAccountData || accountDataLoading) {
		return (
			<div className="flex flex-row h-screen">
				<NavBar
					onClickBack={onClickBack}
					onClickDown={onClickDown}
					onClickUp={onClickUp}
					verticalArrowsVisible={false}
				/>
				<div className="flex-grow h-screen" />
				<FillableSidebar />
			</div>
		);
	}

	return (
		<div className="flex flex-row h-screen">
			<NavBar
				onClickBack={onClickBack}
				onClickDown={onClickDown}
				onClickUp={onClickUp}
				backIsCloseButton={goBackToCta}
				verticalArrowsVisible={!goBackToCta}
			/>
			<div className="flex flex-col flex-grow h-screen">
				<CtaHeader
					tabs={headerTabs}
					logo={currentAccountData?.company_clearbit_info?.logo}
					title={currentAccountData.company_name}
					subtitle={currentAccountData.cta}
					type={currentAccountData?.type}
					grade={currentAccountData.grade}
					activePage={match.params.page}
					stage={currentAccountData.stage}
					variant="scorecard"
				/>

				{/* Sub-page Section */}
				<div className="flex-grow mb-4 mr-6 overflow-hidden">
					<Switch>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/overview`}
							render={(props) => (
								<Overview
									data={currentAccountData}
									onClickStage={onClickStage}
									{...props}
								/>
							)}
						/>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/ctas`}
							render={(props) => (
								<MainGrid data={openCta} {...props} />
							)}
						/>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/tickets`}
							render={(props) => (
								<SubGrid
									data={[
										{
											ticket_id: 1,
											name:
												"This is what a ticket looks like",
											date: "10/10/10",
											description:
												"This is a placeholder ticket",
										},
									]}
									variant="tickets"
									{...props}
								/>
							)}
						/>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/notes`}
							render={(props) => (
								<Notes
									onClickNewNote={createNewNote}
									onClickDeleteNote={deleteNote}
									notes={currentAccountData?.notes || []}
									{...props}
								/>
							)}
						/>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/jira`}
							render={(props) => (
								<SubGrid
									data={[
										{
											id: 1010,
											name:
												"This is what a ticket looks like",
											date: "10/10/10",
											description:
												"Sample description Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore",
										},
									]}
									variant="jira"
									{...props}
								/>
							)}
						/>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/people`}
							render={(props) => (
								<People
									userData={companyUserData}
									accountData={currentAccountData?.company_id}
									currentAccountData={currentAccountData}
									tags={tagData}
									setCommandKOpen={setPeopleCommandsOpen}
									setAssignType={setAssignType}
									assign={assign}
									gptEmails={gptEmailData}
									{...props}
								/>
							)}
						/>
						<Route
							path={`${Routes.ACCOUNT_BASE_ROUTE}/:cid/timeline`}
							render={(props) => (
								<SubGrid
									data={currentAccountData?.timeline?.notes}
									variant="timeline"
									{...props}
								/>
							)}
						/>
					</Switch>
				</div>
			</div>

			{/* Sidebar */}
			<Sidebar ctaData={currentAccountData} variant="account" />

			{/* Command K */}
			<ModalContainer top="30%">
				{commandKOpen && (
					<CommandK
						options={accountCommands.concat(navigationCommands)}
						setOpen={setCommandKOpen}
					/>
				)}
				{peopleCommandsOpen && match.params.page === "people" && (
					<CommandK
						options={peopleCommands}
						setOpen={setPeopleCommandsOpen}
					/>
				)}
			</ModalContainer>
		</div>
	);
});

export default Account;

import { BsPencil } from "react-icons/bs";
import { BiPlus, BiTrash } from "react-icons/bi";
import { GoSearch } from "react-icons/go";

const HeaderButton = ({ onClick, title, icon }) => (
	<button
		className="flex justify-center items-center text-gray-500 h-10 w-10 rounded-full hover:bg-gray-100"
		onClick={onClick}
		title={title}
	>
		{icon}
	</button>
);

const PageHeader = ({
	title,
	annotation,
	onClickEdit,
	editButtonTitle = "",
	onClickAdd,
	addButtonTitle = "",
	onClickTrash,
	trashButtonTitle = "",
	onClickSearch,
	searchButtonTitle = "",
	splits,
	setSelectedSplit,
	selectedSplit,
}) => {
	return (
		<div className="flex flex-row flex-grow justify-between items-center pr-3 h-10">
			{/* Page Title */}
			{title && (
				<div className="flex flex-row justify-start items-center flex-grow h-full">
					<p className="font-sans text-xl font-semi-bold pr-2 truncate select-none">
						{title}
					</p>
					{annotation && (
						<span className="font-light text-sm text-gray-500 truncate select-none">
							{annotation}
						</span>
					)}
				</div>
			)}

			{/* Split Titles */}
			{!title && (
				<div className="flex flex-row items-center">
					{splits?.map((item, index) => (
						<button
							key={index}
							onClick={() => setSelectedSplit(item.slug)}
							className={`focus:outline-none font-sans pr-2 text-lg font-semibold truncate select-none ${
								item.slug !== selectedSplit &&
								"text-gray-500 text-opacity-60"
							} `}
						>
							{item.title}

							<span
								className={`font-light text-xs text-gray-500  pl-1.5 leading-10 pr-3 truncate select-none ${
									item.slug !== selectedSplit &&
									"text-opacity-60"
								}`}
							>
								{item.length}
							</span>
						</button>
					))}
				</div>
			)}

			{/* Header Buttons */}
			<span className="flex flex-row">
				{onClickAdd && (
					<HeaderButton
						onClick={onClickAdd}
						title={addButtonTitle}
						icon={<BiPlus size={24} />}
					/>
				)}
				{onClickEdit && (
					<HeaderButton
						onClick={onClickEdit}
						title={editButtonTitle}
						icon={<BsPencil size={18} />}
					/>
				)}
				{onClickSearch && (
					<HeaderButton
						onClick={onClickSearch}
						title={searchButtonTitle}
						icon={
							<GoSearch
								size={18}
								style={{
									transform: "rotate(90deg)",
									marginBottom: "2px",
								}}
							/>
						}
					/>
				)}
				{onClickTrash && (
					<HeaderButton
						onClick={onClickTrash}
						title={trashButtonTitle}
						icon={<BiTrash size={20} />}
					/>
				)}
			</span>
		</div>
	);
};

export default PageHeader;

import {useEffect, useState} from "react"


const EmailSuggestion =({
    emailsToDisplay,
    addUserToSend,
    setDidAddEmail,

})=>{

    return emailsToDisplay ? (
        <div className="relative">
        <div className= "bg-white absolute py-2 top-8 right-2 border border-gray-100 rounded-md shadow-xl">
            {emailsToDisplay.map((index)=>{
                return (
                    <div className="hover:bg-gray-100 cursor-pointer px-4" onClick={(()=>{addUserToSend(index.toString()); setDidAddEmail([])})}>{index}</div>
                )
            })}
        </div>
        </div>
    ) : (
       null
    )

}
export default EmailSuggestion;
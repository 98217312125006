import React, { useEffect, useState } from "react";

import ChatBox from "./chatBox";

import submitZendeskComment from "../data/ZendeskFunctions/submitZendeskComment";

const MainGrid = ({
	data,
	userData,
	userNameDatabase,
	mutateTicketData
}) => {
	const [CCId, setCCId] = useState([""]);
	const updateCommentData = (
		body,
		isPublic,
		ticketId,
		recipients,
		removeFromSend,
		oldEmailCCs,
		author_id,
		userEmail
	) => {
		submitZendeskComment(
			body,
			isPublic,
			ticketId,
			recipients,
			removeFromSend,
			oldEmailCCs,
			author_id,
			userEmail
		);
	};

	function submitComment(
		body,
		isPublic,
		recipients,
		removeFromSend,
		oldEmailCCs
	) {
		//this is used to filter and clean data passed to submitComment
		var today = new Date();

		const author_id = userData.zendesk_id; //this is stored in their profile in supabase

		let newComment = {
			//This is the sample comment appended into our curr data before mutate
			id: Math.round(Math.random() * 1000000),
			body: body,
			author_id: userData.email,
			public: isPublic,
			created_at: today,
			via: {
				source: {
					to: {
						email_ccs: recipients,
					},
				},
			},
		};

		const dat = JSON.parse(JSON.stringify(data));
		dat.ticket_comments.output.comments.push(newComment);

		
		//YOU HAVE TO PASS THE REMOVEFROMSEND
		updateCommentData(
			body,
			isPublic,
			data.ticket_id,
			recipients,
			removeFromSend,
			oldEmailCCs,
			author_id,
			userData.email
		);
		mutateTicketData(dat)
	}

	return data && userNameDatabase ? (
		<>
			<ChatBox
				data={data}
				userData={userData}
				submitComment={submitComment}
				userNameDatabase={userNameDatabase}
			></ChatBox>
		</>
	) : (
		<>
			<div>loading...</div>
		</>
	);
};

export default MainGrid;

import { useRef } from "react";

import { useOutsideAlerter } from "../../hooks";

const SimpleDropdown = ({ button, options, setOpen, open }) => {
	let dropdownRef = useRef(null);

	useOutsideAlerter(dropdownRef, () => setOpen(false));

	return (
		<div ref={dropdownRef}>
			<div
				className="flex justify-center items-center h-8 w-8 rounded-full hover:bg-hovergray cursor-pointer"
				onClick={() => setOpen(true)}
			>
				{button}
			</div>
			<div className="relative">
				{open && (
					<div className="absolute top-0 right-0 p-2 cloud-shadow rounded-lg bg-white">
						{options &&
							options.map((option, index) => (
								<div
									onClick={() => {
										option.onClick();
										setOpen(false);
									}}
									key={index}
									className="flex flex-row hover:bg-hovergray rounded cursor-pointer body-s px-2 py-2"
								>
									<div className="flex flex-row justify-start items-center h-full w-5 mr-1 flex-none">
										{option.icon}
									</div>
									<p className="flex flex-row justify-start items-center h-full flex-grow truncate">
										{option.label}
									</p>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

export default SimpleDropdown;

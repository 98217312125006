import React, { useEffect, useRef, useState } from "react";
import { useParams, withRouter } from "react-router-dom";
import { AiOutlineEllipsis, AiOutlineLink } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { useHotkeys } from "react-hotkeys-hook";
import cloneDeep from "lodash.clonedeep";

import {
	EmojiPicker,
	NavBar,
	RichTextEditor,
	Sidebar,
	SmallDropdown,
	SidebarCheckboxMenu,
	SimpleDropdown,
	ModalContainer,
	ConfirmModal,
	FillableSidebar,
	FadingModal,
} from "../components";
import {
	getAllAccountData,
	getCtaData,
	getSellerantUsers,
	requestPost,
	requestUpdate,
} from "../requests";
import {
	callWithoutModifiers,
	getIconOptionsFromUsers,
	handleCaughtError,
} from "../utils";
import Routes from "../Routes";
import { ReactComponent as SalesforceIcon } from "../assets/salesforce.svg";

const Note = withRouter(({ history, location }) => {
	// useRef/useState

	let titleRef = useRef(null);

	const [initialCtaSet, setInitialCtaSet] = useState(false);
	const [initialAccountSet, setInitialAccountSet] = useState(false);
	const [initialBodySet, setInitialBodySet] = useState(false);

	const [title, setTitle] = useState("");
	const [emoji, setEmoji] = useState("");
	const [account, setAccount] = useState(null);
	const [companyId, setCompanyId] = useState(null);
	const [cta, setCta] = useState(null);
	const [ctaId, setCtaId] = useState(null);
	const [body, setBody] = useState(null);
	const [timeCreated, setTimeCreated] = useState(null);
	const [attendees, setAttendees] = useState([]);
	const [attendeeIds, setAttendeeIds] = useState([]);

	const [accountOptions, setAccountOptions] = useState([]);
	const [ctaOptions, setCtaOptions] = useState([]);
	const [userOptions, setUserOptions] = useState([]);

	const [emojiPickerOpen, setEmojiPickerOpen] = useState(null);
	const [accountDropdownOpen, setAccountDropdownOpen] = useState(false);
	const [ctaDropdownOpen, setCtaDropdownOpen] = useState(false);
	const [attendeesDropdownOpen, setAttendeesDropdownOpen] = useState(false);
	const [actionsDropdownOpen, setActionsDropdownOpen] = useState(null);
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);
	const [copiedUrlModalOpen, setCopiedUrlModalOpen] = useState(false);

	const [noteLoading, setNoteLoading] = useState(true);

	// Custom/Package hooks

	let { id } = useParams();

	// SWR Data Hooks

	const {
		data: ctaData,
		isLoading: ctaLoading,
		isError: ctaError,
		mutateCta,
	} = getCtaData();

	const {
		data: accountsData,
		isLoading: accountsLoading,
		isError: accountsError,
		mutate: mutateAccount,
	} = getAllAccountData();

	const {
		data: usersData,
		loading: usersLoading,
		error: usersError,
	} = getSellerantUsers();

	// Constants (only ones that require dynamic values to calculate)

	const actionOptions = [
		{
			icon: <AiOutlineLink size={16} className="body-md-gray" />,
			label: "Copy Link",
			onClick() {
				onClickCopyUrl();
				setCopiedUrlModalOpen(true);
			},
		},
		{
			icon: <BiTrash size={16} className="body-md-gray" />,
			label: "Delete",
			onClick() {
				setConfirmModalOpen(true);
			},
		},
	];

	// TODO: Back button changes after CTA change/account change

	// TODO: CTA Sidebar instead of account sidebar

	// useEffect

	useEffect(() => {
		const init = async () => {
			if (location?.state?.note) {
				setTitle(location.state.note.title);
				setEmoji(location.state.note.emoji);
				setBody(location.state.note.body);
				setTimeCreated(location.state.note.time_created);
				setCompanyId(location.state.note.company_id);
				setCtaId(location.state.note.cta_id);
				setAttendeeIds(location.state.note.attendees);
				setInitialBodySet(true);
				setNoteLoading(false);
			} else {
				let noteData = await requestPost("/api/notes/fetchNote", {
					id,
				});
				if (noteData) {
					setTitle(noteData.title);
					setEmoji(noteData.emoji);
					setBody(noteData.body);
					setTimeCreated(noteData.time_created);
					setCompanyId(noteData.company_id);
					setCtaId(noteData.cta_id);
					setAttendeeIds(noteData.attendees);
					setInitialBodySet(true);
					setNoteLoading(false);
				} else {
					history.push(Routes.ACTION_ITEMS_PAGE);
				}
			}
		};
		init();
	}, []);

	useEffect(() => {
		if (!noteLoading && !title && titleRef.current) {
			titleRef.current.focus();
		}
	}, [noteLoading]);

	useEffect(() => {
		if (usersData && !usersLoading && !usersError) {
			let users = getIconOptionsFromUsers(usersData.data);
			setUserOptions(users);
			if (attendeeIds) {
				let newAttendees = createAttendeesFromIds(users, attendeeIds);
				setAttendees(newAttendees);
			}
		}
	}, [usersData, attendeeIds]);

	useEffect(() => {
		if (accountsData) {
			if (companyId && !initialAccountSet) {
				let selectedAccount = accountsData.data.find(
					(company) => company.company_id === companyId
				);
				if (selectedAccount) setAccount(selectedAccount);
				setInitialAccountSet(true);
			}
			let accounts = accountsData.data.map((item) => ({
				value: item.company_name,
				company: item,
			}));
			setAccountOptions(accounts);
		}
	}, [accountsData, companyId]);

	useEffect(() => {
		if (ctaData) {
			if (ctaId && !initialCtaSet) {
				let selectedCta = ctaData.data.find(
					(cta) => cta.cta_id === ctaId
				);
				if (selectedCta) setCta(selectedCta);
				setInitialCtaSet(true);
			}
			if (account) {
				let ctas = ctaData.data
					.filter((item) => item.company_id === account.company_id)
					.map((item) => ({
						value: item.cta,
						cta: item,
					}));
				ctas.unshift({
					value: "No CTA",
					cta: null,
				});
				setCtaOptions(ctas);
			}
		}
	}, [ctaData, ctaId]);

	useEffect(() => {
		if (account && ctaData) {
			let ctas = ctaData.data
				.filter((item) => item.company_id === account.company_id)
				.map((item) => ({
					value: item.cta,
					cta: item,
				}));
			ctas.unshift({
				value: "No CTA",
				cta: null,
			});
			setCtaOptions(ctas);
			if (cta) {
				let ctaFound = ctas.find(
					(item) => item.cta?.cta_id === cta.cta_id
				);
				if (ctaFound === undefined) {
					mutateNoteValue(cta, setCta, null, null, null, "cta_id");
				}
			}
		}
	}, [account]);

	// Request/Mutation Functions

	const createAttendeesFromIds = (users, ids) => {
		return users.filter((user) => ids.includes(user.userId));
	};

	const updateLocalCtas = (attr, newValue) => {
		// If moving note to new CTA
		if (attr === "cta_id" && newValue !== null) {
			if (cta === null) {
				return addCta(newValue);
			} else {
				return moveBetweenCtas(cta.cta_id, newValue);
			}
		}

		// If changing any other attribute
		if (!cta || !ctaData.data) {
			return null;
		}
		let allCtaDataCopy = cloneDeep(ctaData.data);
		for (let i = 0; i < allCtaDataCopy.length; i++) {
			if (allCtaDataCopy[i].cta_id === cta.cta_id) {
				for (let j = 0; j < allCtaDataCopy[i].notes.length; j++) {
					if (allCtaDataCopy[i].notes[j].id === id) {
						// If removing cta, splice out of array
						if (attr === "cta_id" && newValue === null) {
							allCtaDataCopy[i].notes.splice(j, 1);
							// Otherwise, just change the attribute
						} else {
							allCtaDataCopy[i].notes[j][attr] = newValue;
						}
						return { data: allCtaDataCopy };
					}
				}
			}
		}
		return null;
	};

	const updateLocalAccounts = (attr, newValue) => {
		// If moving note to new account
		if (!account) return;
		if (attr === "company_id") {
			return moveBetweenCompanies(account.company_id, newValue);
		}

		// If changing any other attribute
		if (!accountsData.data) {
			return;
		}
		let allAccountsCopy = cloneDeep(accountsData.data);
		for (let i = 0; i < allAccountsCopy.length; i++) {
			if (allAccountsCopy[i].company_id === account.company_id) {
				for (let j = 0; j < allAccountsCopy[i].notes.length; j++) {
					if (allAccountsCopy[i].notes[j].id === id) {
						allAccountsCopy[i].notes[j][attr] = newValue;
						return { data: allAccountsCopy };
					}
				}
			}
		}
		return null;
	};

	const addCta = (ctaId) => {
		// For adding cta to note, but only when CTA is currently null
		let newNote = {
			id,
			company_id: account.company_id,
			cta_id: ctaId,
			title,
			emoji,
			body,
			time_created: timeCreated,
		};

		// Find index of new cta
		let allCtaDataCopy = cloneDeep(ctaData.data);
		let newCtaIndex = allCtaDataCopy.findIndex(
			(cta) => cta.cta_id === ctaId
		);

		// Push to notes and return new list
		allCtaDataCopy[newCtaIndex].notes.push(newNote);
		return { data: allCtaDataCopy };
	};

	const moveBetweenCtas = (oldCtaId, newCtaId) => {
		// For moving note between CTAs
		let allCtaDataCopy = cloneDeep(ctaData.data);

		// Find index of oldCta
		let oldCtaIndex = allCtaDataCopy.findIndex(
			(cta) => cta.cta_id === oldCtaId
		);

		// Find index of note within that cta
		let noteIndex = allCtaDataCopy[oldCtaIndex].notes.findIndex(
			(note) => note.id === id
		);

		// Copy that note and splice it out of oldCta
		let noteCopy = cloneDeep(allCtaDataCopy[oldCtaIndex].notes[noteIndex]);
		allCtaDataCopy[oldCtaIndex].notes.splice(noteIndex, 1);

		// Find index of newCta
		let newCtaIndex = allCtaDataCopy.findIndex(
			(cta) => cta.cta_id === newCtaId
		);

		// Update note and push to end of newCta notes attribute
		allCtaDataCopy[newCtaIndex].notes.push(noteCopy);
		return { data: allCtaDataCopy };
	};

	const moveBetweenCompanies = (oldAccountId, newAccountId) => {
		// For moving note between accounts
		let allAccountsCopy = cloneDeep(accountsData.data);

		// Find index of oldAccount
		let oldAccountIndex = allAccountsCopy.findIndex(
			(company) => company.company_id === oldAccountId
		);

		// Find index of note within that account
		let noteIndex = allAccountsCopy[oldAccountIndex].notes.findIndex(
			(note) => note.id === id
		);

		// Copy that note and splice it out of oldAccount
		let noteCopy = cloneDeep(
			allAccountsCopy[oldAccountIndex].notes[noteIndex]
		);
		allAccountsCopy[oldAccountIndex].notes.splice(noteIndex, 1);

		// Find index of newAccount
		let newAccountIndex = allAccountsCopy.findIndex(
			(company) => company.company_id === newAccountId
		);

		// Update note and push to end of newAccount notes attribute
		allAccountsCopy[newAccountIndex].notes.push(noteCopy);
		return { data: allAccountsCopy };
	};

	const mutateNoteValue = async (
		currentState,
		setState,
		newState,
		newValue,
		newNoteValue,
		attr
	) => {
		let previousState = currentState;
		if (previousState === newState) {
			return;
		}
		if (setState) setState(newState);
		let newCtas = updateLocalCtas(attr, newValue);
		let newAccounts = updateLocalAccounts(attr, newValue);
		try {
			if (newCtas) mutateCta(newCtas, false);
			if (newAccounts) mutateAccount(newAccounts, false);
			await requestUpdate("/api/notes/updateNote", {
				note_id: id,
				attr,
				value: newNoteValue,
			});
			if (newCtas) mutateCta();
			if (newAccounts) mutateAccount();
		} catch (err) {
			handleCaughtError(err);
			if (setState) setState(previousValue);
		}
	};

	// Event Handler Functions

	const onClickCopyUrl = () => {
		// Super hacky way of copying current URL
		const el = document.createElement("input");
		el.value = window.location.href;
		document.body.appendChild(el);
		el.select();
		document.execCommand("copy");
		document.body.removeChild(el);
	};

	const onBlurTitle = () => {
		mutateNoteValue(null, setTitle, title, title, title, "title");
	};

	const onChangeAccount = (item) => {
		mutateNoteValue(
			account,
			setAccount,
			item.company,
			item.company.company_id,
			item.company.company_id,
			"company_id"
		);
	};

	const onChangeCta = (item) => {
		if (item.cta) {
			mutateNoteValue(
				cta,
				setCta,
				item.cta,
				item.cta.cta_id,
				item.cta.cta_id,
				"cta_id"
			);
		} else {
			mutateNoteValue(cta, setCta, null, null, null, "cta_id");
		}
	};

	const onChangeEmoji = (emoji) => {
		mutateNoteValue(
			emoji,
			setEmoji,
			emoji.native,
			emoji.native,
			emoji.native,
			"emoji"
		);
	};

	const onChangeBody = () => {
		mutateNoteValue(body, null, null, body, body, "body");
	};

	const onKeyDownTitle = (e) => {
		if (e.key === "Enter" || e.key === "Escape") {
			titleRef.current.blur();
		}
	};

	const onClickBack = () => {
		history.goBack();
	};

	const onToggleAttendee = (attendee) => {
		let currentAttendeeIds = attendees.map((user) => user.userId);
		let userIndex = currentAttendeeIds.findIndex(
			(id) => id === attendee.userId
		);
		if (userIndex === -1) {
			// Add user to attendees
			currentAttendeeIds.push(attendee.userId);
		} else {
			// Remove user from attendees
			currentAttendeeIds.splice(userIndex, 1);
		}

		// Rebuild attendees list and set local state
		let newAttendees = createAttendeesFromIds(
			userOptions,
			currentAttendeeIds
		);

		// Mutate attendees list
		mutateNoteValue(
			attendees,
			setAttendees,
			newAttendees,
			currentAttendeeIds,
			currentAttendeeIds,
			"attendees"
		);
	};

	const onDeleteNote = async () => {
		// Remove from CTAs
		if (cta) {
			let allCtaDataCopy = cloneDeep(ctaData.data);
			for (let i = 0; i < allCtaDataCopy.length; i++) {
				if (allCtaDataCopy[i].cta_id === cta.cta_id) {
					for (let j = 0; j < allCtaDataCopy[i].notes.length; j++) {
						if (allCtaDataCopy[i].notes[j].id === id) {
							allCtaDataCopy[i].notes.splice(j, 1);
						}
					}
				}
			}
			mutateCta({ data: allCtaDataCopy }, false);
		}

		// Remove from accounts
		if (account) {
			let allAccountsCopy = cloneDeep(accountsData.data);
			for (let i = 0; i < allAccountsCopy.length; i++) {
				if (allAccountsCopy[i].company_id === account.company_id) {
					for (let j = 0; j < allAccountsCopy[i].notes.length; j++) {
						if (allAccountsCopy[i].notes[j].id === id) {
							allAccountsCopy[i].notes.splice(j, 1);
						}
					}
				}
			}
			mutateAccount({ data: allAccountsCopy }, false);
		}

		// Navigate back to notes page
		onClickBack();

		// Delete remotely
		await requestUpdate("/api/notes/deleteNote", { id });

		// Validate both CTAs and Accounts
		mutateCta();
		mutateAccount();
	};

	const onClickSalesforce = () => {
		// TODO
	};

	// Key Commands (useHotKeys)

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Escape":
					callWithoutModifiers(event, () => onClickBack());
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	if (noteLoading) {
		return (
			<div className="flex flex-row h-screen w-full">
				<NavBar
					verticalArrowsVisible={false}
					onClickBack={onClickBack}
				/>
				<div className="flex justify-center items-center flex-grow h-screen text-gray-400 italic">
					Loading Note...
				</div>
				<FillableSidebar />
			</div>
		);
	}

	return (
		<div className="flex flex-row h-screen w-full">
			<NavBar verticalArrowsVisible={false} onClickBack={onClickBack} />
			<div className="relative flex flex-col flex-grow h-screen mr-6 py-5 overflow-scroll">
				<div className="flex-none">
					{/* Top Right buttons (Absolute) */}
					<div className="flex justify-center items-center absolute top-5 right-3">
						{/* Salesforce Button */}
						<div
							className="flex flex-row justify-center items-center h-8 w-8 rounded-full hover:bg-hovergray cursor-pointer mr-2"
							onClick={onClickSalesforce}
						>
							<SalesforceIcon className="h-6 w-6" />
						</div>

						{/* Copy Link/Delete Note (Dropdown) */}
						<SimpleDropdown
							button={
								<AiOutlineEllipsis
									size={22}
									className="body-md-gray cursor-pointer"
								/>
							}
							open={actionsDropdownOpen}
							setOpen={setActionsDropdownOpen}
							options={actionOptions}
						/>
					</div>

					{/* Emoji Picker */}
					<div className="relative flex flex-row justify-start items-center w-14 h-14">
						<EmojiPicker
							open={emojiPickerOpen}
							setOpen={setEmojiPickerOpen}
							chosenEmoji={emoji}
							onClick={onChangeEmoji}
							buttonSize={36}
							pickerStyle={{
								position: "absolute",
								zIndex: "1000",
								left: "50px",
							}}
						/>
					</div>

					{/* Title Input */}
					<div className="flex flex-row justify-start items-center w-full h-10 mt-2 mb-3">
						<input
							value={title}
							onChange={(e) => setTitle(e.target.value)}
							className="h2-plus bg-transparent w-full"
							placeholder="Untitled"
							onKeyDown={onKeyDownTitle}
							onBlur={onBlurTitle}
							ref={titleRef}
							spellCheck={false}
						/>
					</div>

					{/* Account Dropdown */}
					<div className="w-1/3 py-1">
						<SmallDropdown
							options={accountOptions}
							open={accountDropdownOpen}
							setOpen={setAccountDropdownOpen}
							status={account?.company_name}
							title="Account"
							onSelect={onChangeAccount}
							variant="createCta"
							bodyStyle={{ top: "0px", left: "65px" }}
							onPressTab={() => null}
							onPressEnter={() => null}
						/>
					</div>

					{/* CTA Dropdown */}
					<div className="w-1/3 py-1">
						<SmallDropdown
							options={ctaOptions}
							open={ctaDropdownOpen}
							setOpen={setCtaDropdownOpen}
							status={cta?.cta}
							title="CTA"
							onSelect={onChangeCta}
							variant="createCta"
							bodyStyle={{ top: "0px", left: "65px" }}
							onPressTab={() => null}
							onPressEnter={() => null}
						/>
					</div>

					{/* Attendees Dropdown */}
					<div className="flex flex-row justify-start items-center w-full h-8">
						<SidebarCheckboxMenu
							options={userOptions}
							selected={attendees}
							open={attendeesDropdownOpen}
							setOpen={setAttendeesDropdownOpen}
							onSelect={onToggleAttendee}
							label="Attendees"
							bodyStyle={{ top: "0px", left: "65px" }}
							labelStyle={{ marginRight: "8px" }}
							closeOnSelect={false}
						/>
					</div>

					{/* Border */}
					<div className="w-full mt-5 border-b border-gray-200"></div>
				</div>

				{/* Text Editor */}
				<RichTextEditor
					body={body}
					setBody={setBody}
					style={{ borderColor: "transparent" }}
					editorStyle={{ padding: "0px" }}
					placeholder="Start typing here..."
					onBlur={onChangeBody}
					bodyLoaded={initialBodySet}
				/>
			</div>

			{/* Sidebar */}
			{!account && <FillableSidebar />}
			{account && <Sidebar ctaData={account} variant="account" />}

			<ModalContainer>
				{confirmModalOpen && (
					<ConfirmModal
						title="Are you sure you want to delete this note?"
						buttonLabel="Yes, I'm sure"
						onClick={onDeleteNote}
						setModalOpen={setConfirmModalOpen}
					/>
				)}
				{copiedUrlModalOpen && (
					<FadingModal
						message="Note URL copied to clipboard"
						fadeSeconds={3}
						setOpen={setCopiedUrlModalOpen}
					/>
				)}
			</ModalContainer>
		</div>
	);
});

export default Note;

import useSWR from "swr";
import fetch from "unfetch";

const fetchSupportData = () => {
	const fetcher = async (url) => {
		const res = await fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				authorization: `Bearer ${localStorage.getItem("access_token")}`,
			},
			body: JSON.stringify({
				email: localStorage.getItem("user_email"),
			}),
		});

		if (!res.ok) {
			const error = new Error(
				"An error occurred while fetching the data."
			);

			error.info = await res.json();
			error.status = res.status;
			throw error;
		}

		return res.json();
	};
	let options = {
		refreshInterval: 2,
	};
	const { data, error } = useSWR(
		"/api/supportTickets/getSupportTickets",
		fetcher
	);

	return {
		data: data,
		loading: !error && !data,
		error: error,
	};
};

export default fetchSupportData;

import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IoMenuOutline } from "react-icons/io5";
import { BsFillGearFill } from "react-icons/bs";
import { useHotkeys } from "react-hotkeys-hook";

import { settingsUrl } from "../requests";
import sellerantLogo from "../assets/loginLogo.svg";
import { callWithoutModifiers } from "../utils";
import Routes from "../Routes";

const menuItems = [
	{
		name: "Inbox",
		link: Routes.ACTION_ITEMS_PAGE,
	},
	{
		name: "Completed",
		link: Routes.ALL_CTA_PAGE,
	},
	{
		name: "Accounts",
		link: Routes.ALL_ACCOUNTS_PAGE,
	},
	{
		name: "Sales",
		link: Routes.SALES_PAGE,
	},
	{
		name: "Handoffs",
		link: Routes.HANDOFFS_PAGE,
	},
	{
		name: "Settings",
		link: Routes.SETTINGS_PAGE,
	},
];

const Menu = ({ userData, setMenuOpen }) => {
	const [open, setOpen] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(null);
	let history = useHistory();
	let match = useRouteMatch();

	useEffect(() => {
		if (setMenuOpen) {
			setMenuOpen(open);
		}
	}, [open]);

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Escape":
					event.preventDefault();
					callWithoutModifiers(event, () => setOpen(false));
					break;
				case "ArrowLeft":
					event.preventDefault();
					callWithoutModifiers(event, () => setOpen(true));
					break;
				case "ArrowUp":
					if (open) {
						event.preventDefault();
						callWithoutModifiers(event, () => onPressUpArrow());
					}
					break;
				case "ArrowDown":
					if (open) {
						event.preventDefault();
						callWithoutModifiers(event, () => onPressDownArrow());
					}
					break;
				case "Enter":
					if (open) {
						event.preventDefault();
						callWithoutModifiers(event, () => onPressEnter());
					}
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[open]
	);

	const onPressUpArrow = () => {
		if (selectedIndex !== null) {
			if (selectedIndex === 0) {
				setSelectedIndex(menuItems.length - 1);
			} else {
				setSelectedIndex((idx) => idx - 1);
			}
		} else {
			setSelectedIndex(menuItems.length - 1);
		}
	};

	const onPressDownArrow = () => {
		if (selectedIndex !== null) {
			if (selectedIndex === menuItems.length - 1) {
				setSelectedIndex(0);
			} else {
				setSelectedIndex((idx) => idx + 1);
			}
		} else {
			setSelectedIndex(0);
		}
	};

	const onPressEnter = () => {
		if (selectedIndex !== null) {
			if (menuItems[selectedIndex].link === match.url) {
			} else {
				history.push(menuItems[selectedIndex].link);
			}
			setOpen(false);
		}
	};
	return (
		<>
			{/* Menu Button */}
			<div
				onClick={() => setOpen(true)}
				className="ml-1 pb-1 flex justify-left items-center h-full cursor-pointer"
			>
				<IoMenuOutline
					className="transition transform delay-150 duration-500 hover:translate-x-4 text-gray-400 font-semibold"
					size={30}
				/>
			</div>

			{/* Menu Body (Expandable) */}
			{open && (
				<div
					className="z-50	h-screen absolute top-0 left-0 flex flex-col pt-6 bg-white shadow-xl "
					onMouseLeave={() => setOpen(false)}
				>
					<div className="px-4">
						<div className="grid grid-cols-6 flex items-center">
							<img
								src={userData?.profile_picture}
								alt=""
								className="flex-shrink-0 h-12 w-12 rounded-full"
							/>
							<div className="pl-3 col-span-2">
								<p className="text-gray-500 text-xs font-light">
									{userData?.first_name} {userData?.last_name}
								</p>
							</div>
							<div className="col-start-8">
								{userData?.admin ? (
									<button
										onClick={() => {
											settingsUrl();
										}}
									>
										<BsFillGearFill />
									</button>
								) : null}
							</div>
						</div>
					</div>
					<div className="mt-6 relative">
						<div className="flex flex-col">
							{/* {menuItems.map((item, index) => (
								<div
									key={index}
									to={item.link}
									className={`px-4 py-3 cursor-pointer ${
										selectedIndex === index && "bg-gray-100"
									}`}
									onMouseOver={() => setSelectedIndex(index)}
									onClick={onPressEnter}
								>
									<p>{item.name}</p>
								</div> */}

							<div className="flex flex-grow justify-center items-center h-9 pb-2">
								<button
									onClick={() => {
										localStorage.removeItem("access_token");
										window.location.reload();
									}}
								>
									Log Out
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default Menu;

import React, { useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";

import "./css/App.css";
import Routes from "./Routes";
import { ErrorBoundaryRoute, PrivateRoute } from "./components";
import { callWithoutModifiers } from "./utils";
import {
	ActionItems,
	AllAccounts,
	Auth,
	Cta,
	EditPlaybook,
	Login,
	Account,
	Search,
	Settings,
	SalesClient,
	ZendeskTicket,
	EditSnippet,
	Note,
	Handoffs,
	EditTemplate,
	CustomerDataRoom,
	Integrations,
} from "./pages";
import {
	AccountSplitContextWrapper,
	CtaContextWrapper,
	CtaSplitContextWrapper,
	SearchContextWrapper,
	SettingsContextWrapper,
	HandoffsContextWrapper,
} from "./context";

const App = () => {
	const [gPressed, setGPressed] = useState(false);

	const history = useHistory();

	return (
		<SearchContextWrapper>
			<Switch>
				<Route path={Routes.LOGIN_PAGE} component={Login} />

				<Route path={Routes.AUTH_REDIRECT_PAGE} component={Auth} />

				<Route
					path={Routes.CUSTOMER_DATAROOM_PAGE}
					component={CustomerDataRoom}
				/>

				<Route
					exact
					path={Routes.CTA_BASE_ROUTE}
					render={() => {
						return <Redirect to={Routes.ACTION_ITEMS_PAGE} />;
					}}
				/>

				{/* CTA-related pages */}
				<PrivateRoute path={Routes.CTA_BASE_ROUTE}>
					<CtaSplitContextWrapper>
						<Switch>
							<ErrorBoundaryRoute
								exact
								path={Routes.ACTION_ITEMS_PAGE}
								location="actionItems"
								fallbackRoute={Routes.ALL_ACCOUNTS_PAGE}
							>
								<SettingsContextWrapper>
									<Settings />
								</SettingsContextWrapper>
							</ErrorBoundaryRoute>
						</Switch>
					</CtaSplitContextWrapper>
				</PrivateRoute>

				{/* Handoffs */}
				<ErrorBoundaryRoute
					exact
					path={`${Routes.HANDOFFS_BASE_ROUTE}/:page`}
					location="handOffsPage"
					fallbackRoute={Routes.ACTION_ITEMS_PAGE}
					privateRoute
				>
					<HandoffsContextWrapper>
						<Handoffs />
					</HandoffsContextWrapper>
				</ErrorBoundaryRoute>
				<PrivateRoute
					exact
					path={Routes.EDIT_HANDOFF_PAGE}
					component={EditTemplate}
				/>
				{/* Redirect if page not found */}
				<Route
					component={() => <Redirect to={Routes.ACTION_ITEMS_PAGE} />}
				/>
			</Switch>
		</SearchContextWrapper>
	);
};

export default App;

import { Stage, Layer, Line } from "react-konva";

const ProgressBarArrow = ({
	height = 50,
	width = 200,
	label,
	status = "waiting",
	doneColor = "#36DB9F",
	activeColor = "#4B4DED",
	waitingColor = "#F5F6FB",
	hoverDone = "#99f2d2",
	hoverActive = "#676be6",
	onMouseEnter,
	onMouseLeave,
	onClick,
}) => {
	const statusColors = {
		done: doneColor,
		active: activeColor,
		waiting: waitingColor,
		hoverDone: hoverDone,
		hoverActive: hoverActive,
	};

	return (
		<div
			className="relative mx-1"
			style={{ width: width - 20, height }}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
		>
			<div className="absolute flex justify-center items-center h-full w-full cursor-pointer">
				<Stage width={width} height={height}>
					<Layer>
						<Line
							fill={statusColors[status]}
							closed
							points={[
								0,
								0,
								20,
								height / 2,
								0,
								height,
								width - 20,
								height,
								width,
								height / 2,
								width - 20,
								0,
							]}
						/>
					</Layer>
				</Stage>
				<p
					className={`absolute text-xs font-medium text-center px-2 ${
						status === "waiting" ? "text-gray-400" : "text-white"
					}`}
				>
					{label}
				</p>
			</div>
		</div>
	);
};

export default ProgressBarArrow;

import React from "react";

import { ReactComponent as Low1 } from "../../assets/low1.svg";
import { ReactComponent as Low2 } from "../../assets/low2.svg";
import { ReactComponent as Low3 } from "../../assets/low3.svg";

const LowPriorityIcon = () => (
	<div className="flex items-end">
		<Low1 />
		<Low2 />
		<Low3 />
	</div>
);

export default LowPriorityIcon;

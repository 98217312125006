import React, { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { BiPlus } from "react-icons/bi";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";

import { SmallDropdown, RichTextEditor, HollowButton } from "..";

const toOptions = [
	{
		value: "Executive Sponsor",
	},
	{
		value: "Power User",
	},
	{
		value: "Champion",
	},
];

const RecipientItem = ({ recipient, onClickClose, readOnly = false }) => (
	<div
		onClick={readOnly ? () => null : () => onClickClose(recipient.email)}
		className={`flex flex-row flex-none justify-center items-center px-2 py-1 rounded-full bg-gray-200 text-xs text-gray-600 select-none ${
			!readOnly && "hover:bg-gray-300 cursor-pointer"
		}`}
	>
		{!readOnly && <IoClose className="mr-1" />}
		<p className="truncate">{recipient.name}</p>
	</div>
);

const DraftModal = ({
	actionBody,
	recipients,
	setModalOpen,
	onClickSave = null,
	onClickSend = null,
	floating = true,
	showHeader = true,
	onAddRecipient = null,
	onRemoveRecipient = null,
	onSelectDropdownRecipient,
	readOnly = false,
	keyHandlerFunction,
	selectedSnippet = null,
}) => {
	let modalRef = useRef(null);

	const [body, setBody] = useState(
		actionBody?.body && typeof actionBody?.body === "object"
			? actionBody?.body
			: null
	);
	const [subject, setSubject] = useState(actionBody?.subject ?? "");
	const [toDropdownOpen, setToDropdownOpen] = useState(false);

	const [cursorPosition, setCursorPosition] = useState(null);
	const [insertLocalSnippet, setInsertLocalSnippet] = useState(false);

	useEffect(() => {
		if (actionBody?.body && typeof actionBody?.body === "object") {
			setBody(actionBody.body);
		} else {
			setBody(null);
		}
	}, [actionBody]);

	useEffect(() => {
		if (selectedSnippet) {
			setInsertLocalSnippet(true);
		}
	}, [selectedSnippet]);
	//seperated these bevasue if the state changes on the outside it may just pass in the same insert snippet
	useEffect(() => {
		if (insertLocalSnippet) {
			insertSnippet(selectedSnippet);
			setInsertLocalSnippet(false);
		}
	}, [insertLocalSnippet]);

	const insertSnippet = (snippetBody) => {
		let blocks = cloneDeep(body.blocks);
		if (blocks.length === 1) {
			blocks =
				blocks[0].text === ""
					? snippetBody.blocks
					: blocks.concat(snippetBody.blocks);
		} else {
			blocks.forEach((block, index) => {
				if (block.key === cursorPosition) {
					blocks.splice(index + 1, 0, ...snippetBody.blocks);
				}
				let newKey = Math.random().toString(36).substring(8);
				block.key = newKey;
			});
		}
		setBody({ blocks, entityMap: snippetBody.entityMap });
		onClickSave(recipients, subject, {
			blocks,
			entityMap: snippetBody.entityMap,
		});
	};

	const cursorPositionFunction = (position) => {
		setCursorPosition(position);
	};

	return (
		<div
			ref={modalRef}
			className={`relative flex flex-col justify-start items-start rounded-lg bg-lightgray z-0 overflow-hidden ${
				floating && "border border-gray-100 shadow-xl py-4 px-6"
			}`}
			style={{
				width: floating ? "500px" : "100%",
				maxWidth: floating ? "500px" : "100%",
				top: floating ? "10vh" : "0px",
				overflow: "hidden",
			}}
		>
			{/* Close Button */}
			{floating && (
				<div
					className="absolute top-0 right-0 p-4"
					onClick={() => setModalOpen(false)}
				>
					<IoClose
						className="text-gray-400 hover:text-gray-500 cursor-pointer"
						size={20}
					/>
				</div>
			)}

			{/* Header */}
			{showHeader && (
				<div className="flex justify-start items-center flex-none w-full">
					<p className="pr-2 pb-2 font-bold text-base text-gray-600">
						New Message
					</p>
				</div>
			)}

			{onAddRecipient && recipients !== null && (
				<div className="flex flex-row items-center">
					<div className="body-small-gray">To:</div>
					<div className="flex flex-row items-center p-2 flex-wrap">
						{recipients &&
							recipients.map((rec, index) => (
								<div className="m-1" key={index}>
									<RecipientItem
										recipient={rec}
										onClickClose={onRemoveRecipient}
										readOnly={readOnly}
									/>
								</div>
							))}
						{!readOnly && (
							<div
								onClick={onAddRecipient}
								className="flex flex-row justify-center items-center h-6 w-6 mx-1 rounded-full bg-gray-200 hover:bg-gray-300 text-xs text-gray-600 cursor-pointer"
							>
								<BiPlus size={16} />
							</div>
						)}
					</div>
				</div>
			)}

			{/* Recipient Section */}
			{!onAddRecipient && (
				<div className="w-full py-2 flex-none">
					<SmallDropdown
						options={toOptions}
						open={toDropdownOpen}
						setOpen={setToDropdownOpen}
						status={recipients}
						title="To:"
						onSelect={onSelectDropdownRecipient}
						variant="email"
						bodyStyle={{ top: "0px", left: "29px" }}
					/>
				</div>
			)}

			{/* Subject Section */}
			<div className="flex flex-row items-center flex-none w-full py-2">
				<p className="body-small-gray pr-2">Subject: </p>
				<input
					className="flex-grow outline-none body-small-black bg-transparent"
					onChange={(e) => setSubject(e.target.value)}
					value={subject}
					readOnly={readOnly}
					onBlur={
						floating
							? () => null
							: () => onClickSave(recipients, subject, body)
					}
				/>
			</div>

			{/* Body Section */}
			<div className="w-full my-2" style={{ minHeight: "300px" }}>
				<RichTextEditor
					setBody={setBody}
					body={body}
					readOnly={readOnly}
					onBlur={
						floating
							? () => null
							: () => onClickSave(recipients, subject, body)
					}
					keyHandlerFunction={keyHandlerFunction}
					cursorPositionFunction={cursorPositionFunction}
				/>
			</div>

			{/* Button Section */}
			<div className="flex flex-row items-center w-full my-2">
				{/* Leaving this button commented here for possible future use */}
				{onClickSave && !readOnly && floating && (
					<div className="mr-5">
						<HollowButton
							colorClass="text-indigo-500"
							label="Save"
							onClick={() =>
								onClickSave(recipients, subject, body)
							}
							active={
								(!isEqual(body, actionBody.body) &&
									body !== null) ||
								actionBody.subject !== subject
							}
						/>
					</div>
				)}
				{onClickSend && !readOnly && (
					<HollowButton
						colorClass="text-brightgreen"
						label="Send"
						onClick={() => onClickSend(recipients, subject, body)}
					/>
				)}
				{setModalOpen && readOnly && (
					<HollowButton
						colorClass="text-indigo-500"
						label="Close"
						onClick={() => setModalOpen(false)}
					/>
				)}
			</div>
		</div>
	);
};

export default DraftModal;

import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { IoClose } from "react-icons/io5";
import cloneDeep from "lodash.clonedeep";
import Fuse from "fuse.js";

import { Sidebar, MainGrid, ModalContainer, CommandK } from "../components";
import {
	getCtaData,
	getAllAccountData,
	getSellerantUsers,
	requestUpdate,
} from "../requests";
import {
	callWithModifiers,
	callWithoutModifiers,
	handleCaughtError,
	getUserSplits,
	removeSplitListHelper,
	getIconOptionsFromUsers,
} from "../utils";
import {
	getNavigationCommands,
	getSearchCommands,
	getSplitPageCommands,
	getRemoveSplitCommands,
} from "../commands";
import { SearchContext } from "../context";
import { splitPages } from "../constants";
import { useScroll } from "../hooks";
import Routes from "../Routes";

const Search = withRouter(({ history, match }) => {
	const { query, setQuery, sourcePage, setSourcePage } = useContext(
		SearchContext
	);

	let searchInputRef = useRef(null);

	const [currentResult, setCurrentResult] = useState(null);

	const [filteredCtas, setFilteredCtas] = useState(null);
	const [filteredAccounts, setFilteredAccounts] = useState(null);
	const [combinedResults, setCombinedResults] = useState(null);
	const [userOptionsObject, setUserOptionsObject] = useState(null);

	const [actionCommandKOpen, setActionCommandKOpen] = useState(null);
	const [splitNameInputOpen, setSplitNameInputOpen] = useState(null);
	const [splitPageCommandOpen, setSplitPageCommandOpen] = useState(null);

	const [listOfSplitsPagesOpen, setListOfSplitsPagesOpen] = useState(null);
	const [currentSplits, setCurrentSplits] = useState(null);

	const [newSplitName, setNewSplitName] = useState(null);
	const [currentSplitPattern, setCurrentSplitPattern] = useState(null);

	const {
		selectedIndex,
		setSelectedIndex,
		onPressUpArrow,
		onPressDownArrow,
	} = useScroll(combinedResults ? combinedResults.length : 0);

	const userId = localStorage.getItem("user_id");
	const myEmail = localStorage.getItem("user_email");

	// Data Hooks

	const {
		data: ctaData,
		loading: ctaDataLoading,
		error: ctaDataError,
	} = getCtaData();

	const {
		data: accountsData,
		isLoading: accountsLoading,
		isError: accountsError,
	} = getAllAccountData();

	const {
		data: sellerantUsers,
		loading: usersLoading,
		error: usersError,
		mutate: mutateUsers,
	} = getSellerantUsers();

	// Search Functions

	const formatSearch = (query) => {
		const regex = /^(.+?):/gm;
		let cleaned_query = query;
		let output = regex.exec(query);
		if (output) {
			const search_query = query.split(":")[1];
			const filter = output[1];
			if (filter == "account") {
				cleaned_query = { company_name: search_query };
			} else if (filter == "grade") {
				cleaned_query = { grade: search_query };
			} else if (filter == "stage") {
				cleaned_query = { stage: search_query };
			}
		}
		return cleaned_query;
	};

	const filterResults = (allData, setFilteredData, addAssignments = true) => {
		const options = {
			threshold: 0.4,
			keys: [
				"cta",
				"company_name",
				"company_id",
				"cta",
				"stage",
				"grade",
				"cta_summary",
				"owner.csm_owner",
			],
		};

		const fuse = new Fuse(allData, options);

		// format results for advnace search
		const pattern = formatSearch(query);
		setCurrentSplitPattern(pattern);

		// Search for results
		let data = fuse.search(pattern).map((x) => x.item);

		// Add assignment to each search result
		if (addAssignments) {
			data.forEach((element, index) => {
				let assigned = element.visiblity.assigned_to?.includes(myEmail);
				let unassigned = assigned
					? false
					: element.visiblity.assigned_to.length === 0;
				let following = element.visiblity.followed_by?.includes(
					myEmail
				);
				data[index].assigned = assigned;
				data[index].unassigned = unassigned;
				data[index].following = following;
				let sortType = assigned
					? "Assigned"
					: unassigned
					? "Unassigned"
					: "Following";
				data[index].sortType = sortType;
			});
		}

		// Assign output
		let output = { data, length: data.length };
		setFilteredData(output);
	};

	// Filter Effects

	useEffect(() => {
		if (ctaData && !ctaDataLoading && !ctaDataError) {
			filterResults(ctaData.data, setFilteredCtas);
		}
	}, [ctaData, query]);

	useEffect(() => {
		if (accountsData && !accountsLoading && !accountsError) {
			filterResults(accountsData.data, setFilteredAccounts, false);
		}
	}, [accountsData, query]);

	useEffect(() => {
		if (filteredCtas && filteredAccounts) {
			setCombinedResults(filteredCtas.data.concat(filteredAccounts.data));
		}
	}, [filteredCtas, filteredAccounts]);

	// General Effects

	useEffect(() => {
		searchInputRef.current.focus();
	}, []);

	useEffect(() => {
		if (match.params.filter !== undefined) {
			setQuery(match.params.filter);
		}
	}, [match]);

	useEffect(() => {
		if (
			combinedResults &&
			selectedIndex !== null &&
			selectedIndex < combinedResults.length
		) {
			setCurrentResult(combinedResults[selectedIndex]);
		} else {
			setCurrentResult(null);
		}
	}, [selectedIndex, combinedResults]);

	useEffect(() => {
		if (!(combinedResults && combinedResults.length > 0)) {
			setSelectedIndex(null);
		}
	}, [combinedResults]);

	useEffect(() => {
		if (sellerantUsers && !usersLoading && !usersError) {
			setCurrentSplits(getUserSplits(sellerantUsers, userId, null, true));
			let users = getIconOptionsFromUsers(sellerantUsers.data);
			let usersObject = {};
			users.map((user) => (usersObject[user.email] = user));
			setUserOptionsObject(usersObject);
		}
	}, [sellerantUsers]);

	// Split Creation Functions

	const saveSplitName = (splitName) => {
		setNewSplitName(splitName);
		setSplitPageCommandOpen(true);
	};

	const createNewSplit = async (splitPage) => {
		setSplitPageCommandOpen(false);

		// Create new split object
		let newSlug =
			newSplitName.split(" ").join("") +
			Math.floor(Math.random() * 100000).toString();
		let newSplitObject = {
			slug: newSlug,
			title: newSplitName,
			filter: currentSplitPattern,
			source: "sellerant",
			protected: false,
		};

		// Determine which part of split settings to put it in
		let selectedSplitPage = splitPages[splitPage].split;

		// Change split settings in sellerantUsers
		let mergedSettings;
		let usersCopy = cloneDeep(sellerantUsers.data);
		for (let i = 0; i < usersCopy.length; i++) {
			if (usersCopy[i].user_id === userId) {
				let splitList = usersCopy[i].settings[selectedSplitPage];

				// If last split in list is Zendesk, add new split just before. Otherwise, add new split at end of list
				if (splitList[splitList.length - 1].source === "zendesk") {
					splitList.splice(splitList.length - 1, 0, newSplitObject);
				} else {
					splitList.push(newSplitObject);
				}
				usersCopy[i].settings[selectedSplitPage] = splitList;
				mergedSettings = usersCopy[i].settings;
				break;
			}
		}

		// Navigate back to page the split applies to
		setSourcePage(splitPages[splitPage].route);
		history.push(splitPages[splitPage].route);

		// Mutate Sellerant Users with new changes
		try {
			mutateUsers({ data: usersCopy }, false);
			await requestUpdate("/api/updateSellerantUsers", {
				attr: "settings",
				newValue: mergedSettings,
			});
			mutateUsers();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	// remove split functions

	const removeSplit = async (splitName, pageKey, pageName) => {
		const { usersCopy, mergedSettings } = removeSplitListHelper(
			pageName,
			sellerantUsers.data,
			splitName,
			pageKey,
			userId
		);

		// Navigate back to page that it was referenced to (All Ctas, Action Items, Etc.)
		setSourcePage(splitPages[pageName].route);
		history.push(splitPages[pageName].route);

		// Mutate Sellerant Users with new changes
		try {
			mutateUsers({ data: usersCopy }, false);
			await requestUpdate("/api/updateSellerantUsers", {
				attr: "settings",
				newValue: mergedSettings,
			});
			mutateUsers();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	// Navigation

	const onPressEnter = () => {
		if (currentResult !== null) {
			if (selectedIndex >= filteredCtas.length) {
				history.push(
					`${Routes.ACCOUNT_BASE_ROUTE}/${combinedResults[selectedIndex].company_id}/overview`
				);
			} else {
				history.push(
					`${Routes.CTA_BASE_ROUTE}/${currentResult.cta_id}/todo`
				);
			}
		}
	};

	const onPressEscape = () => {
		if (sourcePage) {
			history.push(sourcePage);
		} else {
			history.push(Routes.ACTION_ITEMS_PAGE);
		}
	};

	// Hot Keys

	useHotkeys(
		"*",
		(event) => {
			switch (event.key) {
				case "Escape":
					callWithoutModifiers(event, () => onPressEscape());
					break;
				case "k":
					callWithModifiers(
						event,
						() => setActionCommandKOpen(true),
						["cmd"]
					);
					break;
				default:
					break;
			}
		},
		{ filter: () => true },
		[]
	);

	const onKeyDown = (e) => {
		if (e.key === "ArrowDown") {
			e.preventDefault();
			onPressDownArrow();
		} else if (e.key === "ArrowUp") {
			e.preventDefault();
			onPressUpArrow();
		} else if (e.key === "Enter") {
			onPressEnter();
		} else if (e.key === "Escape") {
			onPressEscape();
		} else if (e.key === "k") {
			callWithModifiers(e, () => setActionCommandKOpen(true), ["cmd"]);
		}
	};

	// Commands

	const searchCommands = getSearchCommands(
		setSplitNameInputOpen,
		setListOfSplitsPagesOpen
	);
	const navigationCommands = getNavigationCommands(history);
	const splitPageCommands = getSplitPageCommands(createNewSplit);
	const currentUserMadeSplits = getRemoveSplitCommands(
		currentSplits,
		removeSplit
	);

	return (
		<div className="flex flex-row h-screen">
			{/* Page Body (Header + Grid) */}

			<div className="flex flex-col flex-grow h-full">
				{/* Page Header (Search Bar) */}
				<div className="flex flex-row items-center flex-none w-full py-5">
					<div className="w-20 flex-none">
						<div className="w-1 bg-purple-400">
							<p className="text-purple-400">|</p>
						</div>
					</div>
					<div className="flex flex-grow items-center pl-1">
						<input
							ref={searchInputRef}
							className="text-md whitespace-nowrap outline-none flex-grow w-3/4 pr-1"
							value={query}
							onChange={(e) => setQuery(e.target.value)}
							placeholder="Search..."
							onKeyDown={onKeyDown}
						/>

						<div className="h-full w-14">
							<IoClose
								className="text-gray-400 hover:text-gray-500 cursor-pointer"
								size={24}
								onClick={onPressEscape}
							/>
							{/* <button onClick={() => saveQuery()}>Save Split</button> */}
						</div>
					</div>
				</div>

				{/* Grid */}
				<div className="flex-grow h-full overflow-scroll">
					{filteredCtas && filteredCtas.length > 0 && (
						<p className="flex flex-row justify-start items-center w-full h-5 py-4 ml-10 text-sm text-gray-500 ">
							Action Items
						</p>
					)}
					<MainGrid
						data={filteredCtas}
						selectedId={selectedIndex}
						setSelectedId={setSelectedIndex}
						variant="allCtas"
						onPressUpArrow={onPressUpArrow}
						onPressDownArrow={onPressDownArrow}
						onPressEnter={onPressEnter}
						loading={ctaDataLoading}
						error={ctaDataError}
						fullSize={false}
						showPlaceholders={false}
						userIcons={userOptionsObject}
					/>
					{filteredAccounts && filteredAccounts.length > 0 && (
						<p className="flex flex-row justify-start items-center w-full h-5 py-4 ml-10 text-sm text-gray-500 ">
							Accounts
						</p>
					)}
					<MainGrid
						data={filteredAccounts}
						selectedId={selectedIndex - filteredCtas?.length}
						setSelectedId={(index) =>
							setSelectedIndex(
								index + (filteredCtas?.length || 0)
							)
						}
						variant="accounts"
						onPressUpArrow={onPressUpArrow}
						onPressDownArrow={onPressDownArrow}
						onPressEnter={onPressEnter}
						loading={accountsLoading}
						error={accountsError}
						fullSize={false}
						showPlaceholders={false}
						rowIdsOffset={filteredCtas?.length || 0}
					/>
				</div>
			</div>

			{/* Sidebar */}
			<Sidebar ctaData={currentResult} variant="actionItems" />

			<ModalContainer>
				{actionCommandKOpen && (
					<CommandK
						options={searchCommands.concat(navigationCommands)}
						setOpen={setActionCommandKOpen}
					/>
				)}
				{splitPageCommandOpen && (
					<CommandK
						options={splitPageCommands}
						setOpen={setActionCommandKOpen}
						placeholder="Where should the split live?"
					/>
				)}
				{listOfSplitsPagesOpen && (
					<CommandK
						setOpen={setListOfSplitsPagesOpen}
						options={currentUserMadeSplits}
						placeholder="Which split do you want to remove?"
					/>
				)}
			</ModalContainer>

			<ModalContainer top="40%">
				{splitNameInputOpen && (
					<CommandK
						setOpen={setSplitNameInputOpen}
						variant="input"
						placeholder="What would you like to name the split?"
						onEnterInput={saveSplitName}
					/>
				)}
			</ModalContainer>
		</div>
	);
});

export default Search;

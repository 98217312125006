import React from "react";

import { ReactComponent as Ellipse1 } from "../../assets/Ellipse.svg";

const InProgressIcon = () => (
	<div className="flex justify-center items-center flex-none relative h-4 w-4">
		<Ellipse1 />
	</div>
);

export default InProgressIcon;

import React from "react";

const variants = {
	Resolve: "h-6 border border-gray-400 text-gray-500",
	"⌘K": "h-6 border-gray-400 text-gray-400 hover:text-gray-500",
	Adopting: "h-5 border border-blue-600 bg-blue-600 text-white font-light",
	Default: "h-5 border border-blue-600 bg-blue-600 text-white font-light",
	View: "h-6 border border-blue-600 bg-blue-600 text-white font-light",
};

const ResolveButton = ({
	label = "Resolve",
	variant = "Resolve",
	onClick,
	style,
}) => {
	return (
		<button
			onClick={onClick}
			className={`px-2 rounded whitespace-nowrap text-center text-xs 
				${variant in variants ? variants[variant] : variants["Default"]}
				${onClick ? "cursor-pointer" : "cursor-default"}
			`}
			style={style}
		>
			{label}
		</button>
	);
};

export default ResolveButton;

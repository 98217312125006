import React, { useEffect, useState, useRef } from "react";
import { IoPersonRemoveSharp } from "react-icons/io5";
import { IoEnterOutline } from "react-icons/io5";
import { useHotkeys } from "react-hotkeys-hook";
import { BsArrow90DegLeft } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import EmailCC from "./ReplyBoxComponents/EmailCC";
import SubmitExternalComment from "./ReplyBoxComponents/SubmitExternalComment";
import MessageTextBox from "./ReplyBoxComponents/MessageTextBox";
const ReplyBox = ({
	id,
	selected,
	submitComment,
	ogCommentData,
	commentData,
	userNameDatabase,
	setSelected,
	updateScroll,
	showReplyTextInput,
}) => {
	let textAreaRef = useRef(null);
	let emailInput = useRef(null);
	const [bodyText, setBodyText] = useState(""); //text for the reply box. Used for submitComment and changing box size
	const [sendTo, setSendTo] = useState([""]); //list of users to send to
	const [deleteFromSend, setDeleteFromSend] = useState([]); //list of users to remove. Default is users will stay CCd.
	const [currTicketUsers, setCurrTicketUsers] = useState([""]); //list of people attached to the ticket. We pass in submit to see if original users were deleted
	const [replySize, setReplySize] = useState(3); // default reply box size
	const [didAddEmail, setDidAddEmail] = useState(false)
	var filtered = commentData.ticket_comments.output.comments.filter(function (
		el
	) {
		//lil function to id which comment we are actively looking at.
		return el.id === id;
	});

	function removeUserFromSend(userId) {
		//used to remove an Email. The reason we use this instead of SENDTO is so that you can remove existing users. You have to actually SPECIFY what emails to remove. No auto remove.

		let updatedList = [];
		let deleteSendList = [];

		for (let i in sendTo) {
			if (sendTo[i] === userId) {
				deleteSendList.push(sendTo[i]); //add to removal list
				sendTo.splice(i, 1); //THEN splice
			}
		}
		for (let i in sendTo) {
			updatedList.push(sendTo[i]);
		}
		for (let i in deleteFromSend) {
			//only works with shallow copy
			if (
				deleteSendList.indexOf(deleteFromSend[i]) === -1 &&
				deleteFromSend[i] !== undefined
			) {
				deleteSendList.push(deleteFromSend[i]);
			}
		}
		setSendTo(updatedList);
		setDeleteFromSend(deleteSendList);
	}
	function validateEmail(email) {
		// used to validate a user email
		// No need to validate on the backend, if the user wants to mess around and enter an invalid email it just wont deliver ¯\_(ツ)_/¯
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	var map = {};
	const addCC = (e) => {
	
		if (e.key === "Enter") {
			addUserToSend(emailInput.current.value);
		}
	};

	const onKeyDown = (e) => {
		//This allows the user to submit via 'commmand + enter'. We use a map to store the keys pressed then check immediately. Might present a challenge if we have a number of key commands (not sure on the aing)
		e = e || event; // to deal with IE
		map[e.key] = e.type == "keydown";
		console.log(map)
		if (map["Enter"] && map["Meta"]) {
			textAreaRef.current.blur();
			submitComment(
				bodyText,
				true,
				sendTo,
				deleteFromSend,
				currTicketUsers
			);
			setSelected(null);
			updateScroll();
			
		}
	};


	function addUserToSend(userId) {
		//This adds a user to the send to list. Validate email checks to make sure it can be passed.
		//Email error will be used for error handling
		let updatedList = [];
		if (validateEmail(userId) && sendTo.indexOf(userId) === -1) {
			emailError = false;
			updatedList.push(userId);
			for (let i in sendTo) {
				updatedList.push(sendTo[i]);
			}
			setSendTo(updatedList);
			setDidAddEmail(true)
			console.log("DID ADD EMAIL IS", didAddEmail)
			emailInput.current.value = "";
		} else {
			emailError = true;
		}
	}

	useEffect(() => {
		//This loop is used to check any change in comment data or when a new comment is selected. This loop adds existing users to setSendTo. The email validation is to allow instant access to the CC's.
		// Because a new Zendesk email will NOT have a stored id until the comment posts, we mutate with the email address instead of the ID. The validate email just checks to see if there are any of these to display.

		let userNameList = [];
		if (userNameDatabase[0].id !== undefined) {
			for (let i in userNameDatabase) {
				for (let j in filtered[0].via.source.to.email_ccs) {
					if (
						userNameDatabase[i].id ===
						filtered[0].via.source.to.email_ccs[j]
					)
						userNameList.push(userNameDatabase[i].email);
				}
			}
			for (let i in filtered[0].via.source.to.email_ccs) {
				if (validateEmail(filtered[0].via.source.to.email_ccs[i])) {
					userNameList.push(filtered[0].via.source.to.email_ccs[i]);
				}
			}
			setCurrTicketUsers(commentData.ticket_data.email_cc_ids);
			setSendTo(userNameList);
		}
	}, [ogCommentData]);

	let emailError = false;

	useEffect(() => {
		//This useEffect monitors the text area input for a public reply. When it gets past 3 lines, the rowSize ups by one. The textRow > 3 allows for deletions
		const textRowCount = bodyText ? bodyText.split("\n").length : 0;
		if (textRowCount > 3) {
			setReplySize(textRowCount);
		}
	}, [bodyText]);

	let bgColor = "white"; //This will let you change the whole bg of zendesk box. Might be useful down the line, made the variable now because couldn't decide on styling.

	return id === selected ? ( //this first case is wheter or not the reply box is selected. The id must match the selected message.
		<>
			<div className={"w-full bg-" + bgColor}>
				<div className="w-full overflow-hidden pl-14 mt-8 text-black">
					<div className=" font-sans pt-4  text-gray-700 font-nomral border-opacity-10 w-5/6 pb-2 whitespace-pre-wrap">
						{ogCommentData}
					</div>
				</div>
				{showReplyTextInput ? (
					<>
						<div className="ml-5">
							{/* Begin outer loop to print the 'reply box'. The first thing checked is whether or not there are any CC'd users */}
							{commentData.ticket_data.email_cc_ids !==
							undefined ? (
								<div>
									{" "}
									<div className="md:mt-4 md:flex md:flex-col md:items-baseline grid grid-cols-1">
										<div className="md:flex md:flex-inline md:items-baseline"> 
											<span className="text-2l text-black font-light text-opacity-90 min-w-max">
													Replying to:
											</span>
												{ commentData?.ticket_data?.via?.source?.from?.name !== undefined ? (
													<span className="bg-gray-100 ml-2 py-2 px-2 rounded-md break-normal min-w-max">
														{
												commentData?.ticket_data?.via?.source?.from?.name
											}
										</span> ): (
											<span> </span>
											)
										}
										
										</div>
										<div className="md:flex md:flex-inline md:items-baseline mt-2"> 
										<span className="text-gray-600 font-light pr-4 ">
											Cc
										</span>

										<EmailCC
											emailInput={emailInput}
											sendTo={sendTo}
											removeUserFromSend={
												removeUserFromSend
											}
											userNameDatabase = {userNameDatabase}
											addUserToSend={addUserToSend}
											addCC={addCC}
											didAddEmail={didAddEmail}
											setDidAddEmail={setDidAddEmail}
										></EmailCC>
										</div>
									</div>
									{emailError ? (
										<div>Invalid Email.</div>
									) : null}
								</div>
							) : null}
							{/* End outer reply box loop.  */}
						</div>
						{/* MessageTextBox is where the user types their reply. */}
						<MessageTextBox
							setBodyText={setBodyText}
							textAreaRef={textAreaRef}
							replySize={replySize}
							bgColor={bgColor}
							onKeyDown={onKeyDown}
						></MessageTextBox>
					</>
				) : null}

				{/* Body input area. The rows are controlled by "replySize, which auto updates to extend the size of the reply box." */}
			</div>

			{showReplyTextInput ? (
				<>
					{/* The submit button + surrounding divs. For more information on what occurs on 'submit' view component. */}
					<SubmitExternalComment
						bodyText={bodyText}
						sendTo={sendTo}
						deleteFromSend={deleteFromSend}
						submitComment={submitComment}
						currTicketUsers={currTicketUsers}
						setSelected={setSelected}
						updateScroll={updateScroll}
						bgColor={bgColor}
					></SubmitExternalComment>
				</>
			) : null}
		</>
	) : null;
};

export default ReplyBox;

import { NPS } from "../components";

export default (companyUserData, assign, assignType) => {
	return companyUserData?.map((user) => ({
		title: `${user.first_name} ${user.last_name}`,
		subtitle: user.job_title,
		nps: <NPS score={user.nps ? user.nps : "NA"} />,
		command() {
			assign(user, assignType);
		},
	}));
};

import React, { useContext, useEffect, useState } from "react";
import cloneDeep from "lodash.clonedeep";
import { v4 as uuidv4 } from "uuid";

import TodoPhase from "./TodoPhase";
import { CtaContext } from "../../../context";
import { getCompanyUsers, requestUpdate } from "../../../requests";
import { handleCaughtError } from "../../../utils";

const Todo = () => {
	const {
		currentCtaData,
		currentTodoList,
		setCurrentTodoList,
		userOptions,
		allCtaData,
		mutateCta,
		currentReport,
		userSnippets,
	} = useContext(CtaContext);

	const [companyUserData, setCompanyUserData] = useState(null);

	// Effects
	useEffect(() => {
		if (currentCtaData) {
			getCompanyUsers(currentCtaData.company_id).then((res) => {
				setCompanyUserData(res);
			});
		}
	}, [currentCtaData]);

	// Mutations

	const updateLocalCtas = (ctaId, attr, newValue) => {
		let allCtaDataCopy = cloneDeep(allCtaData.data);
		for (let i = 0; i < allCtaDataCopy.length; i++) {
			if (allCtaDataCopy[i].cta_id === ctaId) {
				allCtaDataCopy[i][attr] = newValue;
				return { data: allCtaDataCopy };
			}
		}
	};

	const mutateTodoData = async (newTodoList, endpoint, body) => {
		// Get updated version of cta list with new todos
		let newCtas = updateLocalCtas(
			currentCtaData.cta_id,
			"todo_list",
			newTodoList
		);

		// Mutate locally and update remotely
		try {
			mutateCta(newCtas, false);
			await requestUpdate(endpoint, body);
			mutateCta();
		} catch (err) {
			handleCaughtError(err);
		}
	};

	const updateTodo = (phaseNumber, todoId, attr, newValue) => {
		let currentTodoData = cloneDeep(currentTodoList);

		// For each phase in todo data
		for (let i = 0; i < currentTodoData.phases.length; i++) {
			// Find todo with correct phase number
			if (currentTodoData.phases[i].phase_number === phaseNumber) {
				// Search for todo with given todoId in that phase
				for (
					let j = 0;
					j < currentTodoData.phases[i].todos.length;
					j++
				) {
					// Change value of given attribute in given todo
					if (currentTodoData.phases[i].todos[j].id === todoId) {
						currentTodoData.phases[i].todos[j][attr] = newValue;
						break;
					}
				}
				break;
			}
		}
		setCurrentTodoList(currentTodoData);
		mutateTodoData(currentTodoData, "/api/todo/update", {
			todo_id: todoId,
			value: newValue,
			attr,
		});
	};

	const createTodo = (
		phaseNumber,
		nameTodo,
		bodyTodo,
		dateTodo,
		assigneeTodo
	) => {
		let currentTodoData = cloneDeep(currentTodoList);
		let newTodo;
		for (let i = 0; i < currentTodoData.phases.length; i++) {
			if (currentTodoData.phases[i].phase_number === phaseNumber) {
				let newTodoId = uuidv4();
				newTodo = {
					id: newTodoId,
					name: nameTodo,
					body: { description: bodyTodo },
					take_action: {
						action: false,
					},
					status: "Planned",
					due_date: dateTodo,
					assigned_to: assigneeTodo,
				};
				currentTodoData.phases[i].todos.push(newTodo);
				break;
			}
		}
		setCurrentTodoList(currentTodoData);
		mutateTodoData(currentTodoData, "/api/todo/addTodo", {
			cta_id: currentCtaData.cta_id,
			phase_number: phaseNumber,
			value: newTodo,
		});
	};

	const updatePhase = async (
		phaseNumber,
		problem,
		nextSteps,
		notes,
		completed = true
	) => {
		let currentTodoData = cloneDeep(currentTodoList);
		let phaseIndex;

		// For each phase in todo data
		for (let i = 0; i < currentTodoData.phases.length; i++) {
			// Find phase with correct phase number and mark completed
			if (currentTodoData.phases[i].phase_number === phaseNumber) {
				phaseIndex = i;

				// If completing phase, then update completed attribute
				if (completed) {
					currentTodoData.phases[i].completed = true;
					setCurrentTodoList(currentTodoData);
					await requestUpdate("/api/updatePhase", {
						phase_number: phaseNumber,
						next_steps: nextSteps,
						cta_id: currentCtaData.cta_id,
						completed,
						problem,
						notes,
					});
				}
				break;
			}
		}

		// If not completing phase (just updating resolution report)
		if (!completed) {
			let allCtaDataCopy = cloneDeep(allCtaData.data);
			for (let i = 0; i < allCtaDataCopy.length; i++) {
				if (allCtaDataCopy[i].cta_id === currentCtaData.cta_id) {
					// Copy report phase
					let reportPhase =
						allCtaDataCopy[i].resolution_report.phases[phaseIndex];

					// Update phase attributes
					reportPhase.problem = problem;
					reportPhase.next_steps = nextSteps;
					reportPhase.notes = notes;

					// Copy variable back to original object
					allCtaDataCopy[i].resolution_report.phases[
						phaseIndex
					] = reportPhase;

					// Mutate and update remotely
					mutateCta({ data: allCtaDataCopy }, false);
					await requestUpdate("/api/updatePhase", {
						phase_number: phaseNumber,
						next_steps: nextSteps,
						cta_id: currentCtaData.cta_id,
						completed,
						problem,
						notes,
					});
					mutateCta();
					break;
				}
			}
		}
	};

	return (
		<div className="flex flex-col h-full w-full items-center overflow-scroll bg-lightgray rounded shadow-sm">
			<div className="w-full">
				{currentTodoList &&
					currentTodoList.phases.map((phase, index) => {
						let currentPhase = currentTodoList.phases.find(
							(phase) => phase.completed !== true
						);
						return (
							<TodoPhase
								key={phase.phase_number}
								phaseNumber={phase.phase_number}
								currentPhase={currentPhase?.phase_number}
								title={phase.name}
								items={phase.todos}
								completed={phase.completed}
								userOptions={userOptions}
								userSnippets={userSnippets}
								phaseReport={currentReport.phases[index]}
								companyUserData={companyUserData}
								buttonVisible={
									phase.completed ||
									phase.phase_number ===
										currentPhase?.phase_number
								}
								onUpdateTodo={(todoId, attr, newValue) =>
									updateTodo(
										phase.phase_number,
										todoId,
										attr,
										newValue
									)
								}
								onCreateTodo={(
									todoName,
									todobody,
									todoDate,
									todoAssignee
								) =>
									createTodo(
										phase.phase_number,
										todoName,
										todobody,
										todoDate,
										todoAssignee
									)
								}
								onUpdatePhase={(
									problem,
									nextSteps,
									notes,
									completed
								) =>
									updatePhase(
										phase.phase_number,
										problem,
										nextSteps,
										notes,
										completed
									)
								}
							/>
						);
					})}
			</div>
		</div>
	);
};

export default Todo;

import { useEffect, useRef, useState } from "react";
import { IoIosAdd } from "react-icons/io";

import { UserIcon } from "..";
import { useOutsideAlerter } from "../../hooks";
import checkmark from "../../assets/checkmark.svg";

const defaultBodyStyle = {
	right: "224px",
	top: "4px",
};

const defaultLabelStyle = { width: "65px" };

const CheckBoxMenu = ({
	options,
	setOpen,
	open = false,
	onSelect,
	keyCommand,
	selected,
	label = "Followers",
	bodyStyle = defaultBodyStyle,
	labelStyle = defaultLabelStyle,
	closeOnSelect = true,
}) => {
	let inputRef = useRef(null);
	let dropdownRef = useRef(null);

	const [selectedIndex, setSelectedIndex] = useState(null);
	const [query, setQuery] = useState("");

	useOutsideAlerter(dropdownRef, () => setOpen(false));

	// Filter Functions

	const isChecked = (email) => {
		if (selected) {
			for (let i = 0; i < selected.length; i++) {
				if (selected[i].email == email) {
					return i;
				}
			}
		}
		return -1;
	};

	const filterByQuery = (input) => {
		let queryLength = query.length;
		if (
			query
				.toLowerCase()
				.localeCompare(input.toLowerCase().slice(0, queryLength)) === 0
		) {
			return true;
		}
		let words = input.split(" ");
		for (var i = 0; i < words.length; i++) {
			if (words[i].toLowerCase().startsWith(query.toLowerCase())) {
				return true;
			}
		}
	};

	// Effects

	useEffect(() => {
		if (open && options.length !== 0) {
			setSelectedIndex(0);
			inputRef.current.focus();
		} else {
			setQuery("");
		}
	}, [open]);

	useEffect(() => {
		if (open) {
			// getSelectedIndexFromStatus();
			// inputRef.current.focus();
		} else {
			setQuery("");
		}
	}, [open]);

	useEffect(() => {
		if (
			options &&
			!options
				.filter((option) => filterByQuery(option.value))
				.includes(options[selectedIndex])
		) {
			setSelectedIndex(
				options.indexOf(
					options.filter((option) => filterByQuery(option.value))[0]
				)
			);
		} else {
		}
	}, [query]);

	// Navigation

	const onDownArrow = () => {
		if (selectedIndex === null) {
			setSelectedIndex(0);
		} else {
			let newIndex = selectedIndex + 1;
			setSelectedIndex(
				newIndex %
					options.filter((option) => filterByQuery(option.value))
						.length
			);
		}
	};

	const onUpArrow = () => {
		if (selectedIndex === null || selectedIndex === 0) {
			setSelectedIndex(
				options.filter((option) => filterByQuery(option.value)).length -
					1
			);
		} else {
			setSelectedIndex((idx) => idx - 1);
		}
	};

	const onEnter = () => {
		if (selectedIndex === null) {
		} else {
			if (options[selectedIndex]) {
				onSelect(options[selectedIndex]);
			}
		}
		setOpen(false);
	};

	const onKeyDown = (e) => {
		if (e.key === "ArrowDown") {
			onDownArrow();
		} else if (e.key === "ArrowUp") {
			onUpArrow();
		} else if (e.key === "Enter" && selectedIndex !== null) {
			onEnter();
			setOpen(false);
		}
	};

	return (
		<div id="dropdown-container">
			{/* Dropdown header - Section that's always visible */}
			{selected && (
				<div className="flex flex-row justify-start items-center">
					<div id="title-section" style={labelStyle}>
						<p className="body-small-gray">{label}</p>
					</div>
					<div
						className="flex flex-row items-center flex-grow h-3 rounded text-gray-900 cursor-pointer mr-4 px-2 py-3 hover:bg-hovergray"
						onClick={() => setOpen(true)}
					>
						<span className="flex flex-row-reverse justify-center ml-0 mr-2">
							{selected.map((user, index) => (
								<div key={index} className=" -mr-1">
									<UserIcon user={user.iconLabel} />
								</div>
							))}
						</span>

						{selected.length === 0 ? (
							<div className="flex flex-row items-center justify-center -ml-3">
								<IoIosAdd size={20} className="font-light" />
								<p className="ml-1.5 body-small-black truncate">
									Add {label}
								</p>
							</div>
						) : (
							<IoIosAdd size={16} className="font-light" />
						)}
					</div>
				</div>
			)}

			{/* Dropdown body - Section that appears when header is clicked */}
			<div className="relative z-50">
				{open && (
					<div
						id="dropdown-body"
						className="flex flex-col bg-white absolute rounded-md shadow-md py-2 w-64"
						ref={dropdownRef}
						style={bodyStyle}
					>
						<span className="flex flex-row justify-between items-center py-1 px-3 mb-1 w-full">
							<input
								className="text-xs whitespace-nowrap outline-none flex-grow w-3/4 pr-1"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								placeholder={`Set ${label}...`}
								ref={inputRef}
								onKeyDown={onKeyDown}
							/>
							{keyCommand && (
								<span className="flex justify-center items-center w-5 h-5 rounded-sm bg-gray-200 text-xs text-black">
									{keyCommand}
								</span>
							)}
						</span>

						{options
							.filter((option) => filterByQuery(option.value))
							.filter((option) => option.email !== "unassigned")
							.map((option, index) => (
								<div
									className={`flex flex-row items-center w-full py-1 px-3 cursor-pointer ${
										index === selectedIndex && "bg-gray-100"
									}`}
									key={index}
									onClick={() => {
										onSelect(option);
										if (closeOnSelect) setOpen(false);
									}}
									onMouseEnter={() => setSelectedIndex(index)}
								>
									{isChecked(option.email) == -1 ? (
										<div className="h-4 w-4 rounded mr-2 border border-gray-100" />
									) : (
										<div className="h-4 w-4 flex content-center justify-center mr-2 rounded bg-blue-600 justify-items-center items-center">
											<img
												className="mr-0.5"
												src={checkmark}
											/>

										</div>
									)}
									<UserIcon
										className="ml-1 "
										user={option.iconLabel}
									/>

									{/* user icon here */}

									<p className="text-gray-900 text-smaller pl-2 whitespace-nowrap">
										{option.value}
									</p>
								</div>
							))}
					</div>
				)}
			</div>
		</div>
	);
};

export default CheckBoxMenu;

import React, { useState, useEffect } from "react";
import { IoAddOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";

const getColorFromUser = (user) => {
	let firstCode = user?.charCodeAt(0) - 65;
	let lastCode = user?.charCodeAt(1) - 65;
	switch (true) {
		case firstCode <= 13 && lastCode <= 13:
			return "bg-green-300";
		case firstCode <= 13 && lastCode > 13:
			return "bg-red-300";
		case firstCode > 13 && lastCode <= 13:
			return "bg-yellow-300";
		case firstCode > 13 && lastCode > 13:
			return "bg-blue-300";
		default:
			break;
	}
};

const PeopleIcon = ({
	img = null,
	name = null,
	grade = null,
	variant = "left",
	data,
	click,
	index,
	assign,
}) => {
	const getColorFromGrade = (grade) => {
		switch (true) {
			case grade > 6:
				return "border-green-400";
			case grade > 3:
				return "border-yellow-400";
			case grade >= 0:
				return "border-red-400";
			default:
				break;
		}
	};
	const getBGColorFromGrade = (grade) => {
		switch (true) {
			case grade > 6:
				return "bg-green-200";
			case grade > 3:
				return "bg-yellow-200";
			case grade >= 0:
				return "bg-red-200";
			default:
				break;
		}
	};

	return (
		<div
			className={`${variant == "left" ? "cursor-pointer" : ""}`}
			onClick={() => {
				if (data) {
					click(index);
				}
			}}
		>
			{data ? (
				<div
					className={`${
						data.nps && click
							? "rounded-lg hover:" +
							  getBGColorFromGrade(data.nps)
							: ""
					} flex flex-col  place-items-center relative  flex-none ${
						variant === "right" ? "flex-row" : ""
					}`}
				>
					{variant == "left" && (
						<div className="h-1 w-1 absolute top-0 -right-1 ">
							<IoMdClose
								className="text-sm"
								onClick={() => {
									assign(data, "User");
								}}
							></IoMdClose>
						</div>
					)}

					{img && (
						<img
							draggable="false"
							src={img}
							className={`flex justify-center items-center h-14 w-14  mt-2 border-4 ${
								data.nps
									? getColorFromGrade(data.nps)
									: "border-gray-200"
							}  ${
								variant == "left" ? "border-solid " : ""
							} rounded-full flex-none`}
						></img>
					)}
					{!img && (
						<div
							className={`flex justify-center items-center text-white h-14 w-14  mt-2 border-4 rounded-full flex-none 
						${data.nps ? getColorFromGrade(data.nps) : "border-gray-200"} 
						${getColorFromUser(data.first_name + data.last_name)}
					`}
						>
							{`${data.first_name[0]} ${data.last_name[0]}`}
						</div>
					)}
					{variant == "left" && (
						<p className="mt-2 justify-self-center  text-center whitespace-nowrap w-24 text-sm">
							{data.first_name + " " + data.last_name}
						</p>
					)}
				</div>
			) : (
				<div className="flex flex-col  place-items-center  flex-none">
					<div className="flex justify-center items-center bg-gray-100 border-4 mt-2  border-white  h-14 w-14   rounded-full flex-none">
						<IoAddOutline className="text-gray-400 text-2xl"></IoAddOutline>
					</div>
					<p className="mt-2 justify-self-center  text-center whitespace-nowrap w-24 text-sm">
						Select User
					</p>
				</div>
			)}
		</div>
	);
};
export default PeopleIcon;

import { ReactComponent as Salesforce } from "../assets/salesforce.svg";
export default (Id, tenant_url = "na123") => [
	{
		icon: <Salesforce style={{ width: 18, height: 18 }} />,
		title: "Open in Salesforce",

		command() {
			window
				.open(
					`https://${tenant_url}.lightning.force.com/lightning/r/Opportunity/${Id}/view`,
					"_blank"
				)
				.focus();
		},
	},
];

import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { AiOutlineEllipsis, AiOutlineLink } from "react-icons/ai";
import { BiPlus, BiTrash } from "react-icons/bi";

import {
	SimpleDropdown,
	ModalContainer,
	ConfirmModal,
	FadingModal,
} from "../..";
import Routes from "../../../Routes";

const Row = ({
	emoji,
	title,
	createdAt,
	onClick,
	onClickDelete,
	onClickCopy,
}) => {
	const [actionsDropdownOpen, setActionsDropdownOpen] = useState(null);

	const actionOptions = [
		{
			icon: <AiOutlineLink size={16} className="body-md-gray" />,
			label: "Copy Link",
			onClick() {
				onClickCopy();
			},
		},
		{
			icon: <BiTrash size={16} className="body-md-gray" />,
			label: "Delete Note",
			onClick() {
				onClickDelete();
			},
		},
	];

	return (
		<div className="flex flex-row w-full h-8 hover:bg-hovergray cursor-pointer rounded px-2">
			{/* Clickable Row */}
			<div
				className="flex flex-row flex-grow justify-between items-center"
				onClick={onClick}
			>
				{/* Left Side */}
				<div className="flex flex-row justify-start items-center h-full">
					{/* Icon */}
					<p className="flex justify-center items-center h-8 w-8 text-sm pr-1">
						{emoji}
					</p>
					{/* Title */}
					<p className="body-md-black">{title || "Untitled"}</p>
				</div>
				{/* Right Side */}
				<div className="flex flex-row justify-end items-center h-full">
					{/* Date */}
					<p className="body-md text-secondaryText">{createdAt}</p>
				</div>
			</div>

			{/* Button */}
			<div className="flex justify-center items-center w-8 h-8">
				<SimpleDropdown
					button={
						<AiOutlineEllipsis
							size={22}
							className="body-md text-secondaryText cursor-pointer"
						/>
					}
					open={actionsDropdownOpen}
					setOpen={setActionsDropdownOpen}
					options={actionOptions}
				/>
			</div>
		</div>
	);
};

const Notes = withRouter(
	({ history, notes, onClickNewNote, onClickDeleteNote }) => {
		const [confirmModalOpen, setConfirmModalOpen] = useState(false);
		const [selectedNote, setSelectedNote] = useState(null);
		const [copiedUrlModalOpen, setCopiedUrlModalOpen] = useState(false);

		const onClickCopyUrl = (valueToCopy) => {
			// Super hacky way of copying to clipboard
			const el = document.createElement("input");
			el.value = valueToCopy;
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		};

		const onClickCopy = (noteId) => {
			let noteUrl = `${window.location.origin}/cta/note/${noteId}`;
			onClickCopyUrl(noteUrl);
			setCopiedUrlModalOpen(true);
		};

		return (
			<div className="flex flex-col h-full w-full items-center overflow-scroll bg-lightgray rounded shadow-sm">
				<div className="w-full p-4">
					{notes &&
						notes.map((note) => (
							<Row
								key={note.id}
								emoji={note.emoji}
								title={note.title}
								createdAt={note.time_created}
								onClickDelete={() => {
									setSelectedNote({
										ctaId: note.cta_id,
										companyId: note.company_id,
										noteId: note.id,
									});
									setConfirmModalOpen(true);
								}}
								onClickCopy={() => onClickCopy(note.id)}
								onClick={() =>
									history.push({
										pathname: `${Routes.NOTES_BASE_ROUTE}/${note.id}`,
										state: { note },
									})
								}
							/>
						))}
					<div
						className="flex flex-row justify-start items-center w-full h-8 px-2 hover:bg-hovergray cursor-pointer rounded body-md"
						onClick={onClickNewNote}
					>
						{/* Plus Icon */}
						<p className="flex justify-center items-center h-8 w-8 text-sm pr-1">
							<BiPlus size={20} />
						</p>

						{/* Prompt */}
						<p>New note</p>
					</div>
				</div>

				<ModalContainer>
					{confirmModalOpen && (
						<ConfirmModal
							title="Are you sure you want to delete this note?"
							buttonLabel="Yes, I'm sure"
							onClick={() => {
								onClickDeleteNote(
									selectedNote.ctaId,
									selectedNote.companyId,
									selectedNote.noteId
								);
								setConfirmModalOpen(false);
							}}
							setModalOpen={setConfirmModalOpen}
						/>
					)}
					{copiedUrlModalOpen && (
						<FadingModal
							message="Note URL copied to clipboard"
							fadeSeconds={3}
							setOpen={setCopiedUrlModalOpen}
						/>
					)}
				</ModalContainer>
			</div>
		);
	}
);

export default Notes;
